import { StyleSheet, Dimensions } from 'react-native';


// 🇬🇧 Get the screen width from the device dimensions.
// 🇫🇷 Récupérer la largeur de l'écran à partir des dimensions du dispositif.
const { width } = Dimensions.get('window');


export default StyleSheet.create({
    screenOptionsStyles: {
        tabBarStyle: {
            height: '10%', // 🇬🇧 Adjusted height for mobile devices. 🇫🇷 Hauteur ajustée pour les appareils mobiles.
            backgroundColor: '#59C09B', // 🇬🇧 Background color for inactive elements. 🇫🇷 Couleur de fond pour les éléments inactifs.
        },
        tabBarItemStyle: {
            flexDirection: "column", // 🇬🇧 Ensures icons and texts are one above the other. 🇫🇷 Assure que les icônes et les textes sont l'un au-dessus de l'autre.
            alignItems: 'center', // 🇬🇧 Centers items horizontally. 🇫🇷 Centre les éléments horizontalement.
            justifyContent: 'center', // 🇬🇧 Centers items vertically. 🇫🇷 Centre les éléments verticalement.
            width: width / 5, // 🇬🇧 Ensures each tab has equal width, adapted to the screen width. 🇫🇷 Assure que chaque onglet ait une largeur égale, adaptée à la largeur de l'écran.
        },
        tabBarActiveTintColor: "white", // 🇬🇧 Text color for the active tab. 🇫🇷 Couleur du texte pour l'onglet actif.
        tabBarInactiveTintColor: "white", // 🇬🇧 Text color for the inactive tabs. 🇫🇷 Couleur du texte pour les onglets inactifs.
        tabBarActiveBackgroundColor: "#F48225", // 🇬🇧 Background color for the active tab. 🇫🇷 Couleur de fond pour l'onglet actif.
        tabBarInactiveBackgroundColor: "#59C09B", // 🇬🇧 Background color for the inactive tabs. 🇫🇷 Couleur de fond pour les onglets inactifs.
    },
    tabScreenOptions: {
        tabBarIconStyle: {
            width: 25, // 🇬🇧 Fixed width for all icons for uniformity. 🇫🇷 Largeur fixe pour toutes les icônes pour uniformité.
            height: 25, // 🇬🇧 Fixed height for all icons. 🇫🇷 Hauteur fixe pour toutes les icônes.
        },
        tabBarLabelStyle: {
            fontSize: 12, // 🇬🇧 Font size adapted for mobile devices. 🇫🇷 Taille de police adaptée pour les appareils mobiles.
            margin: 0, // 🇬🇧 Removes margins around the text. 🇫🇷 Supprime les marges autour du texte.
            padding: 0, // 🇬🇧 Removes padding around the text. 🇫🇷 Supprime le padding autour du texte.
        },
    },
});
