// 🇫🇷 Ce fichier gère la page qui verifie la présence du code de parrainage donnée dans la base de donnée et du formatage d'un code de parrainage (FrameFigma004B)
// 🇬🇧 This file manages the page that checks the presence of the given sponsorship code in the database and formats a sponsorship code (FrameFigma004B).
import React, {useState} from 'react';
import {Text, View, Platform, Alert} from 'react-native';
import {hostname} from '../../../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';
import TextInputCustom from '../../../components/TextInputCustom/TextInputCustom';
import ButtonNext from '../../../components/ButtonNext/ButtonNext';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useError } from '../../../contexts/ErrorContext';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const Sponsorship = ({ navigation }) => {
  const [displaySponsorshipNumber, setDisplaySponsorshipNumber] = useState('');
  const [sponsorshipNumber, setSponsorshipNumber] = useState('');
  const [isButtonActive, setIsButtonActive] = useState(true);
  const { translations } = useLanguage();
  const { error, setError } = useError();

  const formatSponsorshipNumber = text => {
    // Réinitialiser l'erreur dès que l'utilisateur commence à taper
    if (error) {
      setError('');
    }

    // Convertir en majuscules et nettoyer le texte
    let upperText = text.toUpperCase().replace(/[^0-9A-Z]/g, '');

    // Extraire les 3 premiers chiffres
    let digits = upperText.slice(0, 3).replace(/[^0-9]/g, '');
    // Extraire les 2 lettres suivantes en majuscule, s'assurant qu'elles sont des lettres
    let letters = upperText.slice(digits.length).replace(/[^A-Z]/g, '').slice(0, 2);
    let formattedDisplayNumber = digits + (digits.length === 3 && letters ? ' ' : '') + letters;

    // Mise à jour pour l'affichage avec espace
    setDisplaySponsorshipNumber(formattedDisplayNumber);

    // Garder une version sans espace pour l'envoi
    setSponsorshipNumber(digits + letters);
    if (upperText.length >= 5 || upperText.length === 0) {
      setIsButtonActive(true);
    }else {
      setIsButtonActive(false);
    }
  };

  const handlePaste = event => {
    const pastedText = event.clipboardData.getData('Text');
    formatSponsorshipNumber(pastedText);
  };

  const verifySponsorshipCode = async () => {
    if (!sponsorshipNumber.trim()) {
      navigation.navigate('NewUserPassword');
      return;
    }
    try {
      const response = await fetch(`${hostname}/verifySponsorshipCode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({memberId: sponsorshipNumber}),
      });
      const data = await response.json();

      if (data.isValid) {
        // Stocker l'état de validité et l'ID du parrain (sponsorId) dans AsyncStorage
        await AsyncStorage.setItem('isSponsorshipCodeValid', 'true');
        if (data.sponsorId) {
          await AsyncStorage.setItem('sponsorId', data.sponsorId);
        }

        // Naviguer vers NewUserPassword avec l'état de validité du code de parrainage
        navigation.navigate('NewUserPassword', {sponsorshipNumberValid: true});
      } else {
        await AsyncStorage.setItem('isSponsorshipCodeValid', 'false');
        setError(translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipInvalid);
      }
    } catch (error) {
      console.error(error);
      Alert.alert('Error', translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipError);
    }
  };

  return (
    <View style={styles.container}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <Text style={styles.title}>{translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipInformation}</Text>
      <View style={styles.containerInput}>
        <TextInputCustom
          placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonMemberShip}
          value={displaySponsorshipNumber}
          handleTextChange={formatSponsorshipNumber}
          onPaste={handlePaste}
          isPlaceHolderTextAlignCenter={false}
        />
      </View>
      <View style={styles.containerBtn}>
        <ButtonNext handleNextStep={verifySponsorshipCode} btnText={translations.JsonLoginRegisterPages.JsonEmailScreen.JsonNextButton} conditionNeed={isButtonActive} />
      </View>
    </View>
  );
};

export default Sponsorship;

