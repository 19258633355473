import React from 'react';
import {View, Text, Pressable, Linking} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';

const InviteByTikTok = () => {
  const {translations} = useLanguage();

  const inviteByTikTok = () => {
    // Note: Direct app linking to TikTok for sharing is limited.
    // This example just attempts to open the TikTok app.
    const tiktokLink = 'tiktok://';
    Linking.openURL(tiktokLink);
  };

  return (
    <View>
      <Pressable onPress={inviteByTikTok}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteTiktok}</Text>
      </Pressable>
    </View>
  );
};

export default InviteByTikTok;
