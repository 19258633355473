//Fr Styles de UserListHozontal (FrameFigma044)
//En Styles of UserListHozontal (FrameFigma044)


import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  listContainer: {
    alignItems: 'center',
    justifyContent: 'top',
    width: '90%',
    backgroundColor: 'white',
  },
});

export default styles