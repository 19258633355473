// Styles Web pour ProfileActivitiesScreen
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    // gap: 20,
    flex: 1,
    width: '100%',
  },
  tabsContainer: {
    paddingHorizontal: 1,
    gap: '15%',
  },
  greyBar: {
    marginTop: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 25,
    backgroundColor: '#797B7A',
  },
  greyBarText: {
    color: 'white',
    fontSize:16,
    fontWeight:600,
  },
  bottomIcons: {
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  notifCount:{
    borderRadius:15,
    color:'white',
    backgroundColor:'red',
    position:'absolute',
    width:13,
    height:13,
    fontSize:8,
    textAlign:'center',
    right:0,
    justifyContent:'center'
  },
})

export default styles;