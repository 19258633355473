import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  activityCard: {
    marginVertical: 15,
    marginHorizontal: 10,
    height: 100,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: 'white',
    boxShadow: '0px 4px 2px rgba(0, 0, 0, 0.3)',
  },
  activityCardLiked: {
    backgroundColor: '#FEEBEF',
  },
  activityCardParticipant: {
    backgroundColor: '#D5EFE6',
  },
  activityCardWaitingList: {
    backgroundColor: '#FFF1C9',
  },
  leftSide: {
    height: 100,
    width: 123,
  },
  activityImage: {
    width: 124,
    height: 70,
  },
  imageContainer: {
    width: 124,
    height: '100%',
  },
  activityImageOne: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 20,
    resizeMode: 'contain',
  },
  componentHeart: {
    position: 'absolute',
    top: 4,
    left: 5,
    width: 12,
    height: 12,
  },
  rightSide: {
    width: '70%',
    padding: 10,
  },
  centerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  activityTitle: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  activityStartTime: {
    fontWeight: 'bold',
    color: '#F48A0D',
  },
  authorImageView: {
    paddingBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  authorImage: {
    width: 45,
    height: 45,
    borderRadius: 50,
    justifyContent: 'center',
  },
  bottomItem: {
    flex: 1,
    flexDirection: 'row',
  },
  bottomItemCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textParticipants: {
    marginLeft: 5,
    fontWeight: 'bold',
  },
  activityBottomTitle: {
    color: '#4D96E9',
    fontWeight: 'bold',
  },
  userStatusTag: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 124,
    height: 30,
    borderBottomLeftRadius: 20,
    participant: {
      backgroundColor: '#59C09B',
    },
    waitingList: {
      backgroundColor: '#FFC107',
    },
    topic: {
      backgroundColor: '#000000',
    },
    text: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 18,
    },
  },
});
