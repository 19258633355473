//🇫🇷 cette page sert a gerer les autorisation (FrameFigma012-FrameFigma015)
//🇬🇧 This page is used to manage permissions (FrameFigma012-FrameFigma015)

import {PermissionsAndroid, Platform} from 'react-native';

//🇫🇷 Demande plusieurs permissions en une seule fois (caméra et écriture externe)
// 🇬🇧 Requests multiple permissions at once (camera and external write)
export const checkCameraAndStoragePermission = async () => {
  if (Platform.OS === 'android' && Platform.Version < 33) {
    const granted = await PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.CAMERA,
      PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    ]);

    // 🇬🇧 Checks if camera and external write permissions have been granted. Displays console messages accordingly
    //🇫🇷 Vérifie si les permissions de la caméra et de l'écriture externe ont été accordées. Affiche des messages de console en conséquence

    if (
      granted[PermissionsAndroid.PERMISSIONS.CAMERA] !== 'granted' ||
      granted[PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE] !==
        'granted'
    ) {
      console.error('Permission refusée');
      return false;
    } else {
      console.info('Vous pouvez utiliser la caméra et accéder au stockage');
      return true;
    }
  }

  // 🇫🇷 Gérer Android 11 et versions ultérieures où WRITE_EXTERNAL_STORAGE n'est pas requis
  // 🇬🇧 Handle Android 11 and later versions where WRITE_EXTERNAL_STORAGE is not required
  if (Platform.OS === 'android' && Platform.Version >= 33) {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.CAMERA,
      {
        title: 'Permission de Caméra',
        message: "Cette application a besoin d'accéder à votre caméra",
        buttonNeutral: 'Demander Plus Tard',
        buttonNegative: 'Annuler',
        buttonPositive: 'OK',
      },
    );

    if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
      console.error('Permission de caméra refusée');
      return false;
    } else {
      console.info('Vous pouvez utiliser la caméra');
      return true;
    }
  }
};

export default checkCameraAndStoragePermission;