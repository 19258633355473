import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1, // 🇫🇷 Utilise tout l'espace disponible de manière flexible
    backgroundColor: 'white', // 🇫🇷 Couleur de fond de la page
  },
  scrollViewContainer: {
    // 🇫🇷 Pas besoin de styles spécifiques ici, mais vous pouvez ajouter des marges si nécessaire
  },
  imgViewContainer: {
    width: '100%', // 🇫🇷 L'image de fond occupe toute la largeur
    height: 200, // 🇫🇷 Hauteur fixe pour l'image de fond
    backgroundColor: '#ccc', // 🇫🇷 Couleur de fond en cas de non-chargement de l'image
  },
  title: {
    fontSize: 20, // 🇫🇷 Taille de la police pour le titre
    fontWeight: '800', // 🇫🇷 Poids de la police
    textAlign: 'center', // 🇫🇷 Alignement du texte
    marginTop: 20, // 🇫🇷 Marge du haut
    marginBottom: 20, // 🇫🇷 Marge du bas
  },
  txt: {
    textAlign: 'center', // 🇫🇷 Alignement du texte
    fontSize: 15, // 🇫🇷 Taille de la police
    fontWeight: '700', // 🇫🇷 Poids de la police
    marginBottom: 20, // 🇫🇷 Marge du bas pour espacer les sections
    width: '80%', // 🇫🇷 Largeur du texte pour le centrer
    alignSelf: 'center', // 🇫🇷 Alignement au centre de son conteneur
  },
  ButtonFB: {
    backgroundColor: '#3B5998', // 🇫🇷 Couleur de fond du bouton Facebook
    alignSelf: 'center', // 🇫🇷 Centre le bouton dans le conteneur
    borderRadius: 10, // 🇫🇷 Bordures arrondies
    flexDirection: 'row', // 🇫🇷 Disposition horizontale des éléments intérieurs
    height: 50, // 🇫🇷 Hauteur fixe
    width: '60%', // 🇫🇷 Largeur relative à son conteneur
    justifyContent: 'center', // 🇫🇷 Centre les éléments horizontalement
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement
    marginTop: 20, // 🇫🇷 Marge du haut
    marginBottom: 20, // 🇫🇷 Marge du bas
  },
  buttonTextFB: {
    color: 'white', // 🇫🇷 Couleur du texte
    fontWeight: '700', // 🇫🇷 Poids de la police
    marginLeft: 10, // 🇫🇷 Marge à gauche pour espacer l'icône du texte
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    textAlign: 'center',
  },
  eventRedirectButton: {
    backgroundColor: '#59c09b', // 🇫🇷 Couleur de fond du bouton d'activité
    alignSelf: 'center', // 🇫🇷 Centre le bouton dans le conteneur
    borderRadius: 10, // 🇫🇷 Bordures arrondies
    justifyContent: 'center', // 🇫🇷 Centre les éléments horizontalement
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement
    marginTop: 20, // 🇫🇷 Marge du haut
    marginBottom: 50, // 🇫🇷 Marge du bas
    height: 50, // 🇫🇷 Hauteur fixe
    width: '60%', // 🇫🇷 Largeur relative à son conteneur
  },
  newslist: {
    flexDirection: 'row', // 🇫🇷 Éléments disposés horizontalement
    justifyContent: 'flex-start', // 🇫🇷 Alignement au début du conteneur
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement dans la ligne
    paddingLeft: 25, // 🇫🇷 Padding à gauche
    paddingRight: 25, // 🇫🇷 Padding à droite
    paddingBottom: 15, // 🇫🇷 Padding en bas
  },
  helpParagraph: {
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement
    marginTop: 20, // 🇫🇷 Marge du haut
    marginBottom: 20, // 🇫🇷 Marge du bas
    paddingLeft: 25, // 🇫🇷 Padding à gauche pour l'alignement
    paddingRight: 25, // 🇫🇷 Padding à droite
  },
  img: {
    width: 50, // 🇫🇷 Largeur fixe pour les icônes
    height: 50, // 🇫🇷 Hauteur fixe pour les icônes
    marginRight: 20, // 🇫🇷 Marge à droite pour espacer l'icône du texte
    marginTop: -25, // 🇫🇷 Marge en haut pour superposer l'icône sur le texte
  },
  row: {
    flexDirection: 'row', // 🇫🇷 Éléments disposés horizontalement
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement
  },
});

export default styles;
