import React, {useState} from 'react';
import {SafeAreaView, ScrollView, Text, Pressable, View, Platform} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {useLanguage} from '../../contexts/LanguageContext';
import {Images} from '../../constants/Images';
// Importez les composants spécifiques pour chaque type d'invitation
import InviteByMessenger from './SocialInvite/InviteByMessenger';
import InviteByWhatsApp from './SocialInvite/InviteByWhatsApp';
import InviteBySMS from './SocialInvite/InviteBySMS';
import InviteByTwitter from './SocialInvite/InviteByTwitter';
import InviteByInstagram from './SocialInvite/InviteByInstagram';
import InviteByTikTok from './SocialInvite/InviteByTikTok';
import InviteBySnapchat from './SocialInvite/InviteBySnapchat';
import InviteByLink from './SocialInvite/InviteByLink';
import InviteByEmail from './SocialInvite/InviteByEmail';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const InviteFriends = ({route}) => {
  const {translations} = useLanguage();
  const navigation = useNavigation();
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const openEmailModal = () => {
    setEmailModalVisible(true);
  };
  const user = route.params?.user
  const userId = route.params?.userId
  const userToken = route.params?.userToken
  // Définition des boutons sociaux avec les composants correspondants
  const socialButtons = [
    {Icon: Images.Email, text: translations.JsonInviteFriendsScreen.JsonInviteEmail, action: () => setEmailModalVisible(true)},
    {Icon: Images.Messenger, Component: InviteByMessenger, text: translations.JsonInviteFriendsScreen.JsonInviteFacebook},
    {Icon: Images.PhoneMsg, Component: InviteBySMS, text: translations.JsonInviteFriendsScreen.JsonInviteTextMessage},
    {Icon: Images.Whatsapp, Component: InviteByWhatsApp, text: translations.JsonInviteFriendsScreen.JsonInviteWhatsapp},
    {Icon: Images.Instagram, Component: InviteByInstagram, text: translations.JsonInviteFriendsScreen.JsonInviteInstagram},
    {Icon: Images.Snapchat, Component: InviteBySnapchat, text: translations.JsonInviteFriendsScreen.JsonInviteSnapchat},
    {Icon: Images.Twitter, Component: InviteByTwitter, text: translations.JsonInviteFriendsScreen.JsonInviteTwitter},
    {Icon: Images.Tiktok, Component: InviteByTikTok, text: translations.JsonInviteFriendsScreen.JsonInviteTiktok},
    {Icon: Images.Link, Component: InviteByLink, text: translations.JsonInviteFriendsScreen.JsonInviteWithLink},
  ];

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{backgroundColor: 'white'}}>
        <View style={styles.inviteStyle}>
          <View style={styles.friendship}>
            <Images.Friendship width="100" height="100" />
          </View>
          <View style={styles.inviteFriendsView}>
            <Text style={styles.inviteFriendsStyle}>{translations.JsonInviteFriendsScreen.JsonInviteFriendsTitle}</Text>
          </View>
          {socialButtons.map(({Icon, text, action}, index) => (
            <Pressable key={index} style={styles.social} onPress={action}>
              <View style={styles.IconStyles}>
                <Icon width="50" height="50" />
              </View>
              <Text style={styles.txt}>{text}</Text>
            </Pressable>
          ))}
        </View>
        <InviteByEmail userId={userId} user={user} userToken={userToken} emailModalVisible={emailModalVisible} setEmailModalVisible={setEmailModalVisible} />
      </ScrollView>
    </SafeAreaView>
  );
};

export default InviteFriends;
