import React, { useState, useLayoutEffect, useEffect } from 'react';
import { View, Pressable, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Fields from '../../../components/Fields/Fields';
import { useLanguage } from '../../../contexts/LanguageContext';

import MyProfileInfoScreen from './ProfileInfoScreen/ProfileInfoScreen';
import MyProfileActivitiesScreen from './ProfileActivitiesScreen/ProfileActivitiesScreen';
import MyProfileNetworkScreen from './ProfileNetworkScreen/ProfileNetworkScreen';

const MyProfileBottomNavigator = ({ userId, user, navigation, allUsers, profileOwner, userActivities }) => {
    const [screen, setScreen] = useState('Info');
    const { translations } = useLanguage();

    const handleScreen = (screenName) => {
        if (screenName === 'Info') {
            setScreen('Info');
        }
        else if (screenName === 'Activities') {
            setScreen('Activities');
        }
        else if (screenName === 'Network') {
            setScreen('Network');
        }
    };

    const renderScreen = () => {
        switch (screen) {
            case 'Info':
                return <MyProfileInfoScreen profileOwner={profileOwner} />;
            case 'Activities':
                return <MyProfileActivitiesScreen profileOwner={profileOwner} allUsers={allUsers} userActivities={userActivities} />;
            case 'Network':
                return <MyProfileNetworkScreen profileOwner={profileOwner} allUsers={allUsers} userActivities={userActivities} />;
            default:
                return <MyProfileInfoScreen profileOwner={profileOwner} />;
        }
    };

    return (
        <View>
            <View style={styles.menuContainer}>
                <Pressable style={[styles.button, screen === 'Info' && { backgroundColor: '#59c09b' }]} onPress={() => handleScreen('Info')}>
                    <Text style={styles.text}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonInformations}</Text>
                </Pressable>

                <Pressable style={[styles.button, screen === 'Activities' && { backgroundColor: '#59c09b' }]} onPress={() => handleScreen('Activities')}>
                    <Text style={styles.text}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonActivities}</Text>
                </Pressable>

                <Pressable style={[styles.button, screen === 'Network' && { backgroundColor: '#59c09b' }]} onPress={() => handleScreen('Network')}>
                    <Text style={styles.text}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}</Text>
                </Pressable>
            </View>
            <View>{renderScreen()}</View>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    menuContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "gray",
        height:'45px',
        marginTop:10,
        marginBottom:10,
    },
    button: {
        flex: 1,
        padding: 10,
        justifyContent:"center",
        alignItems: "center",
        borderRightColor:"white",
        borderLeftColor:"white",
        borderRightWidth:1,
        borderLeftWidth:1,
        borderTopWidth:0,
        borderBottomWidth:0,
    },
    text: {
        color: "white",
    },
});

export default MyProfileBottomNavigator;
