// Import Modules
import React from 'react';
import { View, Text, Pressable, Platform } from 'react-native';

// Import Contexts
import { useLanguage } from "../../../contexts/LanguageContext";

// Import Icons
import { Icons } from "../../../constants/Icons";

// Import Styles
const styles =
  Platform.OS === 'web'
  // Attention - ce fichier utilise les styles de parent BecomeCoOrganiser
    ? require('../StylesWeb').default
    : require('../StylesMobile').default;

// Logique de component commence ici --------------------------------------------------
const ConfirmationModal = ({ message, onClose }) => {

  // Contexts
  const { translations } = useLanguage();
  const t = translations.JsonConfirmFriendshipPopup

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Icons.Checkbox width={40} height={40} />
        <Text>
          {message}
        </Text>
      </View>
      <View>
        <Pressable onPress={onClose}>
          <Text>{t.JsonButtonClose}</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default ConfirmationModal