const handleFriendAdded = (socket, addNotification) => {
  socket.on('friendAdded', data => {
    const notification = {
      type: 'friendAdded',
      message: `Nouvel ami : ${data.from}. ${data.message}`,
      from: data.from,
      to: data.to,
    };
    addNotification(notification);
    // Vous pouvez ajouter ici une logique pour mettre à jour l'état des amis si nécessaire
  });

  // Gérer la suppression d'un ami (si cet événement est implémenté côté serveur)
  socket.on('friendRemoved', data => {
    const notification = {
      type: 'friendRemoved',
      message: `Ami supprimé : ${data.from}`,
      from: data.from,
      to: data.to,
    };
    addNotification(notification);
    // Mettre à jour l'état pour supprimer l'ami
    removeFriend(data.from); // Implémentez cette fonction selon vos besoins
  });
};

export default handleFriendAdded;
