import React from 'react';
import PhoneInput from 'react-phone-input-2';

const PhoneNumberInput = ({ value, onPhoneNumberChange }) => {
  // Styles en ligne pour customiser le PhoneInput
  const customStyles = {
    container: {
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      marginBottom: '10px',
      width: '100%',
      maxWidth: '400px', // Ajustez cette valeur selon la largeur souhaitée
    },
    input: {
      width: '100%',
      padding: '10px',
    },
    button: {
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
    dropdownContainer: {
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    searchBox: {
      padding: '10px',
      margin: '10px 0',
      border: '1px solid #ccc',
    },
  };

  return (
    <PhoneInput
      country={'fr'}
      value={value}
      onChange={onPhoneNumberChange}
      containerStyle={customStyles.container}
      inputStyle={customStyles.input}
      buttonStyle={customStyles.button}
      dropdownContainer={customStyles.dropdownContainer}
      searchStyle={customStyles.searchBox}
    />
  );
};

export default PhoneNumberInput;
