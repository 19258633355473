// Fr Cette page affiche l'étape 1 pour créer une activité(FrameFigma031)
// En This page displays the first step to create an activity(FrameFigma031)
import { View, ScrollView, Text, Pressable, Platform, TextInput,TouchableOpacity } from 'react-native';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import DateTimePickerComponent from '../../../../components/DateTimePicker/DateTimePickerMobile/DateTimePickerMobile';
import DateTimePickerModal from '../../../../components/DateTimePicker/DateTimePickerWeb/DateTimePickerWeb';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext'
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom'
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import { useNavigation } from '@react-navigation/native';
import Fields from '../../../../components/Fields/Fields';
//Import du context error
import { useError } from '../../../../contexts/ErrorContext';

const styles =
    Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;

const ClassicStepOne = ({ onNextStep }) => {
    
    const {error, setError} = useError(); //On defini le context dans le code
    const { translations } = useLanguage();
    const navigation = useNavigation();

    const [selectedQuestion, setSelectedQuestion] = useState('classic');
    const [isFocused, setIsFocused] = useState(false);
    const [switchBtn, setSwitchBtn] = useState(false);
    const [switchBtn1, setSwitchBtn1] = useState(false);
    const [switchBtn2, setSwitchBtn2] = useState(false);
    const [activityTitle, setActivityTitle] = useState('');
    const [attendeesValidation, setAttendeesValidation] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        AsyncStorage.setItem('selectedQuestion', selectedQuestion);
        AsyncStorage.setItem('switchBtn', JSON.stringify(switchBtn));
        AsyncStorage.setItem('switchBtn1', JSON.stringify(switchBtn1));
        AsyncStorage.setItem('switchBtn2', JSON.stringify(switchBtn2));
        AsyncStorage.setItem('activityTitle', activityTitle);
        AsyncStorage.setItem('attendeesValidation', attendeesValidation);
    }, [selectedQuestion, switchBtn, switchBtn1, switchBtn2, activityTitle, attendeesValidation]);

    useEffect(() => {
        // Fonction de nettoyage pour supprimer les données du AsyncStorage lorsque la page est déchargée
        const cleanup = async () => {
            await AsyncStorage.removeItem('selectedQuestion');
            await AsyncStorage.removeItem('switchBtn');
            await AsyncStorage.removeItem('switchBtn1');
            await AsyncStorage.removeItem('switchBtn2');
            await AsyncStorage.removeItem('activityTitle');
            await AsyncStorage.removeItem('attendeesValidation');
        };

        cleanup();

    }, []);

    // Fonction pour gérer le changement du titre de l'activité
    const handleActivityTitleChange = (text) => {
        const trimmedText = text.slice(0, 60);  
        setActivityTitle(trimmedText);
        setError(''); //Quand le texte change on vide le useError pour ne plus afficher d'erreur
    };

    const handleQuestionPress = option => {
        setSelectedQuestion(option)
    };

    //Cette fonction permet de vérifier les champs de texte obligatoire et d'ajouter le message d'erreur dans le setError 
    const validateFields = () => {
        let isValid = true;
        let errorMessage = '';

        if (!activityTitle && selectedDate === null) {
            isValid = false;
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTimeErrorMessage;
        } else if (!activityTitle) {
            isValid = false;
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonTitleErrorMessage;
        } else if (selectedDate === null) {
            isValid = false;
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateErrorMessage;
        }

        setError(errorMessage);
        setIsErrorVisible(!isValid);
        return isValid;
    };

    //Si isValid est true on peut passer à l'étape d'après
    const handleNextStep = () => {
        const isValid = validateFields();
        if (isValid) {
            onNextStep(selectedQuestion); // Passer à l'étape suivante
        }
    };


    const handleAttendeeValidationPress = option => {
        setAttendeesValidation(option)
    };



    return (
        <View style={styles.container}>
            {/*Cette condition vérifie si error est non null pour afficher le message d'erreur*/}
            {error ? (
                <View style={styles.headerError}>
                <Text style={styles.headerErrorText}>{error}</Text>
                </View>
            ) : null}
            <ScrollView style={styles.scrollContainer}>
                <View style={styles.textTitleOne}>
                    <Text style={styles.textTitleOne}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonWhatKindOfActivityToCreate}</Text>
                </View>
                <View style={styles.questionContainer}>
                    <Pressable
                        style={() => [
                            styles.classic,
                            selectedQuestion === 'classic' && styles.activeQuestion,
                        ]}
                        onPress={() => handleQuestionPress('classic')}
                    >
                        <Text style={styles.textQuestion}> Classic </Text>
                    </Pressable>
                    <Pressable
                        disabled='true'
                        style={() => [
                            styles.withStep,
                            selectedQuestion === 'withStep' && styles.activeQuestion,
                        ]}
                        onPress={() => handleQuestionPress('withStep')}
                    >
                        <Text style={styles.textQuestion}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonActivitiesItinerary}</Text>
                    </Pressable>
                </View>
                <View>
                    <Text style={styles.titleA}>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitle + " :"}</Text>
                </View>
                <View>
                    <TextInputCustom
                        placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitlePlaceholder}
                        handleTextChange={handleActivityTitleChange}
                        value={activityTitle}
                        multiline={true}
                        numberOfLines={2}
                        maxLength={60}
                    />
                </View>
                <View style={styles.dateContainer}>
                    {Platform.OS === 'web' ?
                        <DateTimePickerModal
                            title={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTime}
                            onDateChange={(date) => {setSelectedDate(date),setError('');}}
                        /> : <DateTimePickerComponent
                            title={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTime}
                            onDateChange={(date) => {setSelectedDate(date),setError('');}}
                        />
                    }
                </View>
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonAddActivityEnd}</Text>
                    <SwitchBtn
                        state={switchBtn}
                        setState={setSwitchBtn}
                    />
                </View>
                {/* {switchBtn && 
                <TimePicker title= {'hour: '} />
            } */}
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonLocalisationOnlyForAttendees}</Text>
                    <SwitchBtn
                        state={switchBtn1}
                        setState={setSwitchBtn1}
                    />
                </View>
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonInviteYourCommunity}</Text>
                    <SwitchBtn
                        state={switchBtn2}
                        setState={setSwitchBtn2}
                    />
                </View>
                <View style={styles.textTitleOne}>
                    <Text style={styles.textTitleOne}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidation}</Text>
                </View>
                <View style={styles.questionContainer}>
                    <Pressable
                        style={[
                            styles.classic,
                            attendeesValidation == false && styles.activeQuestion,
                        ]}
                        onPress={() => handleAttendeeValidationPress(false)}
                    >
                        <Text style={styles.textQuestion}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationAutomatic}</Text>
                    </Pressable>
                    <Pressable
                        onPress={() => handleAttendeeValidationPress(true)}
                        style={[
                            styles.withStep,
                            attendeesValidation == true && styles.activeQuestion,
                        ]}
                    >
                        <Text style={styles.textQuestion}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationManual}</Text>
                    </Pressable>
                </View>
                <View style={styles.btnContainer}>
                    <ButtonNext
                        handleNextStep={handleNextStep}
                        conditionNeed={activityTitle && selectedDate !== null}
                        btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}
                    />
                </View>
            </ScrollView>
        </View>
    )
    
}

export default ClassicStepOne;
