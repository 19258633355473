// Fr  Styles de CopyActivityStep3Screen(FrameFigma037A)
// En  Styles of CopyActivityStep3Screen(FrameFigma037A) 
import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
    },
    addPhoto: {
        marginLeft: 10,
    },
    imageContainer: {
        marginBottom: 10,
    },
    choosePhoto: {
        backgroundColor: "#DCF1EA",
        padding: 25,//modifier le hauteur ici
        borderRadius: 15,
        marginVertical: 10,
        marginLeft: 5,
        marginRight: 10,
    },
    btnChoose: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageStyle: {
        width: '90%', // Assurez-vous que la largeur de 'choosePhoto' est définie
        height: 200, // Hauteur fixe, ou 'auto' si vous préférez
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    field: {
        margin: 5,
        backgroundColor: 'white',
        borderColor: '#9A9A9A',
        borderWidth: 9,
        borderRadius: 50,
        paddingHorizontal: 10,
        paddingTop: 9,
        paddingBottom: 11,
    },
    btnChooseText: {
        fontWeight: 700
    },
    title: {
        fontWeight: "bold",
        alignItems: 'center',
        fontSize: 16,
        marginVertical: 10,
    },
    inputContainer: {
        marginVertical: 10,
    },
    textContainer: {
        position: "absolute",
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        left: 25,
        top: -11,
    },
    multiline: {
        borderWidth: 1,
        borderRadius: 15,
        verticalAlign: "top",
        padding: 15,
    },
    btnsRow: {
        flexDirection: 'row',
        width: '100%',
        alignContent: 'center',
    },
    btnContainer: {
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        marginHorizontal: 50,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    btnText: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
})