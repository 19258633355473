//Fr Styles de ButtonNext (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma036) (FrameFigma037A) (FrameFigma031) (FrameFigma035A,FrameFigma035B) (FrameFigma036) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
//En Styles of ButtonNext (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma036) (FrameFigma037A) (FrameFigma031) (FrameFigma035A,FrameFigma035B) (FrameFigma036) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  btnNext: {
    backgroundColor: '#59C09B',
    width: '48%',
    paddingVertical: 15,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  btnText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    paddingHorizontal: 2,
  },
});

export default styles;
