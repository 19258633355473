// Fr Ce component affiche tout en bas de la page les boutons pour gérer les activités en fonction de si c'est un organisateur(modifier ou supprimer) ou un participant (FrameFigma041,FrameFigma042)
// En This component displays on the down bottom of the page the buttons to manage the activity if you are de organizer(edit or delete) or the participant (FrameFigma041,FrameFigma042)

// Import Modules
import React, { useState } from 'react'
import { View, Text, Platform, Pressable } from 'react-native'

// Import Components
import Modal from "../../../../../components/Modal/Modal";
import CopyToCreateActivity from "./CopyToCreateActivity/CopyToCreateActivity";
import DeleteActivity from "./DeleteActivity/DeleteActivity";

// Import Contexts / Hooks / Utils
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { useNavigation } from '@react-navigation/native';

// Import Styles
const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const ActivityFooterButtons = ({ activity, userStatus, handleCoOrgModal }) => {
  // Contexts
  const { translations } = useLanguage();
  const navigation = useNavigation();

  // States
  const [modalVisible, setModalVisible] = useState(false);
  const [pressedButton, setPressedButton] = useState(null);

  // Handle navigation to Edit Activity
  const handleEditActivity = () => {
    navigation.navigate('EditActivity', { activityId: activity._id });
  }

  // Handle Button Press
  const handleButtonPress = (button) => {
    setPressedButton(button);
    setModalVisible(true);
  }

  return (
    <>
      <View style={styles.btnContainer}>

        {/* Les Boutons pour les Organisateurs */}
        {userStatus.author ? (
          <>
            <View style={styles.btnContent}>
              {/* <Pressable style={styles.btnHelp} onPress={() => handleButtonPress('edit')}> */}
              <Pressable style={styles.btnHelp} onPress={() => handleEditActivity()}>
                <Text style={styles.btnHelpText}>{translations.JsonActivityPages.JsonActivityScreen.JsonEditActivity}</Text>
              </Pressable>
            </View>
            <View style={styles.btnContent}>
              <Pressable style={[styles.btnHelp, { backgroundColor: 'red' }]} onPress={() => handleButtonPress('delete')}>
                <Text style={styles.btnHelpText}>{translations.JsonActivityPages.JsonActivityScreen.JsonDeleteActivity}</Text>
              </Pressable>
            </View>
          </>
        ) : (
          <>

          {/* Les Boutons pour les Participants / Co-Organisateurs */}
            <View style={styles.btnContent}>
              <Pressable style={styles.btnHelp} onPress={handleCoOrgModal}>
                <Text style={styles.btnHelpText}>{translations.JsonActivityPages.JsonActivityScreen.JsonHelpAndOrganisation}</Text>
              </Pressable>
            </View>
            <View style={styles.btnContent}>
              <Pressable style={styles.btnCopy} onPress={() => navigation.navigate('CopyActivity', { activityId: activity._id })}>
                <Text style={styles.btnCopyText}>{translations.JsonActivityPages.JsonActivityScreen.JsonCopyPasteActivity}</Text>
              </Pressable>
            </View>
          </>
        )}
      </View>
      <Modal modalVisible={modalVisible} onClose={() => setModalVisible(false)}>
        {pressedButton === 'delete' && <DeleteActivity activity={activity} onClose={() => setModalVisible(false)} />}
        {pressedButton === 'copy' && <CopyToCreateActivity activity={activity} onClose={() => setModalVisible(false)} />}
      </Modal>
    </>
  )
}

export default ActivityFooterButtons