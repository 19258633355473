import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    scrollViewContainer: {
        backgroundColor: 'white',
    },
    textViewContainer: {
        alignItems: 'center',
        padding: 20,
    },
    text: {
        textAlign: 'center',
        fontSize: 17,
        fontWeight: '400',
        marginBottom: '8%',
        marginTop: '3%',
        marginHorizontal: 10,
    },
    iconContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        marginBottom: 20,
    },
    iconWithCaption: {
        alignItems: 'center',
        marginBottom: 20,
        width: '33%',  
    },
    iconStyle: {
        height: 50,
        width: 50,
    },
    iconCaption: {
        marginTop: 8,
        textAlign: 'center',
        fontWeight: 'bold',  
    },
    subtitle: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    line: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    phoneNumber: {
        marginTop: 16,
    },
});

export default styles;
