// Fr Cette page affiche l'étape 2 du formulaire pour modifier une activité de type itinéraire(FrameFigma034)
// En This page displays the second step of the form to edit an activity of type itinery (FrameFigma034)

import React, {useState} from 'react';
import {View, Text, Platform, TextInput, Pressable, ScrollView} from 'react-native';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLanguage} from '../../../../contexts/LanguageContext';
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const WithStepsTwo = ({sliderValue, onNextStep, onPrevStep}) => {
  const {translations} = useLanguage();

  const [isFocused, setIsFocused] = useState(false);
  const [addressValues, setAddressValues] = useState(Array.from({length: sliderValue}, () => ''));
  const [switchBtnStates, setSwitchBtnStates] = useState(Array.from({length: sliderValue}, () => false));

  const handleAddressChange = (index, address) => {
    setAddressValues(prevState => {
      const updatedValues = [...prevState];
      updatedValues[index] = address;
      return updatedValues;
    });
  };

  const handleSwitchBtnChange = (index, value) => {
    setSwitchBtnStates(prevState => {
      const updatedStates = [...prevState];
      updatedStates[index] = value;
      return updatedStates;
    });
  };

  const saveAddressToAsyncStorage = async () => {
    try {
      await AsyncStorage.setItem('addressValues', JSON.stringify(addressValues));
      console.log('Address values saved to AsyncStorage:', addressValues);
      onNextStep();
    } catch (error) {
      console.error('Error saving address values to AsyncStorage:', error);
    }
  };

  const DisplayStep = ({index}) => {
    return (
      <View style={{marginTop: 20, marginHorizontal: 20}}>
        <View style={styles.inputContainer}>
          <Text style={styles.textTitle}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonActivitiesWithStepScreen.JsonAddress} {index + 1}
          </Text>
          <TextInput
            style={isFocused && styles.focusedInput}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChangeText={text => handleAddressChange(index, text)}
          />
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivitiesWithStepScreen.JsonFreeShot}</Text>
          <SwitchBtn state={switchBtnStates[index]} setState={value => handleSwitchBtnChange(index, value)} />
        </View>
      </View>
    );
  };

  return (
    <ScrollView style={{backgroundColor: 'white'}}>
      {Array.from({length: sliderValue}, (_, index) => (
        <DisplayStep key={index} index={index} />
      ))}

      <View style={styles.btnContainer}>
        <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
        <Pressable style={styles.btnNext} onPress={saveAddressToAsyncStorage}>
          <Text style={styles.btnText}>{translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}</Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

export default WithStepsTwo;
