//🇫🇷 Ce fichier gère le style web du composant Sponsorship.js (FrameFigma004B)
//🇬🇧 This file manages the web style of the Sponsorship.js component (FrameFigma004B)
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 15,
  },
  containerInput: {
    width: '80%',
    marginVertical: 30,
  },
  containerBtn: {
    width: '62%',
    alignItems: 'center',
    marginVertical: '10%',
  },
  input: {
    width: '100%', // Prend toute la largeur disponible
    height: 40,
    paddingHorizontal: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#cccccc', // Bordure légèrement grise
    borderRadius: 5, // Coins arrondis pour l'input
    backgroundColor: '#ffffff', // Fond blanc pour l'input
  },
  btnContainer: {
    width: '70%',
    alignItems: 'center',
    marginVertical: '10%',
  },
  buttonText: {
    color: '#ffffff', // Texte blanc pour le bouton
    fontSize: 18,
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});