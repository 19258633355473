//FR  demande a utilisateur de saisir son mot de passe pour fermer son compte (FrameFigma111B)
//GB  The component asks the user to enter their password to close their account. (FrameFigma111B)

// Import Modules
import React, { useState } from "react";
import { Modal, View, Text, Pressable, Platform, StyleSheet, TouchableOpacity } from "react-native";

// Import Contexts
import { useLanguage } from "../../../contexts/LanguageContext";
import { useUser } from "../../../contexts/UserContext";
import { hostname } from "../../../../hostName/hostName";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigation } from "@react-navigation/native";

// Import Icons
import { Icons } from "../../../constants/Icons";
import Fields from '../../../components/Fields/Fields';

// Import Components
import TextInputCustom from '../../../components/TextInputCustom/TextInputCustom'

const ConfirmationModal = ({ showConfirmation, setShowConfirmation, formData }) => {
    const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

    const { translations } = useLanguage();
    const navigation = useNavigation();
    const { user } = useUser();
    const { userToken } = useAuth();
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleNext = async () => {
        try {
            if (password === '') {
                setError('Saisis ton mot de passe avant de supprimer.')
                return
            }
            const email = user?.email;

            const response = await fetch(`${hostname}/login`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });

            const responseData = await response.json();

            if (responseData.authToken && responseData.user) {
                setShowConfirmation(!showConfirmation)
                navigation.navigate('DeleteAccountStep2', formData)
            }
        } catch (validationError) {
            setError('Mot de passe incorrect. Réessaie.');
        }
    }

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={showConfirmation}
            onRequestClose={() => {
                setShowConfirmation(!showConfirmation);
            }}
        >
            <View style={styles.container}>
                <View style={styles.modal}>
                    <Pressable onPress={() => setShowConfirmation(!showConfirmation)}>
                        <Icons.LeftArrowGreen width={30} height={30} />
                    </Pressable>
                    <View style={styles.AlignCenter}>
                        <Text style={styles.TextBold}>Pour sécuriser ton compte, saisie ton mot de passe de connexion</Text>
                        <TextInputCustom
                            Icon={Fields.IconPassword}
                            placeholderText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder}
                            value={password}
                            handleTextChange={text => { setPassword(text), setError('') }}
                            isPlaceHolderTextAlignCenter={false}
                            isPassword={true}
                        />
                        {error && <Text style={styles.err}>{error}</Text>}
                    </View>
                    <View style={styles.deleteCancelButtonRow}>
                        <TouchableOpacity style={[styles.cancelButton, styles.btnSelectedGreen]} onPress={() => setShowConfirmation(!showConfirmation)}>
                            <Text style={[styles.buttonBigSize, styles.TextBold, styles.buttonTextWhite]}>CANCEL</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.deleteButton}
                            onPress={() => handleNext()}
                        >
                            <Text style={[styles.buttonBigSize, styles.TextBold]}>DELETE</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    )
}

export default ConfirmationModal