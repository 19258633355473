// Fr Cette page affiche l'étape 3 pour modifier une activité(FrameFigma037A)
// En This page displays the third step to edit an activity(FrameFigma037A)
// Import Modules
import React, {useState, useEffect} from 'react';
import {ScrollView, View, Text, Pressable, TextInput, Platform, Image} from 'react-native';
import Fields from '../../../../components/Fields/Fields';
import ActivityImagePicker from '../../../../components/ActivityImagePicker/ActivityImagePicker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation} from '@react-navigation/native';
import {hostname} from '../../../../../hostName/hostName';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useError} from '../../../../contexts/ErrorContext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import Modal from '../../../../components/Modal/Modal';
import ImageCropperWeb from '../../../../components/ImageCropper/ImageCropperWeb';
import ImageCropperMobile from '../../../../components/ImageCropper/ImageCropperMobile';
import axios from 'axios';

const CopyActivityStepThree = ({activity, onPrevStep}) => {
  const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
  const {translations} = useLanguage();
  const {error, setError} = useError();

  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [cropperModalVisible, setCropperModalVisible] = useState(false);
  const [activityImage, setActivityImage] = useState(null); // Ajouté pour gérer l'image sélectionnée
  const [selectedActivityTitle, setSelectedActivityTitle] = useState('');
  const [description, setDescription] = useState('');

  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState({hour: 0, minute: 0});
  const [endTime, setEndTime] = useState({hour: 0, minute: 0});
  const [location, setLocation] = useState({
    address: '',
    latitude: 0,
    longitude: 0,
    latitudeDelta: 0,
    longitudeDelta: 0,
  });
  const [attendeeLimit, setAttendeeLimit] = useState(0);
  const [hasPrice, setHasPrice] = useState(false);
  const [price, setPrice] = useState(0);
  const [howToFind, setHowToFind] = useState('');
  const [topic, setTopic] = useState(0);
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    // get data of current activty
    setActivityImage(activity.activityImage || null);
    setDescription(activity.description || '');
    setHowToFind(activity.howToFind || '');
  }, [activity]);

  useEffect(() => {
    const fetchActivityDetails = async () => {
      try {
        const savedTitle = await AsyncStorage.getItem('activityTitle');
        const savedDate = await AsyncStorage.getItem('selectedDate');
        const savedHour = await AsyncStorage.getItem('selectedHour');
        const savedMinute = await AsyncStorage.getItem('selectedMinute');
        // const savedEndTime = await AsyncStorage.getItem('endTime');//n'exist pas dans etape avant
        const savedAttendeeLimit = await AsyncStorage.getItem('AttendeeLimitation');
        const savedHasPrice = await AsyncStorage.getItem('hasPrice');
        const savedPrice = await AsyncStorage.getItem('priceValue');
        const savedTopic = await AsyncStorage.getItem('topic');
        const savedAddress = await AsyncStorage.getItem('city');
        const userId = await AsyncStorage.getItem('userId');
        // const attendeesValidation = await AsyncStorage.getItem('attendeesValidation');
        // Vérifier si switchBtnUnlimited est true
        const switchBtnUnlimited = JSON.parse(await AsyncStorage.getItem('switchBtnUnlimited'));
        let attendeeLimitValue = switchBtnUnlimited ? 1000000 : parseInt(savedAttendeeLimit, 10);

        setTitle(savedTitle);
        setDate(savedDate);
        setStartTime({hour: parseInt(savedHour, 10), minute: parseInt(savedMinute, 10)});
        setAttendeeLimit(attendeeLimitValue);
        setHasPrice(savedHasPrice);
        setTopic(savedTopic);
        // setEndTime(JSON.parse(savedEndTime));
        setLocation(prevLocation => ({
          ...prevLocation,
          address: savedAddress || '',
        }));
        setPrice(savedPrice);
        setAuthor(userId);
      } catch (error) {
        console.error("Erreur lors de la récupération des détails de l'activité depuis AsyncStorage", error);
      }
    };
    fetchActivityDetails();
  }, []);

  console.log('savedTitle:', title);

  useEffect(() => {
    const getTitle = async () => {
      const title = await AsyncStorage.getItem('selectedActivityTitle');
      setSelectedActivityTitle(title);
    };

    getTitle();
  }, []);

  const openIllustrationModal = () => {
    setModalVisible(true);
  };

  const saveActivity = async () => {
    const userId = await AsyncStorage.getItem('userId');
    const latitude = parseFloat(await AsyncStorage.getItem('latitudeActivities'));
    const longitude = parseFloat(await AsyncStorage.getItem('longitudeActivities'));
    const hour = await AsyncStorage.getItem('selectedHour');
    const minute = await AsyncStorage.getItem('selectedMinute');
    const postalCode = await AsyncStorage.getItem('postalCode');
    const address = await AsyncStorage.getItem('city');
    const locality = await AsyncStorage.getItem('locality');

    const startTime = {
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
    };

    // Assumer que endTime n'est pas configuré et est donc null
    const endTime = null;

    const activityData = {
      title,
      author,
      date,
      startTime: JSON.stringify(startTime), // Assurez-vous que startTime est un objet
      endTime: JSON.stringify(endTime), // De même pour endTime s'il est non null
      attendeeLimit,
      attendees: [userId],
      hasPrice,
      price,
      topic,
      activityImage,
      location: {address, latitude, longitude, postalCode, locality},
      description,
      howToFind,
      createAtivityType: (await activity.createAtivityType) || 'classic',
      localOnlyForAttendees: (await activity.localOnlyForAttendees) || false,
      inviteCommunity: (await activity.inviteCommunity) || false,
      buyTicketsLink: await AsyncStorage.getItem('buyTicketsLink'),
      friendsNumber: await AsyncStorage.getItem('friendsNumber'),
      notifyPreviousAttendees: (await activity.notifyPreviousAttendees) || false,
      inviteMore: (await activity.inviteMore) || false,
      requestCoOrga: (await activity.requestCoOrga) || false,
      coOrganizerConditions: JSON.parse(await activity.coOrganizerConditions),
      coOrganizerGifts: JSON.parse(await activity.coOrganizerGifts),
      attendeesValidation: (await activity.attendeesValidation) || false,
    };

    const formDataToSend = new FormData();
    // Ajouter chaque propriété de activityData comme un champ dans le FormData
    Object.keys(activityData).forEach(key => {
      if (typeof activityData[key] === 'object' && activityData[key] !== null) {
        formDataToSend.append(key, JSON.stringify(activityData[key]));
      } else {
        formDataToSend.append(key, activityData[key]);
      }
    });

    // Ajouter l'image s'il y en a une
    if (activityImage && activityImage.file) {
      console.info('Selected image:', activityImage);
      formDataToSend.append('activityImage', activityImage.file);
    }

    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/activities`, {
        method: 'POST',
        body: formDataToSend,
        headers: {
          Authorization: 'Bearer ' + userToken,
        },
      });

      console.log('Valeurs à envoyer :', activityData);
      console.log('Corps de la requête :', JSON.stringify(activityData));

      if (response.ok) {
        Platform.OS === 'web' ? navigation.navigate('Activities') : navigation.navigate('Home');
        setTitle('');
        setDate('');
        setStartTime({hour: 0, minute: 0});
        setAttendeeLimit(0);
        setHasPrice(false);
        setTopic(0);
        setPrice(0);

        // Récupérer toutes les clés du AsyncStorage
        const allKeys = await AsyncStorage.getAllKeys();

        // Filtrer les clés à conserver
        const keysToKeep = allKeys.filter(
          key => key === 'selectedLanguage' || key === 'selectedLanguageName' || key === 'userToken' || key === 'userId',
        );

        // Supprimer les clés restantes
        await AsyncStorage.multiRemove(allKeys.filter(key => !keysToKeep.includes(key)));

        // Ajouter un console.log pour vérifier que les clés ont été supprimées
        const remainingKeys = await AsyncStorage.getAllKeys();
        console.log('Clés restantes dans AsyncStorage :', remainingKeys);
      } else {
        console.error('Échec de la sauvegarde de l activité sur le serveur:', response.status, response.statusText);
        console.error("Détails de l'erreur du serveur:", await response.text());
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de l activité:', error);
    }
  };

  const handleNextStep = () => {
    if (activityImage !== null && howToFind !== '' && description !== '') {
      saveActivity(); // Appel à la fonction pour sauvegarder l'activité
    } else {
      let errorMessage = 'Remplit toutes les conditions avant de publier l\'activité.';
  
      // Vérifiez les conditions individuellement et mettez à jour le message d'erreur en conséquence
      if (activityImage === null && howToFind !== '' && description !== '') {
        errorMessage = 'Choisis une image pour ton activité.';
      } else if (activityImage !== null && howToFind === '' && description !== '') {
        errorMessage = 'Indique comment trouver ton activité.';
      } else if (activityImage !== null && howToFind !== '' && description === '') {
        errorMessage = 'Rédige une description pour ton activité.';
      } else if (activityImage === null && howToFind === '' && description !== '') {
        errorMessage = 'Choisis une image et indique comment trouver ton activité.';
      } else if (activityImage !== null && howToFind === '' && description === '') {
        errorMessage = 'Indique comment trouver et rédige une description pour ton activité.';
      } else if (activityImage === null && howToFind !== '' && description === '') {
        errorMessage = 'Choisis une image et rédige une description pour ton activité.';
      } else if (activityImage === null && howToFind === '' && description === '') {
        errorMessage = 'Choisis une image, indique comment trouver et rédige une description pour ton activité.';
      }
  
      setError(errorMessage);
    }
  };

    //Supprime le msg d'erreur lorsque l'on quitte la page
    useEffect(() => {
      // Cleanup function
        return () => {
          setError(""); // Reset error message when unmounting
        };
       }, []);
  

  // Handle Cropped Image

  const handleCroppedImage = croppedImage => {
    setActivityImage(croppedImage);
    setCropperModalVisible(false);
  };
const CropperComponent = Platform.select({
  web: () => <ImageCropperWeb file={activityImage.file} handleCroppedImage={handleCroppedImage} />,
  default: () => <ImageCropperMobile file={activityImage.file} handleCroppedImage={handleCroppedImage} />,
});
  console.info(activityImage);
  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      {error ? (
      <View style={styles.headerError}>
        <Text style={styles.headerErrorText}>{error}</Text>
      </View>
    ) : null}
      <View style={styles.title}>
        <Text style={styles.title}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonExplainYourActivityMessage}
        </Text>
      </View>

      {activityImage ? (
        // Appeler l'Image Cropper si Image Uploaded
        <View style={styles.imageContainer}>
          <Pressable onPress={() => setCropperModalVisible(true)}>
            <Text>Crop Image</Text>
          </Pressable>
          <Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
            {CropperComponent()}
          </Modal>
          <Pressable onPress={openIllustrationModal}>
            <Image source={{uri: activityImage.imageUrl ? activityImage.imageUrl : activityImage}} style={styles.imageStyle} />
          </Pressable>
        </View>
      ) : (
        <Pressable style={styles.choosePhoto} onPress={openIllustrationModal}>
          <View style={styles.btnChoose}>
            <View style={styles.field}>
              <Fields.IconUpload />
            </View>
            <Text style={styles.btnChooseText}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonAddImageMessage}</Text>
          </View>
        </Pressable>
      )}
      <ActivityImagePicker
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onImageSelected={imageUrl => setActivityImage(imageUrl)}
        selectedActivityTitle={selectedActivityTitle}
      />

      <TextInputCustom
        placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionPlaceholder}
        handleTextChange={text => {
          setDescription(text.slice(0, 7500));
          setError('');
        }}
        value={description}
        multiline={true}
        numberOfLines={18}
        isPlaceHolderTextAlignCenter={false}
        maxLength={7500}
      />

      <TextInputCustom
        placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMePlaceholder}
        handleTextChange={text => {
          setHowToFind(text.slice(0, 7500));
          setError('');
        }}
        value={howToFind}
        multiline={true}
        numberOfLines={10}
        isPlaceHolderTextAlignCenter={false}
      />
      <View style={styles.btnsRow}>
        <View style={styles.btnContainer}>
          <ButtonNext handleNextStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
        </View>
        <View style={styles.btnContainer}>
          <ButtonNext
            conditionNeed={activityImage !== null && howToFind !== '' && description !== ''}
            handleNextStep={handleNextStep}
            btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPublishButton}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default CopyActivityStepThree;
