//Fr styles de ConfirmDeleteModal (FrameFigma041) (FrameFigma041D)
//En styles of ConfirmDeleteModal (FrameFigma041) (FrameFigma041D)
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        justifyContent: 'center', 
        alignItems: 'center' 
    },
    modal: {
        backgroundColor: 'white', 
        padding: 50
    },
    close: {
        alignSelf:'flex-end',
    },
    closeText: {
        color: '#59C09B',
        fontSize: 20,
        fontWeight: 'bold'
    },
    btnContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '95%',
        marginVertical: 15,
        marginTop: '10%',
        marginLeft: 10,
    },
    btnConfirm: {
        borderWidth: 1,
        borderColor: '#59C09B',
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 10,
    },
    btnCancel: {
        borderRadius: 10,
        backgroundColor: '#59C09B',
        padding: 10
    },
    textMsg: {
        color: '#59C09B',
        fontSize: 16,
        fontWeight: 'bold',
    },
    textMsgConfirm: {
        color: '#59C09B',
    },
    textMsgCancel: {
        color: 'white',
    },
})

export default styles;