import React, { useContext } from 'react';
import { View, Image, Text, StyleSheet, Platform } from 'react-native';
import { useUser } from '../../../../contexts/UserContext';
import { useLanguage } from '../../../../contexts/LanguageContext';
import countriesListCircleFlags from '../../../../components/LanguagesFlags/LanguagesFlags';
import { activitiesIcon } from '../../../../components/Fields/ActivityListIcon';

const MyProfileInfoScreen = ({ profileOwner }) => {

    const styles =
        Platform.OS === 'web'
            ? require('./StylesWeb').default
            : require('./StylesMobile').default;

    const { translations } = useLanguage();
    const LanguageFlagNative = countriesListCircleFlags.find((flag) =>
        flag.bigram === profileOwner?.nativeLanguage
    );

    const mapLanguagesToFlags = (Languages) => {
        return Languages?.map((language) => {
            const flag = countriesListCircleFlags.find((flag) => flag.bigram === language);
            return flag.FlagComponent;
        });
    };
    const languageFlagsSpoken = mapLanguagesToFlags(profileOwner?.spokenLanguage);

    const userActivityIcons = activitiesIcon.filter((icon) => {
        return profileOwner?.activitiesTopic.find((topic) => parseInt(topic) === parseInt(icon.id));
    })
    return (
        <View style={styles.container}>
            <View style={styles.infoContainer}>
                {profileOwner && profileOwner?.about ? (
                    <Text style={styles.aboutDescription}>{profileOwner?.about}</Text>
                ) : (
                    <Text>Nothing about this user</Text> // Fallback si aucune description n'est disponible
                )}
            </View>
            <View style={[styles.detailsContainer, styles.pad20]}>
                <View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonCity}: </Text>
                        <Text style={styles.text}>{profileOwner?.city?.split(",")[0]}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonChildren}: </Text>
                        <Text style={styles.text}>{profileOwner?.children}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonStudies}: </Text>
                        <Text style={styles.text}>{profileOwner?.studies}</Text>
                    </View>
                </View>
                <View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonUniversity}: </Text>
                        <Text style={styles.text}>{profileOwner?.university}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonAlcohol}: </Text>
                        <Text style={styles.text}>{profileOwner?.alcohol}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonTobacco}: </Text>
                        <Text style={styles.text}>{profileOwner?.tobacco}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.languagesContainer}>
                <View style={styles.individualEntry}>
                    <Text style={styles.langTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNativeLanguage}: </Text>
                    {profileOwner?.nativeLanguage ? <LanguageFlagNative.FlagComponent width={20} height={20} style={styles.flags} /> : <Text>Not Selected</Text>}
                </View>
                <View style={styles.individualEntry}>
                    <Text style={styles.langTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonSpokenLanguage}: </Text>
                    {profileOwner?.spokenLanguage?.length > 0 ? languageFlagsSpoken.map((Flag, index) => (
                        <Flag width={20} height={20} style={styles.flags} key={index} />
                    )) : <Text>Not Selected</Text>
                    }
                </View>
            </View>

            <View style={styles.greenBar}>
                <Text style={styles.greenBarText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonWhatILoveToDo}:</Text>
            </View>
            {userActivityIcons.length > 0
                ? <View style={styles.bottomIcons}>
                    {userActivityIcons.map((icon) => (
                        <View key={icon.id}>
                            {icon.activityIconOn}
                        </View>
                    ))}
                </View>
                :<Text>Not Selected</Text>}
        </View>
    );
};


export default MyProfileInfoScreen;
