// Fr Ce component affiche en bas commentaires d'une activité (FrameFigma043)
// En This component displays on bottom the comments about the activity (FrameFigma043)

import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, Button, FlatList, StyleSheet, Alert, KeyboardAvoidingView, Platform, Image } from 'react-native';
import { useWebSocket } from '../../../../../sockets/WebSocketLocal';
import { useUser } from '../../../../../contexts/UserContext';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import Images from '../../../../../constants/Images';
import { hostname } from '../../../../../../hostName/hostName';
import { format } from 'date-fns';


const ActivityBodyBottomCommentsComponent = ({ activity }) => {
  const activityId = activity._id;
  const [comments, setComments] = useState([]);
  const [newCommentText, setNewCommentText] = useState('');
  const { user, allUsers } = useUser();
  const { translations } = useLanguage();
  const { sendActivityComment, requestActivityComments, groupChatMessages } = useWebSocket();
  const flatListRef = useRef(); // Ajout d'une ref pour le FlatList

  // Fonction pour obtenir une couleur aléatoire
  const getColorCorresponding = (role) => {
    const colors = ['#4A85DD', '#CB55B1', '#57B556', '#9D9D9D'];
    return colors[role === "Male" ? 0 : role === "Female" ? 1 : role === "organizer" ? 2 : 3];
  };
  const senderDetails = (senderId) => allUsers.find((user) => user._id === senderId);
  useEffect(() => {
    requestActivityComments(activityId);
  }, [activityId, requestActivityComments]);
  useEffect(() => {
    if (groupChatMessages && groupChatMessages[activityId]) {
      setComments(groupChatMessages[activityId]);
    }
  }, [groupChatMessages, activityId]);
  const handlePostComment = () => {
    if (user && user._id && newCommentText.trim() !== '') {
      const role = (activity.author === user._id || activity.coOrganizers.includes(user._id)) ? "organizer" : user.sexe;
      const userColor = getColorCorresponding(role); // Obtenir une couleur 
      const newComment = {
        _id: Date.now().toString(),
        comment: newCommentText,
        sender: user._id,
        userName: user.userName,
        userColor: userColor, // Utilisez la couleur aléatoire pour le commentaire
        createdAt: Date(),
        commentedBy: user._id,
      };

      sendActivityComment({
        chatId: activityId,
        message: newCommentText,
        senderId: user._id,
        userColor: userColor, // Envoyez également la couleur aléatoire au backend
      });
      setComments(prevComments => [...prevComments, newComment]);
      console.log(comments)
      setNewCommentText('');
      // Afficher les détails dans le terminal
      console.log('Message sent:', {
        chatId: activityId,
        senderId: user._id,
        message: newCommentText,
        userColor: userColor, // Afficher la couleur du commentaire dans le terminal
      });
    } else {
      Alert.alert('Error', 'Please enter a comment.');
    }
  };
  console.log(comments)
  const isShowDate = (index) => {
    console.log(index)
    const dateString1 = comments[index - 1]?.createdAt
    const dateString2 = comments[index]?.createdAt
    const date1 = new Date(dateString1);
    const date2 = new Date(dateString2);
    if (index > 0)
      return !(date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getFullYear() === date2.getFullYear())
    // Comparer le mois, le jour et l'année des deux dates
    // si c meme date, alors return false, c a dire pas show date
    else return true
  }
  const renderComment = ({ item, index }) => {
    const sender = senderDetails(item.commentedBy)
    const senderNotMe = item.commentedBy !== user._id;
    const senderIsMe = item.commentedBy === user._id;
    const role = (activity.author == item.commentedBy || activity.coOrganizers.includes(item.commentedBy)) ? "organizer" : user.sexe;
    return (
      <View style={{ width: '100%' }}>
        {isShowDate(index) && <Text style={styles.dateCenter}>{format(new Date(item.createdAt), "dd MMMM yyyy")}</Text>}
        <View style={[styles.containerMsgBox, { justifyContent: senderNotMe ? 'flex-start' : 'flex-end' }]}>
          {senderNotMe &&
            <View style={styles.imgContainer}>
              <Image
                source={{ uri: sender.profileImage }}
                style={[styles.profileImage, { borderColor: role === "organizer" ? '#05FF00' : '#9D9D9D' }]}
              />
              {sender.status == 'online' &&
                <View style={styles.statusOnline}></View>
              }
            </View>}
          <View style={[styles.withNameContainer, { justifyContent: senderNotMe ? 'flex-start' : 'flex-end' }]}>
            {senderNotMe && <Text style={sender.sexe === "Male" ? styles.nameMale : styles.nameFemale}>{item.userName}</Text>}
            <View style={styles.avecArrow}>
              {senderNotMe
                && <View style={[styles.arrowDownRight, { transform: [{ rotateX: '180deg' }, { rotateZ: '180deg' }] }, { borderBottomColor: item.userColor }]}></View>
              }
              <View style={[styles.comment, { backgroundColor: item.userColor }, senderIsMe && { borderBottomLeftRadius: 15 }, senderNotMe && { borderBottomRightRadius: 15 }]}>
                <Text style={styles.commentText}>{item.comment}</Text>
                <Text style={styles.timestamp}>{format(new Date(item.createdAt), 'HH:mm')}</Text>
              </View>
              {senderIsMe ?
                <View style={[styles.arrowDownRight, { borderBottomColor: item.userColor }]}></View> : <></>
              }
            </View>
          </View>

        </View>

      </View>
    );
  };
  return (
    <KeyboardAvoidingView style={styles.container} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
      <View style={styles.titleContainer}>
        <Text style={styles.titleText}>{translations.JsonActivityPages.JsonActivityScreen.JsonChat}</Text>
        <Images.GeneralBlackAndWhitePoints />
        {/* menu ici pas detaillé sur figma */}
      </View>
      <FlatList ref={flatListRef} data={comments} renderItem={renderComment} keyExtractor={item => item._id.toString()} style={styles.commentsContainer} onContentSizeChange={()=> flatListRef.current.scrollToEnd()}/> {/* inverted */}
      <View style={styles.inputContainer}>
        <TextInput style={styles.input} value={newCommentText} onChangeText={setNewCommentText} placeholder="Write a comment..." multiline />
        <Button title="Post Comment" onPress={handlePostComment} />
      </View>
    </KeyboardAvoidingView>
  );
};
const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // justifyContent: 'space-between',
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    paddingHorizontal: '7%',
    paddingVertical: '3%',
  },
  dateCenter: {
    textAlign: 'center',
    marginBottom: 10
  },
  imgContainer: {
    width: 40,
    height: 40,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 50,
    borderWidth: 2,
  },
  statusOnline: {
    width: 8,
    height: 8,
    borderRadius: 10,
    backgroundColor: '#2AE09F',
    position: 'absolute',
    right: 5,
    bottom: 1,
    borderWidth: 0.2,
    borderColor: '#000000',
    shadowColor: '#00000040',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
  },
  titleText: {
    fontSize: 22,
    fontWeight: 'bold', // Fixer la hauteur maximale pour le conteneur de commentaires
  },
  commentsContainer: {
    // flex: 1,
    height: 300, // Fixer la hauteur maximale pour le conteneur de commentaires
  },
  containerMsgBox: {
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  withNameContainer: {
    maxWidth: '70%',
    minWidth: '40%',
    flexDirection: 'column',
  },
  avecArrow: {
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    alignItems: 'flex-end',
  },
  arrowDownRight: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 10,
    borderBottomWidth: 10,
    borderRightColor: 'transparent',
  },
  input: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'gray',
    marginRight: 10,
    padding: 10,
    borderRadius: 5,
  },
  comment: {
    padding: 10,
    // marginVertical: 5,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    width: '95%',
  },
  commentText: {
    fontSize: 16,
    color: 'white',
  },
  timestamp: {
    fontSize: 12,
    color: 'white',
    textAlign: 'right'
  },
  nameMale: {
    color: '#001AFF',
    marginLeft: 20,
  },
  nameFemale: {
    color: '#FF13CB',
    marginLeft: 20,
  },
});

export default ActivityBodyBottomCommentsComponent;
