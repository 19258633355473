import React, { useContext } from 'react';
import { View, Image, Text, StyleSheet, Platform } from 'react-native';
import { useLanguage } from '../../../../contexts/LanguageContext';
import NetworkUserCard from '../../../../components/UserCardBasic/UserCardBasic';

const MyProfileNetworkScreen = ({ profileOwner, allUsers, userActivities }) => {

    const styles = Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;

    const { translations } = useLanguage();

    const userHasMet = userActivities?.flatMap((activity) => {
        // Trouver les rencontres depuis les listes attendees
        const attendees = activity.attendees?.filter((attendee) => attendee !== profileOwner?._id);
        // Enlever les doublons dans le liste userHasMet
        const uniqueUserHasMet = [...new Set(attendees)];
        return uniqueUserHasMet;
    });
    const filteredNetwork = allUsers?.filter((user) => userHasMet.includes(user._id) || profileOwner?.friends.includes(user._id));
    const userNetwork = filteredNetwork?.map((user) => ({
        user: user,
        hasMet: userHasMet.includes(user._id),
        isFriend: profileOwner?.friends.includes(user._id),
    }))

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.headerLeft}>
                    <View style={styles.networkNumber}>
                        <Text style={styles.membersTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}</Text>
                        <Text style={styles.networkCount}>{userNetwork?.length}</Text>
                    </View>
                    {/* Manque les traductions pour 'members met' */}
                    {/* <Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}</Text> */}
                </View>
                <View style={styles.headerRight}>
                    <Text style={styles.boldText}>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll}   {'>'}</Text>
                    {/* <RightArrow width={20} height={20} viewBox={'0 0 20 20'}/> */}
                </View>
            </View>
            <Text style={styles.underHeader}>(members met)</Text>
            <View style={styles.legend}>
                <View style={styles.legendItem}>
                    <View style={styles.legendCircleYellow}></View>
                    {/* Manque les traductions pour les deux text */}
                    <Text style={styles.legendText}> = people met with the user</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={styles.legendCircleBlue}></View>
                    <Text style={styles.legendText}> = friends with user</Text>
                </View>
            </View>
            <View>
                <View style={styles.network}>
                    {userNetwork.length > 0 ? userNetwork.map((user) => (
                        <View style={styles.networkItem} key={user.user._id}>
                            <NetworkUserCard user={user} />
                        </View>
                    )) : <View><Text>User has no connections</Text></View>}
                </View>
            </View>
        </View>
    );
};


export default MyProfileNetworkScreen;