import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  tabBar: {
    flexDirection: "row",
    backgroundColor: "#59C09B",
    marginTop: 20,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#59C09B",
  },
  tabBarButtonContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: '11%',
  },
  buttonSelected: {
    backgroundColor: "#59C09B",
  },
  buttonUnselected: {
    backgroundColor: "white",
  },
  activeLabel: {
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
  },
  inactiveLabel: {
    color: "#59C09B",
    fontSize: 10,
    fontWeight: "bold",
  },
  separator: {
    width: 1,
    height: "100%", // Ajustez la hauteur du séparateur pour qu'elle corresponde à la hauteur des boutons
    backgroundColor: "#59C09B",
  }
});

export default styles;