//🇫🇷 Ce fichier gère le style mobile du composant LanguagesScreen.js (FrameFigma002)
//🇬🇧 This file manages the mobile style of the LanguagesScreen.js component (FrameFigma002)
import { StyleSheet, Dimensions } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2C2C46',
    width: '100%'
  },
  searchInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    width: '80%',
    marginBottom: 20,
  },
  itemContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // margin: 5,
    // paddingHorizontal: 10,
    // width: Dimensions.get('window').width / 4 - 20,
  },
  flatList: {
    width: '100%'
  },
  languageText: {
    marginTop: 5,
    fontWeight: 'bold',
    color: 'white',
  },
  // rowContainer: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-around',
  //   marginBottom: 10, // Ajustez la marge selon vos besoins
  // },
  listContentContainer: {
    // alignItems: 'center',
    alignContent: 'space-around',
    // flexGrow: 1,
    // paddingBottom: 20,
  },
  columnWrapper: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    marginVertical: '5%',
  },
  title: {
    margin: 15,
    fontWeight: "bold",
    fontSize: 20,
    color: "white",
    textAlign: "center",
  },
  countryPage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    // height: "100%",
    paddingTop: 5,
    paddingBottom: 10,
    paddingHorizontal: 5,
    backgroundColor: "#2C2C46",
  },
  searchInput: {
    marginTop: 20,
    marginBottom: 15,
    paddingVertical: 10,
    paddingRight: 8,
    paddingLeft: 40,
    width: "90%",
    height: 45,
    alignSelf: "center",
    borderRadius: 25,
    backgroundColor: "white",
    fontSize: 14,
    zIndex: 1,
    borderWidth: 1,
    borderColor: "#59b09c",
  }
});

export default styles;
