import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: '100%',
    paddingTop: '3%',
    // alignItems: 'center',
    paddingHorizontal: '5%',
    backgroundColor: 'white'
  },
  deleteAccountText: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: 28,
    lineHeight: 42,
    textAlign: 'center',
  },
  feedbackText: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    marginTop: 40,
  },
  input: {
    width: '100%',
    minHeight: 177.92,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: 'black',
    // padding: 10,
    marginTop: 20,
    alignSelf: 'center',
    padding:10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    gap: '10%',
  },
  btnYesNo: {
    width: 60,
    height: 35,
    borderRadius: 11,
    borderWidth: 1,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnSelectedRed: {
    backgroundColor:'#FF0000',
    borderColor: '#FF0000',
  },
  btnSelectedGreen: {
    backgroundColor:'#59C09B',
    borderColor: '#59C09B',
  },
  buttonTextWhite: {
    color: 'white',
  },
  buttonText: {
    fontSize: 16,
  },
  buttonBold: {
    fontWeight:'bold'
  },
  buttonBigSize: {
    fontSize: 18
  },
  issueButtonContainer: {
    marginTop: 20,
    paddingHorizontal:10
  },
  issueButtonRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    gap:'20%'
  },
  issueButton: {
    // width: 123,
    // height: 35,
    flex: 1,
    borderRadius:8,
    borderWidth: 1,
    borderColor: '#404040',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal:5,
    minHeight:35,
  },
  cancelButton: {
    flex: 1,
    borderRadius:3,
    minHeight:42,
    borderWidth: 1,
    borderColor: '#404040',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    flex: 1,
    borderRadius:3,
    minHeight:42,
    borderWidth: 1,
    borderColor: '#404040',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteCancelButtonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingHorizontal:20,
    gap:'15%',
    marginBottom:60,
    marginTop:5
  },
});

export default styles;
