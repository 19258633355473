import React from 'react';
import {ScrollView, Platform} from 'react-native';
import {useUser} from '../../../contexts/UserContext';
import MapsWebUser from './MapsWebUser';
import MapsMobileUser from './MapsMobileUser';

const UsersMapsScreen = () => {
  const {allUsers} = useUser();

  // Déterminer le composant à utiliser en fonction de la plateforme
  const MapsComponent = Platform.OS === 'web' ? MapsWebUser : MapsMobileUser;

  return (
    <ScrollView>
      {/* Afficher la carte avec les utilisateurs */}
      <MapsComponent users={allUsers} />
    </ScrollView>
  );
};

export default UsersMapsScreen;



