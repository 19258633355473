import React, { useState, useEffect } from 'react';
import { FlatList, View, ActivityIndicator, Platform } from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hostname } from '../../../../hostName/hostName';
import UserCard from '../../../components/UserCardFriend/UserCardFriend';
import { CalculateUserPointsDrawer } from '../../../utils/CalculateUserPointsDrawer';
import { useUser } from '../../../contexts/UserContext';


const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const FriendListScreen = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();

useEffect(() => {
  const fetchUsers = async () => {
    try {
      console.log('Fetching token and user ID...');
      const userToken = await AsyncStorage.getItem('userToken');
      const myUserId = await AsyncStorage.getItem('userId');
      console.log(`Token: ${userToken}, UserID: ${myUserId}`);

      console.log('Making API call...');
      const response = await fetch(`${hostname}/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log('API call complete:', response.ok);

      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      let usersData = await response.json();
      usersData = usersData.filter(user => user.friends && user.friends.includes(myUserId));
      setUsers(usersData);
    } catch (error) {
      console.error('Failed to fetch or process users:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchUsers();
}, []);

/* TODO : Implementer la version qui est plus efficase
useEffect(() => {
  const fetchFriends = async () => {
    try {
      const myUserId = await AsyncStorage.getItem('userId');
      if (myUserId && user) {
        const friendsData = user.filter(u => u.friends && u.friends.includes(myUserId));
        setUsers(friendsData);
      }
    } catch (error) {
      console.error('Failed to fetch or process users:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchFriends();
}, [user]);*/



  if (loading) {
    return <ActivityIndicator size="large" />;
  }

  return (
    <FlatList
      data={users}
      renderItem={({ item }) => <UserCard user={item} />}
      keyExtractor={item => item._id.toString()}
    />
  );
};

export default FriendListScreen;