import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    cardContainer: {
      backgroundColor: '#FFFFFF',
      shadowColor: '#000000',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#BCBCBC',
    },
    profileImage: {
      width: 80,
      height: 80,
      borderRadius: 40,
    },
    profileMedal: {
      padding: 5,
      marginTop: -10,
    },
    infoContainer: {
      flex: 1,
      paddingLeft: 10,
    },
    nameContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    nameText: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    cityText: {
      fontSize: 14,
      color: '#666666',
      marginTop: 8,
    },
    arrowImage: {
      width: 24,
      height: '100%',
      marginLeft: 'auto',
      //backgroundColor: 'yellow',
    },
  });