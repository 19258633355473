//🇫🇷 Ce fichier gère le style web du composant LanguagesScreen.js (FrameFigma002)
//🇬🇧 This file manages the web style of the LanguagesScreen.js component (FrameFigma002)
import { StyleSheet, Dimensions } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#2C2C46',
    width: '100%'
  },
  searchInput: {
    height: 30,
    borderColor: 'blue',
    borderWidth: 2,
    width: '60%',
    marginBottom: 10,
  },
  icon: {
    position: 'absolute',
    height: '36%',
    top: 13,
    left: 10,
    zIndex: 2,
  },
  focusedInput2: {
    backgroundColor: 'white',
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  itemContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    // width: 100,
  },
  flatList: {
    width: '100%'
  },
  languageText: {
    marginTop: 5,
    fontWeight: 'bold',
    color: 'white',
  },
  listContentContainer: {
    // alignItems: 'center',
    alignContent: 'space-between',
  },
  columnWrapper: {
    marginVertical: '5%',
  },
  title: {
    margin: 15,
    fontWeight: "bold",
    fontSize: 20,
    color: "white",
    textAlign: "center",
  },
  countryPage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    // height: "100%",
    paddingTop: 5,
    paddingBottom: 10,
    paddingHorizontal: 15,
    backgroundColor: "#2C2C46",
  },
  // rowContainer: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-around',
  //   marginBottom: 10, // Ajustez la marge selon vos besoins
  // },
  searchInput: {
    marginTop: 20,
    marginBottom: 15,
    paddingVertical: 10,
    paddingRight: 8,
    paddingLeft: 40,
    width: "90%",
    height: 45,
    alignSelf: "center",
    borderRadius: 25,
    backgroundColor: "white",
    fontSize: 14,
    zIndex: 1,
    borderWidth: 1,
    borderColor: "#59b09c",
  }
});
