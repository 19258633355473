import React from 'react';
import {Platform} from 'react-native';
import AppWeb from './AppWeb';
import AppMobile from './AppMobile';
import {LanguageProvider} from './src/contexts/LanguageContext';
import {registerTranslation} from 'react-native-paper-dates';
import {AuthProvider} from './src/contexts/AuthContext';
import {UserProvider} from './src/contexts/UserContext';
import {ActivityProvider} from './src/contexts/ActivityContext';
import {ChatModalProvider} from './src/contexts/ChatModalContext';
// en cas de build il faut utiliser userSocketService
//import { WebSocketProvider } from './src/sockets/WebSocketProvider';
import {WebSocketProvider} from './src/sockets/WebSocketLocal';
import {ErrorProvider} from './src/contexts/ErrorContext';

registerTranslation('fr', {
  save: 'Sauvegarder',
  selectSingle: 'Sélectionner la date',
  selectMultiple: 'Sélectionner les dates',
  selectRange: 'Sélectionner la période',
  notAccordingToDateFormat: inputFormat => `Le format de la date doit être : ${inputFormat}`,
  mustBeHigherThan: 'Doit être supérieur à',
  mustBeLowerThan: 'Doit être inférieur à',
  mustBeBetween: 'Doit être entre',
  dateIsDisabled: 'La date est désactivée',
  previous: 'Précédent',
  next: 'Suivant',
  typeInDate: 'Entrez la date',
  pickDateFromCalendar: 'Choisissez la date à partir du calendrier',
  close: 'Fermer',
});

const App = () => {
  // Sélectionner le composant en fonction de la plateforme
  const SelectedApp = Platform.OS === 'web' ? AppWeb : AppMobile;

  // Retourner le composant sélectionné englobé dans le LanguageProvider
  return (
    <AuthProvider>
      <LanguageProvider>
        <UserProvider>
          <ActivityProvider>
            <WebSocketProvider>
              <ChatModalProvider>
                <ErrorProvider>
                <SelectedApp />
                </ErrorProvider>
              </ChatModalProvider>
            </WebSocketProvider>
          </ActivityProvider>
        </UserProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default App;



