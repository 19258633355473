// Fr Cette page affiche l'étape 5 du formulaire pour créer une activité (FrameFigma037A)
// En This page displays the FIFTH step of the form to create an activity (FrameFigma037A)

import React, {useState, useEffect} from 'react';
import {ScrollView, View, Text, Pressable, TextInput, Platform, Image} from 'react-native';
import Fields from '../../../../components/Fields/Fields';
import ActivityImagePicker from '../../../../components/ActivityImagePicker/ActivityImagePicker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation} from '@react-navigation/native';
import {hostname} from '../../../../../hostName/hostName';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useError} from '../../../../contexts/ErrorContext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import Modal from '../../../../components/Modal/Modal';
import ImageCropperWeb from '../../../../components/ImageCropper/ImageCropperWeb';
import ImageCropperMobile from '../../../../components/ImageCropper/ImageCropperMobile';
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const CreateActivityCommonStepThree = ({onPrevStep, onNextStep}) => {
  const {translations} = useLanguage();
  const {error, setError} = useError();

  const navigation = useNavigation();
  const [isFocused, setIsFocused] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [cropperModalVisible, setCropperModalVisible] = useState(false);
  const [activityImage, setActivityImage] = useState(null); // Ajouté pour gérer l'image sélectionnée
  const [selectedActivityTitle, setSelectedActivityTitle] = useState('');
  const [description, setDescription] = useState('');

  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState({hour: 0, minute: 0});
  const [endTime, setEndTime] = useState({hour: 0, minute: 0});
  const [location, setLocation] = useState({
    address: '',
    latitude: 0,
    longitude: 0,
    latitudeDelta: 0,
    longitudeDelta: 0,
  });
  const [attendeeLimit, setAttendeeLimit] = useState(0);
  const [hasPrice, setHasPrice] = useState(false);
  const [price, setPrice] = useState(0);
  const [howToFind, setHowToFind] = useState('');
  const [topic, setTopic] = useState(0);
  const [author, setAuthor] = useState(null);
  const [metroStation, setMetroStation] = useState(''); // Ajouté pour gérer la station de métro

  useEffect(() => {
    const fetchActivityDetails = async () => {
      try {
        const savedTitle = await AsyncStorage.getItem('activityTitle');
        const savedDate = await AsyncStorage.getItem('selectedDate');
        const savedHour = await AsyncStorage.getItem('selectedHour');
        const savedMinute = await AsyncStorage.getItem('selectedMinute');
        const savedEndTime = await AsyncStorage.getItem('endTime');
        const savedAttendeeLimit = await AsyncStorage.getItem('AttendeeLimitation');
        const savedHasPrice = await AsyncStorage.getItem('hasPrice');
        const savedPrice = await AsyncStorage.getItem('priceValue');
        const savedTopic = await AsyncStorage.getItem('topic');
        const savedAddress = await AsyncStorage.getItem('city');
        const savedMetroStation = await AsyncStorage.getItem('metroStation'); // Récupérer la station de métro
        const userId = await AsyncStorage.getItem('userId');
        const savedLatitude = await AsyncStorage.getItem('latitude');
        const savedLongitude = await AsyncStorage.getItem('longitude');
        const savedPostalCode = await AsyncStorage.getItem('postalCode');

        // Vérifier si switchBtnUnlimited est true
        const switchBtnUnlimited = JSON.parse(await AsyncStorage.getItem('switchBtnUnlimited'));
        let attendeeLimitValue = switchBtnUnlimited ? 1000000 : parseInt(savedAttendeeLimit, 10);

        setTitle(savedTitle);
        setDate(savedDate);
        setStartTime({hour: parseInt(savedHour, 10), minute: parseInt(savedMinute, 10)});
        setAttendeeLimit(attendeeLimitValue);
        setHasPrice(savedHasPrice);
        setTopic(savedTopic);
        setEndTime(JSON.parse(savedEndTime));
        setLocation(prevLocation => ({
          ...prevLocation,
          address: savedAddress || '',
          latitude: parseFloat(savedLatitude),
          longitude: parseFloat(savedLongitude),
          postalCode: savedPostalCode || '',
        }));
        setPrice(savedPrice);
        setAuthor(userId);
        setMetroStation(savedMetroStation); // Mettre à jour la station de métro
      } catch (error) {
        console.error("Erreur lors de la récupération des détails de l'activité depuis AsyncStorage", error);
      }
    };
    fetchActivityDetails();
  }, []);

  useEffect(() => {
    const getTitle = async () => {
      const title = await AsyncStorage.getItem('selectedActivityTitle');
      setSelectedActivityTitle(title);
    };

    getTitle();
  }, []);

  const openIllustrationModal = () => {
    setModalVisible(true);
  };

  // Handle Cropped Image
  const handleCroppedImage = croppedImage => {
    setActivityImage(croppedImage);
    setCropperModalVisible(false);
  };

  useEffect(() => {
    console.log(activityImage);
    AsyncStorage.setItem('activityImage', activityImage);
    AsyncStorage.setItem('activityDescription', description);
    AsyncStorage.setItem('howToFind', howToFind);
  }, [activityImage, description, howToFind]);

  useEffect(() => {
    // Fonction de nettoyage pour supprimer les données du AsyncStorage lorsque la page est déchargée
    const cleanup = async () => {
      await AsyncStorage.removeItem('activityImage');
      await AsyncStorage.removeItem('activityDescription');
      await AsyncStorage.removeItem('howToFind');
    };

    cleanup();
  }, []);

  const saveActivity = async () => {
    const userId = await AsyncStorage.getItem('userId');
    const latitude = parseFloat(await AsyncStorage.getItem('latitude'));
    const longitude = parseFloat(await AsyncStorage.getItem('longitude'));
    const hour = await AsyncStorage.getItem('selectedHour');
    const minute = await AsyncStorage.getItem('selectedMinute');
    const postalCode = await AsyncStorage.getItem('postalCode');
    const address = await AsyncStorage.getItem('city');
    const locality = await AsyncStorage.getItem('locality');

    const startTime = {
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
    };

    // Assumer que endTime n'est pas configuré et est donc null
    const endTime = null;

    const activityData = {
      title,
      author,
      date,
      startTime: JSON.stringify(startTime), // Assurez-vous que startTime est un objet
      endTime: JSON.stringify(endTime), // De même pour endTime s'il est non null
      attendeeLimit,
      attendees: [userId],
      hasPrice,
      price,
      topic,
      activityImage,
      location: {address, latitude, longitude, postalCode, locality},
      metroStation, // Ajouter la station de métro
      description,
      howToFind,
      createAtivityType: await AsyncStorage.getItem('selectedQuestion'),
      localOnlyForAttendees: await AsyncStorage.getItem('switchBtn1'),
      inviteCommunity: await AsyncStorage.getItem('switchBtn2'),
      buyTicketsLink: await AsyncStorage.getItem('buyTicketsLink'),
      friendsNumber: await AsyncStorage.getItem('friendsNumber'),
      notifyPreviousAttendees: await AsyncStorage.getItem('notifyPreviousAttendees'),
      inviteMore: await AsyncStorage.getItem('inviteMore'),
      requestCoOrga: await AsyncStorage.getItem('requestCoOrga'),
      coOrganizerConditions: JSON.parse(await AsyncStorage.getItem('coOrganizerConditions')),
      coOrganizerGifts: JSON.parse(await AsyncStorage.getItem('coOrganizerGifts')),
      attendeesValidation: await AsyncStorage.getItem('attendeesValidation'),
    };

    const formDataToSend = new FormData();
    // Ajouter chaque propriété de activityData comme un champ dans le FormData
    Object.keys(activityData).forEach(key => {
      if (typeof activityData[key] === 'object' && activityData[key] !== null) {
        formDataToSend.append(key, JSON.stringify(activityData[key]));
      } else {
        formDataToSend.append(key, activityData[key]);
      }
    });

    // Ajouter l'image s'il y en a une
    if (activityImage && activityImage.file) {
      console.info('Selected image:', activityImage);
      formDataToSend.append('activityImage', activityImage.file);
    }

    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/activities`, {
        method: 'POST',
        body: formDataToSend,
        headers: {
          Authorization: 'Bearer ' + userToken,
        },
      });

      if (response.ok) {
        Platform.OS === 'web' ? navigation.navigate('Activities') : navigation.navigate('Home');
        setTitle('');
        setDate('');
        setStartTime({hour: 0, minute: 0});
        setAttendeeLimit(0);
        setHasPrice(false);
        setTopic(0);
        setPrice(0);

        // Récupérer toutes les clés du AsyncStorage
        const allKeys = await AsyncStorage.getAllKeys();

        // Filtrer les clés à conserver
        const keysToKeep = allKeys.filter(
          key => key === 'selectedLanguage' || key === 'selectedLanguageName' || key === 'userToken' || key === 'userId',
        );

        // Supprimer les clés restantes
        await AsyncStorage.multiRemove(allKeys.filter(key => !keysToKeep.includes(key)));

        // Ajouter un console.log pour vérifier que les clés ont été supprimées
        const remainingKeys = await AsyncStorage.getAllKeys();
        console.log('Clés restantes dans AsyncStorage :', remainingKeys);
      } else {
        console.error('Échec de la sauvegarde de l activité sur le serveur:', response.status, response.statusText);
        console.error("Détails de l'erreur du serveur:", await response.text());
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de l activité:', error);
    }
  };
  const handleNextStep = async () => {
    let errorMessage = '';
    // Vérifiez si les champs obligatoires sont remplis
    if (!activityImage) {
      errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonImageErrorMessage;
      setError(errorMessage);
    } else if (!description) {
      errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionErrorMessage;
      setError(errorMessage);
    } else if (!howToFind) {
      errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMeErrorMessage;
      setError(errorMessage);
    } else {
      await saveActivity(); // Enregistrez d'abord les données de l'activité
      onNextStep(); // Passez à l'étape suivante une fois que les données sont enregistrées
    }
  };

  const CropperComponent = Platform.select({
    web: () => <ImageCropperWeb file={activityImage.file} handleCroppedImage={handleCroppedImage} />,
    default: () => <ImageCropperMobile file={activityImage.file} handleCroppedImage={handleCroppedImage} />,
  });
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.container}>
        <View style={styles.title}>
          <Text style={styles.title}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonExplainYourActivityMessage}
          </Text>
        </View>
        {activityImage ? (
          // Appeler l'Image Cropper si Image Uploaded
          <>
            <Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
              <CropperComponent />
            </Modal>
            <Pressable onPress={openIllustrationModal}>
              <Pressable onPress={() => setCropperModalVisible(true)} style={styles.cropBtn}>
                <Text style={styles.cropBtnText}>
                  {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonEditButton}
                </Text>
                <Fields.EditSmall />
              </Pressable>
              {/* activityImage.imageUrl pour image choisis depuis le bibliotheque, activityImage pour unsplash/cropped image */}
              <Image source={{uri: activityImage.imageUrl ? activityImage.imageUrl : activityImage}} style={styles.imageStyle} />
            </Pressable>
          </>
        ) : (
          <Pressable style={styles.choosePhoto} onPress={openIllustrationModal}>
            <View style={styles.btnChoose}>
              <View style={styles.field}>
                <Fields.IconUpload />
              </View>
              <Text style={styles.btnChooseText}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonAddImageMessage}</Text>
            </View>
          </Pressable>
        )}
        <ActivityImagePicker
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          // ouvrir crop image automatiquement pour image local library
          onImageSelected={imageUrl => {
            setActivityImage(imageUrl);
            if (imageUrl.file) setCropperModalVisible(true);
            setError('');
          }}
          selectedActivityTitle={selectedActivityTitle}
        />

        <TextInputCustom
          placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionPlaceholder}
          handleTextChange={text => {
            setDescription(text.slice(0, 7500));
            setError('');
          }}
          value={description}
          multiline={true}
          numberOfLines={12}
          isPlaceHolderTextAlignCenter={false}
          maxLength={7500}
        />

        <TextInputCustom
          placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMePlaceholder}
          handleTextChange={text => {
            setHowToFind(text.slice(0, 7500));
            setError('');
          }}
          value={howToFind}
          multiline={true}
          numberOfLines={12}
          isPlaceHolderTextAlignCenter={false}
          maxLength={7500}
        />
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
          <ButtonNext
            conditionNeed={activityImage !== null && howToFind !== '' && description !== ''}
            handleNextStep={handleNextStep}
            btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPublishButton}
          />
        </View>
        <View style={styles.btnsRow}>
          <ButtonNext
            conditionNeed={false}
            handleNextStep={onNextStep}
            btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default CreateActivityCommonStepThree;


