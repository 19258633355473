// Note: Direct app linking to post on Instagram is not supported through URL schemes.
// This example just opens the Instagram app.
import React from 'react';
import {View, Text, Pressable, Linking} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';

const InviteByInstagram = () => {
  const {translations} = useLanguage();

  const inviteByInstagram = () => {
    const instagramLink = 'instagram://app';
    Linking.openURL(instagramLink);
  };

  return (
    <View>
      <Pressable onPress={inviteByInstagram}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteInstagram}</Text>
      </Pressable>
    </View>
  );
};

export default InviteByInstagram;
