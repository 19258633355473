// ProfileVisited.js
const profileVisited = (socket, notifyUser) => {
  socket.on('profileVisitedNotification', data => {
    console.log('Received profileVisitedNotification:', data); // Log la réception de la notification
    notifyUser(data.message); // Notifie l'utilisateur avec le message reçu
    console.log('Notified user with message:', data.message); // Log le message de notification
  });
};

export default profileVisited;
