// FR affiche le nombre d'utilisateurs en ligne
// EN "Display the number of users online."

import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

const OnlineFriends = () => {
  const [onlineFriends, setOnlineFriends] = useState(0);

  useEffect(() => {
    // Function to fetch the number of online friends from your backend API
    const fetchOnlineFriends = async () => {
      try {
        // Make a fetch request to your backend API
        const response = await fetch('YOUR_BACKEND_API_ENDPOINT');
        if (!response.ok) {
          throw new Error('Failed to fetch online users');
        }
        // Parse the response JSON
        const data = await response.json();
        // Update the state with the number of online friends
        setOnlineFriends(data.onlineFriends);
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchOnlineFriends function when the component mounts
    fetchOnlineFriends();

    // Optionally, you can set up a timer to periodically fetch the online friends
    // For example, to fetch every 30 seconds:
    const intervalId = setInterval(fetchOnlineFriends, 30000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Nombre d'amis en ligne: {onlineFriends}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default OnlineFriends;
