// FR choisir une date et une heure à partir d'un calendrier (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma031,FrameFigma039)
// EN Select a date and time from a calendar. (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma031,FrameFigma039)
import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';
import AsyncStorage from '@react-native-async-storage/async-storage';

import styles from './StylesMobile';

const DateTimePickerComponent = ({ title, onDateChange = "", dateExisting=null }) => {
    const [date, setDate] = useState(dateExisting);
    const [mode, setMode] = useState('date');
    const [show, setShow] = useState(false);

    // Calculer la date de demain
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const onChange = (event, selectedDate) => {
        const currentDate = selectedDate || date;
        setShow(Platform.OS === 'ios');
        setDate(currentDate);
        { onDateChange !== "" && onDateChange(date) }
        // Basculer entre les modes de date et d'heure
        if (mode === 'date') {
            setMode('time');
            setShow(true); // Afficher automatiquement l'horloge après la sélection de la date
        } else {
            setMode('date');
            setShow(false); // Cacher automatiquement le calendrier après la sélection de l'heure

            // Enregistrer l'heure sélectionner dans AsyncStorage
            const selectedHour = moment(currentDate).hours();
            const selectedMinute = moment(currentDate).minutes();

            AsyncStorage.setItem('selectedHour', selectedHour.toString())
            .then(() => {
                console.log('Heures sélectionnées enregistrées dans AsyncStorage:', selectedHour);
            })
            .catch(error => {
                console.error("Erreur lors de l'enregistrement des heures dans AsyncStorage:", error);
            });

            AsyncStorage.setItem('selectedMinute', selectedMinute.toString())
            .then(() => {
                console.log('Minutes sélectionnées enregistrées dans AsyncStorage:', selectedMinute);
            })
            .catch(error => {
                console.error("Erreur lors de l'enregistrement des minutes dans AsyncStorage:", error);
            });

            // Enregistrer la date et l'heure sélectionnées dans AsyncStorage
            AsyncStorage.setItem('selectedDate', moment(currentDate).format("YYYY-MM-DD"));
            AsyncStorage.setItem('selectedTime', moment(currentDate).format("HH:mm"));
        }
    };

    const showPicker = () => {
        setShow(true);
    };

    const formattedDate = date ? moment(date).format('DD/MM/YYYY à HH:mm') :  '__ / __ / ____ à __ : __';

    return (
        <View>
            <Pressable onPress={showPicker}>
                <View style={styles.container}>
                    <Text style={styles.label}>{title}</Text>
                    <Text style={{ marginHorizontal: 10 }}>{formattedDate}</Text>
                </View>
            </Pressable>
            {show && (
            <DateTimePicker
            testID="dateTimePicker"
            value={date || tomorrow} // Utiliser la date actuelle ou la date de demain comme valeur initiale
            mode={mode}
            is24Hour={true}
            display="default"
            onChange={onChange}
            minimumDate={tomorrow} // Bloquer les jours passés en définissant la date minimale à demain
          />
        )}
        </View>
        );
        };

export default DateTimePickerComponent;