// Fr Ce component affiche la view qui permet de supprimer une activité (FrameFigma041D)
// En This component displays the view which allows to delete an activity (FrameFigma041D)

// Import Modules
import React, { useState } from 'react';
import { View, Text, Platform, Pressable } from 'react-native';

// Import Contexts / Hooks
import { useNavigation } from '@react-navigation/native';
import { useLanguage } from '../../../../../../contexts/LanguageContext';

// Import Services
import { deleteActivity } from '../../../../../../services/Activities';

const DeleteActivity = ({ activity, onClose }) => {

  // Contexts
  const { translations } = useLanguage();
  const navigation = useNavigation();

  // States
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  // Delete Activity
  const handleDeleteActivity = async () => {
    try {
      const response = await deleteActivity(activity._id);
      if (!response.ok) {
        setError(true);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Navigation to new activity
  const handleNavigationToActivities = () => {
    navigation.navigate('Activities');
  }
  return (
    <View>
      {
        success && (
          <>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonPopupConfirmDelete}</Text>
            <Pressable onPress={handleNavigationToActivities}>
              <Text>{translations.JsonConfirmFriendshipPopup.JsonButtonClose}</Text>
            </Pressable>
          </>
        )}
      {
        error && (
          <>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonErrorCreateActivity}</Text>
          </>
        )
      }
      {
        !success && !error && (
          <>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonDeleteConfirmation}</Text>
            <Pressable onPress={handleDeleteActivity}>
              <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonYesButton}</Text>
            </Pressable>
            <Pressable onPress={onClose}>
              <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonCancel}</Text>
            </Pressable>
          </>
        )}
    </View>
  )
}

export default DeleteActivity
