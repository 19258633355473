// FR  affiche une interface utilisateur pour la confirmation de la suppression du compte (FrameFigma112)
// GB This code displays a user interface for confirming the deletion of the account (FrameFigma112)

import { View, Text, TextInput, TouchableOpacity, ScrollView, Platform } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../../contexts/UserContext';

const DeleteAccountStep2 = ({route}) => {
    const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
    const { deleteUserAccount } = useUser();
    const formData= route.params
    return (
        <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
            <View style={styles.textContainer}>
                <Text style={styles.bold}>One more time ...</Text>
                <Text style={styles.textNormal}>By deleting your account, you will lose all data</Text>
                <Text style={styles.textNormal}>So you your achievements, cups and awwards, friends message, liked user, history and more !!!</Text>
                <Text style={styles.textNormal}>This action is irreversible!!!</Text>
                <Text style={styles.textNormal}>Instead, write to our support and we will see if we can meet your expectations to keep you with us</Text>
                <Text style={styles.textNormal}>If you still want to leave us, know that we will respect your privacy. Your personal information will be permanently deleted from our database (once the legal retention periods have been respected).</Text>
                <Text style={styles.textNormal}>NB: Your email address will no longer be usable at Frimake</Text>
                <Text style={styles.bold}>Are you sure ?</Text>
            </View>
            <View style={styles.deleteCancelButtonRow}>
                <TouchableOpacity style={styles.cancelButton} onPress={() => { }}>
                    <Text style={[styles.buttonBigSize, styles.bold]}>CANCEL</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.deleteButton, styles.btnSelectedRed]}
                    onPress={() => deleteUserAccount(formData)} // Utilisation de la fonction de suppression
                >
                    <Text style={[styles.buttonBigSize, styles.bold, styles.buttonTextWhite]}>DELETE</Text>
                </TouchableOpacity>
            </View>
        </ScrollView>
    );
};

export default DeleteAccountStep2;
