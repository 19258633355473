import React from 'react';
import { View, Modal, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { hostname } from '../../../hostName/hostName';
import { useLanguage } from '../../contexts/LanguageContext';


  // Gère la sélection d'image à partir de l'input de type file
const ProfileImagePickerWeb = ({ onImageSelected, modalVisible, setModalVisible }) => {
  const {translations} = useLanguage();
  const handleImageSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      console.log("Image URL:", imageUrl);
      onImageSelected({ file: file, imageUrl: imageUrl });
      setModalVisible(false);
    }
  };


  return (
    <View style={styles.container}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            {/* Bouton pour déclencher l'input de type file. Rendre l'input de type file invisible tout en le rendant fonctionnel */}
            <TouchableOpacity style={styles.button}>
              <Text style={styles.textStyle}>{translations.JsonImagePickerModal.JsonLibrary}</Text>
              <input
                type="file"
                onChange={handleImageSelection}
                style={styles.fileInput}
                accept="image/*"
              />
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={() => setModalVisible(false)}>
              <Text style={styles.textStyle}>{translations.JsonActivityPages.JsonActivityScreen.JsonCancel}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    // Utilisation de boxShadow pour un effet d'ombre compatible web
    // La syntaxe générale est 'offsetX offsetY blurRadius spreadRadius color'
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    elevation: 5, // Laissez elevation pour Android, boxShadow n'aura pas d'effet sur cette plateforme
  },

  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: '#59C09B',
    marginBottom: 10,
    width: 200, // Assure que tous les boutons ont la même largeur
    position: 'relative', // Pour superposer l'input de type file sur le bouton
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
});

export default ProfileImagePickerWeb;
