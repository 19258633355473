import React, { useEffect } from 'react';
import { View, Text, Pressable, ScrollView, Image, TouchableWithoutFeedback } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useWebDrawer } from './WebDrawerContext';
import Fields from '../../Fields/Fields';
import { useLanguage } from '../../../contexts/LanguageContext';
import LogoutButton from '../../LogoutButton/LogoutButton';
import styles from './StyleWeb';
import { useUser } from '../../../contexts/UserContext';
import { useAuth } from '../../../contexts/AuthContext';
import ProfileMedal from '../../../components/ProfileMedal/ProfileMedal';
import {CalculateUserPointsDrawer} from '../../../utils/CalculateUserPointsDrawer';

const WebDrawer = () => {
  const { user, fetchUser } = useUser();
  const { translations } = useLanguage();
  const { isOpen, toggleDrawer } = useWebDrawer();
  const navigation = useNavigation();
  const t = translations.JsonDrawerNavMenu;

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);

  const navigateToScreen = (screenName) => {
    toggleDrawer(false);
    navigation.navigate(screenName, {
      userId: user?._id,
    });
  };

  const userPoints = CalculateUserPointsDrawer(user?._id ?? '');
  const userSponsorships = user?.sponsoredUsers?.length || 0;

  if (!user || !isOpen) {
    return null;
  }

  const screens = [
    { name: 'ConceptSlides', title: t.JsonConcept, icon: <Fields.IconConceptOn /> },
    {
      name: 'MyScreen',
      title: t.JsonMyProfile,
      icon: user.sexe === 'Male' ? <Fields.IconMaleIcon /> : <Fields.IconFemaleIcon />
    },
    { name: 'CreateActivity', title: t.JsonCreateEvent, icon: <Fields.CreateAnActivity /> },
    { name: 'InviteFriends', title: t.JsonInviteFriends, icon: <Fields.InviteFriends /> },
    { name: 'VipBadges', title: t.JsonBecomeVip, icon: <Fields.GoldStar /> },
    { name: 'Settings', title: t.JsonSettings, icon: <Fields.Settings /> },
    { name: 'News', title: t.JsonNews, icon: <Fields.News /> },
    { name: 'Update', title: t.JsonUpdate, icon: <Fields.Update /> },
  ];

  return (
    <View style={styles.overlay}>
      <TouchableWithoutFeedback onPress={() => toggleDrawer(false)}>
        <View style={styles.background} />
      </TouchableWithoutFeedback>
      <ScrollView style={styles.drawerContainer} contentContainerStyle={styles.drawerContentContainer}>
        <View style={styles.drawerHeader}>
          <Pressable onPress={() => navigateToScreen('MyScreen')}>
            {user.profileImage ? (
              <Image source={{ uri: user.profileImage }} style={styles.profileImage} />
            ) : (
              <Fields.NoGender />
            )}
          </Pressable>
          <View style={styles.userInfosContainer}>
            <View style={styles.userNameContainer}>
              <Text style={user.sexe === 'Male' ? styles.userNameMale : styles.userNameFemale}>
                {user.userName}
              </Text>
            </View>
              <View style={styles.trophyContainer}>
                <ProfileMedal role={user.role} points={userPoints} />
                <Text style={styles.textPoint}>{userPoints} {translations.JsonAchievementsPages.JsonMedalsScreen.JsonPoints}</Text>
                {/* TODO: Remplacer par la vrai valeur de la note de l'organisateur lors de l'implementation dans le back-end */}
                <Text >⭐⭐⭐⭐⭐</Text>
              </View>
            </View>
        </View>
        {screens.map((screen, index) => (
          <Pressable key={index} style={styles.drawerItem} onPress={() => navigateToScreen(screen.name)}>
            <View>
              {screen.icon}
            </View>
            <Text style={styles.drawerItemText}>{screen.title}</Text>
          </Pressable>
        ))}
        <View style={styles.drawerItem}>
          <LogoutButton style={styles.drawerItemText} />
        </View>
      </ScrollView>
    </View>
  );
};

export default WebDrawer;