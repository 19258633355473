// FR gère un composant de fenêtre modale de chat (FrameFigma025A,025,025B)
// EN This script manages a chat modal component (FrameFigma025A,025,025B)
import React, {createContext, useContext, useState} from 'react';

const ChatModalContext = createContext();

export const useChatModal = () => useContext(ChatModalContext);

export const ChatModalProvider = ({children}) => {
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [chatDetails, setChatDetails] = useState({
    chatId: null,
    userId: null,
    currentUser: null,
    privateMessages: {},
  });

const openChatModal = (chatId, userId, currentUser, privateMessages) => {
  console.log('Modal opening with chatId:', chatId, 'and messages:', privateMessages);
  console.log('Modal will be visible:', true);
  setChatDetails({chatId, userId, currentUser, privateMessages});
  setIsChatModalVisible(true);
};

  const closeChatModal = onCloseCallback => {
    setIsChatModalVisible(false);
    setChatDetails({chatId: null, userId: null, currentUser: null, privateMessages: {}});
    if (onCloseCallback) {
      onCloseCallback(); // Exécute la fonction de rappel après avoir fermé le modal
    }
  };

  return <ChatModalContext.Provider value={{isChatModalVisible, chatDetails, openChatModal, closeChatModal}}>{children}</ChatModalContext.Provider>;
};
