import {StyleSheet} from 'react-native';

export default StyleSheet.create({
   container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  nameContent: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '7%',
  },
  lastName: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

  },
  firstName: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    borderWidth: 1,
    marginVertical: 13,
    width: '100%',
    borderRadius: 13,
    paddingLeft: '10%',
    padding: 10,

    marginLeft: 1,
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
  },
  btnContainer: {
    width: '80%',
    alignItems: 'center',
    marginTop: '1%',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',

  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  comment: {
    marginTop: 10,
    fontSize: 12,
    width: '60%',
  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)',
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  errorText: {
    color: 'red',
    fontSize: 12,
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold',
  },
});
