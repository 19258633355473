// Fr Cette page affiche l'étape 2 pour créer une activité(FrameFigma032A,FrameFigma032B,FrameFigma032C)
// En This page displays the second step to create an activity(FrameFigma032A,FrameFigma032B,FrameFigma032C)

// Import Modules
import React, {useState, useEffect} from 'react';
import {View, Text, Pressable, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Components
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import AddressAutoPicker from '../../../../components/AddressAutoPicker/AddressAutoPickerActivites';
import AddressMapPickerMobile from '../../../../components/AddressMapPicker/AdressMapPickerMobile/AddressMapPickerMobile';
import AddressMapPickerWeb from '../../../../components/AddressMapPicker/AdressMapPickerWeb/AddressMapPickerWeb';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage'; // Importer le composant ErrorMessage
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext'; // Make sure to import ButtonNext

// Import Contexts / Hooks / utils
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useError} from '../../../../contexts/ErrorContext';

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ClassicStepTwo = ({onNextStep, onPrevStep}) => {
  const {translations} = useLanguage();
  const {error, setError} = useError();

  const [selectedCoords, setSelectedCoords] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [switchBtn, setSwitchBtn] = useState(false);
  const [selectedButton, setSelectedButton] = useState('Address'); // État pour suivre le bouton sélectionné
  const [meetUrl, setMeetUrl] = useState('');
  const [urlIsValid, setUrlIsValid] = useState();
  const [freeText, setFreeText] = useState(''); // Ajout d'un état pour le texte libre

  // Local Variables
  const t = translations.JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen;

  const handleButtonPress = buttonName => {
    setSelectedButton(buttonName); // Met à jour le nom du bouton sélectionné
    setError('');
  };

  const handleAddressSelect = (address, latitude, longitude) => {
    console.log('Selected Address:', address);
    setSelectedAddress(address);
    // Assurez-vous d'utiliser les clés lat et lng pour correspondre à l'attente de Maps.js
    setSelectedCoords({lat: latitude, lng: longitude});
    setError('');
    console.log('Selected Coords:', {lat: latitude, lng: longitude});
  };

  useEffect(() => {
    console.log('SwitchBtn State:', switchBtn);
  }, [switchBtn]);

  const adressePlaceholder =
    selectedButton === 'Address'
      ? t.JsonAddressPlaceholder
      : selectedButton === 'Online'
      ? t.JsonOnlinePlaceholder
      : selectedButton === 'Google maps url'
      ? t.JsonGoogleMapPlaceholder
      : '';

  // Handle text input url
  const handleMeetUrlChange = url => {
    setMeetUrl(url);
    setError('');
  };

  const handleFreeTextChange = (text) => {
    // Supprimer les caractères spéciaux
    const cleanedText = text.replace(/[^a-zA-ZÀ-ÿ0-9'\-\s]/g, '');
  
    // Limiter à 50 caractères
    const trimmedText = cleanedText.slice(0, 50);
  
    setFreeText(trimmedText);
  };

  const handleNextStep = async () => {
    if ((selectedButton === 'Online' && urlIsValid) || (selectedButton !== 'Online' && selectedAddress)) {
      setError('');
      if (switchBtn) {
        await AsyncStorage.setItem('metroStation', freeText);
      } else {
        await AsyncStorage.removeItem('metroStation');
      }
      onNextStep(); // Simplement passe à l'étape suivante
    } else {
      let errorMessage = 'Tu dois remplir toutes les conditions avant de continuer.';
      // Vérifie les conditions individuelles et met à jour le message d'erreur en conséquence
      if (selectedButton === 'Online' && !urlIsValid) {
        errorMessage = t.JsonOnlineErrorMessage;
      } else if (selectedButton !== 'Online' && !selectedAddress) {
        errorMessage = t.JsonAddressErrorMessage;
      }

      setError(errorMessage);
    }
  };

  // Handle URL Validation
  const validateUrl = () => {
    const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;
    if (meetUrl === '') {
      setError('');
      setUrlIsValid(false);
      AsyncStorage.removeItem('meetUrl');
      return;
    }
    if (urlRegex.test(meetUrl)) {
      setUrlIsValid(true);
      setError('');
      AsyncStorage.setItem('meetUrl', meetUrl);
    } else {
      setUrlIsValid(false);
      setError(t.JsonOnlineErrorMessage);
      AsyncStorage.removeItem('meetUrl');
    }
  };

  useEffect(() => {
    validateUrl();
  }, [meetUrl]);

  return (
    <View>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.container}>
        <View style={styles.buttonContainer}>
          {/* le style de boutton changé quand on switch entre les 3 bouttons */}
          <Pressable
            style={[styles.button, {backgroundColor: selectedButton === 'Address' ? '#59C09B' : 'white'}]}
            onPress={() => handleButtonPress('Address')}>
            <Text style={[styles.btnText, {color: selectedButton === 'Address' ? 'white' : '#59C09B'}]}>{t.JsonAddress}</Text>
          </Pressable>
          <Pressable
            style={[styles.button, {backgroundColor: selectedButton === 'Online' ? '#59C09B' : 'white'}]}
            onPress={() => handleButtonPress('Online')}>
            <Text style={[styles.btnText, {color: selectedButton === 'Online' ? 'white' : '#59C09B'}]}>{t.JsonOnline}</Text>
          </Pressable>
          <Pressable
            style={[styles.button, {backgroundColor: selectedButton === 'Google maps url' ? '#59C09B' : 'white'}]}
            onPress={() => handleButtonPress('Google maps url')}>
            <Text style={[styles.btnText, {color: selectedButton === 'Google maps url' ? 'white' : '#59C09B'}]}>{t.JsonGoogleMapsUrl}</Text>
          </Pressable>
        </View>

        {selectedButton === 'Online' ? (
          <View>
            <Text>{t.JsonOnlinePlaceholder}</Text>
            <TextInputCustom placeholderText={t.JsonOnlinePlaceholder} value={meetUrl} handleTextChange={handleMeetUrlChange} />
          </View>
        ) : (
          <>
            {/* ajout de view pour zindex(sinon suggestion caché par map et les espaces entre addressAutoPicker et map) */}
            <View style={styles.addressAutoPicker}>
              <AddressAutoPicker
                onAddressSelect={handleAddressSelect}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                selectedButton={selectedButton}
                addressType="activity"
                placeholder={adressePlaceholder}
              />
            </View>
            <View style={styles.map}>
              {/* view ici pour ajout des espace entre AddressAutoPicker et Map */}
              {Platform.OS === 'web' ? (
                <AddressMapPickerWeb selectedCoords={selectedCoords} />
              ) : (
                <AddressMapPickerMobile selectedCoords={selectedCoords} />
              )}
            </View>
            <View style={styles.switchBtn}>
              <Text style={styles.textbtn}>{t.JsonAddMetroIndication}</Text>
              <SwitchBtn state={switchBtn} setState={setSwitchBtn} />
            </View>
            {switchBtn && (
              <View style={styles.placeholder}>
                <TextInputCustom 
                  placeholderText="add your metro station"
                  value={freeText} 
                  handleTextChange={handleFreeTextChange} 
                  maxLength={50}
                  />
              </View>
            )}
          </>
        )}
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
          <ButtonNext
            conditionNeed={selectedAddress || urlIsValid}
            handleNextStep={handleNextStep}
            btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}
          />
        </View>
      </View>
    </View>
  );
};

export default ClassicStepTwo;
