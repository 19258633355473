// Fr  Styles de CreateActivityClassicStep2Screen( FrameFigma032A,FrameFigma032B,FrameFigma032C )
// En  Styles of CreateActivityClassicStep2Screen( FrameFigma032A,FrameFigma032B,FrameFigma032C )

import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 25,
    paddingRight: 25,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 5,
  },
  button: {
    padding: 6,
    backgroundColor: '#59C09B',
    borderRadius: 6,
    margin: 1,
  },
  inputContainer: {
    fontSize: 16,
    borderWidth: 1,
    marginVertical: 30,
    borderRadius: 15,
    padding: 10,
  },
  textTitle: {
    paddingHorizontal: 15,
    backgroundColor: 'white',
    fontSize: 13,
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    left: 20,
    top: -10,
  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)',
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  switchBtn: {
    //marginVertical: 10,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  textbtn: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 10,
  },
  btnContainer: {
    width: '100%',
    alignContent: 'center',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnNext: {
    backgroundColor: '#59C09B',

    padding: 8,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 15,
  },
  errorText: {
    backgroundColor: 'red',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
    textAlign: 'center',
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});
