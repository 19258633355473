// FR rend la partie supérieure de l'écran de profil utilisateur
// EN renders the top part of the user profile screen

import React, {useEffect, useState} from 'react';
import {View, ImageBackground, Text, TouchableOpacity, Platform} from 'react-native';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {Icons} from '../../../../constants/Icons';
import ProfileMedal from '../../../../components/ProfileMedal/ProfileMedal';
import {calculateUserPoints} from '../../../../utils/UserPointsCalculator';
import {useWebSocket} from '../../../../sockets/WebSocketLocal';

const MyProfileTopScreen = ({userId, user, profileOwner, navigation}) => {
  const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
  const {translations} = useLanguage();
  const {socket, requestChatHistory, privateChatMessages, updateCurrentChatId, currentChatId} = useWebSocket();
  const [showModal, setShowModal] = useState(false);
  const [userIsLiked, setUserIsLiked] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const userPoints = calculateUserPoints(userId || profileOwner?._id);

  const calculYears = (date1, date2) => {
    const diffInMs = Math.abs(date2 - date1);
    const msPerYear = 1000 * 60 * 60 * 24 * 365;
    return Math.floor(diffInMs / msPerYear);
  };

  return (
    <View style={styles.container}>
      <ImageBackground source={{uri: profileOwner?.profileImage}} style={styles.profileImage}>
        <TouchableOpacity onPress={() => navigation.navigate('EditProfile', {userData: user})} style={styles.editButton}>
          <Icons.Edit width={24} height={24} style={styles.editIcon} />
          <Text style={styles.editButtonText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonTitleEditProfile}</Text>
        </TouchableOpacity>
      </ImageBackground>
      <View style={styles.cupIconContainer}>
        <View style={styles.cupIcon}>
          <ProfileMedal role={profileOwner?.role} points={userPoints} />
        </View>
        <Text style={styles.pointsTextBelowCup}>
          {userPoints} {translations.JsonProfilePages.JsonMyProfileScreen.JsonPoints}
        </Text>
      </View>
      <View style={styles.infosContainer}>
        <Text style={styles.info}>Organizer: ⭐⭐⭐⭐⭐</Text>
        <Text style={styles.info}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonReliability}: -------------</Text>
      </View>
      <View style={styles.infosContainer2}>
        <Text style={styles.userName}>{profileOwner?.userName}</Text>
        {profileOwner?.useMyBirthday ? (
          <Text style={styles.info2Text}>
            {calculYears(profileOwner?.dateOfBirth, new Date())} {translations.JsonProfilePages.JsonMyProfileScreen.JsonYears}
          </Text>
        ) : (
          <Text>secret</Text>
        )}
        <View style={styles.infosCityRow}>
          <Icons.PinBlackAndWhite style={styles.Location} width={24} height={24} />
          <Text style={styles.text}>{profileOwner?.city?.split(',')[0]}</Text>
        </View>
      </View>
    </View>
  );
};

export default MyProfileTopScreen;

