import React, {useState} from 'react';
import {Linking, View, Text, Pressable} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';

const InviteByWhatsApp = () => {
  const {translations} = useLanguage();

  const inviteByWhatsApp = () => {
    const message = `${translations.JsonInviteFriendsScreen.JsonInvitationMessage}`;
    const whatsappLink = `whatsapp://send?text=${encodeURIComponent(message)}`;
    Linking.openURL(whatsappLink);
    // Ajoutez ici la logique pour incrémenter et sauvegarder le nombre d'invitations
  };

  return (
    <View>
      <Pressable onPress={inviteByWhatsApp}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteWhatsapp}</Text>
      </Pressable>
    </View>
  );
};

export default InviteByWhatsApp;
