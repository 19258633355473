// fr pour afficher des activités de maniére attrayante et permettre aux utilisateur d'interagir avec elle de maniére intuitive
// "To display activities in an attractive manner and allow users to interact with them intuitively."
import React, {useState, useEffect, useCallback} from 'react';
import {View, Text, Pressable, Image, Platform} from 'react-native';
import Fields from '../Fields/Fields';
import {activitiesIcon} from '../Fields/ActivityListIcon';
import {useNavigation} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {handleLike, handleUnlike} from './LikedActivityContent';
import {hostname} from '../../../hostName/hostName';
import {useFocusEffect} from '@react-navigation/native';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ActivityCard = ({activity, userId}) => {
  const navigation = useNavigation();
  const [liked, setLiked] = useState(false);
  const [likeId, setLikeId] = useState(null);
  const [authorDetails, setAuthorDetails] = useState(null);

  // Fonction pour vérifier l'état "liked"
  const checkLikedStatus = async () => {
    try {
      const userId = await AsyncStorage.getItem('userId');
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/activityLikes`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.ok) {
        const likedActivities = await response.json();
        const likedActivity = likedActivities.find(likedActivity => likedActivity.activity === activity._id && likedActivity.likedBy === userId);
        if (likedActivity) {
          setLiked(true);
          setLikeId(likedActivity._id); // Stockez l'ID du like s'il existe
        } else {
          setLiked(false);
          setLikeId(null); // Réinitialisez l'ID du like s'il n'existe pas
        }
      } else {
        console.error('Erreur lors de la récupération des activités likées:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des activités likées:', error);
    }
  };

  const fetchAuthorDetails = async () => {
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/users/${activity.author}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const authorDetails = await response.json();
        return authorDetails;
      } else {
        throw new Error("Erreur lors de la récupération des détails de l'auteur");
      }
    } catch (error) {
      console.error(error.message);
      return null;
    }
  };

  useEffect(() => {
    const fetchAuthor = async () => {
      const details = await fetchAuthorDetails();
      setAuthorDetails(details);
    };

    fetchAuthor();
  }, [activity.author]);

  useEffect(() => {
    checkLikedStatus(); // Appel de la fonction au montage du composant
  }, [activity._id]);

  useFocusEffect(
    useCallback(() => {
      checkLikedStatus();
    }, [activity._id]),
  );

  // Fonction pour gérer le like
  const handleLikeAction = async () => {
    await handleLike(activity);
    checkLikedStatus();
  };

  // Fonction pour gérer le "unlike"
  const handleUnlikeAction = async () => {
    if (likeId) {
      await handleUnlike(likeId);
      checkLikedStatus();
    } else {
      console.error("L'ID du like n'est pas disponible.");
    }
  };

  // Checks pour logged in User
  const userStatus = {
    // Retourne boolean
    participant: activity?.attendees.length > 0 ? activity.attendees?.includes(userId) : false,

    inWaitingList: activity?.waitingList.length > 0 ? activity.waitingList.includes(userId) : false,
  };
  return (
    <Pressable
      style={[
        styles.activityCard,
        liked && styles.activityCardLiked,
        userStatus.participant && styles.activityCardParticipant,
        userStatus.inWaitingList && styles.activityCardWaitingList,
      ]}
      onPress={() => navigation.navigate('Activity', {activityId: activity._id})}>
      <View style={styles.leftSide}>
        <View style={styles.activityImage}>
          <View style={styles.imageContainer}>
            <Image source={{uri: activity.activityImage}} style={styles.activityImageOne} />
          </View>
          <View
            style={[
              styles.userStatusTag,
              userStatus.participant
                ? styles.userStatusTag.participant
                : userStatus.inWaitingList
                ? styles.userStatusTag.waitingList
                : styles.userStatusTag.topic,
            ]}>
            <Text style={styles.userStatusTag.text}>
              {userStatus.participant
                ? 'Attendee'
                : userStatus.inWaitingList
                ? 'Waiting List'
                : activitiesIcon[activity.topic]?.activityTitle || 'Activity'}
            </Text>
          </View>
        </View>
        <View style={styles.componentHeart}>
          <Pressable style={styles.heart} onPress={liked ? handleUnlikeAction : handleLikeAction}>
            {liked ? <Fields.HeartRed /> : <Fields.HeartGrey />}
          </Pressable>
        </View>
      </View>
      <View style={styles.rightSide}>
        <View style={styles.centerItem}>
          <View>
            <Text style={styles.activityTitle}>{activity.title}</Text>
          </View>
          <View style={styles.authorImageView}>
            <Image source={{uri: authorDetails ? authorDetails.profileImage : null}} style={styles.authorImage} />
          </View>
        </View>
        <View>
          {activity.startTime ? (
            <Text style={styles.activityStartTime}>
              {activity.startTime.hour}:{activity.startTime.minute}
            </Text>
          ) : (
            <Text style={styles.activityStartTime}>null</Text>
          )}
        </View>
        <View style={styles.centerItem}>
          <View style={styles.bottomItem}>
            <View style={styles.bottomItemCenter}>
              <Fields.Participants style={{width: 20, height: 20}} />
              <Text style={styles.textParticipants}>
                {activity.attendeeLimit === 1000000 ? activity.attendees.length : activity.attendees.length + '/' + activity.attendeeLimit}
              </Text>
            </View>
            <View style={[styles.bottomItemCenter, {marginLeft: 15}]}>
              <Fields.LocalisationIcon />
              <Text>{activity?.location.postalCode ? activity?.location.postalCode : activity?.location.locality}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.activityBottomTitle}>{activity.startTime?.hour+":"+activity.startTime?.minute}</Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default ActivityCard;
