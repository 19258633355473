// Import Modules
import React, { useState } from 'react';
import { View, Text, TextInput, Pressable, Platform } from 'react-native';

// Import Components
import Step2ConfirmationScreen from '../../Step2ConfirmationScreen/Step2ConfirmationScreen';
import PhoneWebPicker from '../../../PhonePicker/PhoneWebPicker/PhoneWebPicker';

// Import Contexts
import { useLanguage } from "../../../../contexts/LanguageContext";
import { addFriendPostRequest } from '../../../../utils/AddFriendPostRequest';

// Import Styles - Attention - ce fichier utilise les styles de parent Step1InputScreen
import { styles } from '../../Step1InputScreen/Step1InputScreen';

const PhoneInputScreen = ({ icon, currentUser, onClose }) => {
  // Contexts
  const { translations } = useLanguage();
  const t = translations.JsonConfirmFriendshipPopup

  // States
  const [phone, setPhone] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  // Handle Form Input
  const handleInputChange = (value) => {
    setPhone(value);
  };
  
  // Handle Submit & Verification
  const handleSubmit = async () => {
    setButtonClicked(true);

    if (phone === currentUser.phone) {
      // Show Success Modal
      setVerificationSuccess(true);

      // Send Backend Requests to Add Friend
      addFriendPostRequest(currentUser._id)
    }
  };

  return (
    <>
      {buttonClicked ? (
      <Step2ConfirmationScreen verificationSuccess={verificationSuccess} onClose={onClose} />
      ) : (
        <>
          <View>
            <Text style={styles.modalTitle}>{t.JsonPopupEnterTelephone}</Text>
            {icon}
          </View>
          <PhoneWebPicker value={phone} onPhoneNumberChange={handleInputChange} />
          <View>
            <Pressable 
              style={[styles.confirmButton, { backgroundColor: phone ? '#59C09B' : '#6f6f6f'}]}
              disabled={!phone}
              onPress={handleSubmit}
            >
              <Text style={styles.confirmButton.buttonText}>{t.JsonButtonConfirm}</Text>
            </Pressable>
          </View>
        </>
      )}
    </>
  );
};

export default PhoneInputScreen