// FR une liste horizontale d'utilisateurs, affichée sous forme de cartes horizontales à l'écran (FrameFigma044)
// EN "A horizontal list of users, displayed as horizontal cards on the screen."(FrameFigma044)
// Import Modules
import React from "react";
import { View, Platform } from "react-native";

// Import Components
import UserCardHorizontal from "../UserCardHorizontal/UserCardHorizontal";

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const UserListHorizontal = ({ userList, prop1, prop2 }) => {
  return (
    <>
      {userList.map((user) => (
        <View key={user._id} style={styles.listContainer}>
          <UserCardHorizontal user={user} prop1={prop1[user._id]} prop2={() => prop2(user._id)}/>
        </View>
      ))}
    </>
  );
};

export default UserListHorizontal