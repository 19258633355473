//🇫🇷 Ce fichier gère le style mobile du composant EmailScreen.js (FrameFigma004A)
//🇬🇧 This file manages the mobile style of the EmailScreen.js component (FrameFigma004A)

import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 60,
    marginHorizontal: 30,
  },
  titlemail: {
    fontSize: 12,
    marginTop: 30,
    marginHorizontal: 30,
  },
  content: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginHorizontal: 'auto',
  },
  email: {
    marginTop: 10,
    marginHorizontal: 30,
  },
  email2: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
input: {
  borderWidth: 1,
  marginVertical: 13,
  width: '100%',
  borderRadius: 15,
  position: 'relative',
  paddingLeft: '10%',
  backgroundColor: '#F0EEE9',
  height: 50,
  justifyContent: 'center',
},
  icon: {
    position: 'absolute',
    marginLeft: '2%',
    zIndex: 1,
  },
  btnContainer: {
    width: '70%',
    alignItems: 'center',
    marginVertical:'10%'
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  centerTerms: {
    width: '60%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  terms: {
    fontSize: 12,
  },
  underline: {
    fontSize: 14,
    textDecorationLine: 'underline',
    marginLeft: 4,
  },
  condition: {
    width: '65%',
    // flexDirection: 'column',
    flexDirection: 'row',
    flexWrap:'wrap',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  buttonActive: {
    backgroundColor: '#59C09B', 
    width: '55%',
    padding: 8,
    borderRadius: 15,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  buttonInactive: {
    backgroundColor: 'grey',
    width: '55%', 
    padding: 8,
    borderRadius: 15,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  }  
});
