// Fr  Styles de CreateActivityCommonStep2Screen(FrameFigma036)
// En  Styles of CreateActivityCommonStep2Screen(FrameFigma036)
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingLeft: 25,
    paddingRight: 25,
  },
  title: {
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 18,
    marginVertical: 10,
  },
  info: {
    marginVertical: 3,
    paddingHorizontal: 20,
  },
  activityIconContainer: {
    flexWrap: 'wrap',
    backgroundColor: '#DDDDDD',
    borderRadius: 30,
    opacity: 0.5,
    marginTop: 15,
    marginHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  activityCard: {
    marginHorizontal: 4,
    marginVertical: 6,
    borderRadius: 10,
    width: 60,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityTitle: {
    marginBottom: 5,
    fontSize: 12.5,
  },
  activityView: {
    position: 'relative',
  },
  flexView: {
    flex: 1,
    justifyContent: 'center',
  },
  btnContainer: {
    width: '100%',
    alignContent: 'center',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '55%',
    padding: 8,
    borderRadius: 15,
    elevation: 5,
    margin: 15,
    alignItems: 'center',
  },
  btnText: {
    color: 'white',
    fontSize: 16,
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});
