//Fr Styles de ActivityImagePicker (FrameFigma037A)
//En Styles of ActivityImagePicker (FrameFigma037A)
import { StyleSheet } from "react-native";

export default StyleSheet.create({
    modalContainer: {
        flex: 1,
        // backgroundColor: "rgba(0,0,0,0.5)",
        alignItems: "center",
    },
    askModalView: {
        width: "100%",
        marginBottom: "2.5%",
        alignContent: "flex-end",
        paddingTop: "2.5%",
        paddingBottom: "5%",
        paddingHorizontal: "2.5%",
        borderRadius: 15,
        backgroundColor: "white",
    },
    askSourceBtn: {
        margin: "2.5%", 
        alignSelf: "flex-end"
    },
    modalMsgTxt: {
        fontSize: 18, 
        textAlign: "center", 
        fontWeight: "bold"
    },
    personalPicContainer: {
        marginTop: "10%",
        flexDirection: "row",
        justifyContent: "space-around",
    },
    unsplashImgContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    photoSourceIcon: {
        marginBottom: 5,
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: "#dcf1ea",
        justifyContent: "center",
        alignItems: "center",
    },
    modalImgTitle: {
        textAlign: "center", 
        fontWeight: "bold"
    },
    personalImageContainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
    },
    photoSourceIcon: {
        marginBottom: 5,
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: "#dcf1ea",
        justifyContent: "center",
        alignItems: "center",
    },
    modalImgTitle: {
        textAlign: "center", 
        fontWeight: "bold"
    },
    unsplashModalView: {
        width: "90%",
        maxWidth: 450,
        height: "90%",
        marginVertical: "5%",
        paddingTop: "5%",
        paddingBottom: "5%",
        paddingHorizontal: 5,
        borderRadius: 15,
        backgroundColor: "white",
    },
    unsplashModalTitle: {
        marginTop: "5%",
        fontWeight: "bold",
        fontSize: 20,
        textAlign: "center",
    },
    unsplashModalText: {
        marginVertical: "5%",
        textAlign: "center",
        fontSize: 16,
    },
    unsplashModalBtn: {
        margin: "2.5%",
        alignSelf: "flex-end"
    },
    searchContainer: {
        flexDirection: "row",
        marginBottom: 10,
    },
    searchInput: {
        flex: 1,
        borderWidth: 1,
        borderColor: "#ccc",
        borderRadius: 5,
        paddingHorizontal: 10,
        marginRight: 10,
        color: "#333",
        fontSize: 14,
        paddingVertical: 8,
    },
    searchButton: {
        backgroundColor: "#59bf9b",
        paddingHorizontal: "5%",
        paddingVertical: "2.5%",
        borderRadius: 5,
    },
    searchButtonText: {
        color: "#fff",
        fontSize: 14,
        fontWeight: "bold",
    },
    choosePhoto: {
        backgroundColor: "#DCF1EA",
        padding: 70,
        borderRadius: 15,
        marginVertical: 10,
        marginLeft: 10,
        marginRight: 30,
    },
    btnChoose: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    field: {
        margin: 5, 
    },
})
