// Fr  Styles de ActivityAttendeesWithButtons(FrameFigma041,FrameFigma042)
// En  Styles of ActivityAttendeesWithButtons(FrameFigma041,FrameFigma042) 

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        marginVertical: 10,
        marginHorizontal: 50,
        gap: 10,
    },
    interestedContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    interestedRow: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent: 'center',
    },
    interestedText: {
        color: 'gray',
    },
    interestedCircle: {
        backgroundColor: '#59C09B80',
        borderRadius:25,
        width:30,
        height:30,
        textAlign:'center',
        paddingTop:4,
    },
    follower: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    followerHeart: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    heartText: {
        color: 'gray', 
        marginLeft: -20,
        justifyContent: 'center',
    },
    heartIcon: {
        width:30,
        height:30
    },
    participantContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    attendeeLimit: {
      alignItems: 'center',
    },
    btnContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '95%',
        marginVertical: 15,
        marginTop: '10%',
        marginLeft: 10,
      },
      btnContent: {
        width: '48%',
      },
      btnParticipate: {
        backgroundColor: '#59C09B',
        paddingVertical: 12,
        paddingHorizontal: 13,
        borderRadius: 10,
      },
      btnUnsubscribe: {
        backgroundColor: 'red',
        paddingVertical: 12,
        paddingHorizontal: 13,
        borderRadius: 10,
      },
      editTrash:{
        flexDirection: 'row',
        alignSelf: 'flex-end',       
      },
      btnShare: {
        backgroundColor: 'gray',
        paddingVertical: 12,
        paddingHorizontal: 13,
        borderRadius: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      btnText: {
        color: 'white',
        fontSize: 18,
        textAlign: 'center',
      },
      aboutText: {
        fontWeight: 'bold',
        fontSize: 16,
      },
      description: {
        padding: 10,
        backgroundColor: "white",
        marginVertical: 20,
      },
      descriptionText: {
        marginTop: 10,
        padding: 5,
        color: "gray",
        textAlign: "justify",
        height: 200,
      },
      btnHelp: {
        backgroundColor: '#FFD700',
        paddingVertical: 12,
        paddingHorizontal: 13,
        borderRadius: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      btnCopy: {
        backgroundColor: 'gray',
        paddingVertical: 12,
        paddingHorizontal: 13,
        borderRadius: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      btnHelpText: {
        color: 'white',
        fontSize: 15,
        textAlign: 'center',
      },
      btnCopyText: {
        color: 'black',
        fontSize: 12.6,
        textAlign: 'center',
      }
})

export default styles;