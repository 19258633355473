// FR pour intégrer facilement fonctionnalité de selection image à partir de différentes source dans apllication (FrameFigma037A)
// EN To easily integrate image selection functionality from various sources into an application (FrameFigma037A)
import React, { useState } from "react";
import { View, Modal, Pressable, Text, Platform } from "react-native";
import Fields from "../Fields/Fields";
import UnsplashPicker from "../UsplashPicker/UsplashPicker";
import ProfileImagePickerWeb from "../ImagePicker/ImagePickerWeb";
import ProfileImagePickerAndroid from "../ImagePicker/ImagePickerAndroid";
import ProfileImagePickerIOS from "../ImagePicker/ImagePickerIOS";
import { useLanguage } from "../../contexts/LanguageContext";

const styles = Platform.OS === 'web'
  ? require('./StylesWeb').default
  : require('./StylesMobile').default;

const ActivityImagePicker = ({ modalVisible, setModalVisible, onImageSelected, selectedActivityTitle }) => {
  const {translations} = useLanguage();
  const [modalUnsplashVisible, setModalUnsplashVisible] = useState(false);
  const [platformSpecificModalVisible, setPlatformSpecificModalVisible] = useState(false);

  const PlatformSpecificImagePicker = Platform.select({
   ios: ProfileImagePickerIOS,
   android: ProfileImagePickerAndroid,
   web: ProfileImagePickerWeb,
 });
  // Fonction pour gérer la sélection d'image
  const handleImageSelected = (imageUrl) => {
    // Mettez à jour le composant parent avec l'image sélectionnée
    if (onImageSelected) {
      onImageSelected(imageUrl);
    }
    // Fermez tous les modaux
    setModalVisible(false);
    setModalUnsplashVisible(false);
    setPlatformSpecificModalVisible(false);
  };

  // Fonction pour ouvrir le modal Unsplash
  const openUnsplashPicker = () => {
    setModalVisible(false);
    setModalUnsplashVisible(true);
  };

  // Fonction pour ouvrir le modal spécifique à la plateforme
  const openPlatformSpecificModal = () => {
    setModalVisible(false);
    setPlatformSpecificModalVisible(true);
  };
const onImageSelectedHandler = (data) => {
  console.log('Selected image from Local Source file:', data.file);
  console.log('Selected image from Local Source imageUrl:', data.imageUrl);
      onImageSelected({file: data.file, imageUrl: data.imageUrl});
};
  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={[styles.modalContainer, { justifyContent: "flex-end" }]}>
          <View style={styles.askModalView}>
            <Pressable
              style={styles.askSourceBtn}
              onPress={() => setModalVisible(false)}
            >
              <Text> X </Text>
            </Pressable>
            <Text style={styles.modalMsgTxt}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonPickPhotoFrom}</Text>
            <View style={styles.personalPicContainer}>
              <View style={styles.unsplash}>
                <Pressable
                  style={styles.photoSourceIcon}
                  onPress={openUnsplashPicker}
                >
                  <Fields.UnsplashFill />
                </Pressable>
                <Text style={styles.modalImgTitle}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonPickPhotoFromUnsplash}</Text>
              </View>
              <View>
                <Pressable
                  style={styles.photoSourceIcon}
                  onPress={openPlatformSpecificModal}
                >
                  <Fields.ImageGallery />
                </Pressable>
                <Text style={styles.modalImgTitle}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonPickPhotoFromLibrary}</Text>
              </View>
            </View>
          </View>
        </View>
      </Modal>
     <UnsplashPicker
      modalVisible={modalUnsplashVisible}
      setModalVisible={setModalUnsplashVisible}
      onImageSelected={handleImageSelected}
      searchTermInitial={selectedActivityTitle}
    />


      <PlatformSpecificImagePicker
          onImageSelected={onImageSelectedHandler}
          modalVisible={platformSpecificModalVisible} 
          setModalVisible={setPlatformSpecificModalVisible}
      />
    </View>
  );
};

export default ActivityImagePicker;
