// 🇫🇷 cette page affiche les informations sur le premium badges Emerald star (FrameFigma051C)
// 🇫🇷 this page displays information about the premium Emerald star badges (FrameFigma051C)


import React from "react";
import { Text, View, Image, Pressable, ScrollView, ImageBackground } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components 
import styles from "./StylesWeb.js"

// import des icons
import { Icons } from "../../../../constants/Icons.js";
import { useLanguage } from '../../../../contexts/LanguageContext.js';


const VipBadgesEmerald = (props) => {
    const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
    const {translations} = useLanguage();
  
    const navigation = useNavigation();

// 🇫🇷 Trait permettant d'afficher la separation entre les étoiles(Golden, Emurald, Ruby ,Diamond) et les parrainages 
  // 🇬🇧 Feature for displaying the separation between the stars (Golden, Emerald, Ruby, Diamond) and sponsorships
 
  const SeparatorE = () => {
    return <View style={styles.separatorE} />;
  }

  
  return (
    <ScrollView>
     <View style={styles.container}>
       <View style={styles.rowText}>
          <Text style={styles.text}>

            {translations.JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
        </View>
        

        <View style={styles.button}>
           {/* Utilisez ImageBackground pour définir l'image comme arrière-plan */}
           <View style={styles.backgroundContainer}>
                <Icons.EmeraldBorder style={styles.backgroundImage} />
            </View>
          <Icons.StarsEmerald style={styles.img} />
            <Text style={styles.emeraldtitle} > {/*title*/}
                {translations.JsonAchievementsPages.JsonEmeraldStar}
            </Text>

            <SeparatorE />
            {/* <Text style={styles.emerald} >
                  9.90€/month 
            </Text> */}
            <View style={styles.container}>
            {/*texte random*/}
                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

             
                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

             
            </View>
            <Pressable style={styles.buttonGrey}   
            onPress={() => navigation.navigate('VipBoardExplanation')}
            >
                <Icons.StarsEmerald style={styles.imgsmall} />
                <Text style={styles.textbutton}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonMoreInformations}
                </Text>

           </Pressable> 
            
        </View>  
         
        <Text style={styles.Text}>
           {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDonatorToGetAStar}
        </Text>
      </View>
    </ScrollView>

    );
};
  
 
export default VipBadgesEmerald;
