import { StyleSheet } from "react-native";

export default StyleSheet.create({
    activityCard: {
        marginVertical: 15,
        marginHorizontal: 10,
        height: 110,
        flexDirection: "row",
        alignItems: "center",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        backgroundColor: 'white',
    },
    activityCardLiked: {
        backgroundColor: '#FEEBEF',
    },
    leftSide: {
        justifyContent: "space-between",
    },
    activityImageOne: {
        width: 110,
        height: 110,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        resizeMode: "cover",
    },
    activityImage: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
    },
    userStatusTag: {
        position: "absolute",
    },
    componentHeart: {
        position: "absolute", 
        top: 4, 
        left: 5,
        width: 12,
        height: 12,
    },
    rightSide: {
        width: '70%',
        padding: 10,
    },
    centerItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    activityTitle: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    activityStartTime: {
        fontWeight: "bold",
        color: "#F48A0D",
    }, 
    authorImageView: {
        paddingBottom: 5,
        justifyContent: "center",
        alignItems: "center",
    }, 
    authorImage: {
        width: 45, 
        height: 45, 
        borderRadius: 50, 
        justifyContent: 'center'
    },
    bottomItem: {
        flex: 1,
        flexDirection: "row"
    },
    bottomItemCenter: {
        flexDirection: "row",
        alignItems: "center",
    },
    textParticipants: {
        marginLeft: 5,
        fontWeight: "bold",
    },
    activityBottomTitle: {
        color: "#4D96E9",
        fontWeight: "bold",
    }
})
