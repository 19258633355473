// FR le parent du profil utilisateur, il gère l'affichage global du profil
// EN  the parent of the user profile, responsible for managing the overall display of the profile

import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Text } from 'react-native';

// Importez vos composants TopProfile et BottomProfile
import MyProfileTopScreen from './MyProfileTopScreen/MyProfileTopScreen';
import MyProfileBottomNavigator from './MyProfileBottomNavigator';
// Import du contexte d'utilisateur
import { useUser } from '../../../contexts/UserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../hostName/hostName';
import { useWebSocket } from '../../../sockets/WebSocketLocal';
import { getActivities } from '../../../utils/ActivityFetch';
import { useLanguage } from '../../../contexts/LanguageContext';


const ProfileParent = ({ route, navigation }) => {
  // Utiliser le contexte pour accéder aux données de l'utilisateur
  const { user, fetchUserDetails, allUsers } = useUser();
  const [userActivities, setUserActivities] = useState([]);
  const userID = route.params.userId
  const profileOwner = userID === user?._id ? user : allUsers.find(u => u._id === userID)
  // Charger les détails de l'utilisateur au montage du composant
  useEffect(() => {
    fetchUserDetails();
  }, []);
  useEffect(() => {
    const fetchActivities = async () => {
      const activities = await getActivities(profileOwner?._id);
      setUserActivities(activities.uniqueUserActivities);
    };
    fetchActivities();
  }, [profileOwner]);

  // Retourner l'interface utilisateur
  return (
    <ScrollView style={styles.container}>
    <Text>Profile de {userID}</Text>
      {/* Haut de profil */}
      <MyProfileTopScreen userId={userID} user={user} profileOwner={profileOwner} navigation={navigation} />
      {/* Bas de profil */}
      <MyProfileBottomNavigator userId={userID} user={user} navigation={navigation} allUsers={allUsers} profileOwner={profileOwner} userActivities={userActivities} />
      {/* a creer */}

    </ScrollView>
  );
};

// Styles du composant ProfileParent
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white'
    // Vous pouvez ajouter plus de styles selon vos besoins
  },
});

export default ProfileParent;
