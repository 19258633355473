import {View, Text, Platform, TextInput, TouchableOpacity, Image, Pressable} from 'react-native';
import React, {useRef, useState, useEffect} from 'react';
import {hostname} from '../../../../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Clipboard from '@react-native-clipboard/clipboard';
import paste from '../../../../assets/paste.png';
import {useLanguage} from '../../../../contexts/LanguageContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import {useError} from '../../../../contexts/ErrorContext';
import {useAuth} from '../../../../contexts/AuthContext';
import {useUser} from '../../../../contexts/UserContext';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const VerifyEmailExistingUserScreen = ({navigation, route}) => {
  const {translations} = useLanguage();
  const {language} = useLanguage();
  const {error, setError} = useError();
  const {login} = useAuth();
  const {updateUserState} = useUser();
  const {newPassword} = route.params;

  const pinRefs = useRef([...Array(6)].map(() => React.createRef()));
  const [pins, setPins] = useState(Array(6).fill(''));
  const [email, setEmail] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(180);

  useEffect(() => {
    getEmailFromStorage();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 0) {
          clearInterval(timer);
          setResendDisabled(false);
        }
        return prevCountdown === 0 ? 0 : prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  const verificationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .matches(/^[0-9]+$/, translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonPhoneNotValid)
      .length(6, translations.JsonLoginRegisterPages.JsonVerificationCode.JsonShortMessage)
      .required(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode),
  });

  const getEmailFromStorage = async () => {
    try {
      const storedEmail = await AsyncStorage.getItem('email');
      console.log('Email retrieved from storage:', storedEmail);
      if (storedEmail) {
        setEmail(storedEmail);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'e-mail depuis le AsyncStorage", error);
    }
  };

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const sendVerificationEmail = async () => {
    setResendDisabled(true);
    setCountdown(180);
    console.log('Sending verification email to:', email);
    try {
      const response = await fetch(`${hostname}/sendVerificationExistingUser`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email}),
      });
      console.log('Email sent response:', response);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande de vérification par e-mail", error);
    }
  };

  const handleVerification = async () => {
    const verificationCode = pins.join('');
    console.log('Verification code entered:', verificationCode);
    if (!verificationCode) {
      setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode);
      return;
    }
    try {
      await verificationSchema.validate({verificationCode});
      const response = await fetch(`${hostname}/verifyEmailExistingUser`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({verificationCode}),
      });
      console.log('Verification response:', response);
      if (response.ok) {
        const responseData = await response.json();
        console.log('Verification response data:', responseData);
        if (responseData.success) {
          // Update password after successful verification
          await updatePassword(email, newPassword);

          // Store auth token and user data
          await AsyncStorage.setItem('authToken', responseData.authToken);
          await AsyncStorage.setItem('userData', JSON.stringify(responseData.user));
          await AsyncStorage.setItem('userId', responseData.user._id);
          await AsyncStorage.setItem('userEmail', responseData.user.email);
          await AsyncStorage.setItem('profileImage', responseData.user.profileImage);
          await AsyncStorage.setItem('userName', responseData.user.userName);

          // Update global auth state
          login(responseData.authToken, responseData.user);
          updateUserState(responseData.user);

          // Navigate to activities
          navigation.navigate('Activities', {userId: responseData.user._id});
          setError('');
        } else {
          setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
        }
      } else {
        setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
      }
    } catch (error) {
      console.error('Error during verification process:', error);
      setError(error.message);
    }
  };

  const updatePassword = async (email, newPassword) => {
    try {
      const response = await fetch(`${hostname}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, newPassword}),
      });
      const responseData = await response.json();
      console.log('Password update response:', responseData);
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  const handlePasteText = async () => {
    const text = await Clipboard.getString();
    const filteredText = text.replace(/[^\d]/g, '');
    const textArray = filteredText.split('').slice(0, 6);

    if (text !== filteredText) {
      setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCopyPaste);
    } else {
      setError('');
    }

    setPins(pins.map((pin, index) => textArray[index] || pin));
  };

  const updatePin = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newPins = [...pins];
      newPins[index] = value;
      setPins(newPins);
      setError('');

      if (value && index < 5) {
        pinRefs.current[index + 1].current.focus();
      } else if (!value && index > 0) {
        pinRefs.current[index - 1].current.focus();
      }
    } else {
      setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonOnlyNumbersAllowed);
    }
  };

  const handleTextChange = (index, value) => {
    updatePin(index, value);
    if (error) {
      setError('');
    }
  };

  return (
    <View key={language} style={styles.verification}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.container}>
        <Text style={styles.verificationText}>{translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode}</Text>
        <TouchableOpacity onPress={handlePasteText}>
          <Image style={styles.paste} source={paste} />
        </TouchableOpacity>
        <View style={styles.inputs}>
          {pins.map((pin, index) => (
            <TextInput
              key={index}
              ref={pinRefs.current[index]}
              style={styles.opt}
              keyboardType={'number-pad'}
              maxLength={1}
              value={pin}
              onChangeText={value => handleTextChange(index, value)}
            />
          ))}
        </View>
        <View style={styles.btnContainer}>
          <ButtonNext
            handleNextStep={handleVerification}
            btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
            conditionNeed={pins.join('').length === 6}
          />
        </View>
        <View style={styles.btnResendContainer}>
          <Pressable onPress={sendVerificationEmail} disabled={resendDisabled}>
            <Text style={[styles.btnTextResend, resendDisabled && styles.btnTextDisabled]}>
              {resendDisabled ? formatTime(countdown) : translations.JsonLoginRegisterPages.JsonVerificationCode.JsonResendButton}
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default VerifyEmailExistingUserScreen;
