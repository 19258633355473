// FR l'écran affiche une liste d'activités organisées par date, avec la possibilité d'ajouter de nouvelles activités. (FrameFigma021A,021B)
// GB The screen displays a list of activities organized by date, with the option to add new activities. (FigmaFrame21A,021B)

// Import Modules
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView, View, Platform, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Components
import ActivityCard from '../../../components/ActivityCard/ActivityCard';

// Import Contexts / Hooks / Utils
import { useActivity } from '../../../contexts/ActivityContext';
import { useLanguage } from '../../../contexts/LanguageContext';

// Import Icons / Images
import { Icons } from '../../../constants/Icons';

// Import Styles
const styles =
    Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;

const ActivitiesScreen = ({ navigation }) => {
    // Context
    const { activities, getActivities } = useActivity();
    const { language } = useLanguage();

    // States
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState({});

    // Fetch User from Async Storage
    // ATTENTION - Impossible d'utiliser le User Context ou Auth Context pour le moment
    const getUserFromStorage = async () => {
        const userID = await AsyncStorage.getItem('userId');
        setUserId(userID);
    };

    // Filter activities to only include those starting from today
    const filteredActivities = activities.filter(activity => {
        const activityDate = new Date(activity.date);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time part for comparison
        return activityDate >= today && !activity.unApprovedUsers.includes(userId);
    });

    let groupedActivities = {};

    if (filteredActivities.length > 0) {
        // Trier les activités par date
        const sortedActivities = filteredActivities?.sort((a, b) => new Date(a.date) - new Date(b.date));

        // Format Date to local
        const formatDate = (dateStr) => {
            const date = new Date(dateStr);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            // pour translation de date
            switch (language.toLowerCase()) {
                case 'ar':
                    return date.toLocaleDateString('ar-EG', options);
                case 'bg':
                    return date.toLocaleDateString('bg-BG', options);
                case 'bs':
                    return date.toLocaleDateString('bs-BA', options);
                case 'cs':
                    return date.toLocaleDateString('cs-CZ', options);
                case 'da':
                    return date.toLocaleDateString('da-DK', options);
                case 'de':
                    return date.toLocaleDateString('de-DE', options);
                case 'el':
                    return date.toLocaleDateString('el-GR', options);
                case 'en':
                    return date.toLocaleDateString('en-US', options);
                case 'es':
                    return date.toLocaleDateString('es-ES', options);
                case 'et':
                    return date.toLocaleDateString('et-EE', options);
                case 'fi':
                    return date.toLocaleDateString('fi-FI', options);
                case 'fr':
                    return date.toLocaleDateString('fr-FR', options);
                case 'ga':
                    return date.toLocaleDateString('ga-IE', options);
                case 'gd':
                    return date.toLocaleDateString('gd-GB', options);
                case 'he':
                    return date.toLocaleDateString('he-IL', options);
                case 'hi':
                    return date.toLocaleDateString('hi-IN', options);
                case 'hr':
                    return date.toLocaleDateString('hr-HR', options);
                case 'hu':
                    return date.toLocaleDateString('hu-HU', options);
                case 'is':
                    return date.toLocaleDateString('is-IS', options);
                case 'it':
                    return date.toLocaleDateString('it-IT', options);
                case 'ja':
                    return date.toLocaleDateString('ja-JP', options);
                case 'lt':
                    return date.toLocaleDateString('lt-LT', options);
                case 'lv':
                    return date.toLocaleDateString('lv-LV', options);
                case 'mg':
                    return date.toLocaleDateString('mg-MG', options);
                case 'nl':
                    return date.toLocaleDateString('nl-NL', options);
                case 'no':
                    return date.toLocaleDateString('nb-NO', options);
                case 'pl':
                    return date.toLocaleDateString('pl-PL', options);
                case 'pt':
                    return date.toLocaleDateString('pt-PT', options);
                case 'ro':
                    return date.toLocaleDateString('ro-RO', options);
                case 'ru':
                    return date.toLocaleDateString('ru-RU', options);
                case 'sk':
                    return date.toLocaleDateString('sk-SK', options);
                case 'sl':
                    return date.toLocaleDateString('sl-SI', options);
                case 'sr':
                    return date.toLocaleDateString('sr-RS', options);
                case 'sv':
                    return date.toLocaleDateString('sv-SE', options);
                case 'uk':
                    return date.toLocaleDateString('uk-UA', options);
                case 'zh':
                    return date.toLocaleDateString('zh-CN', options);
                default:
                    return date.toLocaleDateString('fr-FR', options);
            }
        };

        // Regrouper les activités par date
        sortedActivities.forEach(activity => {
            const date = formatDate(activity.date);
            if (!groupedActivities[date]) {
                groupedActivities[date] = [];
            }
            groupedActivities[date].push(activity);
        });
    }

    // Fetcher le nouveau activite cree
    useFocusEffect(
        useCallback(() => {
            getUserFromStorage();
            activities.length === 0 ? setLoading(false) : setLoading(true);
            getActivities().then(() => setLoading(false));
        }, [])
    );

    return (
        <>
            {/* Empty State - Si aucune activité */}
            {filteredActivities.length === 0 && (
                <View style={styles.noActivities}>
                    <Text style={{ fontSize: 20 }}>Hmm! It is a bit lonely in here!</Text>
                    <Text style={{ fontSize: 20 }}>Try creating an activity!</Text>
                </View>
            )}
            {/* Liste des activites */}
            <ScrollView>

                {/* Si la requête est en cours */}
                {loading && <ActivityIndicator size={"large"} />}

                {Object.entries(groupedActivities).map(([date, activities]) => (
                    <View key={date}>
                        <Text>{date}</Text>
                        {activities.map(activity => (
                            <ActivityCard key={activity._id} activity={activity} userId={userId} />
                        ))}
                    </View>
                ))}
            </ScrollView>

            {/* Bouton pour ajouter une activité */}
            <TouchableOpacity
                style={styles.fab}
                onPress={() => navigation.navigate("CreateActivity")}
            >
                <View>
                    <Icons.Plus width={40} height={40} />
                </View>
            </TouchableOpacity>
        </>
    );
};

export default ActivitiesScreen;
