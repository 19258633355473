import React, {useEffect, useState, useCallback} from 'react';
import {View, Text, FlatList, Image, TouchableOpacity, StyleSheet} from 'react-native';
import {useWebSocket} from '../../sockets/WebSocketLocal';
import axios from 'axios';
import {hostname} from '../../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useUser} from '../../contexts/UserContext';
import {useFocusEffect} from '@react-navigation/native';
import InboxChatModal from '../../components/ChatModal/InboxChatModal';
import {useChatModal} from '../../contexts/ChatModalContext';

const InboxScreen = () => {
  const {userChats, subscribeToNewMessages, getLatestMessages, updateCurrentChatId, privateChatMessages, requestChatHistory} = useWebSocket();
  const [conversations, setConversations] = useState([]);
  const {user} = useUser();
  const userId = user?._id;
  const [selectedChat, setSelectedChat] = useState(null);
const [isModalVisible, setIsModalVisible] = useState(false);  useFocusEffect(
    useCallback(() => {
      const fetchConversations = async () => {
        const results = await Promise.all(
          userChats.map(async chat => {
            const otherUserId = chat.participants.find(p => p._id !== userId)?._id;
            const userDetails = await fetchUserDetails(otherUserId);
            const lastMessage = chat.messages[chat.messages.length - 1] || {content: 'No message'};
            return userDetails
              ? {
                  _id: chat._id, // Here, make sure to use the chat's ID
                  name: userDetails.userName || 'Unknown User',
                  imageUrl: userDetails.profileImage || 'path_to_default_image',
                  lastMessageContent: lastMessage.content,
                  otherUserId: otherUserId, // Store the other user's ID for reference
                }
              : null;
          }),
        );
        setConversations(results.filter(Boolean));
      };

      fetchConversations();
    }, [userChats, userId]),
  );

  const fetchUserDetails = async otherUserId => {
    if (!otherUserId) {
      console.error('Invalid user ID');
      return null;
    }
    const userToken = await AsyncStorage.getItem('userToken');
    try {
      const response = await axios.get(`${hostname}/users/${otherUserId}`, {
        headers: {Authorization: `Bearer ${userToken}`},
      });
      console.log('User data fetched:', response.data._id);
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };



   useEffect(() => {
     console.log('Mise à jour des messages privés :', privateChatMessages);
     Object.entries(privateChatMessages).forEach(([messageId, messagesArray]) => {
       console.log('ID du message privé :', messageId);
       messagesArray.forEach(message => {
         console.log('Expéditeur :', message.sender);
         console.log('Contenu du message :', message.content);
         console.log('Date du message :', message.timestamp);
         console.log('privateChatMessages :', privateChatMessages);
       });
     });
   }, [privateChatMessages]);

   // useEffect(() => {
   //   if (userId) {
   //     // On simule une recherche où l'on suppose que chaque clé dans privateChatMessages correspond à un chatId
   //     const chatId = Object.keys(privateChatMessages).find(key => {
   //       const messages = privateChatMessages[key];
   //       // Hypothétique vérification: Si nous avions des informations sur les participants ici...
   //       return messages.some(message => message.sender === userId);
   //     });

   //     console.log(`Chat ID trouvé pour l'utilisateur ${userId}:`, chatId);
   //     if (chatId) {
   //       updateCurrentChatId(chatId);
   //     }
   //   }
   // }, [userId, privateChatMessages, updateCurrentChatId]);
const openChatModal = async chatId => {
  console.log('Opening modal for chat ID:', chatId);
  if (privateChatMessages.hasOwnProperty(chatId)) {
    const messages = privateChatMessages[chatId];
    setSelectedChat({chatId, messages}); // Assuming you structure the selectedChat state like this
    setIsModalVisible(true); // Open the modal
  } else {
    // Attempt to fetch chat history if no messages are cached
    try {
      const history = await requestChatHistory(chatId);
      if (history && history.messages) {
        setSelectedChat({chatId, messages: history.messages});
        setIsModalVisible(true);
      } else {
        console.warn('No messages found for this chat ID:', chatId);
      }
    } catch (error) {
      console.error('Failed to fetch chat history for chat ID:', chatId, error);
    }
  }
};

// useEffect to detect user involvement in chats and open the first relevant chat
useEffect(() => {
  if (userId) {
    const chatIds = Object.keys(privateChatMessages).filter(key => {
      const messages = privateChatMessages[key];
      return messages.some(message => message.sender === userId || message.receiver === userId);
    });

    console.log(`Chat IDs found for user ${userId}:`, chatIds);
    if (chatIds.length > 0) {
      openChatModal(chatIds[0]); // Automatically open the modal for the first relevant chat
    }
  }
}, [userId, privateChatMessages]);
   useEffect(() => {
     if (userId) {
       // Récupérer tous les chatIds où l'utilisateur est impliqué (expéditeur ou non)
       const chatIds = Object.keys(privateChatMessages).filter(key => {
         const messages = privateChatMessages[key];
         // Vérifiez si l'utilisateur a envoyé ou reçu des messages dans ce chat
         return messages.some(message => message.sender === userId || message.receiver === userId); // supposition que vous avez accès à `receiver`
       });

       console.log(`Chat IDs trouvés pour l'utilisateur ${userId}:`, chatIds);

       // Afficher tous les messages de ces chats
       chatIds.forEach(chatId => {
         const messages = privateChatMessages[chatId];
         console.log(`Messages for chat ID ${chatId}:`);
         messages.forEach(message => {
           console.log('Expéditeur :', message.sender);
           console.log('Contenu du message :', message.content);
           console.log('Date du message :', message.timestamp);
         });
       });

       // Si un chatId est trouvé, le mettre à jour pour l'UI ou autre traitement
       if (chatIds.length > 0) {
         updateCurrentChatId(chatIds[0]); // Mettre à jour avec le premier chatId trouvé, exemple simpliste
       }
     }
   }, [userId, privateChatMessages, updateCurrentChatId]);



  return (
    <View style={{flex: 1}}>
      <FlatList
        data={conversations}
        keyExtractor={item => item._id.toString()}
        renderItem={({item}) => (
          <TouchableOpacity onPress={() => openChatModal(item)} style={styles.conversationItem}>
            <Image source={{uri: item.imageUrl}} style={styles.image} />
            <View>
              <Text style={styles.name}>{item.name}</Text>
              <Text style={styles.lastMessage}>{item.lastMessageContent}</Text>
            </View>
          </TouchableOpacity>
        )}
      />
      {selectedChat && (
        <InboxChatModal
          isVisible={!!selectedChat}
          onClose={() => setSelectedChat(null)}
          currentUser={user}
          chatId={selectedChat._id}
          messages={selectedChat.messages} // Passing messages to the modal
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  conversationItem: {flexDirection: 'row', padding: 10, alignItems: 'center'},
  image: {width: 50, height: 50, borderRadius: 25},
  name: {fontSize: 16, fontWeight: 'bold', marginLeft: 10},
  lastMessage: {fontSize: 14, color: '#666', marginLeft: 10},
});

export default InboxScreen;
