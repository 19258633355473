// Fr Cette page affiche l'étape 1 du formulaire pour créer une activité de type itinéraire(FrameFigma033)
// En This page displays the first step of the form to create an activity of type itinery (FrameFigma033)


import { View, Text, Platform, Pressable } from 'react-native'
import React, {useState, useEffect} from 'react'
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn'
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import AsyncStorage from '@react-native-async-storage/async-storage';

const styles = 
    Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;

const WithStepsOne = ({onNextStep, onPrevStep}) => {
    // const [switchBtnStepOne, setSwitchBtnStepOne] = useState(false);
    // const min = 1;
    // const max = 10;
    // const [sliderValue, setSliderValue] = useState((max - min) / 2);

    // useEffect(() => {
    //     AsyncStorage.setItem('switchBtnStepOne', JSON.stringify(switchBtnStepOne));
    //     AsyncStorage.setItem('sliderValue', JSON.stringify(sliderValue));
    // }, [switchBtnStepOne, sliderValue]);

    return (
        <View style={styles.container}>
            <Text style={{fontWeight:'bold', textAlign: 'center', marginBottom: 70}}>COMING SOON</Text>
            <View>
                    <Pressable style={{padding: 8, backgroundColor: '#59C09B', borderRadius: 15, marginHorizontal: 50, alignItems: 'center'}} onPress={onPrevStep}>
                        <Text style={styles.btnText}>Create Classic Activity</Text>
                    </Pressable>
            </View>
            {/* <View style={styles.textTitleOne}>
                <Text style={styles.textTitleOne}>So you want to create an activity with steps</Text>
            </View>
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>Is this a pubcrawl or a barathon ?</Text>
                <SwitchBtn 
                    state={switchBtnStepOne}
                    setState={setSwitchBtnStepOne}
                />
            </View>
            <View style={styles.slider}>
                <Text style={styles.slider}>How many steps do you want in your activity ?</Text>
                <SliderComponent 
                    min={min} 
                    max={max}
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                />
            </View>
            <View style={styles.textTitleOne}>
                <Text style={styles.textTitleOne}>how much time do you stay for each step ?</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <View style={styles.btnContainer}>
                    <Pressable style={styles.btnNext} onPress={onPrevStep}>
                        <Text style={styles.btnText}>Previous</Text>
                    </Pressable>
                </View>
                <View style={styles.btnContainer}>
                    <Pressable style={styles.btnNext} onPress={() => onNextStep(sliderValue)}>
                        <Text style={styles.btnText}>Continue</Text>
                    </Pressable>
                </View>
            </View> */}
        </View>
    )
}

export default WithStepsOne
