// FR implémente la génération d'un nom d'utilisateur unique en combinant le prénom et le nom de famille de l'utilisateur
// EN Implement generating a unique username by combining the user's first and last name."

import React, {useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {hostname} from '../../../hostName/hostName';
import {Text, View} from 'react-native';

const verifyExistingUserName = async (userName) => {
  try {
    console.log('Vérification de l\'existence du nom d\'utilisateur :', userName);

    const response = await fetch(`${hostname}/verifyUserName`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userName }),
    });

    // Vérifiez le statut de la réponse
    if (response.status === 200) {
      // Le nom d'utilisateur est unique
      console.log('Le nom d\'utilisateur est unique.');
      return false;
    } else if (response.status === 400) {
      // Le nom d'utilisateur existe déjà
      console.log('Le nom d\'utilisateur existe déjà dans la base de données.');
      console.log('Nom d\'utilisateur dans la base de données :', await response.json());
      return true;
    } else {
      // Gérer les autres statuts d'erreur
      console.error('Erreur inattendue lors de la vérification du nom d\'utilisateur :', response.status);
      return false;
    }
  } catch (error) {
    console.error("Erreur lors de la vérification du nom d'utilisateur existant :", error);
    return false;
  }
};


export const generateUsername = async () => {
  try {
    const firstname = await AsyncStorage.getItem('firstname');
    const lastname = await AsyncStorage.getItem('lastname');
    
    if (!firstname || !lastname) {
      throw new Error("Prénom ou nom de famille non disponible.");
    }

    // Remplacer les espaces et les apostrophes par des tirets et garder les tirets existants
    const sanitizedFirstname = firstname.replace(/[\s']/g, '-');
    const sanitizedLastname = lastname.replace(/[\s']/g, '-');

    // Capitaliser les noms
    const capitalizedFirstname = sanitizedFirstname.charAt(0).toUpperCase() + sanitizedFirstname.slice(1);
    const capitalizedLastname = sanitizedLastname.charAt(0).toUpperCase() + sanitizedLastname.slice(1);

    let userName = `${capitalizedFirstname}.${capitalizedLastname}`;

    if (userName.length > 15) {
      userName = userName.slice(0, 15);
    }

    let isUsernameExists = await verifyExistingUserName(userName);

    let attempts = 0;
    const maxAttempts = 10;

    while (isUsernameExists && attempts < maxAttempts) {
      console.log('Le username existe déjà :', userName);

      if (userName.length === 15) {
        userName = userName.slice(0, 14) + Math.floor(Math.random() * 10);
      } else {
        userName = userName + Math.floor(Math.random() * 10);
      }

      console.log('Vérification avant la régénération :', await verifyExistingUserName(userName));

      isUsernameExists = await verifyExistingUserName(userName);

      console.log('Vérification après la régénération :', isUsernameExists);

      attempts++;
    }

    if (attempts === maxAttempts) {
      throw new Error('Impossible de générer un username unique après plusieurs tentatives.');
    }

    console.log('Username généré avec succès :', userName);
    return userName;
  } catch (error) {
    console.error('Erreur lors de la génération du username :', error);
    throw error;
  }
};

// Les fonctions addNumberToUsername et generateUniqueUsername restent inchangées

const UniqueUserName = () => {
  useEffect(() => {
    const generateAndSaveUsername = async () => {
      try {
        const generatedUsername = await generateUsername();
        // Enregistrez le username généré où vous en avez besoin dans votre application
        console.log('Username généré :', generatedUsername);
      } catch (error) {
        console.error('Erreur lors de la génération du username :', error);
      }
    };

    // Appelez la fonction pour générer et sauvegarder le username
    generateAndSaveUsername();
  }, []);

  return (
    <View>
      <Text>Vérification...</Text>
    </View>
  );
};

export default UniqueUserName;
