// Fr Cette page affiche un formulaire pour créer une activité étape par étape (FrameFigma031)
// En This component displays a form to create an activity step by step (FrameFigma031)

import { View, TouchableOpacity, StyleSheet } from 'react-native';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { useLanguage } from '../../../contexts/LanguageContext';

import ClassicStepOne from './CreateActivityClassicStep1Screen/CreateActivityClassicStep1Screen';
import ClassicStepTwo from './CreateActivityClassicStep2Screen/CreateActivityClassicStep2Screen';
import WithStepsOne from './CreateActivityScreenWithSteps1Screen/CreateActivityScreenWithSteps1Screen';
import WithStepsTwo from './CreateActivityScreenWithSteps2Screen/CreateActivityScreenWithSteps2Screen';
import CreateActivityCommonStepOne from './CreateActivityCommonStep1Screen/CreateActivityCommonStep1Screen';
import CreateActivityCommonStepTwo from './CreateActivityCommonStep2Screen/CreateActivityCommonStep2Screen';
import CreateActivityCommonStepThree from './CreateActivityCommonStep3Screen/CreateActivityCommonStep3Screen';
import CreateActivityVipStepOne from './CreateActivityVipStep1Screen/CreateActivityVipStep1Screen';
import CreateActivityVipStepTwo from './CreateActivityVipStep2Screen/CreateActivityVipStep2Screen';
import Fields from '../../../components/Fields/Fields';

const CreateActivityScreen = () => {
  const navigation = useNavigation();
  const [questionType, setQuestionType] = useState(null);
  const [step, setStep] = useState(1);
  const [sliderValue, setSliderValue] = useState(0);
  const {translations} = useLanguage();

  useEffect(() => {
    const fetchData = async () => {
      const value = await AsyncStorage.getItem('selectedQuestion');
      console.log('Async:', value)
      setQuestionType(value)
    }
    fetchData();
  }, [step]);

  const resetSteps = () => {
    setStep(1);
    setQuestionType(null);
    // Réinitialisez toutes les autres valeurs que vous devez réinitialiser
  };

  const onNextStep = async (sliderValue, selectedQuestion) => {
    console.log('selectedQuestion', selectedQuestion);
    setSliderValue(sliderValue);
    if (step === 1 && selectedQuestion) {
      await AsyncStorage.setItem('selectedQuestion', selectedQuestion === 'classic' ? 'classic' : 'withStep');
      setQuestionType(selectedQuestion === 'classic' ? 'classic' : 'withStep')
    }
    if (step < 7) {
      setStep(step + 1)
    }
  };


  const onPrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      resetSteps(); // Réinitialisez les étapes lorsque l'écran est en focus
    });
    return unsubscribe;
  }, [navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => navigation.navigate('Activities')} style={{ marginLeft: 15 }}>
          <Fields.IconLeftArrow />
        </TouchableOpacity>
      ),
      title: translations.JsonActivityPages.JsonActivityManagement.JsonTitleCreateActivity,
      headerStyle: { backgroundColor: '#59C09B' },
      headerTintColor: 'white',
      headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
    });
  }, [navigation, step, onPrevStep]);


  const renderStep = () => {
    switch (step) {
      case 1:
        return <ClassicStepOne onNextStep={onNextStep} />;
      case 2:
        return questionType === 'classic' ? (<ClassicStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} />) : (<WithStepsOne onNextStep={onNextStep} onPrevStep={onPrevStep} />);
      case 3:
        if (questionType === 'classic') {
          return <CreateActivityCommonStepOne onNextStep={onNextStep} onPrevStep={onPrevStep} />;
        } else {
          return <WithStepsTwo onNextStep={onNextStep} sliderValue={sliderValue} onPrevStep={onPrevStep} />;
        }
      case 4:
        if (questionType === 'classic') {
          return <CreateActivityCommonStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} />;
        } else {
          return <CreateActivityCommonStepOne onNextStep={onNextStep} onPrevStep={onPrevStep} />;
        }
      case 5:
        if (questionType === 'classic') {
          return <CreateActivityCommonStepThree onPrevStep={onPrevStep} onNextStep={onNextStep} />;
        } else {
          return <CreateActivityCommonStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} />;
        }
      case 6:
        if (questionType !== 'classic') {
          return <CreateActivityCommonStepThree onPrevStep={onPrevStep} onNextStep={onNextStep} />;
        } else {
          //  a ajouter condition que pour vip
          return <CreateActivityVipStepOne onNextStep={onNextStep} onPrevStep={onPrevStep} />;
        }
      case 7:
        return <CreateActivityVipStepTwo onPrevStep={onPrevStep} onNextStep={onNextStep} />;
      default:
        return null;
    }
  }


  return <View style={styles.PContainer}>{renderStep()}</View>;
};


const styles = StyleSheet.create({
  PContainer: {
    flex: 1,
    backgroundColor: 'white',
    //paddingLeft: 25,
    //paddingRight: 25,
  }
})

export default CreateActivityScreen;
