// 🇫🇷 Selectionner le language de l'application (FrameFigma002)
// 🇬🇧 Select the application language (FrameFigma002)
import React, { useEffect, useState } from 'react';
import { FlatList, Platform, Text, TextInput, TouchableOpacity, View, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { useLanguage } from '../../contexts/LanguageContext'; // Vérifiez le chemin
import countriesListCircleFlags from '../../components/LanguagesFlags/LanguagesFlags';
import TextInputCustom from '../../components/TextInputCustom/TextInputCustom'
import Fields from '../../components/Fields/Fields';
//🇬🇧 Conditionally loads styles based on the platform (web or mobile)
//🇫🇷 Conditionnellement charge les styles en fonction de la plateforme (web ou mobile)
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const LanguagesScreen = () => {
  const {translations} = useLanguage();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(countriesListCircleFlags);
  const navigation = useNavigation();
  const { changeLanguage } = useLanguage();
  const [isFocused, setIsFocused] = useState(false);

  const handleLanguageSelect = async (bigram, language) => {
    try {
      await AsyncStorage.setItem('selectedLanguage', bigram);
      await AsyncStorage.setItem('selectedLanguageName', language);
      changeLanguage(bigram); // Met à jour le contexte de langue
      navigation.navigate('Start'); // Assurez-vous que 'Start' est le nom correct de votre écran
    } catch (e) {
      console.error('Erreur lors de la sauvegarde de la langue', e);
    }
  };

  useEffect(() => {
    const filtered = countriesListCircleFlags.filter(item => item.language.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredData(filtered);
  }, [searchQuery]);
  const renderItem = ({ item }) => (
    <TouchableOpacity onPress={() => handleLanguageSelect(item.bigram, item.language)} style={styles.itemContainer}>
      <item.FlagComponent width="65" height="65" />
      <Text style={styles.languageText}>{item.language}</Text>
    </TouchableOpacity>
  );

  //🇬🇧 Overall rendering of the component, with a title, a search field, and a FlatList to display the languages
  //🇫🇷 Rendu global du composant, avec un titre, un champ de recherche, et une FlatList pour afficher les langue
  return (
    <View style={styles.countryPage}>
      <View>
        <Text style={styles.title}>{translations.JsonHomeScreen.JsonSelectLanguage}</Text>
      </View>
      <View style={styles.searchInput}>
        <View style={styles.icon}>
          <Fields.IconLoop />
        </View>
        <TextInput placeholder={translations.JsonHomeScreen.JsonSelectLanguage} onChangeText={setSearchQuery} value={searchQuery} style={[isFocused && styles.focusedInput2]} placeholderTextColor="#ADADAD" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} />
      </View>
      <FlatList
        data={filteredData}
        renderItem={renderItem}
        keyExtractor={(item, index) => 'key' + index}
        numColumns={3}
        contentContainerStyle={styles.listContentContainer}
        columnWrapperStyle={styles.columnWrapper}
        showsVerticalScrollIndicator={false}
        style={styles.flatList}
      />
    </View>
  );
};

export default LanguagesScreen;
