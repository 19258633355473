import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    // padding: 20,
    width: '100%',
  },
  profileImage: {
    width: '100%',
    height: 380,
    // borderRadius: 100, // Pour rendre l'image ronde
    // marginBottom: 20,
  },
  userName: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingHorizontal: 15
  },
  editButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0,
    borderRadius: 4,
    position: 'absolute',
    right: 0,
    margin: 20,
    padding: 10,
    backgroundColor: 'white',
  },
  editButtonText: {
    marginLeft: 5,
    fontSize: 10,
  },
  likeButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    zIndex: 1,
  },
  cupIconContainer: {
    marginTop: -30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cupIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1F1F1',
    borderRadius: 150,
    padding: 3,
  },
  pointsTextBelowCup: {
    fontWeight: 700,
  },
  infosContainer: {
    // width:'100%',
    alignContent: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginVertical: 10
  },
  info: {
    fontWeight: 700,
    paddingHorizontal: 16
  },
  infosContainer2: {
    width: '100%',
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'flex-end',
    justifyContent: 'space-around'
  },
  info2Text: {
    paddingHorizontal: 15
  },
  infosCityRow: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    alignItems: 'flex-end',
  },
  actionButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    borderStyle: 'none',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  addFriendButton: {
    backgroundColor: `#62A1FF`,
  },
  chatButton: {
    backgroundColor: `#B85EB7`,
  },
  blockButton: {
    backgroundColor: `#FF0000`,
  },
  btnsAmiContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    paddingHorizontal: 3
  },
  buttonText: {
    color: `#FFFFFF`,
  },
});