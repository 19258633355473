// Fr  Styles de EditActivityClassicStep2Screen( FrameFigma032A,FrameFigma032B,FrameFigma032C )
// En  Styles of EditActivityClassicStep2Screen( FrameFigma032A,FrameFigma032B,FrameFigma032C ) 

import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        paddingLeft: 25,
        paddingRight: 25,
      },
     buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30,// dev9_ajou
        marginTop: 5,
    },
    button: {
        padding: 5,
        paddingVertical:10,
        borderRadius: 6,
        margin: 5,
        marginBottom:-6,
        border: "1px solid rgba(89, 192, 155, 1)",// dev9_ajou
        flex:1,
        alignItems:"center",
        justifyContent:"center"
    },
    addressAutoPicker:{
        zIndex:2,
        marginBottom:30
    },
    map:{
        zIndex: -1
    },
    inputContainer: {
        fontSize: 16,
        borderWidth: 1,
        // marginVertical: 300,
        borderRadius: 15,
        padding: 10,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: "white",
        fontSize: 13,
        textAlign: "center",
        position: "absolute",
        zIndex: 1,
        left: 20,
        top: -10,
    },
    focusedInput: {
        borderColor: 'rgba(0, 0, 0, 0)',
        outlineColor: 'rgba(0, 0, 0, 0)',
    },
    switchBtn: {
        marginVertical: 10,
        paddingVertical: 5,
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 10,
        justifyContent: "center"
    },
    btnText: {
        fontSize: 13
    },
    textbtn: {
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        marginVertical: 10,
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
