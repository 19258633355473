// Fr  Styles de CopyActivityStep1Screen(FrameFigma031,FrameFigma032A,FrameFigma035A)
// En  Styles of CopyActivityStep1Screen(FrameFigma031,FrameFigma032A,FrameFigma035A) 
import { StyleSheet } from "react-native";

export default StyleSheet.create({
    textTitleOne: {
        marginTop: '2.5%',
        marginTop: 15,
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 18,
        lineHeight: 27
        
    },
    slider: {
        fontWeight: "bold",
        fontSize: 18,
        marginVertical: 10,
        width:"100%",
      },
    questionContainer: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: '5%',
    },
    classic: {
        borderWidth: 1,
        width: '45%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: 7,
    },
    withStep: {
        borderWidth: 1,
        width: '45%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: 7,
    },
    titleA: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 10
    },
    textQuestion: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    activeQuestion: {
        backgroundColor: '#59C09B',
    },
    titleContainer: {
        paddingHorizontal: 8,
        justifyContent: "center",
        fontSize: 16,
        borderWidth: 1,
        marginTop: 10,
        width: '95%',
        borderRadius: 15,
        padding: 20,
        marginLeft: 10,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: "white",
        fontSize: 13,
        textAlign: "center",
        position: "absolute",
        zIndex: 1,
        left: 20,
        top: -10,
    },
    focusedInput: {
        borderColor: 'rgba(0, 0, 0, 0)',
        outlineColor: 'rgba(0, 0, 0, 0)',
    },
    dateContainer: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 10,
        marginTop:5,
    },
    switchBtn: {
        marginVertical: 2.5,
        // paddingBottom: 5,
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 10
    },
    textbtn: {
        fontSize: 18,
        fontWeight: "bold",
        marginVertical: 5,
    },
    btnContainer: {
        width: '80%',
        alignContent: 'center',
        marginTop:60
    },
    addressAutoPicker:{
        zIndex:2,
        marginBottom:30
    },
    map:{
        zIndex: -1
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});