// FR pour passer à l'étape suivante dans une interface utilisateur
// EN To proceed to the next step in a user interface

import React, { useEffect, useState, useRef } from 'react';
import { Modal, View, Text, TextInput, Pressable, StyleSheet, ScrollView } from 'react-native';
import { useWebSocket } from '../../sockets/WebSocketLocal';
import { format } from 'date-fns';

function ChatModal({ showModal, toggleModal, currentUser, chatId, userID, privateMessages }) {
  const [message, setMessage] = useState('');
  const [messagesDisplay, setMessagesDisplay] = useState([]);
  const { sendChatMessage, requestChatHistory } = useWebSocket();
  const scrollViewRef = useRef(); // Étape 2: Créer une référence pour le ScrollView

  useEffect(() => {
    if (showModal && userID) {
      requestChatHistory(chatId || userID);
    }
  }, [showModal, chatId, userID, requestChatHistory]);

  useEffect(() => {
    // Convertir l'objet privateMessages en tableau pour l'affichage
    const messagesArray = Object.entries(privateMessages).flatMap(([messageId, messagesArray]) =>
      messagesArray.map(message => ({
        ...message,
        messageId,
      })),
    );
    setMessagesDisplay(messagesArray);
  }, [privateMessages]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  }, [messagesDisplay]);

  const handleSubmit = () => {
    if (message.trim()) {
      const newMessage = {
        sender: currentUser.firstName,
        //senderId: currentUser._id,
        content: message,
        timestamp: new Date().toISOString(),
      };
      // Ajouter le message à l'affichage immédiatement
      setMessagesDisplay([...messagesDisplay, newMessage]);
      sendChatMessage(userID, message, true);
      setMessage('');
    } else {
      alert('Le message ne peut pas être vide.');
    }
  };

  const isShowDate = (date1, date2, index) => {
    if (index > 0)
      return !(date1?.slice(0, 10) == date2?.slice(0, 10))
    // si c meme date, alors return false, c a dire pas show date
    else return true
  }

  return (
    <Modal visible={showModal} animationType="slide" transparent={false} onRequestClose={toggleModal}>
      <View style={styles.modalContainer}>
        <View style={styles.modalView}>
          <Text>
            Conversation entre {currentUser.firstName} et {userID}
          </Text>
          <ScrollView
            style={styles.messagesContainer}
            ref={scrollViewRef} // Attacher la référence au ScrollView
          >
            {messagesDisplay.map((message, index, array) => (
              <View key={index} style={styles.messagesSpace}>
                {array && isShowDate(array[index - 1]?.timestamp, array[index]?.timestamp, index) && <Text style={styles.dateCenter}>{format(new Date(array[index]?.timestamp), 'dd/MM/yyyy')}</Text>}

                {message.sender == userID
                  ?
                  <View style={styles.messageContainerLeft}>
                    <Text style={currentUser.sexe === "Male" ? styles.nameMale : styles.nameFemale}>{currentUser.userName}</Text>
                    <View style={styles.messageContainerLeft2}>
                      <View style={styles.arrowDownLeft}></View>
                      <View style={styles.messageTextContainerLeft}>
                        <Text style={styles.messageContent}>{message.content}</Text>
                        <Text style={styles.timestamp}>{format(new Date(message.timestamp), 'HH:mm')}</Text>
                      </View>
                    </View>
                  </View>
                  :<View style={styles.messageContainerRight}>
                    <View style={styles.messageTextContainerRight}>
                      <Text style={styles.messageContent}>{message.content}</Text>
                      <Text style={styles.timestamp}>{format(new Date(message.timestamp), 'HH:mm')}</Text>
                    </View>
                    <View style={styles.arrowDownRight}></View>
                  </View>}
              </View>
            ))}
          </ScrollView>
          <TextInput
            style={styles.input}
            placeholder="Tapez votre message ici"
            value={message}
            onChangeText={setMessage}
          />
          <Pressable style={styles.button} onPress={handleSubmit}>
            <Text style={styles.buttonText}>Envoyer</Text>
          </Pressable>
          <Pressable style={styles.button} onPress={toggleModal}>
            <Text style={styles.buttonText}>Fermer</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    width: 450,
    height: '80%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  messagesContainer: {
    width: '100%',
    flex: 1,
    marginBottom: 10,
  },
  messagesSpace: {
    width: '100%',
    // flex: 1,
    alignContent: 'space-between',
  },
  messageContainerLeft: {
    // marginBottom: 15,
    justifyContent: 'flex-start'
  },
  messageContainerLeft2: {
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  messageContainerRight: {
    flexDirection: 'row',
    marginBottom: 15,
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  messageTextContainerLeft: {
    left: -10,
    backgroundColor: '#f0f0f0',
    padding: 10,
    borderRadius: 15,
    maxWidth: '70%',
  },
  messageTextContainerRight: {
    right: -10,
    backgroundColor: '#f0f0f0',
    padding: 10,
    borderRadius: 15,
    maxWidth: '70%',
  },
  arrowDownRight: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 20,
    borderBottomWidth: 10,
    borderRightColor: 'transparent',
    borderBottomColor: 'lightgray',
  },
  arrowDownLeft: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 20,
    borderBottomWidth: 10,
    borderRightColor: 'transparent',
    borderBottomColor: 'lightgray',
    transform: [{ rotateX: '180deg' }, { rotateZ: '180deg' }],//same que right, jueste ajouté cette ligne 
  },
  message: {
    marginVertical: 5,
  },
  input: {
    width: '100%',
    padding: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
  },
  button: {
    width: '100%',
    padding: 10,
    backgroundColor: '#007bff',
    borderRadius: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: 'white',
  },
  timestamp: {
    fontSize: 12,
    color: '#888',
    textAlign: 'right'
  },
  dateCenter: {
    textAlign: 'center',
    marginBottom: 15
  },
  nameMale: {
    color: '#001AFF',
  },
  nameFemale: {
    color: '#FF13CB',
  },
});

export default ChatModal;
