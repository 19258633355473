// une boîte de dialogue réutilisable (FrameFigma041,FrameFigma042,FrameFigma044,FrameFigma037A,FrameFigma038A,FrameFigma038B,FrameFigma039)
// A reusable dialog box (FrameFigma041,FrameFigma042,FrameFigma044,FrameFigma037A,FrameFigma038A,FrameFigma038B,FrameFigma039)
// Import Modules
import React from "react";
import { Modal as NativeModal, View, Pressable, Text, Platform } from "react-native";

// Import Icons
import { Icons } from "../../constants/Icons";

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const Modal = ({ modalVisible, onClose, children }) => {
  return (
    <NativeModal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <View style={styles.modal}>

          {/* Close (X) Button */}
          <Pressable onPress={onClose} style={styles.close}>
            <Icons.CloseSignGreen width={30} height={30} />
          </Pressable>

          {/* Internal Contents of Modal passed from other Components  */}
          {children}

        </View>
      </View>
    </NativeModal>
  )
};

export default Modal