import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f0f0f0',
  },
  header: {
    height: 260,
    position: 'relative',
  },
  profilePhoto: {
    flex: 1,
    resizeMode: 'cover',
  },
  editBtn: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 85,
    height: 28,
    borderWidth: 0,
    borderRadius: 4,
    position: 'absolute',
    right: 0,
    margin: 20,
    backgroundColor: 'white',
  },
  editBtnText: {
    fontSize: 9,
    fontWeight: '600',
    color: '#59C09B',
  },
  cupIconContainer: {
    position: 'absolute',
    bottom: -26,
    alignSelf: 'center',
  },
  cupsIcon: {},
  pointsTextBelowCup: {
    position: 'absolute',
    bottom: -38,
    alignSelf: 'center',
    fontSize: 14,
    fontWeight: '900',
  },
  userInfoAboveBlocks: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 22,
    marginTop: 110,
  },
  userCityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userNameAbove: {
    fontSize: 17,
    fontWeight: '900',
  },
  userAgeAbove: {
    fontSize: 17,
    fontWeight: '600',
  },
  userCityAbove: {
    fontSize: 17,
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '600',
  },
  locationIcon: {
    marginLeft: 5,
  },
  blocksContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 8,
  },
  infoBlock: {
    backgroundColor: '#59C09B',
    flex: 1,
    margin: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  activitiesBlock: {
    backgroundColor: 'grey',
    flex: 1,
    margin: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  networkBlock: {
    backgroundColor: 'grey',
    flex: 1,
    margin: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  blockText: {
    color: 'white',
  },
  containerInfo: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideBySideContainers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16, 
    marginTop: 16, 
  },
  leftContainer: {
    flex: 1,
    marginRight: 8, 
  },
  rightContainer: {
    flex: 1,
    marginLeft: 8,

  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: 10,
    paddingHorizontal: 16,
  },
  greenBar: {
    backgroundColor: '#59C09B',
    marginTop: 15,
    padding: 3,
    alignItems: 'center',
  },
  greenBarText: {
    color: 'white',
  },
});
