import React, {useState} from 'react';
import {View, Text, Pressable, TextInput, Modal, StyleSheet, Linking} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';
import axios from 'axios';
import {hostname} from '../../../../hostName/hostName';

const InviteByEmail = ({userId, userToken, emailModalVisible, setEmailModalVisible}) => {
  const {translations} = useLanguage();
  const [friendEmail, setFriendEmail] = useState('');

  const inviteByEmail = async () => {
    try {
      const response = await axios.get(`${hostname}/sponsorships/${userId}/generateReferralLink`, {
        headers: {Authorization: `Bearer ${userToken}`},
      });
      const referralLink = response.data.referralLink;
      console.info('referralLink', referralLink);
      const emailBody = `${translations.JsonInviteFriendsScreen.JsonInvitationMessage} Voici mon lien de parrainage: ${referralLink}`;
      const mailtoLink = `mailto:${friendEmail}?subject=Invitation&body=${encodeURIComponent(emailBody)}`;
      Linking.openURL(mailtoLink);
      setEmailModalVisible(false); // Fermer le modal après l'envoi
    } catch (error) {
      console.error('Erreur lors de la génération du lien de parrainage', error);
    }
  };

  return (
    <View>
      <Modal animationType="slide" transparent={true} visible={emailModalVisible} onRequestClose={() => setEmailModalVisible(false)}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <TextInput style={styles.input} onChangeText={setFriendEmail} value={friendEmail} placeholder="Email de votre ami" keyboardType="email-address" />
            <Pressable style={[styles.button, styles.buttonClose]} onPress={inviteByEmail}>
              <Text style={styles.textStyle}>Envoyer l'invitation</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: 200,
  },
});

export default InviteByEmail;
