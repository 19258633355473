import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  editProfileContainer: {
    flex: 1,
    paddingTop: '5%',
    backgroundColor: '#fff',
  },
  profileImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  camera: {
    // marginTop: 0,
    position: 'absolute',
    top: 85,
    right: '37%',
  },
  compte: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10%',
    marginBottom: '5%',
    width: '100%',
  },
  ButtonPersonelCompte: {
    borderWidth: 1,
    padding: 10,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    width: '45%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textButtonPersonelCompte: {
    fontSize: '100%',
    fontWeight: 'bold',
  },
  ButtonProfessionelCompte: {
    borderWidth: 1,
    padding: 10,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    width: '45%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textButtonProfessionelCompte: {
    fontSize: '100%',
    fontWeight: 'bold',
  },
  userForm: {
    // Pas besoin de alignItems: 'center' ici car nous ne centrons pas les labels
    width: '100%', // Prendre toute la largeur disponible du parent
  },
  inputContainer: {
    width: '100%', // Assurez-vous que le conteneur d'input prend toute la largeur pour aligner les labels à gauche
    marginTop: -5, 
    paddingHorizontal: 15, //pour longueuer de textInput ne soit pas trop long(>width d'écran)
  },
  label: {
    marginLeft: '2.5%',
    marginBottom: -10,
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#C4C4C4'
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
    zIndex: 2
  },
  containerWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  pickerContainer: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // borderWidth: 1,
    // borderColor: 'gray',
    // borderRadius: 5,
    // paddingHorizontal: 10,
    width: '100%',
    marginTop: -5,
    paddingHorizontal: 15,
  },
  pickerStyle: {
    flex: 1,
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 1)',
    marginVertical: 13,
    borderRadius: 15,
    position: 'relative',
    paddingHorizontal: 45,
    padding: 15,
    marginHorizontal: 150,
    fontSize: '16px',
    marginLeft: 1,
    zIndex: 0,
  },
  containerWithFlags: {
    justifyContent: 'center',
    width: '100%'
  },
  flagStyle: {
    maxHeight: 25,
    maxWidth: 25,
    position: 'absolute',
    right: 20,
    // bottom:20,
    zIndex: 1,
    marginTop: -2,
  },
  // input: {
  //   borderWidth: 1,
  //   borderColor: '#cccccc',
  //   padding: 10,
  //   borderRadius: 15,
  //   width: '90%', // Largeur de l'input à 90% de son conteneur
  //   alignSelf: 'center', // Centrer l'input dans son conteneur large
  // },
  btnsRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 10,
    width: '100%',
    marginLeft: -32,
    marginTop: '2.5%',
  },
  ButtonContainer: {
    flex: 1,
    marginHorizontal: 30,
  },
  submitButton: {
    borderWidth: 1,
    padding: 10,
    borderRadius: 10,
  },
  continueButton: {
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
  },
  headerError: {
      backgroundColor: 'red',
      padding: 20,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
  },
  headerErrorText: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold',
  },
});
