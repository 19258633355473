// 🇫🇷 Ce fichier gère la quatrième étape du processus d'inscription, y compris la sélection d'image de profil et la soumission du formulaire utilisateur.
// 🇬🇧 This file manages the fourth step of the registration process, including profile image selection and user form submission.

import React, {useState, useEffect} from 'react';
import {View, Pressable, Platform, Text, Image, TouchableOpacity, ActivityIndicator} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import ProfileImagePickerAndroid from '../../../../components/ImagePicker/ImagePickerAndroid';
// import ProfileImagePickerIOS from '../../../../components/ImagePicker/ImagePickerIOS';
// import ProfileImagePickerWeb from '../../../../components/ImagePicker/ImagePickerWeb';
import Fields from '../../../../components/Fields/Fields';
import {hostname} from '../../../../../hostName/hostName';
import {useNavigation} from '@react-navigation/native';
import {useAuth} from '../../../../contexts/AuthContext';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useError} from '../../../../contexts/ErrorContext'; // Import useError hook
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import ProfileImagePickerModal from '../../../../components/ImagePicker/ProfileImagePickerModal';
import ImageCropperWeb from '../../../../components/ImageCropper/ImageCropperWeb';
import ImageCropperMobile from '../../../../components/ImageCropper/ImageCropperMobile';
import Modal from '../../../../components/Modal/Modal';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const Step4Screen = () => {
  const {translations} = useLanguage();
  const {language} = useLanguage();
  const {error, setError} = useError(); // 🇫🇷 Initialiser error et setError depuis useError hook
  const [isLoading, setIsLoading] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const {login} = useAuth();

  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [sexe, setSexe] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [accountType, setAccountType] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [progress, setProgress] = useState(0);
  const [isVerified, setIsVerified] = useState('');
  const [userName, setUserName] = useState('');
  const [cropperModalVisible, setCropperModalVisible] = useState(false);

  // 🇫🇷 useEffect pour récupérer les détails de l'utilisateur à partir d'AsyncStorage
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const savedSexe = await AsyncStorage.getItem('sexe');
        const savedFirstName = await AsyncStorage.getItem('firstname');
        const savedLastName = await AsyncStorage.getItem('lastname');
        const savedCity = await AsyncStorage.getItem('city');
        const isPersonalAccount = await AsyncStorage.getItem('isPersonalAccount');
        const isProAccount = await AsyncStorage.getItem('isProAccount');
        const savedEmail = await AsyncStorage.getItem('email');
        const savedPassword = await AsyncStorage.getItem('password');
        const savedIsVerified = await AsyncStorage.getItem('isVerified');
        const savedUserName = await AsyncStorage.getItem('userName');

        let accountType = '';
        if (isPersonalAccount === 'true') {
          accountType = 'isPersonalAccount';
        } else if (isProAccount === 'true') {
          accountType = 'isProAccount';
        }

        setSexe(savedSexe || '');
        setFirstName(savedFirstName || '');
        setLastName(savedLastName || '');
        setCity(savedCity || '');
        setAccountType(accountType);
        setEmail(savedEmail || '');
        setPassword(savedPassword || '');
        setIsVerified(savedIsVerified || '');
        setUserName(savedUserName || '');
      } catch (error) {
        setError(error.message); // 🇫🇷 Définir error en utilisant setError depuis useError hook
        console.error("Erreur lors de la récupération des détails de l'utilisateur depuis AsyncStorage", error);
      }
    };

    fetchUserDetails();
  }, []);

  // 🇫🇷 Fonction pour ouvrir le modal de sélection d'image
  const openImagePickerModal = () => {
    setIsLoading(true);
    setModalVisible(true);
  };

  // 🇫🇷 Fonction pour sauvegarder le profil utilisateur
 const saveProfile = async () => {
   if (!isImageSelected) {
     setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonAvatarImageMissing);
     console.log('Veuillez sélectionner une image avant de continuer.');
     return;
   }
    setIsLoading(true); // 🇫🇷 Début du chargement

    try {
      if (!userName) {
        setError("Le nom d'utilisateur n'est pas défini.");
        console.error("Le nom d'utilisateur n'est pas défini.");
        setIsLoading(false);
        return;
      }
      const sponsorId = await AsyncStorage.getItem('sponsorId');
      const latitude = parseFloat(await AsyncStorage.getItem('latitude'));
      const longitude = parseFloat(await AsyncStorage.getItem('longitude'));
      console.log('Latitude:', latitude, ', Longitude:', longitude);

      const formDataToSend = new FormData();
      const userData = {
        sexe,
        firstName,
        lastName,
        city,
        email,
        password,
        isVerified,
        userName,
        ...(sponsorId && {sponsorId: sponsorId}),
      };

      if (accountType) {
        userData[accountType] = true;
      }

      Object.keys(userData).forEach(key => formDataToSend.append(key, userData[key]));

      if (selectedImage && selectedImage.file) {
        console.info('Selected image:', selectedImage);
        formDataToSend.append('profileImage', selectedImage.file);
      }
      formDataToSend.append('location[type]', 'Point');
      formDataToSend.append('location[coordinates][0]', longitude);
      formDataToSend.append('location[coordinates][1]', latitude);

      console.log('Form Data To Send:', formDataToSend);

      const response = await fetch(`${hostname}/users`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        const responseData = await response.json();
        await login(responseData.token, {
          _id: responseData.user._id,
          email: responseData.user.email,
          userName: responseData.user.userName,
          profileImage: responseData.user.profileImage,
        });
        navigation.navigate('Home');
      } else {
        setError(`Échec de la sauvegarde du profil sur le serveur: ${response.status}, ${response.statusText}`);
        console.error('Échec de la sauvegarde du profil sur le serveur:', response.status, response.statusText);
        console.error("Détails de l'erreur du serveur:", await response.text());
      }
    } catch (error) {
      setError(`Erreur lors de la sauvegarde du profil: ${error.message}`);
      console.error('Erreur lors de la sauvegarde du profil:', error);
    } finally {
      setIsLoading(false); // 🇫🇷 Arrêter le chargement
    }
  };

  // 🇫🇷 Gestionnaire pour la sélection d'image
  const onImageSelectedHandler = data => {
    console.log('Selected image data:', data);
    setSelectedImage(data);
    setIsImageSelected(true);
    setCropperModalVisible(true);
    simulateProgress();
  };

  // 🇫🇷 Simulation de la barre de progression
  const simulateProgress = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress(currentProgress => {
        const newProgress = currentProgress + 0.1;
        if (newProgress >= 1) {
          clearInterval(interval);
          setIsLoading(false);
          return 1;
        }
        return newProgress;
      });
    }, 100);
  };

  // 🇫🇷 Gestion de l'image recadrée
  const handleCroppedImage = croppedImage => {
    setSelectedImage(croppedImage);
    setCropperModalVisible(false);
  };

  const ImageCropper = Platform.select({
    web: ImageCropperWeb, // 🇫🇷 Utiliser ImageCropperWeb sur les plateformes web
    default: ImageCropperMobile, // 🇫🇷 Utiliser ImageCropperMobile sur les plateformes natives
  });

  return (
    <View style={styles.profile}>
      {error && ( // 🇫🇷 Afficher le message d'erreur si error est présent
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      )}
      <View style={styles.picture}>
        <Pressable onPress={openImagePickerModal} style={styles.pressableContainer}>
          {selectedImage ? (
            <Image source={{uri: selectedImage.imageUrl ? selectedImage.imageUrl : selectedImage}} style={styles.image} />
          ) : (
            <Fields.IconNoGenderNormalSize />
          )}
        </Pressable>
      </View>
      {isLoading && <ProgressBar progress={progress} />}
      <View style={styles.camera}>
        <Pressable onPress={openImagePickerModal}>
          <Fields.IconCamera />
        </Pressable>
      </View>
      {isImageSelected && (
        <Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
          <ImageCropper file={selectedImage.file} imgType={'profileImage'} hideToggleAspect={true} handleCroppedImage={handleCroppedImage} />
        </Modal>
      )}
      {/* 🇫🇷 Utilisation du composant sélectionné */}
      {modalVisible && (
        <ProfileImagePickerModal onImageSelected={onImageSelectedHandler} modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
      <View style={styles.btnContainer}>
        <ButtonNext
          // 🇫🇷 Désactiver onPress si aucune image n'est sélectionnée ou si isLoading est true
          conditionNeed={isImageSelected && !isLoading}
          handleNextStep={saveProfile}
          btnText={translations.JsonCreateProfilePages.JsonSaveButton}
        />
      </View>
    </View>
  );
};

export default Step4Screen;
