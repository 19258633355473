//FR  un formulaire à plusieurs étapes pour créer un profil utilisateur.
//EN  a multi-step form for creating a user profile
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useNavigation } from '@react-navigation/native';
import Step1Screen from './CreateProfileStep1Screen/CreateProfileStep1Screen';
import Step2PersonalScreen from './CreateProfileStep2PersoScreen/CreateProfileStep2PersoScreen';
import Step2ProScreen from './CreateProfileStep2ProScreen/CreateProfileStep2ProScreen';
import Step3PersonalScreen from './CreateProfileStep3PersoScreen/CreateProfileStep3PersoScreen';
import Step3ProScreen from './CreateProfileStep3ProScreen/CreateProfileStep3ProScreen';
import Step4Screen from './CreateProfileStep4Screen/CreateProfileStep4Screen';
import Fields from '../../../components/Fields/Fields';

const CreateProfileScreen = () => {
  const navigation = useNavigation();
  const [step, setStep] = useState(1);
  const [accountType, setAccountType] = useState(null); // Utilisez null comme valeur initiale

  // Utilisez useEffect pour récupérer la valeur de accountType depuis AsyncStorage au chargement de l'écran
  useEffect(() => {
    const getAccountType = async () => {
      const storedAccountType = await AsyncStorage.getItem('accountType');
      console.log('Stored Account Type:', storedAccountType);
      setAccountType(storedAccountType);
    };
  
    getAccountType();
  }, [step]); // Changez ici pour déclencher le useEffect à chaque changement de 'step'

  const onNextStep = async (selectedAccountType) => {
    console.log('Selected Account Type:', selectedAccountType);
    if (step === 1 && selectedAccountType) {
      await AsyncStorage.setItem('accountType', selectedAccountType === 'personal' ? 'personal' : 'pro');
      setAccountType(selectedAccountType === 'personal' ? 'personal' : 'pro'); // Ajoutez cette ligne pour mettre à jour immédiatement la valeur dans le state
    }
    if (step < 4) {
      setStep(step + 1);
    }
  };
  

  const onPrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () =>
        step > 1 ? (
          <TouchableOpacity onPress={onPrevStep} style={{ marginLeft: 15 }}>
            <Fields.IconLeftArrow />
          </TouchableOpacity>
        ) : null,
      title: 'Create Profile',
      headerStyle: { backgroundColor: '#59C09B' },
      headerTintColor: 'white',
      headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
    });
  }, [navigation, step]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1Screen onNextStep={onNextStep} />;
      case 2:
        return accountType === 'personal' ? (
          <Step2PersonalScreen onNextStep={onNextStep} />
        ) : (
          <Step2ProScreen onNextStep={onNextStep} />
        );
      case 3:
        return accountType === 'personal' ? (
          <Step3PersonalScreen onNextStep={onNextStep} />
        ) : (
          <Step3ProScreen onNextStep={onNextStep} />
        );
      case 4:
        return <Step4Screen />;
      default:
        return null;
    }
  };

  return <View style={styles.container}>{renderStep()}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default CreateProfileScreen;
