import React from 'react';
import { View, Modal, Text, TouchableOpacity, StyleSheet, Alert } from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { checkCameraPermissionIOS, checkPhotoLibraryPermissionIOS } from '../Permissions/PermissionIOS';
import { useLanguage } from '../../contexts/LanguageContext';

const ProfileImagePickerIOS = ({ onImageSelected, modalVisible, setModalVisible }) => {

  const {translations} = useLanguage();
  const handleTakePhoto = async () => {
    console.log('Demande de permission de caméra iphone...');
    const hasCameraPermission = await checkCameraPermissionIOS();
    if (!hasCameraPermission) {
      console.log('Permission de caméra refusée iphone');
      Alert.alert('Permission refusée', 'La permission de caméra est nécessaire pour prendre une photo.');
      return;
    }
    console.log('Lancement de la caméra...');
    launchCamera({ mediaType: 'photo' }, response => {
      console.log('Réponse de la caméra:', response);
      if (response.didCancel) {
        Alert.alert('Annulé');
      } else if (response.errorCode) {
        Alert.alert('Erreur de la caméra', response.errorMessage);
      } else if (onImageSelected) {
        onImageSelected(response.assets[0].uri);
      }
    });
  };

  const handleChooseFromLibrary = async () => {
    console.log('Demande de permission de bibliothèque de photos iphoe...');
    const hasStoragePermission = await checkPhotoLibraryPermissionIOS();
    if (!hasStoragePermission) {
      console.log('Permission de bibliothèque de photos refusée');
      Alert.alert('Permission refusée', 'La permission de lire le stockage est nécessaire pour choisir une photo.');
      return;
    }

    console.log('Ouverture de la bibliothèque de photos Iphone...');
    launchImageLibrary({ mediaType: 'photo' }, response => {
      console.log('Réponse de la bibliothèque de photos:', response);
      if (response.didCancel) {
        Alert.alert('Annulé');
      } else if (response.errorCode) {
        Alert.alert('Erreur de la bibliothèque', response.errorMessage);
      } else if (onImageSelected) {
        onImageSelected(response.assets[0].uri);
      }
    });
  };

  return (
    <View style={{ marginVertical: 10 }}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                handleTakePhoto();
                setModalVisible(!modalVisible);
              }}>
              <Text style={styles.textStyle}>{translations.JsonImagePickerModal.JsonTakePicture}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.button}
              onPress={() => {
                handleChooseFromLibrary();
                setModalVisible(!modalVisible);
              }}>
              <Text style={styles.textStyle}>{translations.JsonImagePickerModal.JsonLibrary}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => setModalVisible(!modalVisible)}>
              <Text style={styles.textStyle}>{translations.JsonActivityPages.JsonActivityScreen.JsonCancel}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

// Les styles restent inchangés par rapport à votre composant original
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: '#2196F3',
    marginBottom: 10,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default ProfileImagePickerIOS;
