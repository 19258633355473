import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import Images from '../../../constants/Images';
import { format } from 'date-fns';

const LikedNotificationCard = ({ notificationId, likerUserName, likedAt, isRead, onRead, onUnread,isMute }) => {
  // Cette fonction est appelée lorsque l'utilisateur appuie sur la notification
  const handlePress = async () => {
    // Si la notification est déjà lue, on appelle onUnread pour la marquer comme non lue
    // Sinon, on appelle onRead pour la marquer comme lue
    if (isRead) {
      onUnread(notificationId);
    } else {
      onRead(notificationId);
    }
  };

  return (
    <TouchableOpacity style={[styles.card, isRead ? styles.pressedCard : styles.unpressedCard]} onPress={handlePress}>
      {isMute
        ? <Images.NotificationsBellMute style={{ width: 50, height: 50 }} />
        : isRead
          ? <Images.NotificationsBellOn style={{ width: 50, height: 50 }} />
          : <Images.NotificationsBellRinging style={{ width: 50, height: 50 }} />
      }
      <View style={styles.textContainer}>
        <Text style={styles.text}>
          {likerUserName} liked your profile.
        </Text>
        <View style={styles.clockContainer}>
          <Images.NotificationsClock style={{ width: 12, height: 12 }} />
          <Text style={styles.textClock}>
            {format(new Date(likedAt), " dd/MM/yyyy  p")}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width:'100%',
    flexDirection: 'row',
    gap: '5%',
    paddingHorizontal:'5%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 87,
    maxHeight:'auto',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    borderColor: 'transparent',
    shadowOpacity: 0.25,
    shadowRadius: 8,
    // elevation: 4,
  },
  textContainer: {
    flex: 0.7,
    gap: 15,
    justifyContent: 'flex-start'
  },
  clockContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 5,
    justifyContent: 'flex-start'
  },
  unpressedCard: {
    backgroundColor: '#B6DFD0', // Couleur pour les notifications non lues
  },
  pressedCard: {
    backgroundColor: '#F8F8F8', // Couleur pour les notifications lues
  },
  text: {
    fontSize: 16,
  },
});

export default LikedNotificationCard;
