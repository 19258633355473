// FR gère le stockage, le chargement et la gestion des activités et des likes d'activités (FrameFigma021A,021B,021C,022A,021D,022A,022S,022B,022C,022D)
// EN This script handles the storage, loading, and management of activities and activity likes. (FrameFigma021A,021B,021C,022A,021D,022A,022S,022B,022C,022D)


// Import Modules
// Import Modules
import React, {createContext, useContext, useState, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

// Import Contexts
import { hostname } from '../../hostName/hostName';

// Declare le Context
const ActivityContext = createContext();

// Creation de Provider
export const ActivityProvider = ({ children }) => {
  // States
  const [activitiesLoading, setActivitiesLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [activityLikes, setActivityLikes] = useState([]);
  const [userLikedActivities, setUserLikedActivities] = useState([]);
  const [userOrganisedActivities, setUserOrganisedActivities] = useState([]);
  const [userCoOrganisedActivities, setUserCoOrganisedActivities] = useState([]);
  const [userAttendedActivities, setUserAttendedActivities] = useState([]);

  // Get All Activities
  const getActivities = async () => {
    setActivitiesLoading(true);
    // Async Storage Variables
    const userId = await AsyncStorage.getItem('userId');
    const userToken = await AsyncStorage.getItem('userToken');

    // Request Headers
    const headers = {
      'Authorization': `Bearer ${userToken}`,
      'Content-Type': 'application/json'
    };

    // API Request
    try {
      const response = await axios.get(`${hostname}/activities`, { headers });

      if (response.status === 200) {
        setActivitiesLoading(false);

        // Tous les activites
        setActivities(response.data);

        // Les activites organiser par logged in User
        setUserOrganisedActivities(response.data.filter(activity => activity.author === userId));

        // Les activites co-organiser par logged in User
        setUserCoOrganisedActivities(response.data.filter(activity => activity.coOrganizers.includes(userId)));

        // Les activites attirer par logged in User
        const attendedActivities = response.data.filter(activity => activity.attendees.includes(userId));
        setUserAttendedActivities(attendedActivities);

        // Log attendees
        attendedActivities.forEach(activity => {
          console.log(`Activity ID: ${activity._id} has attendees: `, activity.attendees);
        });

      }
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  // Get Activity Likes
  const getActivityLikes = async () => {
    // Async Storage Variables
    const userId = await AsyncStorage.getItem('userId');
    const userToken = await AsyncStorage.getItem('userToken');

    // Request Headers
    const headers = {
      'Authorization': `Bearer ${userToken}`,
      'Content-Type': 'application/json'
    };

    // API Request
    try {
      const response = await axios.get(`${hostname}/activityLikes`, { headers });

      // Tous les acitivities likes
      setActivityLikes(response.data);

      // Les activites liker par logged in User
      setUserLikedActivities(response.data.filter(like => like.likedBy === userId));
    } catch (error) {
      console.error('Error fetching activity likes:', error);
    }
  }

  useEffect(() => {
    getActivities();
    getActivityLikes();
  }, []);

  return (
    <ActivityContext.Provider
      value={{
        activitiesLoading,
        activities,
        getActivities,
        activityLikes,
        getActivityLikes,
        userLikedActivities,
        userOrganisedActivities,
        userCoOrganisedActivities,
        userAttendedActivities
      }}>
      {children}
    </ActivityContext.Provider>
  );
};

// Export Hook
export const useActivity = () => useContext(ActivityContext);

