import React from 'react';
import { Text, TouchableOpacity, View, Platform } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

// Import Components
import InfoScreen from './NotificationsInfoScreen/NotificationsInfoScreen';
import ManageScreen from './NotificationsManageScreen/NotificationsManageScreen';
import InteractionsScreen from './NotificationsInteractionScreen/NotificationsInteractionScreen';

import { useUser } from '../../contexts/UserContext';
// Importation conditionnelle des fichiers de style
const styles = Platform.OS === 'web'
  ? require('./StylesWeb').default
  : require('./StylesMobile').default;

const Tab = createMaterialTopTabNavigator();

const CustomTabBar = ({state, descriptors, navigation}) => {
  return (
    <View style={{flexDirection: 'row', backgroundColor: '#000'}}>
      {state.routes.map((route, index) => {
        const {options} = descriptors[route.key];
        const label = options.tabBarLabel !== undefined ? options.tabBarLabel : options.title !== undefined ? options.title : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        return (
          <TouchableOpacity
            key={index}
            onPress={onPress}
            style={{
              flex: 1,
              alignItems: 'center',
              padding: 16,
              backgroundColor: isFocused ? '#59C09B' : 'gray', // Couleur de fond basée sur l'état
            }}>
            <Text style={{color: isFocused ? 'gray' : 'white'}}>{label}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const TabNavigator = () => {
  // Utilisation du contexte User
  const { user, allUsers } = useUser();

  return (
    <Tab.Navigator
      tabBar={props => <CustomTabBar {...props} />}
      screenOptions={{
        headerShown: false,
      }}>
      {/* Utilisez une fonction de rendu pour passer le contexte comme prop */}
      <Tab.Screen name="Info">{props => <InfoScreen {...props} user={user} allUsers={allUsers} />}</Tab.Screen>
      <Tab.Screen name="Manage">{props => <ManageScreen {...props} user={user} />}</Tab.Screen>
      <Tab.Screen name="Interactions">{props => <InteractionsScreen {...props} user={user} allUsers={allUsers} />}</Tab.Screen>
    </Tab.Navigator>
  );
};

export default TabNavigator;