//🇫🇷 Affichage de la Burger Button pour le header pour ouvrir le drawer navigation (FrameFigma-24B)
//🇬🇧 Display of the burger button for the header to open the drawer navigation (FrameFigma-24B)
import React from 'react';
import {View, Text, Platform, Pressable, StyleSheet} from 'react-native';
import {useNavigation, DrawerActions} from '@react-navigation/native';
import {useWebDrawer} from '../DrawerNavigator/DrawerWeb/WebDrawerContext';

const BurgerButton = () => {
  const navigation = useNavigation();
  const context = useWebDrawer();
  const toggleDrawer = context ? context.toggleDrawer : () => {};

  const handlePress = () => {
    if (Platform.OS === 'web') {
      toggleDrawer();
    } else {
      navigation.dispatch(DrawerActions.openDrawer());
    }
  };

  return (
    <Pressable style={styles.burgerButton} onPress={handlePress}>
      <View>
        <View style={styles.line} />
        <View style={styles.line} />
        <View style={styles.line} />
      </View>
      <View style={styles.menuBtn}>
        <Text style={styles.menuText}>Menu</Text>
      </View>
    </Pressable>
  );
};

export default BurgerButton;

const styles = StyleSheet.create({
  burgerButton: {
    flexDirection: 'column', // Correction de 'colomn' à 'column'
    alignItems: 'center',
    cursor: 'pointer',
    padding: 10,
  },
  line: {
    height: 2,
    width: 35,
    backgroundColor: 'white',
    marginVertical: 2,
  },
  menuText: {
    fontSize: 13,
    color: 'white',
  },
});
