/*
  NE PAS UTLISER CE FICHIER
  ICI l'IDEE EST DE CREER UNE NOUVELLE ACTIVITE A PARTIR D'UNE ACTIVITE EXISTANTE
  DIRECTMENT AVEC LE MEME TITRE D'ACTIVITE (MAIS AJOUTE COPY) ET TOUS LES AUTRES DETAILS

  LE FICHIER QUI FAIT LE COPY ACTIVITE SE TROUVE ICI:
  ActivityModule/CopyActivityScreen/CopyActivityNavigator.js
*/

// Import Modules
import React, { useState } from 'react';
import { View, Text, Platform, Pressable } from 'react-native';

// Import Contexts / Hooks
import { useUser } from '../../../../../../contexts/UserContext';
import { useNavigation } from '@react-navigation/native';

// Import Services
import { createActivity } from '../../../../../../services/Activities';

const CopyToCreateActivity = ({ activity, onClose }) => {

  // Contexts
  const { user } = useUser();
  const navigation = useNavigation();

  // States
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [newActivity, setNewActivity] = useState(null);

  // Create new activity from current activity
  const newActivityData = {
    title: activity.title + ' copy',
    description: activity.description,
    activityImage: activity.activityImage,
    date: activity.date,
    startTime: activity.startTime,
    endTime: activity.endTime,
    location: activity.location,
    attendeeLimit: activity.attendeeLimit,
    attendeesValidation: activity.attendeesValidation,
    hasPrice: activity.hasPrice,
    price: activity.price,
    howToFind: activity.howToFind,
    topic: activity.topic,
    author: user._id // current user becomes author
  }

  // Send new activity to backend
  const handleCopyToCreateActivity = async () => {
    try {
      const result = await createActivity(newActivityData);
      if (!result.response) {
        setError(true);
      } else {
        setSuccess(true);
        setNewActivity(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle Navigation to new activity
  // NOTE - Normalement, j'ai voulu d'avance vers le nouvelle activite creee, mais a cause de la 'lastRouteState' c'est compliquer (il toujours prends le url ancien)
  const handleNavigationToNewActivity = () => {
    navigation.navigate('Activities');
    // navigation.navigate('Activity', { activityId: newActivity._id });
  }

  return (
    <View>
      {
        success && (
          <>
            <Text>Success! Your activity was created!</Text>
            <Pressable onPress={handleNavigationToNewActivity}>
              <Text>Take Me To My Activity</Text>
            </Pressable>
          </>
        )}
        {
          error && (
            <>
              <Text>Error! Your activity could not be created.</Text>
            </>
          )
        }
        {
          !success && !error && (
          <>
            <Text>Copy To Create Activity</Text>
            <Text>Do you wish to use details from this activity to create your own?</Text>
            <Pressable onPress={handleCopyToCreateActivity}>
              <Text>Create My Activity</Text>
            </Pressable>
        </>
        )}
    </View>
  )
}

export default CopyToCreateActivity
