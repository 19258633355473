// Fr Cette page affiche l'étape 1 pour modifier une activité(FrameFigma031,FrameFigma032A,FrameFigma035A)
// En This page displays the first step to edit an activity(FrameFigma031,FrameFigma032A,FrameFigma035A)
// Import Modules
import React, { useState, useEffect } from 'react'
import { ScrollView, View, Text, Platform } from 'react-native'

// Import Components
import AddressAutoPicker from '../../../../components/AddressAutoPicker/AddressAutoPicker';
import AddressMapPickerMobile from '../../../../components/AddressMapPicker/AdressMapPickerMobile/AddressMapPickerMobile';
import AddressMapPickerWeb from '../../../../components/AddressMapPicker/AdressMapPickerWeb/AddressMapPickerWeb';
import DateTimePickerComponent from '../../../../components/DateTimePicker/DateTimePickerMobile/DateTimePickerMobile';
import DateTimePickerModal from '../../../../components/DateTimePicker/DateTimePickerWeb/DateTimePickerWeb';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import {useError} from '../../../../contexts/ErrorContext';

// Import Contexts
import { useLanguage } from '../../../../contexts/LanguageContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default

const CopyActivityStepOne = ({ activity, onNextStep }) => {

  // Contexts
  const { translations } = useLanguage();
  const { language } = useLanguage();
  const t = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen
  const {error, setError} = useError();

  // States
  const [switchBtnUnlimitted, setSwitchBtnUnlimitted] = useState(false);
  const [activityTitle, setActivityTitle] = useState('');
  const [selectedCoords, setSelectedCoords] = useState({
    lat: activity.location.latitude,
    lng: activity.location.longitude
  });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sliderValue, setSliderValue] = useState(1);
  const [sliderValue1, setSliderValue1] = useState(1);
  const [price, setPrice] = useState(false);
  const [priceValue, setPriceValue] = useState(0);
  const [buyTicketsLink, setBuyTicketsLink] = useState('');


  // Fonction pour gérer le changement du titre de l'activité
  const handleActivityTitleChange = (text) => {
    const trimmedText = text.slice(0, 60);  
    setActivityTitle(trimmedText);
  };

  const handleAddressSelect = (address, latitude, longitude) => {
    console.log('Selected Address:', address);
    setSelectedAddress(address);
    // Assurez-vous d'utiliser les clés lat et lng pour correspondre à l'attente de Maps.js
    setSelectedCoords({ lat: latitude, lng: longitude });
    console.log('Selected Coords:', { lat: latitude, lng: longitude });
  };

  const handleNextStep = () => {
    let errorMessage = '';
    if (activityTitle === "") {
      errorMessage = 'Veuillez saisir un titre pour votre activité.';
    } else if (selectedDate === null) {
      errorMessage = 'Veuillez sélectionner une date pour votre activité.';
    }

    if (errorMessage !== '') {
      setError(errorMessage);
    } else {
      onNextStep();
    }
  };  

  useEffect(() => {
    async function saveData() {
      try {
        const roundedSliderValue = Math.round(sliderValue);

        await AsyncStorage.setItem('switchBtnUnlimited', JSON.stringify(switchBtnUnlimitted));
        await AsyncStorage.setItem('activityTitle', activityTitle);
        await AsyncStorage.setItem('AttendeeLimitation', roundedSliderValue.toString());
        await AsyncStorage.setItem('hasPrice', price.toString());
        await AsyncStorage.setItem('priceValue', priceValue.toString());
        await AsyncStorage.setItem('buyTicketsLink', buyTicketsLink.toString());
        await AsyncStorage.setItem('friendsNumber', sliderValue1.toString());
      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
    saveData();
  }, [switchBtnUnlimitted, activityTitle, sliderValue, price, sliderValue1, priceValue, buyTicketsLink]);

  useEffect(() => {
    // Fonction de nettoyage pour supprimer les données du AsyncStorage lorsque la page est déchargée
    const cleanup = async () => {
      await AsyncStorage.removeItem('activityTitle');
      await AsyncStorage.removeItem('switchBtnUnlimited');
      await AsyncStorage.removeItem('AttendeeLimitation');
      await AsyncStorage.removeItem('hasPrice');
      await AsyncStorage.removeItem('priceValue');
      await AsyncStorage.removeItem('buyTicketsLink');
      await AsyncStorage.removeItem('friendsNumber');
    };
    cleanup();

  }, []);

  //Supprime le msg d'erreur lorsque l'on quitte la page
  useEffect(() => {
  // Cleanup function
    return () => {
      setError(""); // Reset error message when unmounting
    };
   }, []);
  

  useEffect(() => {
    // get data of current activty
    setSwitchBtnUnlimitted(activity.attendeeLimit > 30)
    setActivityTitle(activity.title || '')
    setSliderValue(activity.attendeeLimit || 2)
    setSliderValue1(activity.friendsNumber || 2)
    setPrice(activity.hasPrice || false)
    setPriceValue(activity.price || 0)
    setBuyTicketsLink(activity.buyTicketsLink || '')
  }, [activity]);

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
    {error ? (
      <View style={styles.headerError}>
        <Text style={styles.headerErrorText}>{error}</Text>
      </View>
    ) : null}
      <Text style={styles.textTitleOne}>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonMainInformation}</Text>
      <TextInputCustom
        handleTextChange={handleActivityTitleChange}
        value={activityTitle}
      />
      <Text>{translations.JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonAddress}</Text>
      <View style={styles.addressAutoPicker}>
        <AddressAutoPicker
          onAddressSelect={handleAddressSelect}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          addressType="activity"
          placeholder={activity.location.address}
        />
      </View>
      <View style={styles.map}>
        {/* view ici pour ajout des espace entre AddressAutoPicker et Map */}
        {Platform.OS === 'web' ? <AddressMapPickerWeb selectedCoords={selectedCoords} /> : <AddressMapPickerMobile selectedCoords={selectedCoords} />}
      </View>
      <View style={styles.dateContainer}>
        {Platform.OS === 'web' ?
          <DateTimePickerModal
            title={t.JsonDateTime}
            onDateChange={(date) => setSelectedDate(date)}
          /> : <DateTimePickerComponent
            title={t.JsonDateTime}
            onDateChange={(date) => setSelectedDate(date)}
          />
        }
      </View>
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonUnlimitedAttendees}</Text>
        <SwitchBtn
          state={switchBtnUnlimitted}
          setState={setSwitchBtnUnlimitted}
        />
      </View>
      {switchBtnUnlimitted !== true &&
        <View style={[styles.slider, { marginLeft: 10 }]}>
          <Text style={styles.slider}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonAttendeesLimitation}</Text>
          <SliderComponent
            min={2}
            max={100}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
          />
        </View>}
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}</Text>
        <SwitchBtn
          state={price}
          setState={setPrice}
        />
      </View>
      {price === true &&
        <View style={{ marginLeft: 10 }}>
          <View style={{ width: "30%" }}>
            <TextInputCustom
              placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}
              handleTextChange={text => setPriceValue(text)}
              value={priceValue}
              isPlaceHolderTextAlignCenter={false}
            />
          </View>
          <View style={{ width: "95%" }}>
            <TextInputCustom
              placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonBuyTicket}
              handleTextChange={text => setBuyTicketsLink(text)}
              value={buyTicketsLink}
              isPlaceHolderTextAlignCenter={false}
            />
          </View>
        </View>
      }
      <View style={[styles.slider, { marginLeft: 10 }]}>
        <Text style={styles.slider}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonHowMuchFriends}</Text>
        <SliderComponent
          min={0}
          max={9}
          sliderValue={sliderValue1}
          setSliderValue={setSliderValue1}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.btnContainer}>
          {/* si btn price est on, ya condition, sinon pas de condition */}
          <ButtonNext conditionNeed={activityTitle !== "" && selectedDate !== null} handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton} />
        </View>
      </View>
    </ScrollView>
  )
}

export default CopyActivityStepOne