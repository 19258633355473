// Import Modules
import { View, Text, Platform } from 'react-native'
import React from 'react'

// Import Contexts
import { useLanguage } from '../../../../../contexts/LanguageContext'

// Import Icons
import { Icons } from '../../../../../constants/Icons';

// Import Styles
const styles = Platform.OS === 'web'
  ? require('./Styles.web').default
  : require('./Styles.mobile').default;

const ActivitiesProchaines = ({ users, allActivities }) => {
  // Contexts
  const { translations } = useLanguage();
  
  // Map Activities to Organisers
  const activitiesWithOrganizers = allActivities.map(activity => {
    const organizer = users.find(user => user._id === activity.author);
    const organizerName = organizer ? organizer.firstName : "Unknown";
    return { ...activity, organizerName };
  });

  return (
    <View style={{ flex: 1, paddingTop: 10 }}>
      <View
        style={{
          flexDirection: "column",
          borderColor: "#000",
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            backgroundColor: "#f2f2f2",
          }}
        >
          <View
            style={{
              ...styles.column,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#505050",
            }}
          >
          <Text
            style={{
              ... styles.column,
              width: 100,
              // paddingTop: 10,
              background: "#505050",
            }}
          >
            <Icons.Calendar width={20} height={20} />
          </Text>
          </View>
          <View
            style={{
              ...styles.column,
              flex: 2,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#505050",
            }}
          >
            <Text style={{ marginRight: 5, fontWeight: "bold", color: "#fff" }}>
              {translations.JsonProfilePages.JsonMyProfileScreen.JsonActivities}
            </Text>
            <Icons.Calendar width={20} height={20} />
          </View>
          <View
            style={{
              ...styles.column,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#505050",
            }}
          >
          <Text style={{ ...styles.column, width: 100}}>
            <Icons.PeopleOn width={20} height={20}/>
          </Text>
          </View>
          <View
            style={{
              ...styles.column,
              width: 100,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 5,
              paddingRight: 5,
              background: "#505050",
              color: "#fff",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "#fff" }}>
              {translations.JsonContactScreen.JsonOrganizer}
            </Text>
          </View>
        </View>

        {activitiesWithOrganizers.map((activity, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderBottomWidth: 1,
              borderColor: "#000",
              backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
            }}
          >
            <Text style={{ ...styles.column, width: 100 }}>
              {new Date(activity.date).toLocaleDateString()}
            </Text>
            <View
              style={{
                ...styles.column,
                flex: 2,
                flexDirection: "row",
                alignItems: "flex-end",
                color: "#59C19C",
                // textWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              <Text>
                {activity.title.length > 17
                  ? `${activity.title}`
                  : activity.title}
              </Text>
            </View>
            <Text style={{ ...styles.column, width: 100 }}>
              {activity.attendees.length}
            </Text>
            <Text
              style={{
                ...styles.column,
                width: 100,
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {activity.organizerName}

            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default ActivitiesProchaines