import React from 'react';
import { Text, StyleSheet, TouchableOpacity,View } from 'react-native';
import Images from '../../../constants/Images';
import { format } from 'date-fns';

const FriendAddedNotificationCard = ({ friendName, friendId, isRead, onRead, onUnread,isMute }) => {
  console.log('FriendAddedNotificationCard', friendName, friendId, isRead);
  const handlePress = () => {
    if (isRead) {
      onUnread(friendId);
    } else {
      onRead(friendId);
    }
  };
  return (
    <TouchableOpacity style={[styles.card, isRead ? styles.pressedCard : styles.unpressedCard]} onPress={handlePress}>
     {isMute
        ? <Images.NotificationsBellMute style={{ width: 50, height: 50 }} />
        : isRead
          ? <Images.NotificationsBellOn style={{ width: 50, height: 50 }} />
          : <Images.NotificationsBellRinging style={{ width: 50, height: 50 }} />
      }
      <View style={styles.textContainer}>
        <Text style={styles.text}>Maintenant tu es ami avec {friendName}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  card: {
    flex: 1,
    width:'100%',
    flexDirection: 'row',
    gap: '5%',
    paddingHorizontal:'5%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 87,
    maxHeight:'auto',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    borderColor: 'transparent',
    shadowOpacity: 0.25,
    shadowRadius: 8,
    // elevation: 4,
  },
  textContainer: {
    flex:0.7,
    gap:15,
    justifyContent:'flex-start'
  },
  unpressedCard: {
    backgroundColor: '#B6DFD0', // Couleur pour les notifications non lues
  },
  pressedCard: {
    backgroundColor: '#FFFFFF', // Couleur pour les notifications lues
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  // Vous pouvez supprimer button et buttonText si non utilisés
});

export default FriendAddedNotificationCard;
