//FR TextInput avec ou sans icon, utilisable aussi pour mdp, multiline (FrameFigma043,FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma031,FrameFigma032A,FrameFigma032B,FrameFigma032C,FrameFigma035A,FrameFigma035B,FrameFigma033,FrameFigma034,FrameFigma038A,FrameFigma038B,FrameFigma039,FrameFigma031)
//EN TextInput with or without an icon, usable also for passwords, multiline. (FrameFigma043,FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma031,FrameFigma032A,FrameFigma032B,FrameFigma032C,FrameFigma035A,FrameFigma035B,FrameFigma033,FrameFigma034,FrameFigma038A,FrameFigma038B,FrameFigma039,FrameFigma031)
import React from "react";
import { View, Text, Pressable, Platform } from "react-native";
const styles =
    Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;

const SwitchBtn = ({ state, setState }) => {
  return (
    <Pressable
      style={[
        styles.container,
        { backgroundColor: state ? "#4caf50" : "#E64C3C" },
      ]}
      onPress={() => {
        setState(!state);
      }}
    >
      <View style={[styles.circle, { marginLeft: state ? 23 : 2 }]} />
      <Text style={[styles.switchInfo, state ? { right: 22 } : { left: 20 }]}>
        {state ? "ON" : "OFF"}
      </Text>
    </Pressable>
  );
};

export default SwitchBtn;
