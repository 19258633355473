// FR une carte horizontale affichant les détails d'un utilisateur
// EN "A horizontal card displaying user details."

// Import Modules
import React from "react";
import { View, Text, Image, Platform } from "react-native";

// Import Components
import SwitchBtn from "../SwitchBtn/SwitchBtn";

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const UserCardHorizontal = ({ user, prop1, prop2 }) => {
  
  // Assurez-vous d'avoir une URL valide avant de tenter de charger l'image
  const imageUrl = user.profileImage
    ? user.profileImage
    : `${hostname}/uploads/${user.profileImage}`;

  return (
    <View style={styles.card}>
      <View style={styles.detailsContainer}>
        {imageUrl ? (
          <Image style={styles.profileImage}
            source={{ uri: imageUrl }}
          />
        ) : null}
        {/* <View style={styles.userBadge}>
          <Text>User Badge Here</Text>
        </View> */}
        <View style={styles.userDetails}>
          <Text>
            {user.firstName} {user.lastName}
          </Text>
          <Text>{user.email}</Text>
        </View>
      </View>
      <View style={styles.actionContainer}>
        <SwitchBtn user={user} state={prop1} setState={prop2} />
      </View>
    </View>
  )
};

export default UserCardHorizontal
