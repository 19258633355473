// Images pour Activity
// /Goodies
import GoodiesFree from '../assets/Icons/Activity/Goodies/Free.svg';
import GoodiesFreeDrink from '../assets/Icons/Activity/Goodies/FreeDrink.svg';
import GoodiesFreePass from '../assets/Icons/Activity/Goodies/FreePass.svg';
import GoodiesMoreFun from '../assets/Icons/Activity/Goodies/MoreFun.svg';

// /Topics
// ./BlackAndWhite
import TopicsBlackAndWhiteAfterwork from '../assets/Icons/Activity/Topics/BlackAndWhite/Afterwork.svg';
import TopicsBlackAndWhiteApero from '../assets/Icons/Activity/Topics/BlackAndWhite/Apero.svg';
import TopicsBlackAndWhiteBusiness from '../assets/Icons/Activity/Topics/BlackAndWhite/Business.svg';
import TopicsBlackAndWhiteCulture from '../assets/Icons/Activity/Topics/BlackAndWhite/Culture.svg';
import TopicsBlackAndWhiteDancing from '../assets/Icons/Activity/Topics/BlackAndWhite/Dancing.svg';
import TopicsBlackAndWhiteGames from '../assets/Icons/Activity/Topics/BlackAndWhite/Games.svg';
import TopicsBlackAndWhiteLinguistic from '../assets/Icons/Activity/Topics/BlackAndWhite/Linguistic.svg';
import TopicsBlackAndWhiteMeal from '../assets/Icons/Activity/Topics/BlackAndWhite/Meal.svg';
import TopicsBlackAndWhiteMovie from '../assets/Icons/Activity/Topics/BlackAndWhite/Movie.svg';
import TopicsBlackAndWhiteMusic from '../assets/Icons/Activity/Topics/BlackAndWhite/Music.svg';
import TopicsBlackAndWhiteMutualHelp from '../assets/Icons/Activity/Topics/BlackAndWhite/MutualHelp.svg';
import TopicsBlackAndWhiteParty from '../assets/Icons/Activity/Topics/BlackAndWhite/Party.svg';
import TopicsBlackAndWhitePicnic from '../assets/Icons/Activity/Topics/BlackAndWhite/Picnic.svg';
import TopicsBlackAndWhitePrivate from '../assets/Icons/Activity/Topics/BlackAndWhite/Private.svg';
import TopicsBlackAndWhiteSports from '../assets/Icons/Activity/Topics/BlackAndWhite/Sports.svg';
import TopicsBlackAndWhiteTravel from '../assets/Icons/Activity/Topics/BlackAndWhite/Travel.svg';
// ./Colors
import TopicsColorsAfterwork from '../assets/Icons/Activity/Topics/Colors/Afterwork.svg';
import TopicsColorsApero from '../assets/Icons/Activity/Topics/Colors/Apero.svg';
import TopicsColorsBusiness from '../assets/Icons/Activity/Topics/Colors/Business.svg';
import TopicsColorsCulture from '../assets/Icons/Activity/Topics/Colors/Culture.svg';
import TopicsColorsDancing from '../assets/Icons/Activity/Topics/Colors/Dancing.svg';
import TopicsColorsGames from '../assets/Icons/Activity/Topics/Colors/Games.svg';
import TopicsColorsLinguistic from '../assets/Icons/Activity/Topics/Colors/Linguistic.svg';
import TopicsColorsMeal from '../assets/Icons/Activity/Topics/Colors/Meal.svg';
import TopicsColorsMovie from '../assets/Icons/Activity/Topics/Colors/Movie.svg';
import TopicsColorsMusic from '../assets/Icons/Activity/Topics/Colors/Music.svg';
import TopicsColorsMutualHelp from '../assets/Icons/Activity/Topics/Colors/MutualHelp.svg';
import TopicsColorsParty from '../assets/Icons/Activity/Topics/Colors/Party.svg';
import TopicsColorsPicnic from '../assets/Icons/Activity/Topics/Colors/Picnic.svg';
import TopicsColorsPrivate from '../assets/Icons/Activity/Topics/Colors/Private.svg';
import TopicsColorsSports from '../assets/Icons/Activity/Topics/Colors/Sports.svg';
import TopicsColorsTravel from '../assets/Icons/Activity/Topics/Colors/Travel.svg';

// /Feedback
import FeedbackContactBug from '../assets/Icons/Feedback/ContactBug.svg';
import FeedbackContactOrga from '../assets/Icons/Feedback/ContactOrga.svg';
import FeedbackContactPhone from '../assets/Icons/Feedback/ContactPhone.svg';

// /General
// ./BlackAndWhite
import GeneralBlackAndWhiteAttachment from '../assets/Icons/General/BlackAndWhite/Attachment.svg';
import GeneralBlackAndWhiteCloseLargeSign from '../assets/Icons/General/BlackAndWhite/CloseLargeSign.svg';
import GeneralBlackAndWhiteCloseSign from '../assets/Icons/General/BlackAndWhite/CloseSign.svg';
import GeneralBlackAndWhiteCloseSignWhite from '../assets/Icons/General/BlackAndWhite/CloseSignWhite.svg';
import GeneralBlackAndWhiteDownArrow from '../assets/Icons/General/BlackAndWhite/DownArrow.svg';
import GeneralBlackAndWhiteFilter from '../assets/Icons/General/BlackAndWhite/Filter.svg';
import GeneralBlackAndWhiteFilterWithDescription from '../assets/Icons/General/BlackAndWhite/FilterWithDescription.svg';
import GeneralBlackAndWhiteImageGallery from '../assets/Icons/General/BlackAndWhite/ImageGallery.svg';
import GeneralBlackAndWhiteInfoSign from '../assets/Icons/General/BlackAndWhite/InfoSign.svg';
import GeneralBlackAndWhiteLeftArrow from '../assets/Icons/General/BlackAndWhite/LeftArrow.svg';
import GeneralBlackAndWhiteLoop from '../assets/Icons/General/BlackAndWhite/Loop.svg';
import GeneralBlackAndWhiteLoopWhite from '../assets/Icons/General/BlackAndWhite/LoopWhite.svg';
import GeneralBlackAndWhiteNextWhite from '../assets/Icons/General/BlackAndWhite/NextWhite.svg';
import GeneralBlackAndWhitePadLock from '../assets/Icons/General/BlackAndWhite/PadLock.svg';
import GeneralBlackAndWhitePadLockOpen from '../assets/Icons/General/BlackAndWhite/PadLockOpen.svg';
import GeneralBlackAndWhitePoints from '../assets/Icons/General/BlackAndWhite/Points.svg';
import GeneralBlackAndWhitePreviousWhite from '../assets/Icons/General/BlackAndWhite/PreviousWhite.svg';
import GeneralBlackAndWhiteRightArrow from '../assets/Icons/General/BlackAndWhite/RightArrow.svg';
import GeneralBlackAndWhiteRightArrowWhite from '../assets/Icons/General/BlackAndWhite/RightArrowWhite.svg';
import GeneralBlackAndWhiteSendPlane from '../assets/Icons/General/BlackAndWhite/SendPlane.svg';
import GeneralBlackAndWhiteShareArrow from '../assets/Icons/General/BlackAndWhite/ShareArrow.svg';
import GeneralBlackAndWhiteShareBlack from '../assets/Icons/General/BlackAndWhite/ShareBlack.svg';
import GeneralBlackAndWhiteShareWhite from '../assets/Icons/General/BlackAndWhite/ShareWhite.svg';
import GeneralBlackAndWhiteSharpPlus from '../assets/Icons/General/BlackAndWhite/SharpPlus.svg';
import GeneralBlackAndWhiteSlideSearch from '../assets/Icons/General/BlackAndWhite/SlideSearch.svg';
import GeneralBlackAndWhiteUnsplashFill from '../assets/Icons/General/BlackAndWhite/UnsplashFill.svg';
import GeneralBlackAndWhiteVerify from '../assets/Icons/General/BlackAndWhite/Verify.svg';
import GeneralBlackAndWhiteZoomIn from '../assets/Icons/General/BlackAndWhite/ZoomIn.svg';
import GeneralBlackAndWhiteZoomOut from '../assets/Icons/General/BlackAndWhite/ZoomOut.svg';
// ./Colors
import GeneralColorsCalendar from '../assets/Icons/General/Colors/Calendar.svg';
import GeneralColorsCheckbox from '../assets/Icons/General/Colors/Checkbox.svg';
import GeneralColorsCheckSign from '../assets/Icons/General/Colors/CheckSign.svg';
import GeneralColorsCloseLargeSignRed from '../assets/Icons/General/Colors/CloseLargeSignRed.svg';
import GeneralColorsCloseRed from '../assets/Icons/General/Colors/CloseRed.svg';
import GeneralColorsCloseSignGreen from '../assets/Icons/General/Colors/CloseSignGreen.svg';
import GeneralColorsComingSoon from '../assets/Icons/General/Colors/ComingSoon.svg';
import GeneralColorsCongratulationsStatus from '../assets/Icons/General/Colors/CongratulationsStatus.svg';
import GeneralColorsCopy from '../assets/Icons/General/Colors/Copy.svg';
import GeneralColorsDollarSign from '../assets/Icons/General/Colors/DollarSign.svg';
import GeneralColorsEyeToggle from '../assets/Icons/General/Colors/EyeToggle.svg';
import GeneralColorsGreenClock from '../assets/Icons/General/Colors/GreenClock.svg';
import GeneralColorsIdea from '../assets/Icons/General/Colors/Idea.svg';
import GeneralColorsInfoGreen from '../assets/Icons/General/Colors/InfoGreen.svg';
import GeneralColorsInviteFriends from '../assets/Icons/General/Colors/InviteFriends.svg';
import GeneralColorsLeftArrow from '../assets/Icons/General/Colors/LeftArrow.svg';
import GeneralColorsLinkChain from '../assets/Icons/General/Colors/LinkChain.svg';
import GeneralColorsLogoSocializusBlack from '../assets/Icons/General/Colors/LogoSocializusBlack.svg';
import GeneralColorsLogoSocializusGrey from '../assets/Icons/General/Colors/LogoSocializusGrey.svg';
import GeneralColorsLogoSocializusWhite from '../assets/Icons/General/Colors/LogoSocializusWhite.svg';
import GeneralColorsLogOut from '../assets/Icons/General/Colors/LogOut.svg';
import GeneralColorsMagnifierOn from '../assets/Icons/General/Colors/MagnifierOn.svg';
import GeneralColorsOffButton from '../assets/Icons/General/Colors/OffButton.svg';
import GeneralColorsOnButton from '../assets/Icons/General/Colors/OnButton.svg';
import GeneralColorsOnlineMeeting from '../assets/Icons/General/Colors/OnlineMeeting.svg';
import GeneralColorsPasswordPadLock from '../assets/Icons/General/Colors/PasswordPadLock.svg';
import GeneralColorsPlus from '../assets/Icons/General/Colors/Plus.svg';
import GeneralColorsQrCode from '../assets/Icons/General/Colors/QrCode.svg';
import GeneralColorsSettings from '../assets/Icons/General/Colors/Settings.svg';
import GeneralColorsStopSign from '../assets/Icons/General/Colors/StopSign.svg';
import GeneralColorsTicket from '../assets/Icons/General/Colors/Ticket.svg';
import GeneralColorsUpdate from '../assets/Icons/General/Colors/Update.svg';
import GeneralColorsUpload from '../assets/Icons/General/Colors/Upload.svg';
import GeneralColorsWarning from '../assets/Icons/General/Colors/Warning.svg';

// Images pour le fichier InviteFriendsScreen.js
import ContactFriends from '../assets/Icons/InviteFriends/ContactFriends.svg';
import Email from '../assets/Icons/InviteFriends/Email.svg';
import Instagram from '../assets/Icons/InviteFriends/Instagram.svg';
import Link from '../assets/Icons/InviteFriends/InviteLinkChain.svg';
import PhoneMsg from '../assets/Icons/InviteFriends/TextMessage.svg';
import Messenger from '../assets/Icons/InviteFriends/Messenger.svg';
import RightArrowGrey from '../assets/Icons/InviteFriends/RightArrowGrey.svg';
import Snapchat from '../assets/Icons/InviteFriends/Snapchat.svg';
import Tiktok from '../assets/Icons/InviteFriends/TikTok.svg';
import Twitter from '../assets/Icons/InviteFriends/Twitter.svg';
import Whatsapp from '../assets/Icons/InviteFriends/WhatsApp.svg';
import Friendship from '../assets/Icons/InviteFriends/Friendship.svg';

// /Maps
import MapsLocationBlackAndWhite from '../assets/Icons/Maps/LocationBlackAndWhite.svg';
import MapsLocationColors from '../assets/Icons/Maps/LocationColors.svg';
import MapsMetro from '../assets/Icons/Maps/Metro.svg';
import MapsPinBlackAndWhite from '../assets/Icons/Maps/PinBlackAndWhite.svg';
import MapsPinGoogleMaps from '../assets/Icons/Maps/PinGoogleMaps.svg';
import MapsPinPointColors from '../assets/Icons/Maps/PinPointColors.svg';

// /NavBar
// ./BlackAndWhite
// import NavbarBlackAndWhiteBell from '../assets/Icons/Navbar/BlackAndWhite/Bell.svg';
// import NavbarBlackAndWhiteCalendarBottom from '../assets/Icons/Navbar/BlackAndWhite/CalendarBottom.svg';
   import NavbarBlackAndWhiteChat from '../assets/Icons/NavBar/BlackAndWhite/Chat.svg';
// import NavbarBlackAndWhiteConcept from '../assets/Icons/Navbar/BlackAndWhite/Concept.svg';
// import NavbarBlackAndWhiteContactUs from '../assets/Icons/Navbar/BlackAndWhite/Contact-us.svg';
   import NavbarBlackAndWhiteEditInfo from '../assets/Icons/NavBar/BlackAndWhite/EditInfo.svg';
// import NavbarBlackAndWhiteMap from '../assets/Icons/Navbar/BlackAndWhite/Map.svg';
// import NavbarBlackAndWhiteMembersBottom from '../assets/Icons/Navbar/BlackAndWhite/MembersBottom.svg';
// import NavbarBlackAndWhiteMessenger from '../assets/Icons/Navbar/BlackAndWhite/Messenger.svg';
// import NavbarBlackAndWhiteNews from '../assets/Icons/Navbar/BlackAndWhite/News.svg';
import NavbarBlackAndWhitePinPointMiddle from '../assets/Icons/NavBar/BlackAndWhite/PinPointMiddle.svg';
// ./Colors
// import NavbarColorsCalendarBottom from '../assets/Icons/Navbar/Colors/CalendarBottom.svg';
// import NavbarColorsConcept from '../assets/Icons/Navbar/Colors/Concept.svg';
// import NavbarColorsContactUs from '../assets/Icons/Navbar/Colors/ContactUs.svg';
import NavbarColorsEditInfo from '../assets/Icons/NavBar/Colors/EditInfo.svg';
// import NavbarColorsMap from '../assets/Icons/Navbar/Colors/Map.svg';
// import NavbarColorsMembersBottom from '../assets/Icons/Navbar/Colors/MembersBottomColor.svg';
import NavbarColorsMembersMiddle from '../assets/Icons/NavBar/Colors/MembersMiddle.svg';
// import NavbarColorsMessenger from '../assets/Icons/Navbar/Colors/Messenger.svg';
// import NavbarColorsNews from '../assets/Icons/Navbar/Colors/News.svg';
import NavbarColorsChat from '../assets/Icons/NavBar/Colors/ChatColors.svg'

// /Notifications
import NotificationsBellMute from '../assets/Icons/Notifications/BellMute.svg';
import NotificationsBellOn from '../assets/Icons/Notifications/BellOn.svg';
import NotificationsBellRinging from '../assets/Icons/Notifications/BellRinging.svg';
import NotificationsClock from '../assets/Icons/Notifications/Clock.svg';

// /Profiles
import ProfilesAddFriend from '../assets/Icons/Profiles/AddFriend.svg';
import ProfilesArobase from '../assets/Icons/Profiles/Arobase.svg';
import ProfilesBirthday from '../assets/Icons/Profiles/Birthday.svg';
import ProfilesBlockFriend from '../assets/Icons/Profiles/BlockFriend.svg';
import ProfilesCalendarColors from '../assets/Icons/Profiles/CalendarColors.svg';
import ProfilesCamera from '../assets/Icons/Profiles/Camera.svg';
import ProfilesChatColors from '../assets/Icons/Profiles/ChatColors.svg';
import ProfilesCity from '../assets/Icons/Profiles/City.svg';
import ProfilesConfidential from '../assets/Icons/Profiles/Confidential.svg';
import ProfilesEdit from '../assets/Icons/Profiles/Edit.svg';
import ProfilesEmail from '../assets/Icons/Profiles/Email.svg';
import ProfilesFemale from '../assets/Icons/Profiles/Female.svg';
import ProfilesFemaleSign from '../assets/Icons/Profiles/FemaleSign.svg';
import ProfilesInformation from '../assets/Icons/Profiles/Information.svg';
import ProfilesLanguage from '../assets/Icons/Profiles/Language.svg';
import ProfilesLastName from '../assets/Icons/Profiles/LastName.svg';
import ProfilesMale from '../assets/Icons/Profiles/Male.svg';
import ProfilesMaleSign from '../assets/Icons/Profiles/MaleSign.svg';
import ProfilesMemberNumber from '../assets/Icons/Profiles/MemberNumber.svg';
import ProfilesNoGender from '../assets/Icons/Profiles/NoGender.svg';
import ProfilesPasswordPadLock from '../assets/Icons/Profiles/PasswordPadLock.svg';
import ProfilesPhone from '../assets/Icons/Profiles/Phone.svg';
import ProfilesSchedule from '../assets/Icons/Profiles/Schedule.svg';
import ProfilesStopWatch from '../assets/Icons/Profiles/StopWatch.svg';
import ProfilesTrash from '../assets/Icons/Profiles/Trash.svg';
import ProfilesTrashcan from '../assets/Icons/Profiles/Trashcan.svg';
import ProfilesUnblock from '../assets/Icons/Profiles/Unblock.svg';

// /Settings
import SettingsBlockedUser from '../assets/Icons/Settings/BlockedUser.svg';
import SettingsContactUs from '../assets/Icons/Settings/ContactUs.svg';
import SettingsDeleteAccount from '../assets/Icons/Settings/DeleteAccount.svg';
import SettingsEditProfile from '../assets/Icons/Settings/EditProfile.svg';
import SettingsLegalNotice from '../assets/Icons/Settings/LegalNotice.svg';
import SettingsLikedUsers from '../assets/Icons/Settings/LikedUsers.svg';
import SettingsNews from '../assets/Icons/Settings/News.svg';
import SettingsPrivacyPolicy from '../assets/Icons/Settings/PrivacyPolicy.svg';
import SettingsGears from '../assets/Icons/Settings/SettingsGears.svg';
import SettingsTermsOfSales from '../assets/Icons/Settings/TermsOfSales.svg';

// /SocialNetworks
import SocialNetworksAppStore from '../assets/Icons/SocialNetworks/AppStore.svg';
import SocialNetworksDiscord from '../assets/Icons/SocialNetworks/Discord.svg';
import SocialNetworksFacebook from '../assets/Icons/SocialNetworks/Facebook.svg';
import SocialNetworksFbGroup from '../assets/Icons/SocialNetworks/FbGroup.svg';
import SocialNetworksFbPage from '../assets/Icons/SocialNetworks/FbPage.svg';
import SocialNetworksGooglePlay from '../assets/Icons/SocialNetworks/GooglePlay.svg';
import SocialNetworksGoogleSymbol from '../assets/Icons/SocialNetworks/GoogleSymbol.svg';
import SocialNetworksLinkChain from '../assets/Icons/SocialNetworks/LinkChain.svg';
import SocialNetworksMeetup from '../assets/Icons/SocialNetworks/Meetup.svg';
import SocialNetworksMessenger from '../assets/Icons/SocialNetworks/Messenger.svg';
import SocialNetworksOtherLink from '../assets/Icons/SocialNetworks/OtherLink.svg';
import SocialNetworksTelegram from '../assets/Icons/SocialNetworks/Telegram.svg';
import SocialNetworksWhatsapp from '../assets/Icons/SocialNetworks/Whatsapp.svg';

// /UsersInteraction
// ./BlackAndWhite
import UsersInteractionBlackAndWhiteHappyOff from '../assets/Icons/UsersInteraction/BlackAndWhite/HappyOff.svg';
import UsersInteractionBlackAndWhiteHeartGrey from '../assets/Icons/UsersInteraction/BlackAndWhite/HeartGrey.svg';
import UsersInteractionBlackAndWhiteParticipants from '../assets/Icons/UsersInteraction/BlackAndWhite/Participants.svg';
import UsersInteractionBlackAndWhitePeopleOff from '../assets/Icons/UsersInteraction/BlackAndWhite/PeopleOff.svg';
// ./Colors
import UsersInteractionColorsGroup from '../assets/Icons/UsersInteraction/Colors/Group.svg';
import UsersInteractionColorsHappyOn from '../assets/Icons/UsersInteraction/Colors/HappyOn.svg';
import UsersInteractionColorsHeartEmpty from '../assets/Icons/UsersInteraction/Colors/HeartEmpty.svg';
import UsersInteractionColorsHeartFilled from '../assets/Icons/UsersInteraction/Colors/HeartFilled.svg';
import UsersInteractionColorsHeartRed from '../assets/Icons/UsersInteraction/Colors/HeartRed.svg';
import UsersInteractionColorsNonProfit from '../assets/Icons/UsersInteraction/Colors/NonProfit.svg';
import UsersInteractionColorsPeopleOn from '../assets/Icons/UsersInteraction/Colors/PeopleOn.svg';

// assets/Images
import ImagesPaste from '../assets/paste.png';
// ./Backgrounds
import BackgroundsBelleFemme from '../assets/Images/Backgrounds/BelleFemme.png';
import BackgroundsGroupSelfieFriends from '../assets/Images/Backgrounds/GroupSelfieFriends.png';
// ./Concept
import ConceptComingSoon from '../assets/Images/Concept/ComingSoon.jpg';
import ConceptCreateActivities from '../assets/Images/Concept/CreateActivities.jpg';
import ConceptLocalsAndInternationals from '../assets/Images/Concept/LocalsAndInternationals.jpg';
import ConceptNeverAlone from '../assets/Images/Concept/NeverAlone.jpg';
import ConceptPrivateAdress from '../assets/Images/Concept/PrivateAdress.jpg';
import ConceptRealUsers from '../assets/Images/Concept/RealUsers.jpg';
// ./Emoji
import EmojiAraidEmoji from '../assets/Images/Emoji/AraidEmoji.jpg';
import EmojiSourire from '../assets/Images/Emoji/sourire.png';
// ./General
import GeneralLogoSocializus from '../assets/Images/General/LogoSocializus.png';
import GeneralSettingsAnimated from '../assets/Images/General/SettingsAnimated.gif';
import GeneralSplash from '../assets/Images/General/Splash.png';
// ./Home
import HomeArtGallery from '../assets/Images/Home/ArtGallery.png';
import HomeFluoParty from '../assets/Images/Home/FluoParty.png';
import HomeGroupApero from '../assets/Images/Home/GroupApero.png';
import HomeHalloweenParty from '../assets/Images/Home/HalloweenParty.png';
import HomeHomePageBR from '../assets/Images/Home/HomePageBR.png';
import HomeLiveConcert from '../assets/Images/Home/LiveConcert.png';
import HomeNumberParty from '../assets/Images/Home/NumberParty.png';
import HomePicnic from '../assets/Images/Home/GroupPicnic.png';//changer nom pour eviter error de "Conflict: Multiple assets emit different content to the same filename assets/images"
import HomeSoapParty from '../assets/Images/Home/SoapParty.png';
// import HomeButtonWorldStart from '../assets/Images/Home/ButtonWorldStart.png';





export const Images = {
 

  //Activity/Goodies
  GoodiesFree,
  GoodiesFreeDrink,
  GoodiesFreePass,
  GoodiesMoreFun,
  //Activity/Topics/BlackAndWhite
  TopicsBlackAndWhiteAfterwork,
  TopicsBlackAndWhiteApero,
  TopicsBlackAndWhiteBusiness,
  TopicsBlackAndWhiteCulture,
  TopicsBlackAndWhiteDancing,
  TopicsBlackAndWhiteGames,
  TopicsBlackAndWhiteLinguistic,
  TopicsBlackAndWhiteMeal,
  TopicsBlackAndWhiteMovie,
  TopicsBlackAndWhiteMusic,
  TopicsBlackAndWhiteMutualHelp,
  TopicsBlackAndWhiteParty,
  TopicsBlackAndWhitePicnic,
  TopicsBlackAndWhitePrivate,
  TopicsBlackAndWhiteSports,
  TopicsBlackAndWhiteTravel,
  //Activity/Topics/Colors
  TopicsColorsAfterwork,
  TopicsColorsApero,
  TopicsColorsBusiness,
  TopicsColorsCulture,
  TopicsColorsDancing,
  TopicsColorsGames,
  TopicsColorsLinguistic,
  TopicsColorsMeal,
  TopicsColorsMovie,
  TopicsColorsMusic,
  TopicsColorsMutualHelp,
  TopicsColorsParty,
  TopicsColorsPicnic,
  TopicsColorsPrivate,
  TopicsColorsSports,
  TopicsColorsTravel,
  //Feedback
  FeedbackContactBug,
  FeedbackContactOrga,
  FeedbackContactPhone,
  //General/BlackAndWhite
  GeneralBlackAndWhiteAttachment,
  GeneralBlackAndWhiteCloseLargeSign,
  GeneralBlackAndWhiteCloseSign,
  GeneralBlackAndWhiteCloseSignWhite,
  GeneralBlackAndWhiteDownArrow,
  GeneralBlackAndWhiteFilter,
  GeneralBlackAndWhiteFilterWithDescription,
  GeneralBlackAndWhiteImageGallery,
  GeneralBlackAndWhiteInfoSign,
  GeneralBlackAndWhiteLeftArrow,
  GeneralBlackAndWhiteLoop,
  GeneralBlackAndWhiteLoopWhite,
  GeneralBlackAndWhiteNextWhite,
  GeneralBlackAndWhitePadLock,
  GeneralBlackAndWhitePadLockOpen,
  GeneralBlackAndWhitePoints,
  GeneralBlackAndWhitePreviousWhite,
  GeneralBlackAndWhiteRightArrow,
  GeneralBlackAndWhiteRightArrowWhite,
  GeneralBlackAndWhiteSendPlane,
  GeneralBlackAndWhiteShareArrow,
  GeneralBlackAndWhiteShareBlack,
  GeneralBlackAndWhiteShareWhite,
  GeneralBlackAndWhiteSharpPlus,
  GeneralBlackAndWhiteSlideSearch,
  GeneralBlackAndWhiteUnsplashFill,
  GeneralBlackAndWhiteVerify,
  GeneralBlackAndWhiteZoomIn,
  GeneralBlackAndWhiteZoomOut,
  //General/Colors
  GeneralColorsCalendar,
  GeneralColorsCheckbox,
  GeneralColorsCheckSign,
  GeneralColorsCloseLargeSignRed,
  GeneralColorsCloseRed,
  GeneralColorsCloseSignGreen,
  GeneralColorsComingSoon,
  GeneralColorsCongratulationsStatus,
  GeneralColorsCopy,
  GeneralColorsDollarSign,
  GeneralColorsEyeToggle,
  GeneralColorsGreenClock,
  GeneralColorsIdea,
  GeneralColorsInfoGreen,
  GeneralColorsInviteFriends,
  GeneralColorsLeftArrow,
  GeneralColorsLinkChain,
  GeneralColorsLogoSocializusBlack,
  GeneralColorsLogoSocializusGrey,
  GeneralColorsLogoSocializusWhite,
  GeneralColorsLogOut,
  GeneralColorsMagnifierOn,
  GeneralColorsOffButton,
  GeneralColorsOnButton,
  GeneralColorsOnlineMeeting,
  GeneralColorsPasswordPadLock,
  GeneralColorsPlus,
  GeneralColorsQrCode,
  GeneralColorsSettings,
  GeneralColorsStopSign,
  GeneralColorsTicket,
  GeneralColorsUpdate,
  GeneralColorsUpload,
  GeneralColorsWarning,
  //InviteFriendsScreen
  ContactFriends,
  Email,
  Instagram,
  Link,
  PhoneMsg,
  Messenger,
  RightArrowGrey,
  Snapchat,
  Tiktok,
  Twitter,
  Whatsapp,
  Friendship,
  //Maps
  MapsLocationBlackAndWhite,
  MapsLocationColors,
  MapsMetro,
  MapsPinBlackAndWhite,
  MapsPinGoogleMaps,
  MapsPinPointColors,
  //Navbar/BlackAndWhite
  // NavbarBlackAndWhiteBell,
  // NavbarBlackAndWhiteCalendarBottom,
  NavbarBlackAndWhiteChat,
  // NavbarBlackAndWhiteConcept,
  // NavbarBlackAndWhiteContactUs,
  NavbarBlackAndWhiteEditInfo,
  // NavbarBlackAndWhiteMap,
  // NavbarBlackAndWhiteMembersBottom,
  // NavbarBlackAndWhiteMessenger,
  // NavbarBlackAndWhiteNews,
  NavbarBlackAndWhitePinPointMiddle,
  //Navbar/Colors
  // NavbarColorsCalendarBottom,
  // NavbarColorsConcept,
  // NavbarColorsContactUs,
  NavbarColorsEditInfo,
  // NavbarColorsMap,
  // NavbarColorsMembersBottom,
  NavbarColorsMembersMiddle,
  // NavbarColorsMessenger,
  // NavbarColorsNews,
  NavbarColorsChat,
  //Notifications
  NotificationsBellMute,
  NotificationsBellOn,
  NotificationsBellRinging,
  NotificationsClock,
  //Profiles
  ProfilesAddFriend,
  ProfilesArobase,
  ProfilesBirthday,
  ProfilesBlockFriend,
  ProfilesCalendarColors,
  ProfilesCamera,
  ProfilesChatColors,
  ProfilesCity,
  ProfilesConfidential,
  ProfilesEdit,
  ProfilesEmail,
  ProfilesFemale,
  ProfilesFemaleSign,
  ProfilesInformation,
  ProfilesLanguage,
  ProfilesLastName,
  ProfilesMale,
  ProfilesMaleSign,
  ProfilesMemberNumber,
  ProfilesNoGender,
  ProfilesPasswordPadLock,
  ProfilesPhone,
  ProfilesSchedule,
  ProfilesStopWatch,
  ProfilesTrash,
  ProfilesTrashcan,
  ProfilesUnblock,
  //Settings
  SettingsBlockedUser,
  SettingsContactUs,
  SettingsDeleteAccount,
  SettingsEditProfile,
  SettingsLegalNotice,
  SettingsLikedUsers,
  SettingsNews,
  SettingsPrivacyPolicy,
  SettingsGears,
  SettingsTermsOfSales,
  //SocialNetworks
  SocialNetworksAppStore,
  SocialNetworksDiscord,
  SocialNetworksFacebook,
  SocialNetworksFbGroup,
  SocialNetworksFbPage,
  SocialNetworksGooglePlay,
  SocialNetworksGoogleSymbol,
  SocialNetworksLinkChain,
  SocialNetworksMeetup,
  SocialNetworksMessenger,
  SocialNetworksOtherLink,
  SocialNetworksTelegram,
  SocialNetworksWhatsapp,
  //UsersInteraction/BlackAndWhite
  UsersInteractionBlackAndWhiteHappyOff,
  UsersInteractionBlackAndWhiteHeartGrey,
  UsersInteractionBlackAndWhiteParticipants,
  UsersInteractionBlackAndWhitePeopleOff,
  //UsersInteraction/Colors
  UsersInteractionColorsGroup,
  UsersInteractionColorsHappyOn,
  UsersInteractionColorsHeartEmpty,
  UsersInteractionColorsHeartFilled,
  UsersInteractionColorsHeartRed,
  UsersInteractionColorsNonProfit,
  UsersInteractionColorsPeopleOn,
  //Images
  ImagesPaste,
  //Images/Backgrounds
  BackgroundsBelleFemme,
  BackgroundsGroupSelfieFriends,
  //Images/Concept
  ConceptComingSoon,
  ConceptCreateActivities,
  ConceptLocalsAndInternationals,
  ConceptNeverAlone,
  ConceptPrivateAdress,
  ConceptRealUsers,
  //Images/Emoji
  EmojiAraidEmoji,
  EmojiSourire,
  //Images/General
  GeneralLogoSocializus,
  GeneralSettingsAnimated,
  GeneralSplash,
  //Images/Home
  HomeArtGallery,
  HomeFluoParty,
  HomeGroupApero,
  HomeHalloweenParty,
  HomeHomePageBR,
  HomeLiveConcert,
  HomeNumberParty,
  HomePicnic,
  HomeSoapParty,
//   HomeButtonWorldStart
};

export default Images;