import React, {useEffect, useRef, useState} from 'react';
import {GOOGLE_MAPS_API_KEY} from '@env';

const Maps = ({selectedCoords, onMapLoad}) => {
  const mapRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window.google && window.google.maps) {
        setScriptLoaded(true);
        return;
      }
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => setScriptLoaded(true);
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    const initMap = coords => {
      const initializedMap = new window.google.maps.Map(mapRef.current, {
        center: coords,
        zoom: 12,
      });
      new window.google.maps.Marker({
        position: coords,
        map: initializedMap,
      });
      onMapLoad(initializedMap);

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({location: coords}, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            console.log('Adresse complète:', results[0].formatted_address);
          } else {
            console.log('Adresse introuvable');
          }
        } else {
          console.error('Erreur de géocodage:', status);
        }
      });
    };

    if (scriptLoaded && mapRef.current && !map) {
      if (selectedCoords) {
        initMap({
          lat: parseFloat(selectedCoords.lat),
          lng: parseFloat(selectedCoords.lng),
        });
      } else if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            initMap({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => console.error('Geolocation failed or permission denied'),
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }
  }, [scriptLoaded, selectedCoords, map, onMapLoad]);

  useEffect(() => {
    if (map && selectedCoords) {
      const newCenter = {
        lat: parseFloat(selectedCoords.lat),
        lng: parseFloat(selectedCoords.lng),
      };
      if (!isNaN(newCenter.lat) && !isNaN(newCenter.lng)) {
        map.setCenter(newCenter);
        new window.google.maps.Marker({
          position: newCenter,
          map: map,
        });
      } else {
        console.error('Invalid coordinates:', selectedCoords);
      }
    }
  }, [selectedCoords, map]);

  return (
    <div
      ref={mapRef}
      style={{
        height: '300px',
        width: '100%',
        position: 'relative',
      }}
    />
  );
};

export default Maps;
