export const handleChat = (socket, updateGroupChatMessages, updatePrivateChatMessages, displayChatHistory) => {
  if (!socket) return {};

  // Gestion des messages de groupe reçus
  socket.on('newChatMessage', ({chatId, message}) => {
    console.log(`Message de groupe reçu - ChatID: ${chatId}, Message: ${message}`);
    updateGroupChatMessages(chatId, [message]);
  });

  // Gestion des messages privés reçus
  socket.on('newPrivateChatMessage', message => {
    console.log(`Message privé reçu - ChatID: ${message.chatId}, SenderID: ${message.senderId}, Message: ${message.content}`);
    updatePrivateChatMessages(message.chatId, [message], false);
  });

  // Gestion de la réponse de l'historique de chat
  socket.on('chatHistoryResponse', ({chatId, messages, error}) => {
    if (error) {
      console.error(`Erreur lors de la récupération de l'historique de chat - ChatID: ${chatId}, Erreur: ${error}`);
    } else {
      console.log(`Historique de chat reçu - ChatID: ${chatId}, Messages:`, messages);
      displayChatHistory(chatId, messages);
    }
  });

  const requestChatHistory = receiverId => {
    console.log(`Demande de l'historique de chat avec l'ID de récepteur: ${receiverId}`);
    socket.emit('requestChatHistory', {receiverId});
  };

  const sendChatMessage = (receiverId, message, isPrivate = true) => {
    console.log(`Envoi du message - ReceiverID: ${receiverId}, Message: ${message}, Private: ${isPrivate}`);
    socket.emit('privateChatMessage', {receiverId, message});
  };

  const joinChatRoom = receiverId => {
    console.log(`Rejoindre la salle de chat avec l'ID de récepteur: ${receiverId}`);
    socket.emit('joinChatRoom', {receiverId});
  };

  const leaveChatRoom = receiverId => {
    console.log(`Quitter la salle de chat avec l'ID de récepteur: ${receiverId}`);
    socket.emit('leaveChatRoom', {receiverId});
  };

  const requestAllChatsForUser = () => {
    console.log(`Demande de récupération de tous les chats pour l'utilisateur`);
    socket.emit('requestAllChatsForUser');
  };

  return {sendChatMessage, joinChatRoom, leaveChatRoom, requestChatHistory, requestAllChatsForUser};
};
