//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷  Ce fichier gère la page de rédaction d'un mot de passe pour un nouveau compte (User) (FrameFigma005)
// 🇬🇧 This file manage the page for writing a password for a new account (User). (FrameFigma005)

import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, Pressable, Linking, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Fields from '../../../../components/Fields/Fields';
import {hostname} from '../../../../../hostName/hostName';
import {useLanguage} from '../../../../contexts/LanguageContext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import {useError} from '../../../../contexts/ErrorContext'; // Importer le contexte d'erreurs

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const NewUserPasswordScreen = ({navigation}) => {
  const {translations} = useLanguage();
  const {language} = useLanguage();
  const {error, setError} = useError(); // Utiliser le contexte d'erreurs

  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageTooShort)
      .required(translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageEmpty),
  });

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    getEmailFromStorage();
  }, []);

  const getEmailFromStorage = async () => {
    try {
      const storedEmail = await AsyncStorage.getItem('email');
      if (storedEmail) {
        setEmail(storedEmail);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'e-mail depuis le AsyncStorage", error);
    }
  };

  const handleNext = async () => {
    try {
      await validationSchema.validate({password});

      /* ATTENTION - Le Password est stocke en plain texte */
      await AsyncStorage.setItem('password', password);

      sendVerificationEmail(); // Appeler la fonction pour envoyer l'e-mail de vérification

      navigation.navigate('VerifyEmail'); // réinitialiser l'erreur du mot de passe
      setError('');
    } catch (validationError) {
      setError(validationError.message); //pour mettre à jour l'état local passwordError
    }
  };

  const sendVerificationEmail = async () => {
    try {
      await fetch(`${hostname}/sendVerification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande de vérification par e-mail", error);
    }
  };

  // Réinitialiser l'erreur dès que l'utilisateur commence à taper
  const handleTextChange = text => {
    setPassword(text);
    if (error) {
      setError('');
    }
  };

  return (
    <View key={language} style={styles.container}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.passwordContent}>
        <TextInputCustom
          Icon={Fields.IconPassword}
          placeholderText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder}
          value={password}
          handleTextChange={handleTextChange}
          isPlaceHolderTextAlignCenter={false}
          isPassword={true}
        />
      </View>
      <View style={styles.btnContainer}>
        <ButtonNext
          handleNextStep={handleNext}
          btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
          conditionNeed={password.length >= 8}
        />
      </View>
      <View style={styles.condition}>
        <Text style={styles.terms}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}</Text>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}</Text>
        </Pressable>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default NewUserPasswordScreen;
