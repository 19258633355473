import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
  profilePhoto: {
    height: 325,
  },
  cupIconContainer: {
    marginTop: -30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  userName: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  blocksContainer: {
    backgroundColor: '#9d9d9d',
    height: 45,
  },
  profileTab: {
    width: '33%',
    height: '100%',
    // backgroundColor: '#59C09B',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  languagesContainer: {
    marginVertical: 20,
    paddingHorizontal: 20,
    gap: 10,
  },
  gap: {
    gap: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  individualEntry: {
    flexDirection: 'row',
    fontSize: 16,
  },
  greenBar: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 25,
    backgroundColor: '#59C09B',
  },
  addFriendButton: {
    borderRadius: 10,
    borderStyle: 'none',
    padding: 10,
    color: `#FFFFFF`,
    backgroundColor: `#62A1FF`,
  },
  chatButton: {
    borderRadius: 10,
    borderStyle: 'none',
    padding: 10,
    color: `#FFFFFF`,
    backgroundColor: `#B85EB7`,
  },
  blockButton: {
    borderRadius: 10,
    borderStyle: 'none',
    padding: 10,
    color: `#FFFFFF`,
    backgroundColor: `#FF0000`,
  },
  pad20: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  flags: {
    marginRight: 10,
  },
  langTitle: {
    fontWeight: 'bold',
    width: '40%',
  },
  editButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0,
    borderRadius: 4,
    position: 'absolute',
    right: 0,
    margin: 20,
    padding: 10,
    backgroundColor: 'white',
  },
  editButtonText: {
    marginLeft: 5,
    fontSize: 10,
  },
  bottomIcons: {
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  }
});