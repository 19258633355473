//FR  un tableau d'objets activitiesIcon, où chaque objet représente une icône d'activité avec son titre correspondant (FrameFigma036)
//EN An array of activitiesIcon objects, where each object represents an activity icon along with its corresponding title (FrameFigma036)
import React from 'react';
import AfterworkOn from "../../assets/Icons/Activity/Topics/Colors/Afterwork.svg";
import AperoOn from "../../assets/Icons/Activity/Topics/Colors/Apero.svg";
import BusinessOn from "../../assets/Icons/Activity/Topics/Colors/Business.svg";
import CultureOn from "../../assets/Icons/Activity/Topics/Colors/Culture.svg";
import DancingOn from "../../assets/Icons/Activity/Topics/Colors/Dancing.svg";
import GamesOn from "../../assets/Icons/Activity/Topics/Colors/Games.svg";
import LinguisticOn from "../../assets/Icons/Activity/Topics/Colors/Linguistic.svg";
import MealOn from "../../assets/Icons/Activity/Topics/Colors/Meal.svg";
import MovieOn from "../../assets/Icons/Activity/Topics/Colors/Movie.svg";
import MusicOn from "../../assets/Icons/Activity/Topics/Colors/Music.svg";
import MutualHelpOn from "../../assets/Icons/Activity/Topics/Colors/MutualHelp.svg";
import PartyOn from "../../assets/Icons/Activity/Topics/Colors/Party.svg";
import PicnicOn from "../../assets/Icons/Activity/Topics/Colors/Picnic.svg";
import PrivateOn from "../../assets/Icons/Activity/Topics/Colors/Private.svg";
import SportsOn from "../../assets/Icons/Activity/Topics/Colors/Sports.svg";
import TravelOn from "../../assets/Icons/Activity/Topics/Colors/Travel.svg";
import AfterworkOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Afterwork.svg";
import AperoOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Apero.svg";
import BusinessOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Business.svg";
import CultureOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Culture.svg";
import DancingOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Dancing.svg";
import GamesOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Games.svg";
import LinguisticOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Linguistic.svg";
import MealOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Meal.svg";
import MovieOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Movie.svg";
import MusicOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Music.svg";
import MutualHelpOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/MutualHelp.svg";
import PartyOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Party.svg";
import PicnicOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Picnic.svg";
import PrivateOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Private.svg";
import SportsOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Sports.svg";
import TravelOff from "../../assets/Icons/Activity/Topics/BlackAndWhite/Travel.svg";

export const activitiesIcon = [
    {
      id: 1,
      activityIconOn: <AfterworkOn width={50} height={50} />,
      activityIconOff: <AfterworkOff width={50} height={50} />,
      activityTitle: "Afterwork",
    },
    {
      id: 2,
      activityIconOn: <AperoOn width={50} height={50} />,
      activityIconOff: <AperoOff width={50} height={50} />,
      activityTitle: "Aperitif",
    },
    {
      id: 3,
      activityIconOn: <BusinessOn width={50} height={50} />,
      activityIconOff: <BusinessOff width={50} height={50} />,
      activityTitle: "Business",
    },
    {
      id: 4,
      activityIconOn: <CultureOn width={50} height={50} />,
      activityIconOff: <CultureOff width={50} height={50} />,
      activityTitle: "Culture",
    },
    {
      id: 5,
      activityIconOn: <DancingOn width={50} height={50} />,
      activityIconOff: <DancingOff width={50} height={50} />,
      activityTitle: "Dancing",
    },
    {
      id: 6,
      activityIconOn: <GamesOn width={50} height={50} />,
      activityIconOff: <GamesOff width={50} height={50} />,
      activityTitle: "Games",
    },
    {
      id: 7,
      activityIconOn: <LinguisticOn width={50} height={50} />,
      activityIconOff: <LinguisticOff width={50} height={50} />,
      activityTitle: "Linguistic",
    },
    {
      id: 8,
      activityIconOn: <MealOn width={50} height={50} />,
      activityIconOff: <MealOff width={50} height={50} />,
      activityTitle: "Meal",
    },
    {
      id: 9,
      activityIconOn: <MovieOn width={50} height={50} />,
      activityIconOff: <MovieOff width={50} height={50} />,
      activityTitle: "Movies",
    },
    {
      id: 10,
      activityIconOn: <MusicOn width={50} height={50} />,
      activityIconOff: <MusicOff width={50} height={50} />,
      activityTitle: "Music",
    },
    {
      id: 11,
      activityIconOn: <MutualHelpOn width={50} height={50} />,
      activityIconOff: <MutualHelpOff width={50} height={50} />,
      activityTitle: "Mutual Help",
    },
    {
      id: 12,
      activityIconOn: <PartyOn width={50} height={50} />,
      activityIconOff: <PartyOff width={50} height={50} />,
      activityTitle: "Party",
    },
    {
      id: 13,
      activityIconOn: <PicnicOn width={50} height={50} />,
      activityIconOff: <PicnicOff width={50} height={50} />,
      activityTitle: "Picnic",
    },
    {
      id: 14,
      activityIconOn: <PrivateOn width={50} height={50} />,
      activityIconOff: <PrivateOff width={50} height={50} />,
      activityTitle: "Private Party",
    },
    {
      id: 15,
      activityIconOn: <SportsOn width={50} height={50} />,
      activityIconOff: <SportsOff width={50} height={50} />,
      activityTitle: "Sport",
    },
    {
      id: 16,
      activityIconOn: <TravelOn width={50} height={50} />,
      activityIconOff: <TravelOff width={50} height={50} />,
      activityTitle: "Travel",
    },
  ];