// FR  rend l'écran des activités pour un profil utilisateur.
// EN  renders the activities screen for a user profile
import React, { useContext } from 'react';
import { View, Image, Text, StyleSheet, Platform } from 'react-native';
import { useLanguage } from '../../../../contexts/LanguageContext';
import countriesListCircleFlags from '../../../../components/LanguagesFlags/LanguagesFlags';
import NextActivitiesBoard from './NextActivitiesBoard/NextActivitiesBoard';
import LastActionBoard from './LastActionBoard/LastActionBoard';
import { activitiesIcon } from '../../../../components/Fields/ActivityListIcon';

const MyProfileActivitiesScreen = ({ profileOwner, allUsers, userActivities }) => {

    const styles = Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;
    const { translations } = useLanguage();
    const userActivityIcons = activitiesIcon.filter((icon) => {
        return userActivities.some((activity) => activity.topic === icon.id - 1);
    })

    // Compter le nombre d'apparitions de chaque icône dans userActivityIcons
    const iconCounts = userActivities.reduce((acc, activity) => {
        acc[activity.topic + 1] = (acc[activity.topic + 1] || 0) + 1;
        return acc;
    }, {});

    return (
        <View style={styles.container}>
            <View style={styles.tabsContainer}>
                {userActivities.filter((activity) => new Date(activity.date) > new Date()).length > 0
                    ? <View>
                        <Text style={{ fontSize: 16, fontWeight: "bold", paddingLeft:"2%" }}>
                            {profileOwner?.userName ? profileOwner.userName : 'unknown'}'s next {Math.min(5, userActivities.filter(activity => new Date(activity.date) > new Date()).length)} activities :
                        </Text>
                        <NextActivitiesBoard users={allUsers} userActivities={userActivities} />
                    </View>
                    // manque json 
                    : <Text>{profileOwner?.userName ? profileOwner.userName : 'unknown'} doesn't have any upcoming activities.</Text>}
                {userActivities.filter((activity) => new Date(activity.date) < new Date()).length > 0
                    ? <View>
                        <Text style={{ fontSize: 16, fontWeight: "bold", paddingLeft:"2%" }}>
                            Last Action from {profileOwner?.userName ? profileOwner.userName : 'unknown'} :
                        </Text>
                        <LastActionBoard users={allUsers} userActivities={userActivities} />
                    </View>
                    : <Text>{profileOwner?.userName ? profileOwner.userName : 'unknown'} doesn't participate in any activities past.</Text>}
            </View>
            <View style={styles.greyBar}>
                <Text style={styles.greyBarText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonActivitiesDone} :</Text>
            </View>
            <View style={styles.bottomIcons}>
                {userActivityIcons.map((icon) => (
                    <View key={icon.id}>
                        {icon.activityIconOn}
                        <View style={styles.notifCount}>{iconCounts[icon.id]}</View>
                    </View>
                ))}
            </View>
        </View>
    );
};



export default MyProfileActivitiesScreen;
