//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷 Ce fichier gère la page qui affiche les amis/contact du profil. (FrameFigma073)
// 🇬🇧 This file manages the page displaying the profile's friends/contacts.  (FrameFigma073)

// Import Modules
import React from 'react'
import { View, Text, Platform, ScrollView } from 'react-native'

// Import Contexts
import { useLanguage } from '../../../../contexts/LanguageContext';

// Import Components
import NetworkUserCard from '../../../../components/UserCardBasic/UserCardBasic';

// Import Styles
const styles = Platform.OS === 'web'
  ? require('./Styles.web').default
  : require('./Styles.mobile').default;

const ProfileNetworkScreen = ({ users, currentUser, userActivities }) => {

  // Contexts
  const { translations } = useLanguage();

  // Trouver tous les users rencontree par notre currentUser
  const userHasMet = userActivities.flatMap((activity) => {
    // Trouver les rencontres depuis les listes attendees
    const attendees = activity.attendees.filter((attendee) => attendee !== currentUser._id);
    // Enlever les doublons dans le liste userHasMet
    const uniqueUserHasMet = [...new Set(attendees)];
    return uniqueUserHasMet;
  });
  
  // Trouver tous les users amis
  const userFriend = currentUser.friends
  /* NOTE IMPORTANT Il faut trouver une solution pour sauvegarder l'userNetwork dans le BDD pour qu'il reste persistent apres la
  activite est termine. */

  // Listes des user IDs soit rencontres soit amis
  const filteredNetwork = users.filter((user) => userHasMet.includes(user._id) || userFriend.includes(user._id));

  // Trouver le data entire des user dans filteredNetwork
  const userNetwork = filteredNetwork.map((user) => ({
    user: user,
    hasMet: userHasMet.includes(user._id),
    isFriend: userFriend.includes(user._id),
  }))
  
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Text style={styles.membersTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}
            <Text style={styles.networkCount}>{userNetwork.length}</Text>
          </Text>
          {/* Manque les traductions pour 'members met' */}
          {/* <Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}</Text> */}
          <Text>(members met)</Text>
        </View>
        <View style={styles.headerRight}>
          <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll}</Text>
          {/* <RightArrow width={20} height={20} viewBox={'0 0 20 20'}/> */}
        </View>
      </View>
      <View style={styles.legend}>
        <View style={styles.legendItem}>
          {/* Manque les traductions pour les deux text */}
          <Text style={styles.legendText}> = people met with the user</Text>
        </View>
        <View style={styles.legendItem}>
          <Text style={styles.legendText}> = friends with user</Text>
        </View>
      </View>
      <ScrollView>
        <View style={styles.network}>
          {userNetwork.length > 0 ? userNetwork.map((user) => (
            <NetworkUserCard user={user} key={user.user._id} />
          )) : <View><Text>User has no connections</Text></View>}
        </View>
      </ScrollView>
    </View>
  )
}

export default ProfileNetworkScreen