//🇬🇧 The component creates a bottom tab navigation bar with multiple tabs, each tab being linked to a specific screen of the application (FrameFigmaO21C,022B,022C)
//🇫🇷 le composant crée une barre de navigation inférieure (bottom tab navigation) avec plusieurs onglets, chaque onglet étant lié à un écran spécifique de l'application (FrameFigma022A,022B,022C)

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { Platform } from 'react-native';
import Navbar from '../TopNavbarActivities/TopNavbarActivities';
import MapsScreen from '../../pages/MapsSection/MapsNavigator';
import MessageScreen from '../../pages/MessagesScreen/MessagesScreen';
import MembersNavigator from '../../pages/MembersSection/MembersNavigator';
import NotificationsNavigator from '../../pages/NotificationsSection/NotificationsNavigator';
import BurgerButton from '../BurgerButton/BurgerButton';
import Fields from '../Fields/Fields';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useLanguage } from '../../contexts/LanguageContext';
import { useUser } from '../../contexts/UserContext';
import MessageTabWrapper from './MessageTabWrapper';
// 🇬🇧 Platform-specific styles
// 🇫🇷 Styles spécifiques à la plate-forme (web ou mobile)
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const Tab = createBottomTabNavigator();
const BottomNav = () => {
  const { user, friendNotifications, profileVisits, isLoadingUser, likeNotifications, activityLikeNotifications } = useUser();

  // Calcul du nombre total de notifications non lues
  const totalNotificationCount =
    !isLoadingUser && user
      ? [
        user.notificationSettings?.visitorNotification && profileVisits,
        user.notificationSettings?.friendRequest && friendNotifications,
        user.notificationSettings?.likeNotification && likeNotifications,
        user.notificationSettings?.activityLikeNotifications && activityLikeNotifications
      ].reduce((acc, notifications) => {
        return acc + (notifications ? notifications.filter(notification => !notification.isRead).length : 0);
      }, 0)
      : 0;

  console.log('user', user);
  const { translations } = useLanguage();
  const navigation = useNavigation();
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: 'blue', // 🇫🇷 Couleur de l'onglet actif //🇬🇧 Color of the active tab.
        tabBarInactiveTintColor: 'gray', // 🇫🇷 Couleur des onglets inactifs //🇬🇧 Color of the inactive tab.
        tabBarStyle: [{ display: 'flex' }, null], // 🇫🇷 Styles supplémentaires pour tabBar //🇬🇧 Additional styles for tabBar.
        ...styles.screenOptionsStyles,
      }}
      initialRouteName="Activities">
      <Tab.Screen
        name="Activities"
        component={Navbar}
        options={{
          title: translations.JsonBottomNav.JsonActivitiesTab,
          tabBarIcon: ({ focused }) => (focused ? <Fields.IconCalendarOn /> : <Fields.IconCalendarOff />),
          headerTitle: translations.JsonTopNav.JsonFirstLine,
          headerStyle: { backgroundColor: '#59C09B' },
          headerTitleAlign: 'center',
          headerLeft: () => <BurgerButton onPress={() => navigation.openDrawer()} />,

          headerTitleStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
          },
          ...styles.tabScreenOptions,
          headerShown: true,
        }}
      />
      <Tab.Screen
        name="Members"
        component={MembersNavigator}
        options={{
          title: translations.JsonBottomNav.JsonMembersTab,
          tabBarIcon: ({ focused }) => (focused ? <Fields.IconMembersOn /> : <Fields.IconMembersOff />),
          headerStyle: { backgroundColor: '#59C09B' },
          headerTitleAlign: 'center',
          headerLeft: () => <BurgerButton onPress={() => navigation.openDrawer()} />,
          headerTitleStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
          },
          ...styles.tabScreenOptions,
          headerShown: true,
        }}
      />
      <Tab.Screen
        name="Maps"
        component={MapsScreen}
        options={{
          title: translations.JsonBottomNav.JsonMapsTab,
          tabBarIcon: ({ focused }) => (focused ? <Fields.MapOn /> : <Fields.MapOff />),
          headerStyle: { backgroundColor: '#59C09B' },
          headerTitleAlign: 'center',
          headerLeft: () => <BurgerButton onPress={() => navigation.openDrawer()} />,
          headerTitleStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
          },
          ...styles.tabScreenOptions,
          headerShown: true,
        }}
      />
      <Tab.Screen
        name="Notifications"
        component={NotificationsNavigator}
        options={{
          tabBarBadge: totalNotificationCount > 0 ? totalNotificationCount : undefined,
          tabBarBadgeStyle: {
            backgroundColor: '#FFF510',
            color: 'black',
            fontSize: 10,
            minWidth: 20,
            height: 20,
            textAlign: 'center',
            borderRadius: 15,
            paddingTop: 2,
          },
          title: translations.JsonBottomNav.JsonNotificationsTab,
          tabBarIcon: ({ focused }) => (focused ? <Fields.BellOn /> : <Fields.BellOff />),
          headerStyle: { backgroundColor: '#59C09B' },
          headerTitleAlign: 'center',
          headerLeft: () => <BurgerButton onPress={() => navigation.openDrawer()} />,
          headerTitleStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
          },
          ...styles.tabScreenOptions,
          headerShown: true,
        }}
      />
      <Tab.Screen
        name="Message"
        component={MessageTabWrapper} // Utilisez MessageTabWrapper au lieu de MessageScreen
        options={{
          title: translations.JsonBottomNav.JsonMessageTab,
          tabBarIcon: ({ focused }) => (focused ? <Fields.MessengerOn /> : <Fields.MessengerOff />),
          headerStyle: { backgroundColor: '#59C09B' },
          headerTitleAlign: 'center',
          headerLeft: () => <BurgerButton onPress={() => navigation.openDrawer()} />,
          headerTitleStyle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
          },
          ...styles.tabScreenOptions,
          headerShown: true,
        }}
      />
    </Tab.Navigator>
  );
};

export default BottomNav;
