import React, {useState} from 'react';
import {View, Platform, Button, Text} from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';

const CustomDatePicker = ({initialDate, onDateChange}) => {
  const [date, setDate] = useState(initialDate || new Date());
  const [show, setShow] = useState(false);

  const onChange = (event, selectedDate) => {
    setShow(Platform.OS === 'ios');
    setDate(selectedDate || date);
    if (onDateChange) {
      onDateChange(selectedDate || date);
    }
  };

  return (
    <View>
      {Platform.OS === 'web' ? (
        <input type="date" value={date.toISOString().split('T')[0]} onChange={e => onChange(null, new Date(e.target.value))} style={{height: 40, borderColor: 'gray', borderWidth: 1, padding: 10}} />
      ) : (
        <View>
          <Button title="Choose Date" onPress={() => setShow(true)} />
          {show && <DateTimePicker testID="dateTimePicker" value={date} mode="date" display="default" onChange={onChange} />}
        </View>
      )}
    </View>
  );
};

export default CustomDatePicker;

// import * as React from 'react';
// import {View, TouchableOpacity, Text} from 'react-native';
// import {DatePickerModal} from 'react-native-paper-dates';
// import {format, subYears} from 'date-fns';
// import {fr} from 'date-fns/locale';

// const DatePicker = ({onDateSelected}) => {
//   const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
//   const [selectedDate, setSelectedDate] = React.useState(null);

//   // Calculer les années de début et de fin pour la sélection de date
//   const currentYear = new Date().getFullYear();
//   const startYear = 1900; // Année de début fixe
//   const endYear = subYears(new Date(), 2).getFullYear(); // 2 ans avant l'année actuelle

//   const handleDateConfirm = ({date}) => {
//     setIsDatePickerOpen(false);
//     setSelectedDate(date); // Mettre à jour la date sélectionnée localement
//     onDateSelected(format(date, 'yyyy-MM-dd')); // Notifier le parent de la nouvelle date sélectionnée
//   };

//   return (
//     <View>
//       <TouchableOpacity onPress={() => setIsDatePickerOpen(true)} style={{height: 40, borderColor: 'gray', borderWidth: 1, justifyContent: 'center'}}>
//         <Text style={{marginLeft: 10}}>{selectedDate ? format(selectedDate, 'dd/MM/yyyy', {locale: fr}) : 'Sélectionnez votre date de naissance'}</Text>
//       </TouchableOpacity>
//       {isDatePickerOpen && (
//         <DatePickerModal
//           mode="single"
//           visible={isDatePickerOpen}
//           onDismiss={() => setIsDatePickerOpen(false)}
//           date={selectedDate || new Date()}
//           onConfirm={handleDateConfirm}
//           locale="fr"
//           // Utiliser les années de début et de fin calculées
//           startYear={startYear}
//           endYear={endYear}
//         />
//       )}
//     </View>
//   );
// };

// export default DatePicker;
