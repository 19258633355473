// Fr Ce component affiche le modal pour rejoindre la liste d'attente (FrameFigma041A)
// En This component displays the modal to join the waitlist  (FrameFigma041A)

// Import Modules
import React from "react";
import { View, Text, Pressable } from "react-native";
import { useLanguage } from "../../../../../../contexts/LanguageContext";

const JoinWaitlist = ({ handleJoinWaitingList, userStatus, attendeeValidation }) => {
  const {translations} = useLanguage();
  return (
    <View>
      {userStatus.inWaitingList ? (
        <>
          <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonLeaveWaitList}</Text>
          <Pressable onPress={() => handleJoinWaitingList('Leave')}>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonLeaveButton}</Text>
          </Pressable>
        </>
      ) : (
        <>
          <Text>{attendeeValidation ? translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonAttendenceMustApproved : translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonActivityfull }</Text> 
          <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonJoinWaitingList}</Text>
          <View>

            {/* Ajouter dans le Waiting List si Yes */}
            <Pressable onPress={() => handleJoinWaitingList('Yes')}>
              <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonJoinWaitingListButton}</Text>
            </Pressable>
          </View>
        </>
      )}
    </View>
  );
};

export default JoinWaitlist