// FR SlidesData représente un tableau d'objets, chaque objet décrivant une diapositive avec un identifiant unique (id) (Frame figma 080AEn /Frame 080Cen) ,
// un titre (title) et une description (description) présentant une fonctionnalité de l'application SOCIALIZUS.
// GB SlidesData represents an array of objects, where each object describes a slide with a unique identifier (id),(Frame figma 080AEn /Frame 080Cen)
// a title (title), and a description (description) presenting a feature of the SOCIALIZUS application.
import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import Image1 from '../assets/Images/Concept/RealUsers.jpg';
import Image2 from '../assets/Images/Concept/NeverAlone.jpg';
import Image3 from '../assets/Images/Concept/LocalsAndInternationals.jpg';
import Image4 from '../assets/Images/Concept/PrivateAdress.jpg';
import Image5 from '../assets/Images/Concept/CreateActivities.jpg';
import Image6 from '../assets/Images/Concept/ComingSoon.jpg';

export const SlidesData = () => {
  const { translations } = useLanguage();

  return [
    {
      id: 1,
      title: translations.JsonConceptScreen.JsonConceptPageOneTitle,
      description: translations.JsonConceptScreen.JsonConceptPageOneDescription,
      image: Image1
    },
    {
      id: 2,
      title: translations.JsonConceptScreen.JsonConceptPageTwoTitle,
      description: translations.JsonConceptScreen.JsonConceptPageTwoDescription,
      image: Image2
    },
    {
      id: 3,
      title: translations.JsonConceptScreen.JsonConceptPageThreeTitle,
      description: translations.JsonConceptScreen.JsonConceptPageThreeDescription,
      image: Image3
    },
    {
      id: 4,
      title: translations.JsonConceptScreen.JsonConceptPageFourTitle,
      description: translations.JsonConceptScreen.JsonConceptPageFourDescription,
      image: Image4
    },
    {
      id: 5,
      title: translations.JsonConceptScreen.JsonConceptPageFiveTitle,
      description: translations.JsonConceptScreen.JsonConceptPageFiveDescription,
      image:  Image5
    },
    {
      id: 6,
      title: translations.JsonConceptScreen.JsonConceptPageSixTitle,
      description: translations.JsonConceptScreen.JsonConceptPageSixDescription,
      image: Image6
    }
  ];
};
