// FR Button avec style différents selon disable (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma036,FrameFigma037A,FrameFigma031,FrameFigma035A,FrameFigma035B,FrameFigma036,FrameFigma038A,FrameFigma038B,FrameFigma039)
// EN A button with different styles based on its disabled state (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma036,FrameFigma037A,FrameFigma031,FrameFigma035A,FrameFigma035B,FrameFigma036,FrameFigma038A,FrameFigma038B,FrameFigma039)

import { Text, TouchableOpacity, Platform } from 'react-native';
import React from 'react';

export default ButtonNext = ({conditionNeed, handleNextStep, btnText}) => {
    const styles =
    Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;
    return (
        <TouchableOpacity
            style={[
                styles.btnNext,
                conditionNeed == null ? "" : !conditionNeed && { backgroundColor: 'grey' }
            ]} // Appliquer le style grisé si conditionNeed est faux
            onPress={handleNextStep}
        >
            <Text style={styles.btnText}>{btnText}</Text>
        </TouchableOpacity>
    )
}
