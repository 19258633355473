//Fr Styles de TextInputCustom (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma037A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B)
//En Styles of TextInputCustom (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma037A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B)

import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '99%',
  },
  input: {
    // React Native ne supporte pas 'border' de cette manière, utilisez borderColor, borderWidth, borderRadius.
    borderColor: 'rgba(0, 0, 0, 1)',
    borderWidth: 1,
    marginVertical: 13,
    width: '100%',
    borderRadius: 20,
    // paddingHorizontal: '13%', // ajustez en fonction du design avec
    // paddingHorizontal: '6%', // ajustez en fonction du design sans
    paddingVertical: 10,
    marginHorizontal: '1%', // Cette propriété pourrait ne pas se comporter comme prévu si la largeur de l'écran est petite
    marginLeft: 1,
    // backgroundColor: '#F0EEE9', // Défini par défaut ici//non, si c ici, ca devient pas blanc qd focused(ya 2 input style, un pr pure inputText, un pr sa container(container pour input+icon), le nom est input car c l'externalisé de input entierement)
    // React Native ne supporte pas zIndex sur Android pour les éléments de TextInput.
    zIndex: 0,
  },
  focusedInput2: {
    backgroundColor: 'white',
    // React Native ne supporte pas 'outlineColor'. Utilisez borderColor pour simuler cet effet.
    // borderColor: 'rgba(0, 0, 0, 0)',//marche pas dans web
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  defaultInput: {
    backgroundColor: '#F0EEE9'
  },
  focusedInput: {
    backgroundColor: 'white',
    // React Native ne supporte pas 'outlineColor'. Utilisez borderColor pour simuler cet effet.
    borderColor: 'rgba(0, 0, 0, 0)',//marche pas qd onFocused
  },
  placeholder: {
    overflow: 'hidden',
    fontSize: 16,
    // fontStyle: 'italic', // fontStyle n'est pas supporté pour le placeholder dans React Native.
    fontWeight: '400',
    // Les propriétés lineHeight, letterSpacing ne sont pas applicables au placeholder dans React Native.
    // textAlign: 'left', // textAlign dans le placeholder n'est pas supporté dans React Native.
  },
  input2: {
    overflow: 'hidden',
    fontSize: 16,
    fontWeight: '400',
    textAlign: 'left',
  },
  icon: {
    position: 'absolute',
    marginLeft: '3%',
    // zIndex peut ne pas fonctionner comme prévu sur tous les éléments dans React Native sur Android.
    zIndex: 2,
  },
  iconEye: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
    marginTop: -2,
  },
});
