import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10
    },
    textOne: {
        fontSize: 15,
        fontWeight: "bold",
        marginBottom: 10,
        textAlign: "center",
    },
    textTwoContainer: {
        flex: 1, 
        justifyContent: "center", 
        alignItems: "center"
    }, 
    textTwo: {
        textAlign: "center", 
        fontSize: 20
    }
});

export default styles;
