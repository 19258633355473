import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    width: '100%',
    gap: 20,
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'center',
  },
  // headerLeft: {
  //   flexDirection: 'row',
  //   gap: 20
  // },
  headerRight: {
    flexDirection: 'row',
    gap: 20
  },
  legend: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  legendItem: {
    flexDirection: 'row',
    gap: 20
  },
  membersTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  networkCount: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#59C09B',
    marginLeft: 15
  },
  network: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20
  }
});