// Fr Ce component affiche en bas la liste des participants d'une activité (FrameFigma044)
// En This component displays on bottom list of attendees about the activity (FrameFigma044)

// Import Modules
import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Image, Pressable, StyleSheet } from 'react-native';

// Import Contexts / Hooks / Utils
import { useLanguage } from '../../../../../contexts/LanguageContext';

// Import Icons
import { Icons } from "../../../../../constants/Icons";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../../../hostName/hostName';

const ActivityAttendees = ({ activity, allUsers, userStatus, handleParticipateAction, user }) => {
  // Contexts
  const { translations } = useLanguage();
  
  const [allLikeStatus, setAllLikeStatus] = useState({});

  // Get Attendees Details
  const attendeesDetails = allUsers.filter((u) => {
    const activityAttendees = activity.attendees.includes(u._id);
    return activityAttendees;
  })

  // Get Event Hosts
  const eventHosts = attendeesDetails.filter((u) => {
    return activity.author === u._id
  })

  // Get Event CoHosts
  const eventCoHosts = attendeesDetails.filter((u) => {
    return activity.coOrganizers.includes(u._id)
  })

  // Get all LikeStatus
  useEffect(() => {
    const getUserLikes = async itemUserId => {
      const token = await AsyncStorage.getItem('userToken');
      const userId = await AsyncStorage.getItem('userId');
      // recuperer ts les likes
      const response = await fetch(`${hostname}/userLikes`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      // On utilise .some() method pour avoir un booleen
      const likeStatus = data.some(like => like.likedBy === userId && like.likedUser === itemUserId);
      // return si user like les autres
      setAllLikeStatus(prev => ({ ...prev, [itemUserId]: likeStatus }));
    };
    attendeesDetails.map(item => { getUserLikes(item._id) })
  }, [attendeesDetails]);


  return (
    <View style={{ marginVertical: 20 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 30, marginRight: 40 }}>
        <View>
          <Text style={{ fontWeight: 'bold' }}>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonAttendees}</Text>
          <Text style={{ fontWeight: 'bold' }}>{activity.attendees.length}/{activity.attendeeLimit}</Text>
        </View>
        <View>
          <Text style={{ color: 'gray', fontWeight: 'bold' }}>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonAge}</Text>
          <Text style={{ color: 'gray', fontWeight: 'bold' }}>21 - 38</Text>
        </View>
        <Pressable style={{ flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold' }}>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll}</Text>
          <Icons.RightArrow width={15} height={15} />
        </Pressable>
      </View>
      <FlatList
        data={attendeesDetails}
        keyExtractor={(item) => (item && item.id ? item.id.toString() : null)}
        renderItem={({ item }) => (
          <Pressable style={{ alignItems: 'center', marginVertical: 20 }}>
            <View style={{ backgroundColor: 'white', borderColor: '#DDDDDD', borderWidth: 1, paddingHorizontal: 30, paddingTop: 10, paddingBottom: 15, borderRadius: 10, marginLeft: 20, alignItems: 'center', justifyContent: 'flex-start' }}>
              {item._id !== user._id
                ? allLikeStatus[item._id]
                  ? <View style={{ position: 'absolute', top: '3%', left: '5%' }}><Icons.HeartFilled width={15} height={15} /></View>
                  : <View style={{ position: 'absolute', top: '3%', left: '5%' }}><Icons.HeartEmpty width={15} height={15} /></View>
                : null
              }
              <View style={{ marginBottom: 5 }}>

                <Image
                  source={{ uri: item.profileImage }}
                  style={{ width: 50, height: 50, borderRadius: 25 }}
                />
                {item.status == 'online' ? <View style={styles.statusOnline} /> : null}
              </View>

              <Text style={{ color: 'blue', fontWeight: "bold", }}>{item.firstName}</Text>
              {eventHosts.includes(item) && <Text>Host</Text>}
              {eventCoHosts.includes(item) && <Text>Co-Host</Text>}
              {!eventHosts.includes(item) && !eventCoHosts.includes(item) && <Text>{item.appPoints}</Text>}
              {userStatus.author &&
                <Pressable
                  style={{ marginTop: 10, backgroundColor: '#FF000026', borderRadius: 25, padding: 1 }}
                  onPress={() => handleParticipateAction(item._id, isRemoved = true)}
                >
                  <Icons.CloseRed width={24} height={24} />
                </Pressable>
              }
            </View>
          </Pressable>
        )}
        numColumns={3}
        contentContainerStyle={{ paddingLeft: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  statusOnline: {
    width: 8,
    height: 8,
    borderRadius: 10,
    backgroundColor: '#2AE09F',
    position: 'absolute',
    right: 0,
    bottom: 1,
    borderWidth: 0.2,
    borderColor: '#000000',
    shadowColor: '#00000040',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
  },
});

export default ActivityAttendees;
