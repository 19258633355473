// FR récupérer les activités d'un utilisateur à partir d'un serveur.
// EN Retrieve user activities from a server.


/**
 * Retrieve activities for a user from the server.
 * 
 * @async
 * @param {string} userId - The ID of the user for whom activities are to be retrieved. If omitted or undefined, all activities will be returned.
 * @returns {Promise<Object>} - An object containing user activities based on user role or all activities if userId is not provided.
 * @throws {Error} Will throw an error if there's an issue with fetching or parsing data.
 */

// Import Modules
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Contexts / Hooks / Utils
import { hostname } from '../../hostName/hostName';

export async function getActivities(userId) {
  try {
    // Retrieve user token from AsyncStorage
    const userToken = await AsyncStorage.getItem('userToken');
    
    // Set headers for API request
    const headers = {
      'Authorization': `Bearer ${userToken}`,
      'Content-Type': 'application/json'
    };
    
    // Fetch activities from server
    const response = await fetch(`${hostname}/activities`, { headers });
    
    // Parse response data as JSON
    const data = await response.json();

    // Find User's liked Activities
    const likesResponse = await fetch(`${hostname}/activityLikes`, { headers });
    const likesData = await likesResponse.json();
    const myLikes = likesData.filter(like => like.likedBy === userId);

    // If userId is not provided, return all activities
    if (!userId || userId === undefined) {
      return data;
    } else {
      // If no activities found, return an empty array
      if (data.length === 0) {
        return [];
      } else {
        // Filter activities based on user's role

        // User has Liked
        const userLikedActivities = data.filter(activity => myLikes.some(like => like.activity === activity._id));
        
        // User is Organiser
        const userOrganisedActivities = data.filter(activity => activity.author === userId);
        
        // User is Attending
        const userAttendedActivities = data.filter(activity => activity.attendees.includes(userId));
        
        // Combine user's organised and attended activities
        const combinedUserActivities = [...userOrganisedActivities, ...userAttendedActivities];
        
        // Remove duplicates from combined activities
        const uniqueUserActivities = [...new Set(combinedUserActivities)];
        
        // Return user's organised activities, attended activities, and unique combined activities
        return {userLikedActivities, userOrganisedActivities, userAttendedActivities, uniqueUserActivities};
      }
    }
  } catch (error) {
    // Handle errors
    console.error(error);
  }
}
