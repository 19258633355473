//🇫🇷 Page de contact (FrameFigma90)
//🇬🇧 ContactUs Screen (FrameFigma90)

import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
  Platform,
  Linking, //🇫🇷 Importer Linking pour les liens externes //🇬🇧 Import Linking for external links
} from 'react-native';

//🇫🇷 Importation des icônes et des styles //🇬🇧 Importing icons and styles
import PhoneIcon from '../../assets/Icons/Feedback/ContactPhone.svg';
import OrgaIcon from '../../assets/Icons/Feedback/ContactOrga.svg';
import BugIcon from '../../assets/Icons/Feedback/ContactBug.svg';
import DiscordIcon from '../../assets/Icons/SocialNetworks/Discord.svg';
import TelegramIcon from '../../assets/Icons/SocialNetworks/Telegram.svg';
import FacebookIcon from '../../assets/Icons/SocialNetworks/Facebook.svg';

import { useLanguage } from '../../contexts/LanguageContext';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ContactUsScreen = () => {
  const { translations } = useLanguage();
  const navigation = useNavigation();


  //🇫🇷 Préparation des données pour les icônes de contact avec les URL et les légendes
  //🇬🇧 Preparing data for contact icons with URLs and captions
  const iconData = [
    {Icon: PhoneIcon, url: 'https://chat.whatsapp.com/LCy2cTDrQ1SC9fwtjMm7XZ', caption: translations.JsonContactScreen.JsonPicture},
    {Icon: OrgaIcon, url: 'https://chat.whatsapp.com/KtR79S8Obfs3WTPTIBsoCm', caption: translations.JsonContactScreen.JsonOrganizer},
    {Icon: BugIcon, url: 'https://chat.whatsapp.com/ELTWw58icvs6BAujghGg0R', caption: translations.JsonContactScreen.JsonBug},
    {Icon: DiscordIcon, url: 'https://discord.gg/bJsFfG7ntU', caption: 'Discord'},
    {Icon: TelegramIcon, url: 'https://t.me/joinchat/KtR79S8Obfs3WTPTIBsoCm', caption: 'Telegram'},
    {Icon: FacebookIcon, url: 'https://www.facebook.com/socializus', caption: 'Facebook'},
  ];

  //🇫🇷 Fonction pour générer chaque icône et son sous-titre //🇬🇧 Function to generate each icon and its caption
  const renderIcon = (Icon, url, caption) => (
    <View style={styles.iconWithCaption}>
      <Pressable onPress={() => Linking.openURL(url)}>
        <Icon width={50} height={50} />
      </Pressable>
      <Text style={styles.iconCaption}>{caption}</Text>
    </View>
  );

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollViewContainer}>
        <View style={styles.textViewContainer}>
          {/* 🇫🇷 Affichage des messages principaux de contact //🇬🇧 Displaying main contact messages */}
          <Text style={styles.text}>{translations.JsonContactScreen.JsonWhatsappOrPhoneContactMessage}</Text>
          <Text style={styles.text}>{translations.JsonContactScreen.JsonWhatsappDifferentContactMessage}</Text>

          {/* 🇫🇷 Mise en page des icônes de contact //🇬🇧 Layout for contact icons */}
          <View style={styles.iconContainer}>{iconData.map(({Icon, url, caption}) => renderIcon(Icon, url, caption))}</View>

          {/* 🇫🇷 Informations sur le contact téléphonique //🇬🇧 Phone contact information */}
          <View style={styles.phoneNumber}>
            <Text style={styles.text}>{translations.JsonContactScreen.JsonPhoneTax}</Text>
            <Text style={styles.text}>{translations.JsonContactScreen.JsonPhone}</Text>
            <Text style={styles.text}>{translations.JsonContactScreen.JsonPhoneServiceDetailsMessage}</Text>
            <Text style={styles.text}>{translations.JsonContactScreen.JsonThanks}</Text>
            <Text style={styles.text}>{translations.JsonContactScreen.JsonSocializusTeam}</Text>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ContactUsScreen;
