// Import Modules
import React from 'react';
import { View, Platform, Pressable } from 'react-native';

// Import Components
import EmailInputScreen from './EmailInputScreen/EmailInputScreen';
import MemberNumberInputScreen from './MemberNumberInputScreen/MemberNumberInputScreen';
import PhoneInputScreen from './PhoneInputScreen/PhoneInputScreen';
import BirthdayInputScreen from './BirthdayInputScreen/BirthdayInputScreen';
import LastNameInputScreen from './LastNameInputScreen/LastNameInputScreen';

// Import Icons
import { Icons } from '../../../constants/Icons';

// Import + Export Styles (Importer ici pour usage et exporter les meme styles pour utiliser dans les ecrans enfants)
export const styles =
  Platform.OS === 'web'
    ? require('./Styles.web').default
    : require('./Styles.mobile').default;

// Logique de component commence ici --------------------------------------------------
export const Step1InputScreen = ({ selectedButton, onBack, onClose, currentUser }) => {

  return (
    <>
      <View style={styles.iconRow}>
        <Pressable onPress={onBack}>
          <Icons.LeftArrow width={20} height={20} />
        </Pressable>
        {selectedButton.icon}
      </View>
      {selectedButton.id === 1 && <EmailInputScreen currentUser={currentUser} onClose={onClose} />}
      {selectedButton.id === 2 && <MemberNumberInputScreen currentUser={currentUser} onClose={onClose} />}
      {selectedButton.id === 3 && <PhoneInputScreen currentUser={currentUser} onClose={onClose} />}
      {selectedButton.id === 4 && <BirthdayInputScreen currentUser={currentUser} onClose={onClose} />}
      {selectedButton.id === 5 && <LastNameInputScreen currentUser={currentUser} onClose={onClose} />}
    </>
  );
};
