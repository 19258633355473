// Fr Ce component affiche les boutons participate, invite, la preview des participants (FrameFigma041,FrameFigma042)
// En This component displays the buttons participate invite, the preview of the participants (FrameFigma041,FrameFigma042)

// Import Modules
import React, { useEffect, useState } from 'react'
import { View, ScrollView, Text, Platform, Pressable, Image, ImageBackground } from 'react-native'

// Import Contexts / Hooks / Utils
import { useLanguage } from '../../../../../contexts/LanguageContext'
import { useActivity } from '../../../../../contexts/ActivityContext';
import { joinActivity, leaveActivity } from '../../../../../utils/ActivityParticipate';

// Import Components
import Modal from '../../../../../components/Modal/Modal';
import JoinWaitlist from './JoinWaitlist/JoinWaitlist';

// Import Icons / Images
import { Icons } from '../../../../../constants/Icons';

// Import Styles
const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const ActivityAttendeesWithButtons = ({ activity, user, allUsers, userStatus, handleParticipateAction, isFull }) => {

  // Contexts
  const { translations } = useLanguage();
  const { getActivities, activityLikes } = useActivity();

  // States
  // const [isFull, setIsFull] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // Get Attendees Details
  const attendeesDetails = allUsers.filter((u) => {
    const activityAttendees = activity?.attendees.includes(u._id);
    return activityAttendees;
  })

  // Handle Join Waiting List
  const handleJoinWaitlist = async (button) => {
    if (button === 'Yes') {
      const inWaitingList = true;
      await joinActivity(activity._id, user._id, inWaitingList);
      setModalVisible(false);
      getActivities();
    }
    if (button === 'No') {
      setModalVisible(false);
    }
    if (button === 'Leave') {
      const inWaitingList = true;
      await leaveActivity(activity._id, user._id, inWaitingList);
      setModalVisible(false);
      getActivities();
    }
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.interestedContainer}>
          <View style={styles.interestedRow}>
            {/* json a traduire  */}
            {/* {translations.JsonActivityPages.JsonActivityScreen.JsonInterested} */}
            <Text style={styles.interestedText}>{translations.JsonActivityPages.JsonActivityScreen.JsonInterested}: </Text>
            <Text style={styles.interestedCircle}>{activity?.attendees.length}</Text>
          </View>
          <View style={styles.follower}>
            <Text style={styles.interestedText}>{translations.JsonActivityPages.JsonActivityScreen.JsonFollowers}: </Text>
            <View style={styles.followerHeart}>
              <Icons.HeartEmpty width={35} height={35} />
              <Text style={styles.heartText}>{activityLikes.length}</Text>
              {/* bgimage avec png a faire plus tard */}
              {/* Les users qui like un activité deviens un Follower */}
            </View>
          </View>
        </View>
        <View style={styles.participantContainer}>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            {attendeesDetails.map((item, index) => (
              <Pressable key={index} style={{ alignItems: 'center', marginHorizontal: 10 }}>
                <Image
                  source={{ uri: item.profileImage }}
                  style={{ width: 50, height: 50, borderRadius: 25, marginBottom: 5 }}
                />
              </Pressable>
            ))}
          </ScrollView>
          <View style={styles.attendeeLimit}>
            <Icons.Participants width={35} height={35} />
            <Text>{activity?.attendees.length + "/" + (activity?.attendeeLimit > 30 ? "" : activity.attendeeLimit)}</Text>
          </View>
        </View>
        <View style={styles.btnContainer}>
          <View style={styles.btnContent}>
            <Pressable style={styles.btnShare}>
              <Text style={styles.btnText}>{translations.JsonActivityPages.JsonActivityScreen.JsonInvite}</Text>
              <Icons.ShareWhite width={20} height={20} />
            </Pressable>
          </View>
          <View style={styles.btnContent}>
            {activity?.attendeesValidation || isFull && !userStatus.participant ? (
              <Pressable style={userStatus.inWaitingList ? [styles.btnParticipate, styles.btnUnsubscribe] : styles.btnParticipate} onPress={() => setModalVisible(true)}>
                <Text style={styles.btnText}>{userStatus.inWaitingList ? 'Leave Waitlist' :translations.JsonActivityPages.JsonActivityScreen.JsonParticipate}</Text>
              </Pressable>
            ) : (
              <Pressable style={userStatus.participant ? [styles.btnParticipate, styles.btnUnsubscribe] : styles.btnParticipate} onPress={handleParticipateAction}>
                <Text style={styles.btnText}>{userStatus.participant ? translations.JsonActivityPages.JsonActivityScreen.JsonUnsubscribe : translations.JsonActivityPages.JsonActivityScreen.JsonParticipate}</Text>
              </Pressable>
            )}
          </View>
        </View>
      </View>
      {modalVisible &&
        <Modal visible={modalVisible} onClose={() => setModalVisible(false)}>
          <JoinWaitlist handleJoinWaitingList={handleJoinWaitlist} userStatus={userStatus} attendeeValidation={activity.attendeesValidation} />
        </Modal>
      }
    </>
  )
}

export default ActivityAttendeesWithButtons