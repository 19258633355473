// AppMobile.js
import React, {useState} from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {AuthProvider, useAuth} from './src/contexts/AuthContext';
import {View, TouchableOpacity} from 'react-native';
import BottomNav from './src/components/BottomNav/BottomNav';
import DrawerContent from './src/components/DrawerNavigator/DrawerMobile/DrawerContent';
import MyProfileScreen from './src/pages/ProfileModule/MyProfileSection/MyProfileBodyTopScreen/MyProfileBodyTopScreen';
import CreateActivityScreen from './src/pages/ActivityModule/CreateActivitySection/CreateActivityNavigator.js';
import InviteFriendsScreen from './src/pages/InviteFriendsScreen/InviteFriendsScreen';
import ContactUsScreen from './src/pages/ContactUsScreen/ContactUsScreen';
import SettingsScreen from './src/pages/SettingsSection/SettingsScreen/SettingsScreen';
import NewsScreen from './src/pages/NewsScreen/NewsScreen';
import UpdateScreen from './src/pages/UpdateScreen/UpdateScreen';
import TermsAndConditionsScreen from './src/pages/TermsAndConditionsScreen/TermsAndConditionsScreen';
import LogoutButton from './src/components/LogoutButton/LogoutButton';
import ActivityScreen from './src/pages/ActivityModule/ActivityDetailsSection/ActivityDetailsNavigator.js';
import HomeScreen from './src/pages/HomeScreen/HomeScreen';
import LanguagesScreen from './src/pages/LanguagesScreen/LanguagesScreen';
import EmailScreen from './src/pages/LoginRegisterSection/EmailScreen/EmailScreen.js';
import ExistingUserPasswordScreen from './src/pages/LoginRegisterSection/PasswordScreen/ExistingUserPasswordScreen/ExistingUserPasswordScreen.js';
import NewUserPasswordScreen from './src/pages/LoginRegisterSection/PasswordScreen/NewUserPasswordScreen/NewUserPasswordScreen.js';
import ResetPasswordScreen from './src/pages/LoginRegisterSection/PasswordScreen/ResetPasswordScreen/ResetPasswordScreen.js';
import VerifyEmailScreen from './src/pages/LoginRegisterSection/VerifyEmail/VerifyEmailNewUserScreen/VerifyEmailScreen.js';
import StartScreen from './src/pages/StartScreen/StartScreen';
import DeleteAccount from './src/pages/DeleteAccountSection/DeleteAccountScreenStep1/DeleteAccountScreenStep1.js';
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
import Fields from './src/components/Fields/Fields';
import CreateProfileScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileNavigator.js';
import {configureStore} from '@reduxjs/toolkit';
import reducers from './src/reducers';
import {Provider} from 'react-redux';
import Sponsorship from './src/pages/LoginRegisterSection/Sponsorship/Sponsorship.js';
import {ErrorProvider} from './src/contexts/ErrorContext';

const defaultScreenOptions = {
  headerShown: true,
  headerStyle: {backgroundColor: '#59C09B'},
  headerTintColor: 'white',
  headerTitleStyle: {fontWeight: 'bold', fontSize: 20},
};

const store = configureStore({
  reducer: reducers,
});

const AppContent = () => {
  const {isLoggedIn} = useAuth();
  const headerLeft = navigation => {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('Home');
        }}>
        <View style={{marginLeft: 15}}>
          <Fields.IconLeftArrow />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <NavigationContainer>
      {isLoggedIn ? (
        <Drawer.Navigator drawerContent={props => <DrawerContent {...props} />}>
          <Drawer.Screen name="Home" component={BottomNav} options={{headerShown: false}} />
          <Drawer.Screen
            name="MyProfile"
            component={MyProfileScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="CreateActivity"
            component={CreateActivityScreen}
            options={({navigation}) => ({
              title: 'Create Activity',
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="InviteFriends"
            component={InviteFriendsScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation, 'Activities', true),
            })}
          />
          <Drawer.Screen
            name="ContactUs"
            component={ContactUsScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="Settings"
            component={SettingsScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="News"
            component={NewsScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="Update"
            component={UpdateScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="TermsAndConditions"
            component={TermsAndConditionsScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen name="Logout" component={LogoutButton} options={{headerShown: false}} />
          <Drawer.Screen
            name="Activity"
            component={ActivityScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen
            name="DeleteAccount"
            component={DeleteAccount}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Drawer.Screen name="BottomNav" component={LanguagesScreen} options={{headerShown: false}} />
        </Drawer.Navigator>
      ) : (
        <Stack.Navigator screenOptions={defaultScreenOptions} initialRouteName="Home">
          {/* Ajoutez les écrans d'authentification ici si nécessaire */}
          <Stack.Screen name="Home" component={HomeScreen} options={{headerShown: false}} />
          <Stack.Screen name="Start" component={StartScreen} options={{headerShown: false}} />
          <Stack.Screen
            name="CreateProfile"
            component={CreateProfileScreen}
            options={({navigation}) => ({
              title: 'Create Profile',
              headerStyle: {backgroundColor: '#59C09B'},
              headerTintColor: 'white',
              headerTitleStyle: {fontWeight: 'bold', fontSize: 20},
              headerLeft: () => headerLeft(navigation, 'Password', true),
            })}></Stack.Screen>
          <Stack.Screen
            name="CreateActivity"
            component={CreateActivityScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}></Stack.Screen>
          <Stack.Screen name="Languages" component={LanguagesScreen} options={{headerShown: false}} />
          <Stack.Screen
            name="Email"
            component={EmailScreen}
            options={({navigation}) => ({
              title: 'Email',
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation, 'Languages', true),
            })}
          />
          <Stack.Screen
            name="Password"
            component={ExistingUserPasswordScreen}
            options={({navigation}) => ({
              title: 'Password',
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation, 'Email', true),
            })}
          />
          <Stack.Screen
            name="Sponsorship"
            component={Sponsorship}
            options={({navigation}) => ({
              title: 'Sponsorship',
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation, 'Email', true),
            })}
          />

          <Stack.Screen
            name="NewUserPassword"
            component={NewUserPasswordScreen}
            options={({navigation}) => ({
              title: 'NewUserPassword',
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation, 'Email', true),
            })}
          />
          <Stack.Screen
            name="ResetPassword"
            component={ResetPasswordScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
          <Stack.Screen
            name="VerifyEmail"
            component={VerifyEmailScreen}
            options={({navigation}) => ({
              ...defaultScreenOptions,
              headerLeft: () => headerLeft(navigation),
            })}
          />
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
};

const AppMobile = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Provider>
  );
};

export default AppMobile;
