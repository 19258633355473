// Fr Ce component affiche le navigateur qui permet de naviger entre la description, l'adresse, les commentaires et la liste des participants(FrameFigma041, FrameFigma042, FrameFigma043, FrameFigma044)
// En This component displays the navigator which alows to navigate between the description, the adress, the comments and the list of particiapants(FrameFigma041, FrameFigma042,FrameFigma043, FrameFigma044)

// Import Modules
import React from "react";
import { View, Text, Pressable, Platform } from "react-native";

// Import Contexts
import { useLanguage } from "../../../../../contexts/LanguageContext";

// Import Icons
import { Icons } from "../../../../../constants/Icons";

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const MiddleActivityNav = ({ activeButton, handleComponentSelection, userStatus }) => {
    
    // Contexts
    const {translations} = useLanguage();

    return (
        <View style={styles.container}>

            {/* Bouton Activity Description - toujours active */}
            <Pressable style={styles.middleNav} onPress={() => handleComponentSelection('ActivityDescription')}>
               {activeButton === 'ActivityDescription' ? 
                <Icons.NavbarEditInfoColors width={30} height={30} /> : 
                <Icons.NavbarEditInfo width={30} height={30} />
                }
                <Text style={styles.middleText}>{translations.JsonActivityPages.JsonActivityScreen.JsonDescription}</Text>
            </Pressable>
            <View style={styles.divider} />

            {/* Bouton Activity Address - desactive si user n'est pas participant ni author */}
            <Pressable style={styles.middleNav} onPress={() => handleComponentSelection('ActivityAddress')} >
                {activeButton === 'ActivityAddress' ?
                <Icons.PinPointColors width={30} height={30} /> :
                <Icons.NavbarPinPointMiddle width={30} height={30} />
                }
                <Text style={styles.middleText}>{translations.JsonActivityPages.JsonActivityScreen.JsonAddress}</Text>
            </Pressable>
            <View style={styles.divider} />

            {/* Bouton Activity Comments - desactive si user n'est pas participant ni author */}
            <Pressable style={styles.middleNav} onPress={() => handleComponentSelection('ActivityComments')} >
                {activeButton === 'ActivityComments' ?
                <Icons.NavbarChatColors width={30} height={30} /> :
                <Icons.NavbarChat width={30} height={30} />
                }
                <Text style={styles.middleText}>{translations.JsonActivityPages.JsonActivityScreen.JsonChat}</Text>
            </Pressable>
            <View style={styles.divider} />

            {/* Bouton Activity Attendees - desactive si user n'est pas participant ni author */}
            <Pressable style={styles.middleNav} onPress={() => handleComponentSelection('ActivityAttendees')} >
                {activeButton === 'ActivityAttendees' ?
                <Icons.NavbarMembersBottom width={30} height={30} /> :
                <Icons.NavbarMembersMiddle width={30} height={30} />
                }
                <Text style={styles.middleText}>{translations.JsonActivityPages.JsonActivityScreen.JsonParticipants}</Text>
            </Pressable>
        </View>
    )
}

export default MiddleActivityNav;