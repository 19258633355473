import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#404040",
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logo: {
    marginTop: 30,
    width: '80%',
    height: '30%',
  },
  update: {
    color: 'white',
    fontSize: 26,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 30,
  },
  appStore: {
    backgroundColor: 'black',
    marginTop: 70,
    width: 264,
    height: 96,
    // borderWidth: 3,
    // borderColor: 'white',
    borderRadius: 17,
    justifyContent: 'center',
    alignItems: 'center',
  }, 
  googlePlay: {
    backgroundColor: 'black',
    marginTop: 70,
    width: 264,
    height: 96,
    // borderWidth: 3,
    // borderColor: 'white',
    borderRadius: 17,
    justifyContent: 'center',
    alignItems: 'center',
  }, 
//   dispo: {
//     color: 'white',
//     fontSize: 20,
//     fontWeight: 700, 
//     textAlign:'center',

//   }
  
});

export default styles;
