// FR permettre à l'utilisateur de choisir des conditions et des offres spécifiques pour des coorganisateurs potentiels (FrameFigma035A,FrameFigma035B)
// EN "Allow the user to choose specific conditions and offers for potential co-organizers." (FrameFigma035A,FrameFigma035B)

//(FrameFigma035A,FrameFigma035B)
// Import Modules
import React, { useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Components
import SelectButtonWithIcon from '../SelectButtonWithIcon/SelectButtonWithIcon';

// Import Contexts / Hooks / Utils
import { useLanguage } from '../../contexts/LanguageContext';

// Import Icons
import { Icons } from '../../constants/Icons';

// Import Styles
const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

// donner valuer par default pour eviter error liee a .length
const RequestCoOrganisers = ({ coOrgConditionsExisting = '', coOrgOffersExisting = '' }) => {

  // Contexts
  const { translations } = useLanguage();

  // States
  const [coOrgConditions, setCoOrgConditions] = React.useState([]);
  const [coOrgOffers, setCoOrgOffers] = React.useState([]);

  const isNullOrEmpty = (arr) => {
    return (Array.isArray(arr) && arr.length === 1 && arr[0] === 'null') || arr.length === 0 || arr === '';
  }

  useEffect(() => {
    console.log(coOrgConditionsExisting)
    console.log(!isNullOrEmpty(coOrgConditionsExisting))
    console.log(coOrgConditionsExisting.length === 1, coOrgConditionsExisting[0] === 'null', coOrgConditionsExisting.length === 0, coOrgConditionsExisting === '')
    if (!isNullOrEmpty(coOrgConditionsExisting)) {
      const parsedConditionsExisting = JSON.parse(coOrgConditionsExisting).map(Number);
      setCoOrgConditions(parsedConditionsExisting)
    }
    if (!isNullOrEmpty(coOrgOffersExisting)) {
      const parsedOffersExisting = JSON.parse(coOrgOffersExisting).map(Number);
      setCoOrgOffers(parsedOffersExisting)
    }
  }, [coOrgConditionsExisting, coOrgOffersExisting])

  // Define the buttons
  const conditionButtons = [
    { id: 1, text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerReceivePrivateMessage, icon: <Icons.Email /> },
    { id: 2, text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerComeEarly, icon: <Icons.GreenClock /> },
  ];

  const giftButtons = [
    { id: 1, text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerOfferDrink, icon: <Icons.FreeDrink /> },
    { id: 2, text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerOfferPass, icon: <Icons.FreePass /> },
    { id: 3, text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerOfferOtherGift, icon: <Icons.OtherGift /> },
  ];

  // Generic function to handle button press
  const handleButtonPress = (button, state, setState) => {
    if (state.includes(button.id)) {
      setState(state.filter(id => id !== button.id));
    } else {
      setState([...state, button.id]);
    }
  };

  useEffect(() => {
    async function saveData() {
      try {
        const mappedConditions = coOrgConditions.map(String); // Mapper chaque option en string appart
        const mappedOffers = coOrgOffers.map(String);

        coOrgConditions.length > 0 ? await AsyncStorage.setItem('coOrganizerConditions', JSON.stringify(mappedConditions)) : []; // Si rien, sauvegarder tableau vide (et pas null)

        coOrgOffers.length > 0 ? await AsyncStorage.setItem('coOrganizerGifts', JSON.stringify(mappedOffers)) : [];

      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
    saveData();
  }, [coOrgConditions, coOrgOffers]);

  return (
    <View style={styles.container}>

      {/* Condition buttons */}
      <View style={styles.innerContainer}>
        <Text style={styles.sectionTitle}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerRequestChoose}</Text>
        <View style={styles.btnContainer}>
          {conditionButtons.map(button => (
            <SelectButtonWithIcon
              key={button.id}
              text={button.text}
              icon={button.icon}
              selected={coOrgConditions.includes(button.id)}
              onPress={() => handleButtonPress(button, coOrgConditions, setCoOrgConditions)}
            />
          ))}
        </View>
      </View>

      {/* Offer buttons */}
      <View style={styles.innerContainer}>
        <Text style={styles.sectionTitle}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerMyOffer}</Text>
        <View style={styles.btnContainer}>
          {giftButtons.map(button => (
            <SelectButtonWithIcon
              key={button.id}
              text={button.text}
              icon={button.icon}
              selected={coOrgOffers.includes(button.id)}
              onPress={() => handleButtonPress(button, coOrgOffers, setCoOrgOffers)}
            />
          ))}
        </View>
      </View>
    </View>
  )
}

export default RequestCoOrganisers;
