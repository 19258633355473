import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    width: '100%',
    gap: 20,
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
  profilePhoto: {
    height: 325,
  },
  menuContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "gray",
  },
  separator: {
    height: "80%",
    width: 1,
    backgroundColor: "white",
    marginTop: 5,
  },
  button: {
    flex: 1,
    padding: 10,
    alignItems: "center",
  },
  text: {
    color: "white",
  },
  cupIconContainer: {
    marginTop: -30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cupIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 150,
    padding: 3,    
  },
  userName: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  blocksContainer: {
    backgroundColor: '#9d9d9d',
    height: 45,
  },
  profileTab: {
    width: '33%',
    height: '100%',
    // backgroundColor: '#59C09B',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gap: {
    gap: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  individualEntry: {
    flexDirection: 'row',
    fontSize: 16,
  },
  greenBar: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 25,
    backgroundColor: '#59C09B',
  },
  actionButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderStyle: 'none',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  addFriendButton: {
    backgroundColor: `#62A1FF`,
  },
  chatButton: {
    backgroundColor: `#B85EB7`,
  },
  blockButton: {
    backgroundColor: `#FF0000`,
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    color: `#FFFFFF`,
  },
  pad20: {
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
  },
  flags: {
    marginRight: 10,
  },
  langTitle: {
    fontWeight: 'bold',
    width: '40%',
  },
  editButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0,
    borderRadius: 4,
    position: 'absolute',
    right: 0,
    margin: 20,
    padding: 10,
    backgroundColor: 'white',
  },
  editButtonText: {
    marginLeft: 5,
    fontSize: 10,
  },
  likeButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    zIndex: 1,
  }
});