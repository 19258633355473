//🇫🇷 Ce fichier gère le style web du composant EmailScreen.js (FrameFigma004A)
//🇬🇧 This file manages the web style of the EmailScreen.js component (FrameFigma004A)
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 60,
    marginHorizontal: 30,
  },
  titlemail: {
    fontSize: 12,
    marginTop: 30,
    marginHorizontal: 30,
  },
  content: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginHorizontal: 'auto',
  },
  email: {
    marginTop: 10,
    marginHorizontal: 30,
    width: '80%',
  },
  email2: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    borderWidth: 1,
    marginVertical: 13,
    width: '100%',
    height: '60%',
    borderRadius: 15,
    position: 'relative',
    paddingLeft: '10%',
    padding: 10,
    marginHorizontal: 150,
    marginLeft: 1,
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
  },
  btnContainer: {
    width: '70%',
    alignItems: 'center',
    marginVertical: '10%',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  buttonActive: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  buttonInactive: {
    backgroundColor: 'grey', // Couleur de fond pour le bouton inactif
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  centerTerms: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  terms: {
    fontSize: 12,
  },
  underline: {
    fontSize: 14,
    textDecorationLine: 'underline',
    marginLeft: 4,
  },
  condition: {
    width: '65%',
    // flexDirection: 'column',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)',
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});