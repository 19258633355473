// AppWeb.js
import React, {useState, useEffect} from 'react';
import {NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {View, TouchableOpacity} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useError} from './src/contexts/ErrorContext';
import {Linking} from 'react-native';

// Importations des composants/pages
import HomeScreen from './src/pages/HomeScreen/HomeScreen';
import LanguagesScreen from './src/pages/LanguagesScreen/LanguagesScreen';
import EmailScreen from './src/pages/LoginRegisterSection/EmailScreen/EmailScreen.js';
import ExistingUserPasswordScreen from './src/pages/LoginRegisterSection/PasswordScreen/ExistingUserPasswordScreen/ExistingUserPasswordScreen.js';
import NewUserPasswordScreen from './src/pages/LoginRegisterSection/PasswordScreen/NewUserPasswordScreen/NewUserPasswordScreen.js';
import Sponsorship from './src/pages/LoginRegisterSection/Sponsorship/Sponsorship.js';
import ResetPasswordScreen from './src/pages/LoginRegisterSection/PasswordScreen/ResetPasswordScreen/ResetPasswordScreen.js';
import VerifyEmailScreen from './src/pages/LoginRegisterSection/VerifyEmail/VerifyEmailNewUserScreen/VerifyEmailScreen.js';
import VerifyEmailExistingUserScreen from './src/pages/LoginRegisterSection/VerifyEmail/VerifyEmailExistingUserScreen/VerifyEmailExistingUserScreen.js';
import StartScreen from './src/pages/StartScreen/StartScreen';
import CreateProfileScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileNavigator.js';
// Composants spécifiques à l'utilisateur connecté
import BottomNav from './src/components/BottomNav/BottomNav';
import WebDrawer from './src/components/DrawerNavigator/DrawerWeb/WebDrawer';
// Importe les screen du drawer
import MyProfileScreen from './src/pages/ProfileModule/MyProfileSection/MyProfileBodyTopScreen/MyProfileBodyTopScreen';
import MyScreenParents from './src/pages/ProfileModule/MyScreen/MyProfileParents.js';
import ProfileScreenParents from './src/pages/ProfileModule/ProfileScreen/ProfileParents.js';
import EditProfileScreen from './src/pages/ProfileModule/EditProfileSection/EditProfileNavigator.js';
import InviteFriendsScreen from './src/pages/InviteFriendsScreen/InviteFriendsScreen';
import ContactUsScreen from './src/pages/ContactUsScreen/ContactUsScreen';
import SettingsScreen from './src/pages/SettingsSection/SettingsScreen/SettingsScreen';
import NewsScreen from './src/pages/NewsScreen/NewsScreen';
import UpdateScreen from './src/pages/UpdateScreen/UpdateScreen';
import TermsAndConditionsScreen from './src/pages/TermsAndConditionsScreen/TermsAndConditionsScreen';
import LogoutButton from './src/components/LogoutButton/LogoutButton';
import {AuthProvider, useAuth} from './src/contexts/AuthContext.js';
import {WebDrawerProvider} from './src/components/DrawerNavigator/DrawerWeb/WebDrawerContext';
import Fields from './src/components/Fields/Fields';
import DeleteAccountStep1 from './src/pages/DeleteAccountSection/DeleteAccountScreenStep1/DeleteAccountScreenStep1.js';
import DeleteAccountStep2 from './src/pages/DeleteAccountSection/DeleteAccountScreenStep2/DeleteAccountScreenStep2.js';
import StepOneScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileStep1Screen/CreateProfileStep1Screen.js';
import Step2PersonalScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileStep2PersoScreen/CreateProfileStep2PersoScreen.js';
import Step2ProScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileStep2ProScreen/CreateProfileStep2ProScreen.js';
import Step3PersonalScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileStep3PersoScreen/CreateProfileStep3PersoScreen.js';
import Step3ProScreen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileStep3ProScreen/CreateProfileStep3ProScreen.js';
import Step4Screen from './src/pages/ProfileModule/CreateProfileSection/CreateProfileStep4Screen/CreateProfileStep4Screen.js';
import MessageChatScreen from './src/pages/MessagesScreen/MessagesScreen.js';
// Import screen invite friends
import InviteByEmail from './src/pages/InviteFriendsScreen/SocialInvite/InviteByEmail';
import InviteByLink from './src/pages/InviteFriendsScreen/SocialInvite/InviteByLink';
import InviteByMessenger from './src/pages/InviteFriendsScreen/SocialInvite/InviteByMessenger';
import InviteBySMS from './src/pages/InviteFriendsScreen/SocialInvite/InviteBySMS';
import InviteBySnapchat from './src/pages/InviteFriendsScreen/SocialInvite/InviteBySnapchat';
import InviteByTwitter from './src/pages/InviteFriendsScreen/SocialInvite/InviteByTwitter';
import InviteByInstagram from './src/pages/InviteFriendsScreen/SocialInvite/InviteByInstagram';
import InviteByTikTok from './src/pages/InviteFriendsScreen/SocialInvite/InviteByTikTok';
// Importation des translations JSON
import {useLanguage} from './src/contexts/LanguageContext';

// Ecrans pour Module Activities
import CreateActivityScreen from './src/pages/ActivityModule/CreateActivitySection/CreateActivityNavigator.js';
import ActivityScreen from './src/pages/ActivityModule/ActivityDetailsSection/ActivityDetailsNavigator.js';
import CopyActivityScreen from './src/pages/ActivityModule/CopyActivityScreen/CopyActivityNavigator.js';
import EditActivityScreen from './src/pages/ActivityModule/EditActivitySection/EditActivityNavigator.js';

import {configureStore} from '@reduxjs/toolkit';
import reducers from './src/reducers';
import {Provider} from 'react-redux';

// import de Nelson provenat du code 2023
import CupsAndAwards from './src/pages/AchievementModule/CupsAndAwardsScreen/CupsAndAwardsScreen.js';
import VipBadges from './src/pages/AchievementModule/VipBadgesSection/VipBadgeExplanationSelector/VipBadgeExplanationSelector.js';
import VipBadgeGold from './src/pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesGoldScreen/VipBadgeAdvantagesGoldScreen.js';
import VipBadgeRuby from './src/pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesRubyScreen/VipBadgeAdvantagesRubyScreen.js';
import VipBadgeEmerald from './src/pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesEmeraldScreen/VipBadgeAdvantagesEmeraldScreen.js';
import VipBadgeDiamand from './src/pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesDiamondScreen/VipBadgeAdvantagesDiamondScreen.js';
import VipBoardExplanation from './src/pages/AchievementModule/VipBoardExplanationScreen/VipBoardExplanationScreen.js';
import Rewards from './src/pages/AchievementModule/RewardScreen/RewardsScreen.js';
import ConceptSlidesScreen from './src/pages/ConceptSlidesScreen/ConceptSlidesScreen.js';
import {hostname} from './hostName/hostName.js';

const headerLeft = navigation => (
  <TouchableOpacity onPress={() => navigation.goBack()}>
    <View style={{marginLeft: 15}}>
      <Fields.IconLeftArrow />
    </View>
  </TouchableOpacity>
);
const LoggedInScreens = () => {
  return (
    <>
      <WebDrawer />
      <View style={{flex: 1}}>
        <BottomNav />
      </View>
    </>
  );
};
const AppContent = () => {
  const {translations} = useLanguage();
  const {isLoggedIn} = useAuth();
  const {error, clearError} = useError(); // Utiliser le contexte d'erreurs
  const navigationRef = useNavigationContainerRef();
  const [isNavigationReady, setIsNavigationReady] = useState(false);

  useEffect(() => {
    let isSubscribed = true; // Flag pour vérifier le montage du composant

    const saveCurrentRoute = () => {
      const currentRoute = navigationRef.getCurrentRoute();
      if (currentRoute && currentRoute.name !== 'Activities' && isSubscribed) {
        const routeParams = currentRoute.name === 'Activity' ? JSON.stringify(currentRoute.params) : null;
        const routeName =
          currentRoute.name === 'MyScreen' || currentRoute.name === 'ProfileScreen' || currentRoute.name === 'EditActivity'
            ? 'Activities'
            : currentRoute.name;
        const routeState = JSON.stringify({routeName, routeParams});
        AsyncStorage.setItem('lastRouteState', routeState);
      }
    };

    if (isNavigationReady) {
      saveCurrentRoute();
    }

    const unsubscribeState = navigationRef.addListener('state', () => {
      if (isSubscribed) {
        saveCurrentRoute();
      }
    });

    return () => {
      isSubscribed = false;
      unsubscribeState();
    };
  }, [navigationRef, isNavigationReady]);

  useEffect(() => {
    const restoreLastRoute = async () => {
      const lastRouteState = await AsyncStorage.getItem('lastRouteState');
      if (lastRouteState && isNavigationReady) {
        const {routeName, routeParams} = JSON.parse(lastRouteState);
        let params = null;
        if (routeParams) {
          params = JSON.parse(routeParams);
        }
        navigationRef.navigate(routeName, params);
      }
    };

    if (isNavigationReady) {
      restoreLastRoute();
    }
  }, [isNavigationReady, navigationRef]);

  const getHeaderTitle = routeName => {
    switch (routeName) {
      case 'Email':
        return translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail;
      case 'Password':
        return translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonHeaderTitlePassword;
      case 'ResetPassword':
        return 'ResetPassword';
      case 'Sponsorship':
        return translations.JsonAchievementsPages.JsonTitleSponsorship;
      case 'NewUserPassword':
        return translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonHeaderTitlePassword;
      case 'VerifyEmail':
        return 'VerifyEmailExistingUserScreen';
      case 'VerifyEmailExistingUserScreen':
        return translations.JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification;
      case 'CreateActivity':
        return translations.JsonActivityPages.JsonActivityManagement.JsonTitleCreateActivity;
      case 'EditActivity':
        return translations.JsonActivityPages.JsonActivityManagement.JsonTitleModifyActivity;
      case 'CreateProfile':
        return translations.JsonCreateProfilePages.JsonTitleCreateProfile;
      default:
        return '';
    }
  };
  const defaultScreenOptions = {
    headerShown: true,
    headerStyle: {backgroundColor: '#59C09B'},
    headerTintColor: 'white',
    headerTitleStyle: {fontWeight: 'bold', fontSize: 20},
  };
  const conditionalScreenOptions = ({navigation, route}) => ({
    ...defaultScreenOptions,
    headerShown: !error,
    headerStyle: {
      backgroundColor: error ? 'red' : '#59C09B',
    },
    headerTitle: error ? error : getHeaderTitle(route.name),
    headerLeft: () => headerLeft(navigation),
  });

  const linking = {
    prefixes: [`http://${hostname}:8080`],
    config: {
      screens: {
        Home: 'home',
        Start: 'start',
        Languages: 'languages',
        Email: 'email',
        Password: 'password',
        NewUserPassword: 'new-user-password',
        ResetPassword: 'reset',
        VerifyEmail: 'verify-email',
        CreateProfile: {
          path: 'CreateProfile',
          screens: {
            StepOneScreen: 'StepOne',
            Step2PersonalScreen: 'SetTwoPersonal',
            Step2ProScreen: 'StepTwoPro',
            Step3PersonalScreen: 'SetthreePersonal',
            Step3ProScreen: 'StepThreePro',
            Step4Screen: 'StepFour',
          },
        },
        LoggedIn: {
          screens: {
            Activities: 'activities',
            MembersNavigator: 'MembersNavigator',
            ConceptSlides: 'concept',
            News: 'news',
            ContactUs: 'contact',
            Activity: {
              path: 'Activity/:activityId',
              parse: {
                activityId: activityId => `${activityId}`,
              },
            },
            MyScreen: {
              path: 'MyScreen/:userId',
              parse: {
                userId: userId => `${userId}`,
              },
            },
            ProfileScreen: {
              path: 'ProfileScreen/:userId',
              parse: {
                userId: userId => `${userId}`,
              },
            },
            EditProfile: 'EditProfile',
            CopyActivity: {
              path: 'CopyActivity/:activityId',
              parse: {
                activityId: activityId => `${activityId}`,
              },
            },
          },
        },
      },
    },
  };

  const Stack = createStackNavigator();

  return (
    <NavigationContainer ref={navigationRef} onReady={() => setIsNavigationReady(true)} linking={linking}>
      {/* linking={linking} */}
      <Stack.Navigator screenOptions={defaultScreenOptions} initialRouteName="Activities">
        {isLoggedIn ? (
          <>
            {/* Affiche le WebDrawer et le BottomNav uniquement pour les utilisateurs connectés */}
            <Stack.Screen name="LoggedIn" component={LoggedInScreens} options={{headerShown: false}} />
            <Stack.Screen
              name="ConceptSlides"
              component={ConceptSlidesScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="MyScreen"
              component={MyScreenParents}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="ProfileScreen"
              component={ProfileScreenParents}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="CreateActivity"
              component={CreateActivityScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="CopyActivity"
              component={CopyActivityScreen}
              options={({navigation}) => ({
                title: translations.JsonActivityPages.JsonActivityManagement.JsonTitleCopyActivity,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="Activity"
              component={ActivityScreen}
              options={({navigation}) => ({
                title: translations.JsonActivityPages.JsonActivityScreen.JsonTitleActivity,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="EditActivity"
              component={EditActivityScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="InviteFriends"
              component={InviteFriendsScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="ContactUs"
              component={ContactUsScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="Settings"
              component={SettingsScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="News"
              component={NewsScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="Update"
              component={UpdateScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="TermsAndConditions"
              component={TermsAndConditionsScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen name="Logout" component={LogoutButton} options={{headerShown: false}} />
            <Stack.Screen
              name="EditProfile"
              component={EditProfileScreen}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="DeleteAccount"
              component={DeleteAccountStep1}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="DeleteAccountStep2"
              component={DeleteAccountStep2}
              options={({navigation}) => ({
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            {/*  import de Nelson provenat du code 2023 */}
            <Stack.Screen
              name="CupsAndAwards"
              component={CupsAndAwards}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonTitleCupsAndAwards,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="VipBadges"
              component={VipBadges}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonTitleVipBadges,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="VipBadgeGold"
              component={VipBadgeGold}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonGoldStar,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="VipBadgeRuby"
              component={VipBadgeRuby}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonRubyStar,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="VipBadgeEmerald"
              component={VipBadgeEmerald}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonEmeraldStar,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="VipBadgeDiamand"
              component={VipBadgeDiamand}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonDiamondStar,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="VipBoardExplanation"
              component={VipBoardExplanation}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonVIPBoardExplanation,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen
              name="Rewards"
              component={Rewards}
              options={({navigation}) => ({
                title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonRewards,
                ...defaultScreenOptions,
                headerLeft: () => headerLeft(navigation),
              })}
            />
            <Stack.Screen name="MessageChatScreen" component={MessageChatScreen} options={{headerShown: false}} />

            <Stack.Screen name="InviteByEmail" component={InviteByEmail} options={{headerShown: false}} />
            <Stack.Screen name="InviteByLink" component={InviteByLink} options={{headerShown: false}} />
            <Stack.Screen name="InviteByMessenger" component={InviteByMessenger} options={{headerShown: false}} />
            <Stack.Screen name="InviteBySMS" component={InviteBySMS} options={{headerShown: false}} />
            <Stack.Screen name="InviteBySnapchat" component={InviteBySnapchat} options={{headerShown: false}} />

            {/* Ajoutez ici les autres écrans du drawer avec `headerShown: true` */}
            {/* ... */}
          </>
        ) : (
          // Écrans pour les utilisateurs non connectés
          <>
            <Stack.Screen name="Home" component={HomeScreen} options={{headerShown: false}} />
            <Stack.Screen name="Start" component={StartScreen} options={{headerShown: false}} />

            <Stack.Screen name="Languages" component={LanguagesScreen} options={{headerShown: false}} />
            <Stack.Screen
              name="Email"
              component={EmailScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="Password"
              component={ExistingUserPasswordScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="Sponsorship"
              component={Sponsorship}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="NewUserPassword"
              component={NewUserPasswordScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="ResetPassword"
              component={ResetPasswordScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="VerifyEmailExistingUserScreen"
              component={VerifyEmailExistingUserScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="VerifyEmail"
              component={VerifyEmailScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen
              name="CreateProfile"
              component={CreateProfileScreen}
              options={({navigation, route}) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({navigation, route});
              }}
            />
            <Stack.Screen name="StepOne" component={StepOneScreen} options={{headerShown: false}} />
            <Stack.Screen name="StepTwoPersonal" component={Step2PersonalScreen} options={{headerShown: false}} />
            <Stack.Screen name="StepTwoPro" component={Step2ProScreen} options={{headerShown: false}} />
            <Stack.Screen name="StepThreePersonal" component={Step3PersonalScreen} options={{headerShown: false}} />
            <Stack.Screen name="StepThreePro" component={Step3ProScreen} options={{headerShown: false}} />
            <Stack.Screen name="StepFour" component={Step4Screen} options={{headerShown: false}} />
            {/* Ajoutez ici les autres écrans pour les utilisateurs non connectés */}
            {/* ... */}
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
const store = configureStore({
  reducer: reducers,
});
const AppWeb = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <WebDrawerProvider>
          <AppContent />
        </WebDrawerProvider>
      </AuthProvider>
    </Provider>
  );
};

export default AppWeb;
