import React, {createContext, useState, useContext, useEffect} from 'react';
import {Platform} from 'react-native';

const WebDrawerContext = createContext();

export const WebDrawerProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    if (Platform.OS === 'web') {
      setIsOpen(!isOpen);
    } else {
      console.log('Drawer toggle not available on mobile');
    }
  };

  // Fournir une valeur par défaut pour les plateformes mobiles
  const value = Platform.OS === 'web' ? {isOpen, toggleDrawer} : {isOpen: false, toggleDrawer: () => {}};

  return <WebDrawerContext.Provider value={value}>{children}</WebDrawerContext.Provider>;
};

export const useWebDrawer = () => useContext(WebDrawerContext);
