// une modal permettant de rechercher et de choisir des images à partir de l'API Unsplash
//"A modal allowing to search and choose images from the Unsplash API."

// UnsplashPicker.js
import React, { useState, useEffect } from 'react';
import { View, Modal, Text, Pressable, FlatList, Image, TextInput, Platform } from 'react-native';
import { useLanguage } from '../../contexts/LanguageContext';


const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const unsplashKey = "V5naPaTjx6P1z10fh5gW_9hRvp1hxrlodqeBz0u1V-Q";
const UnsplashPicker = ({ modalVisible, setModalVisible, onImageSelected, searchTermInitial }) => {
  const {translations} = useLanguage();
  const [searchTerm, setSearchTerm] = useState(searchTermInitial || "");
  const [images, setImages] = useState([]);

 useEffect(() => {
    if (searchTermInitial && searchTerm === "") {
      setSearchTerm(searchTermInitial);
      searchUnsplash(searchTermInitial);
    } else if (searchTerm) {
      searchUnsplash(searchTerm);
    }
  }, [searchTerm, searchTermInitial]);
  const searchUnsplash = async (searchQuery) => {
    const response = await fetch(`https://api.unsplash.com/search/photos?query=${searchQuery}&per_page=30&client_id=${unsplashKey}`);
    const data = await response.json();
    setImages(data.results);
  };

  const pickImage = (image) => {
    onImageSelected(image.urls.small);
    setModalVisible(false);
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalView}>
        <View style={styles.close}>
          <Pressable
              style={styles.closeButton}
              onPress={() => setModalVisible(false)}
          >
              <Text>X</Text>
          </Pressable>
        </View>
        <View style={styles.informations}>
          <View>
              <Text>
                {translations.JsonActivityPages.JsonCreateActivityImageModal.JsonFindImageChoice}
              </Text>
          </View>
          <View>
            <Text>
            {translations.JsonActivityPages.JsonCreateActivityImageModal.JsonUnsplashFindThePerfectImageMessage}
            </Text>
          </View>
          <View>
              <Text>
              {translations.JsonActivityPages.JsonCreateActivityImageModal.JsonUnsplashGoBackMessage}
              </Text>
          </View>
        </View>
          <TextInput
            style={styles.searchInput}
            placeholder={translations.JsonActivityPages.JsonCreateActivityImageModal.JsonResearchImagePlaceholder}
            value={searchTerm}
            onChangeText={setSearchTerm}
          />

          <FlatList
            data={images}
            keyExtractor={item => item.id}
            numColumns={3}
            renderItem={({ item }) => (
              <Pressable onPress={() => pickImage(item)}>
                <Image source={{ uri: item.urls.small }} style={styles.image} />
              </Pressable>
            )}
          />
        </View>
      </View>
    </Modal>
  );
};



export default UnsplashPicker;
