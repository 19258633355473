// ProfileImagePickerModal.js
import React from 'react';
import {Modal, View, TouchableOpacity, Text} from 'react-native';
import ProfileImagePickerIOS from './ImagePickerIOS';
import ProfileImagePickerAndroid from './ImagePickerAndroid';
import ProfileImagePickerWeb from './ImagePickerWeb';
import {Platform} from 'react-native';

const ProfileImagePickerComponent = Platform.select({
  ios: () => ProfileImagePickerIOS,
  android: () => ProfileImagePickerAndroid,
  web: () => ProfileImagePickerWeb,
})();

const ProfileImagePickerModal = ({modalVisible, setModalVisible, onImageSelected}) => {
  return <ProfileImagePickerComponent onImageSelected={onImageSelected} modalVisible={modalVisible} setModalVisible={setModalVisible} />;
};

export default ProfileImagePickerModal;
