//FR un tableau d'objets activitiesIcon, où chaque objet représente une icône d'activité avec son titre correspondant
//EN An array of activityIcon objects, where each object represents an activity icon with its corresponding title

import React, { useEffect, useState } from 'react';
import {View, StyleSheet, Text, TouchableOpacity, Image} from 'react-native';
import {DrawerContentScrollView, DrawerItem} from '@react-navigation/drawer';
import LogoutButton from '../../LogoutButton/LogoutButton'; // Importer le composant LogoutButton
import OnlineUsers from '../../OnlineUsers/OnlineUsers';
import OnlineFriends from '../../OnlineUsers/OnlineFriends';
import { useLanguage } from '../../../contexts/LanguageContext';
import Male from '../../../assets/Icons/Profiles/Male.svg';
import TermsOfSales from '../../../assets/Icons/Settings/TermsOfSales.svg';
import ContactUs from '../../../assets/Icons/Settings/ContactUs.svg';
import News from '../../../assets/Icons/Settings/News.svg';
import Fields from '../../Fields/Fields'
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useUser } from '../../../contexts/UserContext';

const DrawerContent = ({navigation}) => {
  const { translations } = useLanguage();
  const { user, fetchUser, updateUser, updateUserProfileImage } = useUser();
  const [profileImage, setProfileImage] = useState('')
  const [userName, setUserName] = useState('')


  useEffect(()=>{
    if(user){
    setProfileImage(user.profileImage)
    setUserName(user.userName)
    console.log(profileImage, userName)
    }
  }, [user])
  
  
  return (
    <DrawerContentScrollView>
      {/* Optionnel : Ajouter une entête ou des informations de profil ici */}
      <View style={styles.drawerHeader}>
        {/* <Text style={styles.headerText}>Mon Application</Text> */}
        <Image key={profileImage} source={{uri: profileImage}} style={styles.imageProfile}/>
        <Text>{userName}</Text>
        <OnlineUsers/>
        <OnlineFriends/>
      </View>

      {/* Les liens vers les écrans */}
      <DrawerItem icon={Male} label= {translations.JsonDrawerNavMenu.JsonMyProfile} onPress={() => navigation.navigate('MyProfile')} />
      <DrawerItem icon={Fields.CreateAnActivity} label={translations.JsonDrawerNavMenu.JsonCreateEvent} onPress={() => navigation.navigate('CreateActivity')} />
      <DrawerItem icon={Fields.InviteFriends} label= {translations.JsonDrawerNavMenu.JsonInviteFriends} onPress={() => navigation.navigate('InviteFriends')} />
      <DrawerItem icon={ContactUs} label={translations.JsonDrawerNavMenu.JsonContactUs} onPress={() => navigation.navigate('ContactUs')} />
      <DrawerItem icon={Fields.Settings} label={translations.JsonDrawerNavMenu.JsonSettings} onPress={() => navigation.navigate('Settings')} />
      <DrawerItem icon={News} label={translations.JsonDrawerNavMenu.JsonNews} onPress={() => navigation.navigate('News')} />
      <DrawerItem icon={Fields.Update} label={translations.JsonDrawerNavMenu.JsonUpdate} onPress={() => navigation.navigate('Update')} />
      <DrawerItem icon={TermsOfSales} label={translations.JsonDrawerNavMenu.JsonTerms} onPress={() => navigation.navigate('TermsAndConditions')} />
      <View style={styles.logoutButtonWrapper}>
        <LogoutButton />
      </View>


    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  drawerHeader: {
    padding: 20,
    backgroundColor: '#eee',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  logoutButton: {
    padding: 10,
    marginHorizontal: 20,
    marginTop: 10,
    backgroundColor: '#f44336',
    borderRadius: 5,
  },
  logoutButtonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  logoutButtonWrapper:{
    flexDirection: 'row',
  }
});

export default DrawerContent;
