import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  passwordContent: {
    marginTop: '20%',
    marginHorizontal: '5%',
    width: '80%'
  },
  btnContainer: {
    width: '70%',
    alignItems: 'center',
    marginVertical: '10%',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  condition: {
    marginTop: '40%',
    width: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1,
  },
  terms: {
    fontSize: 12,
    textAlign: 'center',
  },
  underline: {
    fontSize: 12,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});
