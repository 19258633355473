// Activities API Service

// Import Contexts / utils / hooks
import { hostname } from '../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const createActivity = async (activityData) => {
  const userToken = await AsyncStorage.getItem('userToken');
  try {
    const response = await fetch(`${hostname}/activities`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      body: JSON.stringify(activityData)
    });
    if (response.ok) {
      const data = await response.json();
      return {response, data};
    }
  } catch (error) {
    console.error(error);
    throw new Error('Failed to create activity');
  }
}

export const deleteActivity = async (activityId) => {
  const userToken = await AsyncStorage.getItem('userToken');
  try {
    const response = await fetch(`${hostname}/activities/${activityId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      }
    });
    if (response.ok) {
      return response;
    }
  } catch (error) {
    console.error(error);
    throw new Error('Failed to delete activity');
  }
}