// FR afficher un message d'erreur à l'utilisateur (FrameFigma004,FrameFigma007C,FrameFigma013)
// EN Display an error message to the user  (FrameFigma004,FrameFigma007C,FrameFigma013)

import React from 'react';
import {Text, StyleSheet, View} from 'react-native';

const ErrorMessage = ({error}) => {
  if (!error) return null;

  return (
    <View style={styles.errorContainer}>
      <Text style={styles.error}>{error}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    alignItems: 'center',
    marginTop: 10,
  },
  error: {
    backgroundColor: 'red',
    color: 'white',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    width: '100%',
  },
});

export default ErrorMessage;
