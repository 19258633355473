//(FrameFigma037A) (FrameFigma037E)
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  toggleAspectButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    backgroundColor: '#59C09B',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    marginBottom: 10,

    // Styles pour Disabled Bouton
    disabled: {
      backgroundColor: '#ccc',
      text: {
        color: '#999',
      },
    },
  },
  saveButton: {
    backgroundColor: '#E8E8E8',
    borderRadius: 50,
    padding: 10,
    marginVertical: 10,
  },
});

export default styles;