// Fr  Styles de CopyActivityStep2Screen(FrameFigma036)
// En  Styles of CopyActivityStep2Screen(FrameFigma036) 
import { StyleSheet } from "react-native";

export default StyleSheet.create({
    title: {
        fontWeight: "bold",
        marginLeft: 10,
        fontSize: 18,
        marginTop: 25,
        marginBottom: 15,
    },
    info: {
        marginBottom: 5,
        marginHorizontal: 10,
    },
    activityIconContainer: {
        flexWrap: "wrap",
        backgroundColor: "#DDDDDD",
        borderRadius: 30,
        opacity: 0.5,
        marginTop: 15,
        // marginHorizontal: 0,
        flexDirection: "row",
        justifyContent: "center",
        paddingVertical: 20,
        boxShadow: "0px 4px 4px 0px #00000040",
        // marginHorizontal: -2.5
        // marginHorizontal:-20,
        color:'black'
    },
    activityCard: {
        marginHorizontal: 4,
        marginVertical: 8,
        borderRadius: 10,
        width: 80,
        height: 100,
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 4px 0px #00000040",
    },
    activityTitle: {
        marginBottom: 5,
        fontSize: 12.5,
    },
    activityView: {
        position: "relative",
    },
    flexView: {
        flex: 1,
        justifyContent: "center",
    },
    btnContainer: {
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: '5%',
    },
    btnsRow: {
        flexDirection: 'row',
        width: '100%',
        alignContent: 'center',
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        marginHorizontal: 50,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    btnText: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
});