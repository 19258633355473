// Fr  Styles de CreateActivityVipStep1Screen(FrameFigma038A,FrameFigma038B)
// En  Styles of CreateActivityVipStep1Screen(FrameFigma038A,FrameFigma038B)
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    marginVertical: 15,
    gap: 30,
    paddingLeft: 25,
    paddingRight: 25,
  },
  textFin: {
    fontSize: 14,
    fontWeight: 250,
  },
  textbtn: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  switchBtn: {
    marginTop: 20,
    marginBottom: 2.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  twoSliders: {
    gap: 5,
    marginVertical: 10,
  },
  sliderText: {
    fontSize: 18,
    fontWeight: 500,
    marginVertical: 10,
  },
  btnContainer: {
    width: '100%',
    alignContent: 'center',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginVertical: 10,
    gap: '2%',
  },
  flagWrapper: {
    width: '21%',
    height: 40,
    backgroundColor: '#FFFFFF', // Default background color
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#59C09B',
    shadowColor: '#000000',
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  flagWrapperSelected: {
    backgroundColor: '#59C09B', // Background color when selected
  },
});
