// Fr  afficher les drapeaux (FrameFigma FrameFigma002 FrameFigma002A FrameFigma002B FrameFigma002C) (FrameFigma038A,FrameFigma038B)
// En  show (FrameFigma002 FrameFigma002A FrameFigma002B FrameFigma002C) (FrameFigma038A,FrameFigma038B)

import FrenchFlag from '../../assets/Icons/Flags/Classic/French/Fr.svg';
import EnglishFlag from '../../assets/Icons/Flags/Classic/English/En.svg';
import SpanishFlag from '../../assets/Icons/Flags/Classic/Spanish/Es.svg';
import GermanFlag from '../../assets/Icons/Flags/Classic/German/De.svg';
import ItalianFlag from '../../assets/Icons/Flags/Classic/Italian/It.svg';
import PortugueseFlag from '../../assets/Icons/Flags/Classic/Portuguese/Pt.svg';
import RussianFlag from '../../assets/Icons/Flags/Classic/Russian/Ru.svg';
import HindiFlag from '../../assets/Icons/Flags/Classic/Hindi/Hi.svg';
import ChineseFlag from '../../assets/Icons/Flags/Classic/Chinese/Zh.svg';
import ArabicFlag from '../../assets/Icons/Flags/Classic/Arabic/Ar.svg';
import HebrewFlag from '../../assets/Icons/Flags/Classic/Hebrew/He.svg';
import JapaneseFlag from '../../assets/Icons/Flags/Classic/Japanese/Ja.svg';
import BosnianFlag from '../../assets/Icons/Flags/Classic/Bosnian/Bs.svg';
import BulgarianFlag from '../../assets/Icons/Flags/Classic/Bulgarian/Bg.svg';
import CroatianFlag from '../../assets/Icons/Flags/Classic/Croatian/Hr.svg';
import CzechFlag from '../../assets/Icons/Flags/Classic/Czech/Cs.svg';
import DanishFlag from '../../assets/Icons/Flags/Classic/Danish/Da.svg';
import EstonianFlag from '../../assets/Icons/Flags/Classic/Estonian/Et.svg';
import FinnishFlag from '../../assets/Icons/Flags/Classic/Finnish/Fi.svg';
import GreekFlag from '../../assets/Icons/Flags/Classic/Greek/El.svg';
import HungarianFlag from '../../assets/Icons/Flags/Classic/Hungarian/Hu.svg';
import IcelandicFlag from '../../assets/Icons/Flags/Classic/Icelandic/Is.svg';
import IrishFlag from '../../assets/Icons/Flags/Classic/Irish/Ga.svg';
import LatvianFlag from '../../assets/Icons/Flags/Classic/Latvian/Lv.svg';
import LithuanianFlag from '../../assets/Icons/Flags/Classic/Lithuanian/Lt.svg';
import MadagascarFlag from '../../assets/Icons/Flags/Classic/Malagasy/Mg.svg';
import DutchFlag from '../../assets/Icons/Flags/Classic/Dutch/Nl.svg';
import NorwegianFlag from '../../assets/Icons/Flags/Classic/Norwegian/No.svg';
import PolishFlag from '../../assets/Icons/Flags/Classic/Polish/Pl.svg';
import RomanianFlag from '../../assets/Icons/Flags/Classic/Romanian/Ro.svg';
import ScottishFlag from '../../assets/Icons/Flags/Classic/ScottishGaelic/Gd.svg';
import SerbianFlag from '../../assets/Icons/Flags/Classic/Serbian/Sr.svg';
import SlovakianFlag from '../../assets/Icons/Flags/Classic/Slovak/Sk.svg';
import SlovenianFlag from '../../assets/Icons/Flags/Classic/Slovenian/Sl.svg';
import SwedishFlag from '../../assets/Icons/Flags/Classic/Swedish/Sv.svg';
import UkrainianFlag from '../../assets/Icons/Flags/Classic/Ukrainian/Uk.svg';

const countriesListSquareFlags = [
  {language: 'French', FlagComponent: FrenchFlag, bigram: 'Fr'},
  {language: 'English', FlagComponent: EnglishFlag, bigram: 'En'},
  {language: 'Spanish', FlagComponent: SpanishFlag, bigram: 'Es'},
  {language: 'German', FlagComponent: GermanFlag, bigram: 'De'},
  {language: 'Italian', FlagComponent: ItalianFlag, bigram: 'It'},
  {language: 'Portuguese', FlagComponent: PortugueseFlag, bigram: 'Pt'},
  {language: 'Russian', FlagComponent: RussianFlag, bigram: 'Ru'},
  {language: 'Hindi', FlagComponent: HindiFlag, bigram: 'Hi'},
  {language: 'Chinese', FlagComponent: ChineseFlag, bigram: 'Zh'},
  {language: 'Arabic', FlagComponent: ArabicFlag, bigram: 'Ar'},
  {language: 'Hebrew', FlagComponent: HebrewFlag, bigram: 'He'},
  {language: 'Japanese', FlagComponent: JapaneseFlag, bigram: 'Ja'},
  {language: 'Bosnian', FlagComponent: BosnianFlag, bigram: 'Bs'},
  {language: 'Bulgarian', FlagComponent: BulgarianFlag, bigram: 'Bg'},
  {language: 'Croatian', FlagComponent: CroatianFlag, bigram: 'Hr'},
  {language: 'Czech', FlagComponent: CzechFlag, bigram: 'Cs'},
  {language: 'Danish', FlagComponent: DanishFlag, bigram: 'Da'},
  {language: 'Estonian', FlagComponent: EstonianFlag, bigram: 'Et'},
  {language: 'Finnish', FlagComponent: FinnishFlag, bigram: 'Fi'},
  {language: 'Greek', FlagComponent: GreekFlag, bigram: 'El'},
  {language: 'Hungarian', FlagComponent: HungarianFlag, bigram: 'Hu'},
  {language: 'Icelandic', FlagComponent: IcelandicFlag, bigram: 'Is'},
  {language: 'Irish', FlagComponent: IrishFlag, bigram: 'Ga'},
  {language: 'Latvian', FlagComponent: LatvianFlag, bigram: 'Lv'},
  {language: 'Lithuanian', FlagComponent: LithuanianFlag, bigram: 'Lt'},
  {language: 'Malagasy', FlagComponent: MadagascarFlag, bigram: 'Mg'},
  {language: 'Dutch', FlagComponent: DutchFlag, bigram: 'Nl'},
  {language: 'Norwegian', FlagComponent: NorwegianFlag, bigram: 'No'},
  {language: 'Polish', FlagComponent: PolishFlag, bigram: 'Pl'},
  {language: 'Romanian', FlagComponent: RomanianFlag, bigram: 'Ro'},
  {language: 'Scottish Gaelic', FlagComponent: ScottishFlag, bigram: 'Gd'},
  {language: 'Serbian', FlagComponent: SerbianFlag, bigram: 'Sr'},
  {language: 'Slovak', FlagComponent: SlovakianFlag, bigram: 'Sk'},
  {language: 'Slovenian', FlagComponent: SlovenianFlag, bigram: 'Sl'},
  {language: 'Swedish', FlagComponent: SwedishFlag, bigram: 'Sv'},
  {language: 'Ukrainian', FlagComponent: UkrainianFlag, bigram: 'Uk'},
];

export default countriesListSquareFlags;
