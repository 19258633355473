//🇫🇷 Ce fichier gère le style mobile du composant NewUserPasswordScreen.js (FrameFigma005)
//🇬🇧 This file manages the mobile style of the NewUserPasswordScreen.js component (FrameFigma005)
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  passwordContent: {
    marginTop: '20%',
    marginHorizontal: 30,
  },
  password: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    borderWidth: 1,
    marginVertical: 13,
    width: '100%',
    borderRadius: 15,
    position: 'relative',
    paddingLeft: '10%',
    backgroundColor: '#F0EEE9',
    height: 50,
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
    zIndex: 1,
  },
  iconEye: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
    marginTop: 12,
  },
  btnContainer: {
    width: '100%',
    alignItems: 'center',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  centerTerms: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  terme: {
    fontSize: 12,
  },
  underline: {
    fontSize: 14,
    textDecorationLine: 'underline',
    marginLeft: 4,
  },
  condition: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  error: {
    backgroundColor: 'red', // Fond rouge pour l'erreur
    color: 'white', // Texte en blanc
    padding: 10, // Espacement interne pour mieux encadrer le texte
    borderRadius: 5, // Bords arrondis
    marginTop: 10, // Marge au-dessus pour séparation
    textAlign: 'center', // Centrage du texte
    width: '100%', // Largeur pour mieux s'adapter au conteneur
  },
});
