import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  backImg: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  },

  logo: {
    // width: '90%',
    // height: '30%',
    width: 340,
    height: 220,
    marginBottom: 100,
  },

  content: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    padding: 20,
    width: '100%',
    marginBottom: 350,
  },

  btnContainer: {
    width: '100%',
    alignItems: 'center',
  },

  btnStart: {
    backgroundColor: 'white',
    padding: 10,
    paddingHorizontal: '10%',
    borderRadius: 15,
    marginHorizontal: 50,
  },

  btnText: {
    color: 'black',
    fontSize: 22,
    fontWeight: 'bold',
    marginHorizontal: 50,
  },
  languageBtn: {
    margin: 15,
    paddingVertical: 5,
    paddingHorizontal: 8,
    width: '60%',
    maxWidth: 200,
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  languageTxt: {
    paddingLeft: '10%',
    fontWeight: 'bold',
  },
  DescriptionTxt: {
    margin: 10,
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22,
  },
});

export default styles;
