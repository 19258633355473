// Fr Ce component affiche en bas informations sur l'adresse et comment retrouver l'activité (FrameFigma042)
// En This component displays on bottom information about the adress and how to find it (FrameFigma042)

// Import Modules
import { View, Text, Platform } from 'react-native'
import React from 'react'

// Import Contexts
import { useLanguage } from '../../../../../contexts/LanguageContext';

// Import Styles
const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const ActivityAddress = ({ activity }) => {
  
  // Contexts
  const { translations } = useLanguage();

  // Local Variables
  const t = translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen

  return (
    <View style={styles.container}>
        <View>
          <Text>
            {t.JsonHowToFindMe}
          </Text>
          <Text>
            {activity.howToFind}
          </Text>
        </View>
    </View>
  )
}

export default ActivityAddress