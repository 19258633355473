//FR permet à un utilisateur de récupérer les utilisateurs qu'il a aimés, d'ajouter un utilisateur à sa liste d'utilisateurs aimés et de supprimer un utilisateur de sa liste d'utilisateurs aimés.
//EN allows a user to retrieve the users they have liked, add a user to their list of liked users, and remove a user from their list of liked users.

// Import Modules
import React, {useState, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Contexts
import { hostname } from '../../hostName/hostName';

export const getUserLikes = async () => {
  const token = await AsyncStorage.getItem('userToken');
  const userId = await AsyncStorage.getItem('userId');
  const response = await fetch(`${hostname}/userLikes`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  const userLikes = data.filter(like => like.likedUser === userId);
  return userLikes;
};

export const addUserLike = async (currentUserId) => {
  const token = await AsyncStorage.getItem('userToken');
  const userId = await AsyncStorage.getItem('userId');
  
  // Update Logged In User
  const response = await fetch(`${hostname}/userLikes`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      likedBy: userId,
      likedUser: currentUserId,
      type: "like"
    }),
  });
  if (response.ok) {
    console.info("You Liked this User")
  }
}

export const deleteUserLike = async (currentUserId) => {
  const token = await AsyncStorage.getItem('userToken');
  const userId = await AsyncStorage.getItem('userId');
  let likeToDelete = {};

  const getUserLikes = async () => {
    const response = await fetch(`${hostname}/userLikes`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    // On utilise .some() method pour avoir un booleen
    likeToDelete = data.find(like => like.likedBy === userId && like.likedUser === currentUserId);
  }
  await getUserLikes();
  const response = await fetch(`${hostname}/userLikes/${likeToDelete._id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    console.info("You UnLiked this User")
  }
}