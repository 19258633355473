// Fr  Styles de CreateActivityCommonStep1Screen(FrameFigma035A,FrameFigma035B)
// En  Styles of CreateActivityCommonStep1Screen(FrameFigma035A,FrameFigma035B)

import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 25,
    paddingRight: 25,
  },
  switchBtn: {
    marginTop: 10,
    marginBottom: 2.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchBtn2: {
    marginBottom: 2.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textbtn: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 5,
  },
  slider: {
    fontWeight: 'bold',
    fontSize: 18,
    marginVertical: 20,
    width: '100%',
  },
  inputContainer: {
    paddingHorizontal: 8,
    justifyContent: 'center',
    fontSize: 16,
    borderWidth: 1,
    marginVertical: 20,
    borderRadius: 15,
    padding: 20,
  },
  textTitle: {
    paddingHorizontal: 15,
    backgroundColor: 'white',
    fontSize: 13,
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    left: 20,
    top: -10,
  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)',
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  btnContainer: {
    width: '100%',
    alignContent: 'center',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  switchBtnSousSwitchBtn2: {
    marginLeft: '10%',
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
});
