import {StyleSheet} from 'react-native';
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
  },
  menuContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "gray",
  },
  button: {
    flex: 1,
    padding: 10,
    alignItems: "center",
  },
  text: {
    color: "white",
  },
    separator: {
    height: "80%",
    width: 1,
    backgroundColor: "white",
    marginTop: 5,
  },
  secondaryMenuContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    backgroundColor: "gray",
    width: "70%",
    marginLeft: "15%",
    marginRight: "15%",
    borderRadius: 5,
  },
  smallSeparator: {
    width: 1,
    backgroundColor: "white",
    marginTop: 2,
    height:"80%"
  },

  smallButton: {
   flex: 1,
    padding: 5,
    alignItems: "center",
  },
  smallText: {
    color: "white",
    fontSize: 12,
  },
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 20,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "7.5%",
    marginRight: "7.5%",
    padding: 10,
    borderColor: "#59c09b",
    borderWidth: 1,
  },
  searchInput: {
    flex: 1,
    marginLeft: 10,
  },
  screenContainer: {
    flex: 1,
    marginTop: 20,
  },
});

export default styles