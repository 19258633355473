// Fr Ce component affiche l'image de l'activité (FrameFigma041)
// En This component displays the activity image (FrameFigma041)

// Import Modules
import React from "react";
import { View, Image } from "react-native";

const ActivityImage = ({ activityImage }) => {
  return (
    <View>
      <Image source={{ uri: activityImage }} style={{ width: '100%', aspectRatio: 16 / 9, resizeMode: 'contain' }}/>
    </View>
  );
};

export default ActivityImage;