//FR gère le contexte utilisateur dans l'application
//EN manages the user context within the application

import React, { createContext, useContext, useState, useEffect } from 'react';
import { hostname } from '../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [profileVisits, setProfileVisits] = useState([]);
  const [friendNotifications, setFriendNotifications] = useState([]);
  const [likeNotifications, setLikeNotifications] = useState([]);
  const [activityLikeNotifications, setActivityLikeNotifications] = useState([]);
  const logout = async () => {
    await AsyncStorage.removeItem('userId');
    await AsyncStorage.removeItem('userToken');
    setUser(null); // Réinitialiser l'état de l'utilisateur
    navigation.navigate('Home'); // Rediriger vers la page d'accueil
  };

  const deleteUserAccount = async (deleteOptions) => {
    const userId = await AsyncStorage.getItem('userId');
    const userToken = await AsyncStorage.getItem('userToken');
    try {
      const response = await axios.delete(`${hostname}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: { deleteOptions }
      });

      if (response.status === 200) {
        logout(); // Utiliser logout pour gérer la déconnexion et la redirection
      }
    } catch (error) {
      console.error('Error deleting user account:', error);
    }
  };
  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const userId = await AsyncStorage.getItem('userId');
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await axios.get(`${hostname}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // Suppose que la réponse de l'API est directement l'objet utilisateur souhaité
      setUser(response.data.user); // Assurez-vous que cela correspond à la structure de votre API
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCurrentUserLikeStatus = async (userIdAutre) => {
    try {
      const userId = await AsyncStorage.getItem('userId');
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/userLikes`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const likeStatus = data.some(like => like.likedBy === userId&&like.likedUser === userIdAutre);
      return likeStatus;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const getAllUsers = async () => {
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await axios.get(`${hostname}/users`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setAllUsers(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const userId = await AsyncStorage.getItem('userId');
      const userToken = await AsyncStorage.getItem('userToken');
      const authHeader = { headers: { Authorization: `Bearer ${userToken}` } };

      // Tentez de récupérer les détails de l'utilisateur
      let userDetails = {};
      try {
        const userDetailsResponse = await axios.get(`${hostname}/users/${userId}/details`, authHeader);
        userDetails = userDetailsResponse.data.user;
        console.log('User details found:', userDetails);
      } catch (error) {
        console.log('User details not found or new user', error);
      }

      // Tentez de récupérer les visites de profil
      let profileVisits = [];
      try {
        const profileVisitsResponse = await axios.get(`${hostname}/users/${userId}/profileVisits`, authHeader);
        profileVisits = profileVisitsResponse.data || [];
        console.log('Profile visits found:', profileVisits);
      } catch (error) {
        console.log('No profile visits found', error);
      }

      // Tentez de récupérer les notifications d'amis
      let friendNotifications = [];
      try {
        const friendNotificationsResponse = await axios.get(`${hostname}/users/${userId}/friendNotifications`, authHeader);
        friendNotifications = friendNotificationsResponse.data || [];
        console.log('Friend notifications found:', friendNotifications);
      } catch (error) {
        console.log('No friend notifications found', error);
      }

      // Tentez de récupérer les notifications de like
      let likeNotifications = [];
      try {
        const likeNotificationsResponse = await axios.get(`${hostname}/users/${userId}/likeNotifications`, authHeader);
        likeNotifications = likeNotificationsResponse.data || [];
        console.log('Like notifications found:', likeNotifications);
      } catch (error) {
        console.log('No like notifications found', error);
      }
      let activityLikeNotifications = [];
      try {
        const activityLikeNotificationsResponse = await axios.get(`${hostname}/users/${userId}/activityLikeNotifications`, authHeader);
        activityLikeNotifications = activityLikeNotificationsResponse.data || [];
        console.log('Activity like notifications found:', activityLikeNotifications);
      } catch (error) {
        console.log('No activity like notifications found', error);
      }
      // Mise à jour de l'état avec les données récupérées
      setUser(userDetails);
      setProfileVisits(profileVisits);
      setFriendNotifications(friendNotifications);
      setLikeNotifications(likeNotifications);
      setActivityLikeNotifications(activityLikeNotifications); // Ajoutez ceci à votre état
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const markVisitAsRead = async visitId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');

    await fetch(`${hostname}/users/${userId}/profileVisits/${visitId}/read`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    // Utilisez updateProfileVisits pour mettre à jour l'état global
    updateProfileVisits(visitId, true);
  };
  const markVisitAsUnread = async visitId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');

    await fetch(`${hostname}/users/${userId}/profileVisits/${visitId}/unread`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    // Utilisez updateProfileVisits pour mettre à jour l'état global
    updateProfileVisits(visitId, false);
  };
  const updateProfileVisits = (visitId, isRead) => {
    setProfileVisits(visits => visits.map(visit => (visit._id === visitId ? { ...visit, isRead: isRead } : visit)));
  };
  const markFriendNotificationAsRead = async notificationId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');
    console.log('userId', userId);
    if (!userId) {
      console.error('UserID is undefined.');
      return;
    }

    await fetch(`${hostname}/users/${userId}/friendNotifications/${notificationId}/read`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    updateFriendNotifications(notificationId, true);
  };
  const markFriendNotificationAsUnread = async notificationId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');
    await fetch(`${hostname}/users/${userId}/friendNotifications/${notificationId}/unread`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });
    updateFriendNotifications(notificationId, false);
  };
  const updateFriendNotifications = (notificationId, isRead) => {
    setFriendNotifications(notifications => notifications.map(notification => (notification._id === notificationId ? { ...notification, isRead: isRead } : notification)));
  };
  const markLikeNotificationAsRead = async notificationId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');

    try {
      await axios.patch(
        `${hostname}/users/${userId}/likeNotifications/${notificationId}/read`,

        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        },
      );

      // Mettre à jour l'état des notifications de like pour marquer celle-ci comme lue
      updateLikeNotifications(notificationId, true);
    } catch (error) {
      console.error('Error marking like notification as read:', error);
    }
  };
  const markLikeNotificationAsUnread = async notificationId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');

    try {
      await axios.patch(
        `${hostname}/users/${userId}/likeNotifications/${notificationId}/unread`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        },
      );

      // Mettre à jour l'état des notifications de like pour marquer celle-ci comme non lue
      updateLikeNotifications(notificationId, false);
    } catch (error) {
      console.error('Error marking like notification as unread:', error);
    }
  };
  const updateLikeNotifications = (notificationId, isRead) => {
    setLikeNotifications(likes =>
      likes.map(like => {
        if (like._id === notificationId) {
          return { ...like, isRead: isRead };
        }
        return like;
      }),
    );
  };
  const markActivityLikeNotificationAsRead = async notificationId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');

    try {
      await axios.patch(
        `${hostname}/users/${userId}/activityLikeNotifications/${notificationId}/read`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        },
      );

      // Mettre à jour l'état des notifications de like d'activité pour marquer celle-ci comme lue
      updateActivityLikeNotifications(notificationId, true);
    } catch (error) {
      console.error('Error marking activity like notification as read:', error);
    }
  };

  const markActivityLikeNotificationAsUnread = async notificationId => {
    const userToken = await AsyncStorage.getItem('userToken');
    const userId = await AsyncStorage.getItem('userId');

    try {
      await axios.patch(
        `${hostname}/users/${userId}/activityLikeNotifications/${notificationId}/unread`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        },
      );

      // Mettre à jour l'état des notifications de like d'activité pour marquer celle-ci comme non lue
      updateActivityLikeNotifications(notificationId, false);
    } catch (error) {
      console.error('Error marking activity like notification as unread:', error);
    }
  };

  const updateActivityLikeNotifications = (notificationId, isRead) => {
    setActivityLikeNotifications(notifications =>
      notifications.map(notification => {
        if (notification._id === notificationId) {
          return { ...notification, isRead: isRead };
        }
        return notification;
      }),
    );
  };

  const updateUser = async (userId, updatedData) => {
    setIsLoading(true);
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await axios.put(`${hostname}/users/${userId}`, updatedData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      });

      setUser(response.data); // Mise à jour des données de l'utilisateur dans le contexte
      await AsyncStorage.setItem('userData', JSON.stringify(response.data)); // Mise à jour du storage local
    } catch (error) {
      console.error('Error updating user data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const updateUserProfileImage = async (userId, imageFile) => {
    if (!imageFile) {
      console.log('No image file to submit.');
      return;
    }

    setIsLoading(true);
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const formData = new FormData();
      formData.append('profileImage', imageFile);

      // Assurez-vous que cette URL correspond exactement à ce que votre serveur attend.
      const response = await axios.put(`${hostname}/users/${userId}/profileImage`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setUser(response.data); // Mise à jour des données de l'utilisateur dans le contexte
      await AsyncStorage.setItem('userData', JSON.stringify(response.data)); // Mise à jour du storage local
      console.log('Profile image updated successfully.');
    } catch (error) {
      console.error('Error updating profile image:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const loadAllUsers = getAllUsers;
  const updateUserState = userData => {
    setUser(userData);
    // Mettez à jour tout autre état ou information nécessaire ici
  };


  useEffect(() => {
    getAllUsers();
    fetchUser();
    getCurrentUserLikeStatus();
    fetchUserDetails();
  }, []);
  return (
    <UserContext.Provider
      value={{
        user,
        profileVisits,
        friendNotifications,
        likeNotifications,
        activityLikeNotifications,
        allUsers,
        getCurrentUserLikeStatus,
        isLoading,
        setUser,
        fetchUserDetails,
        setProfileVisits,
        setFriendNotifications,
        setLikeNotifications,
        setActivityLikeNotifications,
        markVisitAsRead,
        markVisitAsUnread,
        updateProfileVisits,
        markFriendNotificationAsRead,
        markFriendNotificationAsUnread,
        updateFriendNotifications,
        markLikeNotificationAsRead,
        markLikeNotificationAsUnread,
        updateLikeNotifications,
        markActivityLikeNotificationAsRead,
        markActivityLikeNotificationAsUnread,
        updateActivityLikeNotifications,
        fetchUser,
        updateUser,
        updateUserProfileImage,
        getAllUsers,
        loadAllUsers,
        updateUserState,
        deleteUserAccount,
        logout,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
