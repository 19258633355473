//FR un sélecteur de date déroulant qui permet à l'utilisateur de choisir une date en sélectionnant le jour, le mois et l'année
//EN A dropdown date picker that allows the user to choose a date by selecting the day, month, and year
import React, { useState } from 'react';
import { View, Text, Picker, Modal, Button, StyleSheet, TouchableOpacity } from 'react-native';
import { Icons } from '../../../../constants/Icons';

const DropdownDatePicker = ({onDateChange, initialState, handleSave}) => {
  // Initial state for selected date and modal visibility
  const [selectedDate, setSelectedDate] = useState({
    day: '1',
    month: 'January',
    year: String(new Date().getFullYear()-24)
  });
  const [modalVisible, setModalVisible] = useState(false);

  // Function to generate an array of days based on selected month and year
  const generateDaysArray = () => {
    const daysInMonth = new Date(selectedDate.year, new Date().getMonth(), 0).getDate();
    return [...Array(daysInMonth).keys()].map((day) => String(day + 1));
  };

  // Arrays for months and years
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const years = [...Array(100).keys()].map((year) => String(new Date().getFullYear() - year));

  const onChange = (event, selectedDate) => {
    setShow(Platform.OS === 'ios');
    setDate(selectedDate || date);
    if (onDateChange) {
      onDateChange(selectedDate || date);
    }
  };

  const handleSaveBirthDate=() =>{
    setModalVisible(false)
    handleSave(selectedDate)
  }

  return (
    <View style={styles.container}>
      {/* Button to open modal */}
      {/* <Button title="Open Date Picker" onPress={() => setModalVisible(true)} /> */}

      {/* Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        onChange={e => onChange(null, new Date(e.target.value))}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {/* Dropdown list for selecting day */}
            <View style={styles.date}>
            <Picker
              selectedValue={selectedDate.day}
              onValueChange={(itemValue) => setSelectedDate({ ...selectedDate, day: itemValue })}
              style={styles.dropdown}
            >
              {generateDaysArray().map((day) => (
                <Picker.Item key={day} label={day} value={day} />
              ))}
            </Picker>

            {/* Dropdown list for selecting month */}
            <Picker
              selectedValue={selectedDate.month}
              onValueChange={(itemValue) => setSelectedDate({ ...selectedDate, month: itemValue })}
              style={styles.dropdown}
            >
              {months.map((month) => (
                <Picker.Item key={month} label={month} value={month} />
              ))}
            </Picker>

            {/* Dropdown list for selecting year */}
            <Picker
              selectedValue={selectedDate.year}
              onValueChange={(itemValue) => setSelectedDate({ ...selectedDate, year: itemValue })}
              style={styles.dropdown}
            >
              {years.map((year) => (
                <Picker.Item key={year} label={year} value={year} />
              ))}
            </Picker>
            </View>
            

            {/* Button to close modal */}
            {/* <Button title="Close" onPress={() => setModalVisible(false)} /> */}
            <Button title="Save" onPress={handleSaveBirthDate} />
          </View>
        </View>
      </Modal>

      {/* Display selected date */}
      <TouchableOpacity onPress={() => setModalVisible(true)} style={styles.birthdate}>
      <Icons.Birthday style={styles.logoBirthday} />
      <Text style={styles.selectedDate}>
      {/* <Icons.Birthday style={styles.logoBirthday} /> */}
      {/* {initialState? (initialState): "no date"} */}
        {/* {selectedDate.day} {selectedDate.month} {selectedDate.year} */}
        {/* {initialState? (initialState): ({selectedDate.day} {selectedDate.month} {selectedDate.year})} */}
        {initialState ? initialState : `${selectedDate.day} ${selectedDate.month} ${selectedDate.year}`}
      </Text>
      </TouchableOpacity>
      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // paddingTop: 20,
    
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    // padding: 20,
    borderRadius: 10,
    elevation: 5,
  },
  dropdown: {
    width: 100,
    marginBottom: 20,
  },
  selectedDate: {
    fontSize: 18,
    // marginTop: 20,
  },
  date: {
    flexDirection: 'row',
  }, 
  logoBirthday: {
    // marginTop: 20,
    width: 24,
    height: 24,
    marginLeft: 10

  }, 
  selectedDate: {
    // paddingBottom: 15,
    marginLeft: 20,
    fontSize: 16,
  },
  birthdate: {
    // flex: 1,
    flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 20,
    height: 40,
    width: '100%',
  }
});

export default DropdownDatePicker;