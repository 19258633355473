import {StyleSheet} from 'react-native';
const styles = StyleSheet.create({
   tabBar: {
    flexDirection: 'row',
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
  },
  text: {
    color: 'white',
    fontSize: 14,
  },
  separator: {
    height: '100%',
    width: 1,
    backgroundColor: 'white',
  },
  button: {
    backgroundColor: '#59C09B',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
  },
  input: {
    borderWidth: 1,
    borderColor: 'gray',
    width: '90%',
    padding: 10,
    marginVertical: 5,
    color: 'black',
    backgroundColor: 'white',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
  imageContainer: {
    backgroundColor: '#59C09B', 
    borderRadius: 50, 
    alignItems:'center',
    justifyContent:'center'
  },
  imageItem: {
    width: '60%', 
    height: '60%'
  }
});

export default styles