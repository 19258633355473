//FR afficher un champ de texte personnalisé, (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma037A,FrameFigma031,FrameFigma032A,FrameFigma032B,FrameFigma032C,FrameFigma035A,FrameFigma035B)
//EN "Display a customized text field (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma037A,FrameFigma031,FrameFigma032A,FrameFigma032B,FrameFigma032C,FrameFigma035A,FrameFigma035B)
import { View, TextInput, Platform, TouchableOpacity } from 'react-native';
import React, { useState } from 'react';
import Fields from '../Fields/Fields';

const TextInputCustom = ({ Icon = "", multiline=false, numberOfLines=1, placeholderText, isPassword = false, value="", handleTextChange,isPlaceHolderTextAlignCenter=true}) => {
    const styles =
        Platform.OS === 'web'
            ? require('./StylesWeb').default
            : require('./StylesMobile').default;
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <View style={styles.container}>
            {Icon !== "" &&
                <View style={styles.icon}>
                    <Icon />
                </View>
            }
            <View style={[isFocused || isPasswordVisible || value.length > 0 ? styles.focusedInput : styles.defaultInput, styles.input,Icon !== ""?{paddingHorizontal: '15%'}:{paddingHorizontal: '6%'}]}>
                <TextInput
                    style={[isFocused && styles.focusedInput2, value.length > 0 ?styles.input2:[styles.placeholder,isPlaceHolderTextAlignCenter?{textAlign: 'center'}:{textAlign: 'left'}]]}
                    multiline={multiline}
                    rows={numberOfLines}
                    placeholder={placeholderText}
                    placeholderTextColor="#8C8F8E"
                    secureTextEntry={isPassword ? !isPasswordVisible : false}
                    value={value}
                    onChangeText={handleTextChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
                {isPassword &&
                    <TouchableOpacity style={styles.iconEye} onPress={handleTogglePasswordVisibility}>
                        {isPasswordVisible ? <Fields.IconEyeToggleOff /> : <Fields.IconEyeToggle />}
                    </TouchableOpacity>
                }
            </View>
        </View>
    )
}
export default TextInputCustom;
