// Import Modules
import React from 'react';
import { View, Text, Pressable, Platform } from 'react-native';

// Import Contexts
import { useLanguage } from "../../../contexts/LanguageContext";

// Import Icons
import { Icons } from "../../../constants/Icons";

// Import Styles
const styles =
  Platform.OS === 'web'
  // Attention - ce fichier utilise les styles de parent AddFriendModal
    ? require('../Styles.web').default
    : require('../Styles.mobile').default;

// Logique de component commence ici --------------------------------------------------
const Step2ConfirmationScreen = ({ verificationSuccess, onClose }) => {

  // Contexts
  const { translations } = useLanguage();
  const t = translations.JsonConfirmFriendshipPopup

  return (
    <View style={styles.container}>
      {verificationSuccess ?
        <View style={styles.innerContainer}>
          <Icons.Checkbox width={40} height={40} />
          <Text>{t.JsonPopupCongratulations}</Text>
          <Text>{t.JsonPopupYouAreNowFriend}</Text>
        </View>
        : 
        <View style={styles.innerContainer}>
          <Icons.CloseLargeRedSign width={40} height={40} />
          <Text>{t.JsonPopupEmailErrorNotARealFriend}</Text>
        </View>
      }
      <View>
        <Pressable onPress={onClose}>
          <Text>{t.JsonButtonClose}</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default Step2ConfirmationScreen