// FR une carte d'utilisateur affichée dans une liste ou une grille d'utilisateurs
// EN "A user card displayed in a list or grid of users."
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Image, ActivityIndicator, Pressable, ImageBackground } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../hostName/hostName';
import { useNavigation } from '@react-navigation/native';
import { Icons } from '../../constants/Icons';

// en cas de build il faut utiliser userSocketService
//import { useWebSocket } from '../../sockets/WebSocketProvider';
import { useWebSocket } from '../../sockets/WebSocketLocal';
import { useUser } from '../../contexts/UserContext';
import { addUserLike, deleteUserLike } from '../../utils/UserLikes';

// import composant
import ProfileMedal from '../../components/ProfileMedal/ProfileMedal';

const UserCard = ({ user }) => {
  const navigation = useNavigation();
  const { userStatus } = useWebSocket(); // Utilise le hook pour accéder au statut des utilisateurs
  const { getCurrentUserLikeStatus } = useUser(); // recuperer user's like
  const isOnline = userStatus[user._id] === 'online'; // Vérifie si l'utilisateur est en ligne
  const [userIsLiked, setUserIsLiked] = useState(false);

  useEffect(() => {
    getCurrentUserLikeStatus(user._id)
      .then(data => setUserIsLiked(data))
      .catch(error => console.error('Error fetching user like:', error));
  }, [user._id]);


  const toggleLike = async () => {
    setUserIsLiked(!userIsLiked);

    if (userIsLiked) {
      deleteUserLike(user._id);
    } else {
      addUserLike(user._id);
    }
  };

  if (!user) {
    return <Text>Utilisateur introuvable</Text>;
  }
  const imageUrl = user.profileImage ? user.profileImage : `${hostname}/uploads/${user.profileImage}`;

  const handleMemberPress = () => {
    // Navigation vers la page de profil de l'utilisateur
    navigation.navigate('ProfileScreen', { userId: user._id });
  };

  const cityArray = user?.city?.split(',');
  const city = cityArray && cityArray.length >= 2 ? cityArray.at(-2).trim() : '';

  return (
    <ImageBackground source={{ uri: imageUrl }} style={styles.profileImageContainer}>
      {/* <LinearGradient
        colors={[
          "rgba(157, 157, 157, 0)",
          "rgba(0, 0, 0, 0.37)",
        ]}
        style={styles.gradient}
      > */}
        <Pressable style={styles.card} onPress={handleMemberPress} key={isOnline ? 'online' : 'offline'}>
          <View style={styles.rowIcon}>
            <View style={styles.iconAchive}>
              <ProfileMedal points={user.appPoints} noOfSponsorships={user.sponsoredUsers?.length || 0} />
            </View>
            {isOnline&&<View style={styles.statusOnline} />}
          </View>
          <View style={styles.rowInfo}>
            <View>
              <Text style={styles.textWhite}>{user.userName}</Text>
              {!user.age === undefined && user.useMyBirthday
                && <Text style={styles.textWhiteSmall}>{user.age}years </Text>
              }
              <Text style={styles.textWhiteSmall}>{city.length > 16 ? city.slice(0, 16) + '...' : city}</Text>
            </View>
            {!userIsLiked &&
              <Pressable onPress={toggleLike} style={styles.likeButton}>
                <Icons.HeartGrey width={45} height={37} />
              </Pressable>
            }
            {userIsLiked &&
              <Pressable onPress={toggleLike} style={styles.likeButton}>
                <Icons.HeartRed width={45} height={37} />
              </Pressable>
            }
          </View>
        </Pressable>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  profileImageContainer: {
    flex: 1,
    aspectRatio: 1 / 1,
  },
  gradient: {
    flex: 1, justifyContent: 'center'
  },
  card: {
    margin: '5%',
    maxWidth: '100%',
    // backgroundColor:'pink',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  iconAchive: {
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 150,
    padding: 3,
    aspectRatio: 1.2 / 1,
    width: '20%'
  },
  statusOnline: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: '#2AE09F',
    position: 'absolute',
    right: 0,
    top: 0,
    borderWidth: 0.2,
    borderColor: '#000000',
  },
  textWhite: {
    color: 'white',
    textAlign: 'left',
    fontSize: 18,
    fontWeight: '400',
  },
  textWhiteSmall: {
    color: 'white',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: '400',
  },
  rowInfo: {
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusOffline: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: 'red',
    position: 'absolute',
    right: 0,
    bottom: 0,
    borderWidth: 2,
    borderColor: 'white',
  },
});

export default UserCard;
