// FR e permet à un utilisateur d'envoyer une demande d'ami à un autre utilisateur et de mettre à jour les listes d'amis des deux utilisateurs en conséquence.
// EN  allows a user to send a friend request to another user and update both users' friend lists accordingly.

// Import Modules
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Contexts
import { hostname } from '../../hostName/hostName';

export const addFriendPostRequest = async (currentUserId) => {
  const token = await AsyncStorage.getItem('userToken');
  const userId = await AsyncStorage.getItem('userId');

  // Update Logged In User
  const response = await fetch(`${hostname}/users/${userId}/addFriend`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      friendId: currentUserId
    }),
  });
  if (response.ok) {
    console.info("Friend Added Successfully")
  }

  // Update Other User
  const otherResponse = await fetch(`${hostname}/users/${currentUserId}/addFriend`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      friendId: userId
    }),
  });
  if (otherResponse.ok) {
    console.info("Friend 2 Added Successfully")
  }
}