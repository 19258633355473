import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, Platform, Pressable, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ConfirmDeleteModal from '../../../../../components/ConfirmDeleteModal/ConfirmDeleteModal';
import { hostname } from '../../../../../../hostName/hostName';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import Fields from '../../../../../components/Fields/Fields';

const styles = Platform.OS === 'web'
  ? require('./StylesWeb').default
  : require('./StylesMobile').default;

const ActivityDescription = ({ activityDescription, activity, userStatus, activityAuthor }) => {
  const navigation = useNavigation();
  const { translations } = useLanguage();
  const [modalVisible, setModalVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleDeleteAction = async () => {
    try {
      const activityId = await AsyncStorage.getItem('selectedActivityId');
      const userToken = await AsyncStorage.getItem('userToken');
  
      if (activityId) {
        const endpoint = `${hostname}/activities/${activityId}`;
        const response = await fetch(endpoint, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.ok) {
          console.log('Activité supprimée avec succès !');
          navigation.navigate('Activities');
        } else {
          console.error('Erreur lors de la suppression de l\'activité:', response.status, response.statusText);
        }
      } else {
        console.error('Impossible de récupérer l\'ID de l\'activité depuis AsyncStorage.');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'activité:', error);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const maxLength = 500;
  const showReadMore = activityDescription.length > maxLength;

  return (
    <View style={styles.container}>
      <View>
        <View>
          <Text style={styles.aboutText}>
            {translations.JsonActivityPages.JsonActivityScreen.JsonActivityDescriptionScreen.JsonAboutEvent}
          </Text>
        </View>
      </View>
      <View style={styles.description}>
        <View style={styles.editTrash}>
          <Pressable>
            <Fields.Trash />
          </Pressable>
          <Pressable>
            <Fields.Edit />
          </Pressable>
        </View>
        <Text style={styles.descriptionText}>
          {isExpanded ? activityDescription : `${activityDescription.substring(0, maxLength)}...`}
        </Text>
        {showReadMore && (
          <Pressable onPress={toggleExpand}>
            <Text style={styles.readMoreText}>
              {isExpanded ? 'Read Less' : 'Read More'}
            </Text>
          </Pressable>
        )}
      </View>
      <ConfirmDeleteModal
        visible={modalVisible}
        onClose={closeModal}
        onDelete={handleDeleteAction}
      />
    </View>
  );
};

export default ActivityDescription;
