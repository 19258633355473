//🇫🇷 Page de paramètre (FrameFigma54)
//🇬🇧 Parameter Page (FrameFigma54)

// Importation des modules et composants nécessaires
import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
  Image,
  Linking,
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
  Platform,
} from "react-native";
import { useLanguage } from "../../../contexts/LanguageContext.js";
import { Images } from "../../../constants/Images.js";
import { Icons } from "../../../constants/Icons.js";

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

// Composant fonctionnel SettingsScreen
const SettingsScreen = () => {
  // Destructuration des props
  const { translations } = useLanguage();

  // Initialisation de la constante de navigation
  const navigation = useNavigation();

  // Définition des options de l'écran
  const optionsData = [
    {
      title: translations.JsonSettingsScreen.JsonBlockedUsers,
      screen: "BlockedUsers",
      icon: <Images.SettingsBlockedUser  style={styles.socialImage}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonLikedUsers,
      screen: "LikedUsers",
      icon: <Images.SettingsLikedUsers  style={styles.socialImageRound}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonEditProfile,
      screen: "EditProfile",
      icon: <Images.SettingsEditProfile  style={styles.socialImage}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonMedals,
      screen: "CupsAndAwards",
      icon: <Icons.Level4GoldStar  style={styles.socialImageGift}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonSponsorship,
      screen: "Rewards",
      icon: <Icons.Gift  style={styles.socialImageGift}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonNotifications,
      screen: "Notification",
      icon: <Images.NotificationsBellOn  style={styles.socialImageBelle}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonContactUs,
      icon: <Images.SettingsContactUs  style={styles.socialImageRound}/>,
      screen: "ContactUs",
    },
    { title: translations.JsonSettingsScreen.JsonNews,
      icon: <Images.SettingsNews  style={styles.socialImage}/>,
      screen: "News",
    },
    {
      title: translations.JsonSettingsScreen.JsonDeleteMyAccount,
      icon: <Images.SettingsDeleteAccount  style={styles.socialImageRound}/>,
      screen: "DeleteAccount",
    },

    {
      title: translations.JsonSettingsScreen.JsonLegalNotice,
      screen: "LegalNotice",
      icon: <Images.SettingsLegalNotice  style={styles.socialImage}/>,
    },{
      title: translations.JsonSettingsScreen.JsonPrivacyPolicy,
      screen: "PrivacyPolicy",
      icon: <Images.SettingsPrivacyPolicy  style={styles.socialImage}/>,
    },
    {
      title: translations.JsonSettingsScreen.JsonTermsOfSales,
      screen: "TermsAndConditions",
      icon: <Images.SettingsTermsOfSales  style={styles.socialImage}/>,
    },

    
    // {
    //   title: translations.JsonSettingsScreen.JsonTermsOfSales,
    //   image: images.termsOfSales,
    //   onPress: () =>
    //     navigateToExternalLink(
    //       "https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An"
    //     ),
    // },
    // Ajoutez d'autres options selon le même modèle
  ];

  // Fonction de navigation vers un écran spécifié
  const navigateToScreen = (screenName) => {
    navigation.navigate(screenName);
  };

  // Fonction de navigation vers un lien externe
  const navigateToExternalLink = (url) => {
    Linking.openURL(url);
  };

  // Rendu principal du composant
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{ backgroundColor: "white" }}>
        <View style={styles.content}>
          <View style={styles.logoContainer}>
            <Images.SettingsGears style={styles.logo} /> 
          </View>
          <View style={styles.socialContainer}>
            {optionsData.map((option, index) => (
              <Pressable
                key={index}
                onPress={
                  option.onPress || (() => navigateToScreen(option.screen))
                }
              > 

                <View style={styles.social}>
                {option.icon}
                    
                  <Text style={styles.txt}>{option.title}</Text>
                  <Images.RightArrowGrey style={styles.arrowImage} />
                  <View style={styles.arrowContainer}>
                  
                    {/* <Image source={images.next2} style={styles.arrowImage} /> */}
                  </View>
                </View>
              </Pressable>
            ))}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

// Exportation du composant SettingsScreen
export default SettingsScreen;
