//Fr styles de DateTimePickerMobile (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma031) (FrameFigma039)
//En styles of DateTimePickerMobile (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma031) (FrameFigma039)
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    marginVertical: 15,
    flexDirection: "row",
    justifyContent: "center",

  },
  title: {
    paddingHorizontal: 10,
    backgroundColor: "#fff",
    fontSize: 13,
    textAlign: "center",
    position: "absolute",
    zIndex: 1,
    left: 45,
    top: -10,
  },
  placeholder: {
    marginLeft: 15,
    color: "#535353",
    fontSize: 18,
  },
  dateTime: {
    marginLeft: 15,
    fontSize: 18,
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  dateTime: {
    fontSize: 16,
  },
});

export default styles;
