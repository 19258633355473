import React, {useEffect, useRef, useState} from 'react';
import {GOOGLE_MAPS_API_KEY} from '@env';
import {useUser} from '../../../contexts/UserContext';

const MapsWebUser = () => {
  const {user, allUsers} = useUser();
  const mapRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  // Chargez le script de l'API Google Maps
  useEffect(() => {
    if (!window.google || !window.google.maps) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => setScriptLoaded(true);
    } else {
      setScriptLoaded(true); // Le script est déjà chargé
    }
  }, []);

  // Initialisation de la carte une fois le script chargé
  useEffect(() => {
    if (scriptLoaded) {
      initMap();
    }
  }, [scriptLoaded]); // Cette fonction dépend maintenant uniquement de scriptLoaded

  const initMap = () => {
    navigator.geolocation.getCurrentPosition(
      success => {
        const initialPosition = {
          lat: success.coords.latitude,
          lng: success.coords.longitude,
        };
        createMap(initialPosition, true);
      },
      error => {
        console.error('Geolocation failed or permission denied, using database locations.');
        const fallbackPosition = user.location && user.location.coordinates ? {lat: user.location.coordinates[1], lng: user.location.coordinates[0]} : {lat: 0, lng: 0}; // Position par défaut si non disponible
        createMap(fallbackPosition, false);
      },
    );
  };

  const createMap = (position, showCurrentUser) => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: position,
      zoom: 14,
      tilt: 60,
    });
    google.maps.event.addListener(map, 'zoom_changed', function () {
      if (map.getZoom() < 14) {
        map.setTilt(0); // Désactive l'inclinaison si le niveau de zoom est inférieur à 14
      } else {
        map.setTilt(60); // Active l'inclinaison à 45 degrés si le niveau de zoom est de 14 ou plus
      }
    });
    // Marquer la position actuelle de l'utilisateur avec un point bleu et un texte personnalisé
    if (showCurrentUser) {
      const userLocationMarker = new window.google.maps.Marker({
        position,
        map: map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 7, // Taille du cercle
          fillColor: '#0000FF', // Couleur bleue pour le cercle
          fillOpacity: 1, // Opacité du remplissage
          strokeWeight: 2, // Épaisseur du bord
        },
      });
      const userLocationCircle = new google.maps.Circle({
        strokeColor: '#0000FF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ADD8E6',
        fillOpacity: 0.35,
        map: map,
        center: position,
        radius: 500, // Rayon de 500 mètres
      });
      // Créer un overlay pour "You are Here"
      const overlay = new google.maps.OverlayView();
      overlay.onAdd = function () {
        const layer = document.createElement('div');
        layer.style.borderStyle = 'none';
        layer.style.borderWidth = '0px';
        layer.style.position = 'absolute';
        layer.style.whiteSpace = 'nowrap';
        layer.style.fontSize = '14px';
        layer.style.fontWeight = 'bold';
        layer.style.color = 'blue';
        layer.innerText = 'You are Here';
        const panes = this.getPanes();
        panes.floatPane.appendChild(layer);

        overlay.draw = function () {
          const projection = this.getProjection();
          const pixel = projection.fromLatLngToDivPixel(userLocationMarker.getPosition());
          layer.style.left = pixel.x - layer.offsetWidth / 2 + 'px';
          layer.style.top = pixel.y - layer.offsetHeight - 30 + 'px'; // Ajustez cette valeur pour positionner le texte
        };
      };
      overlay.setMap(map);
    }
    // Afficher les autres utilisateurs avec leur photo de profil
    allUsers.forEach(userItem => {
      if (userItem._id !== user.userId && userItem.location && userItem.location.coordinates) {
        const [lng, lat] = userItem.location.coordinates;
        const userPosition = {lat, lng};

        new window.google.maps.Marker({
          position: userPosition,
          map: map,
          icon: {
            url: userItem.profileImage,
            scaledSize: new window.google.maps.Size(50, 50),
          },
          title: userItem.userName,
        });
      }
    });
  };

  return <div ref={mapRef} style={{height: '100vh', width: '100%'}} />;
};

export default MapsWebUser;