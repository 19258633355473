//FR qui calcule et met à jour les points d'un utilisateur sur la base de diverses activités et interactions
//EN that calculates and updates a user's points based on various activities and interactions
import {useEffect, useState} from 'react';
import {useUser} from '../contexts/UserContext';
import {useAuth} from '../contexts/AuthContext';
import {hostname} from '../../hostName/hostName';

export const calculateUserPoints = userId => {
  const {allUsers} = useUser();
  const {userToken} = useAuth();
  const [userLikes, setUserLikes] = useState([]);
  const [userOrganisedActivities, setUserOrganisedActivities] = useState([]);
  const [userCoOrganisedActivities, setUserCoOrganisedActivities] = useState([]);
  const [userAttendedActivities, setUserAttendedActivities] = useState([]);

  const currentUser = allUsers.find(user => user._id === userId);

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const response = await fetch(`${hostname}/userLikes`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          const userLikesReceived = data.filter(like => like.likedUser === userId);
          setUserLikes(userLikesReceived);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchActivities = async () => {
      try {
        const response = await fetch(`${hostname}/activities`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          const userOrganisedActivities = data.filter(activity => activity.author === userId);
          const userCoOrganisedActivities = data.filter(activity => activity.coOrganizers.includes(userId));
          const userAttendedActivities = data.filter(activity => activity.attendees.includes(userId));
          setUserOrganisedActivities(userOrganisedActivities);
          setUserCoOrganisedActivities(userCoOrganisedActivities);
          setUserAttendedActivities(userAttendedActivities);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchLikes();
    fetchActivities();
  }, [userId]);

  const userProfileVisits = currentUser?.profileVisits?.length > 0 ? [...new Set(currentUser.profileVisits?.map(visit => visit.visitor))].length : 0;
  const userFriends = currentUser?.friends?.length > 0 ? currentUser.friends.length : 0;
  const userActivitiesOrganising = userOrganisedActivities?.length > 0 ? userOrganisedActivities.length : 0;
  const userActivitiesCoOrganising = userCoOrganisedActivities?.length > 0 ? userCoOrganisedActivities.length : 0;
  const userActivitiesAttending = userAttendedActivities?.length > 0 ? userAttendedActivities.length : 0;
  const userSponsoredUsers = currentUser?.sponsoredUsers?.length > 0 ? currentUser.sponsoredUsers.length : 0;
  const userLiked = userLikes?.length > 0 ? userLikes.length : 0;

  const userPoints =
    userFriends * 20 +
    userActivitiesOrganising * 100 +
    userActivitiesAttending * 25 +
    userActivitiesCoOrganising * 50 +
    userProfileVisits * 5 +
    userSponsoredUsers * 250 +
    userLiked * 10;

  useEffect(() => {
    const updateUserPoints = async () => {
      try {
        const response = await fetch(`${hostname}/users/${userId}/updatePoints`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({points: userPoints}),
        });

        if (response.ok) {
          console.info('User Points Updated');
        } else {
          console.error('Failed to update user points:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error updating user points:', error);
      }
    };

    if (userPoints !== 0) {
      updateUserPoints();
    }
  }, [userPoints]);

  return userPoints;
};
