import React, { useEffect, useState } from 'react';
import { View, PermissionsAndroid, Platform, Dimensions, Image } from 'react-native';
import Geolocation from '@react-native-community/geolocation';
import MapView, { Marker } from 'react-native-maps';
import { useUser } from '../../../contexts/UserContext';

const MapsMobileUser = () => {
  const { user, allUsers } = useUser();

  const [region, setRegion] = useState({
    latitude: 48.8566,
    longitude: 2.3522,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  });

  // State to hold calculated image sizes
  const [imageSize, setImageSize] = useState(30); // Default image size

  const requestLocationPermission = async () => {
    if (Platform.OS === 'ios') {
      Geolocation.requestAuthorization('whenInUse');
    } else if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
        {
          title: 'Location Permission',
          message: 'This app needs access to your location.',
        }
      );
      return granted === PermissionsAndroid.RESULTS.GRANTED;
    }
    return false;
  };

  const getCurrentPosition = () => {
    Geolocation.getCurrentPosition(
      position => {
        setRegion({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        });
      },
      error => console.warn(error),
      {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
    );
  };

  useEffect(() => {
    requestLocationPermission().then(granted => {
      if (granted) {
        getCurrentPosition();
      }
    });
  }, []);

  useEffect(() => {
    // Calculate the image size based on the current latitudeDelta when region changes
    const newSize = calculateImageSize(region.latitudeDelta);
    setImageSize(newSize);
  }, [region]);

  const calculateImageSize = latitudeDelta => {
    const baseSize = 30;
    if (latitudeDelta < 0.01) return baseSize * 1.5; // Closer zoom
    else if (latitudeDelta < 0.05) return baseSize * 1.2; // Medium zoom
    return baseSize; // Default size
  };

  return (
    <View style={{flex: 1}}>
      <MapView
        style={{width: Dimensions.get('window').width, height: Dimensions.get('window').height}}
        region={region}
        showsUserLocation={true}
        onRegionChangeComplete={region => setRegion(region)}
      >
        {allUsers.map(userItem => {
          if (userItem._id !== user._id && userItem.location && userItem.location.coordinates && userItem.profileImage) {
            const [longitude, latitude] = userItem.location.coordinates;
            return (
              <Marker key={userItem._id} coordinate={{latitude, longitude}} title={userItem.userName} description="Another user">
                <Image
                  source={{uri: userItem.profileImage}}
                  style={{
                    width: imageSize,
                    height: imageSize,
                    borderRadius: imageSize / 2, // Keeps the image circular
                    borderWidth: 1,
                    borderColor: 'white',
                  }}
                  resizeMode="cover"
                />
              </Marker>
            );
          }
          return null;
        })}
      </MapView>
    </View>
  );
};

export default MapsMobileUser;
