// FR Frame 22B3 il qui affiche les activités aimées par l'utilisateur. (FrameFigma22B3)
// GB Frame 22B3 displays the activities liked by the user.(FrameFigma22B3)

import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, Platform } from 'react-native';   // composants d'interface utilisateur
import AsyncStorage from '@react-native-async-storage/async-storage'; // composant AsyncStorage : Gère le stockage asynchrone des données.
import ActivityCard from '../../../../components/ActivityCard/ActivityCard'; // composant personnalisé pour afficher les détails d'une activité.

import { useLanguage } from '../../../../contexts/LanguageContext';
import { getActivities } from '../../../../utils/ActivityFetch';
import { useFocusEffect } from '@react-navigation/native';

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const LikedScreen = () => {
  const { translations } = useLanguage();
  const [likedActivities, setLikedActivities] = useState([]);

  // Recupération des activités likées
  const fetchLikedActivities = async () => {
    try {
      // Use User ID from Local Storage
      const userId = await AsyncStorage.getItem('userId');
  
      // Fetch Activities of User from getActivities Hook
      const usersActivities = await getActivities(userId);
  
      // Set Liked Activities
      setLikedActivities(usersActivities.userLikedActivities);
    } catch (error) {
      console.error('Erreur lors de la récupération des activités aimées :', error);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchLikedActivities();
    }, [])
  );

  return (
    <View style={styles.container}>
      <View>
        <Text
          style={styles.textOne}
        >
          {translations.JsonTopNav.JsonMyActivitiesExplanationLiked}
        </Text>
      </View>
      {likedActivities.length > 0 ? likedActivities.map((activity) => (
        <ActivityCard key={activity._id} activity={activity} />
      )) : (
        <View style={styles.textTwoContainer}>
          <Text style={styles.textTwo}>
            {translations.JsonActivityPages.JsonMyActivitiesScreen.JsonLikedActivityEmpty}
          </Text>
        </View>
      )}
    </View>
  );
};

export default LikedScreen;
