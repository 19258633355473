// Import Modules
import React from 'react';

// Import Icons
import { Icons } from '../../../constants/Icons';

const NoStarMedals = ({points}) => {
  return (
    <>
      {points >= 0 && points <= 499 && <Icons.Level1NoStar width={52} height={52} />}
      {points >= 500 && points <= 999 && <Icons.Level2NoStar width={52} height={52} />}
      {points >= 1000 && points <= 1999 && <Icons.Level3NoStar width={52} height={52} />}
      {points >= 2000 && points <= 4999 && <Icons.Level4NoStar width={52} height={52} />}
      {points >= 5000 && points <= 9999 && <Icons.Level5NoStar width={52} height={52} />}
      {points >= 10000 && points <= 49999 && <Icons.Level6NoStar width={52} height={52} />}
      {points >= 50000 && <Icons.Level7NoStar />}
    </>
  )
}

export default NoStarMedals