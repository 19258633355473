//FR l'écran pour la troisième étape du processus d'inscription pour un compte professionnel
//EN the screen for the third step of the registration process for a professional account.

import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AddressAutoPicker from '../../../../components/AddressAutoPicker/AddressAutoPicker';
import { generateUsername } from '../../../../components/UniqueUserName/UniqueUserName';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from "../../../../components/ButtonNext/ButtonNext";
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import Fields from '../../../../components/Fields/Fields';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const StepThreeProScreen = ({ onNextStep }) => {
  const { translations } = useLanguage();
  const { language } = useLanguage();
  const { error, setError } = useError(); // Initialize error and setError from useError hook

  const [selectedAddress, setSelectedAddress] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [generatedUserName, setGeneratedUserName] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [validationErrors, setValidationErrors] = useState(false);

  useEffect(() => {
    // Mettez à jour le bouton en fonction de la validité du username généré
    setIsButtonDisabled(!generatedUserName || validationErrors.nickname);
  }, [generatedUserName, validationErrors]);

  useEffect(() => {
    const generateAndSetUsername = async () => {
      try {
        const username = await generateUsername(); // Générer le nom d'utilisateur
        setGeneratedUserName(username); // Affecter le nom d'utilisateur généré à l'état

        // Enregistrer le nom d'utilisateur dans AsyncStorage
        await AsyncStorage.setItem('userName', username);
      } catch (error) {
        setError(error.message); // Set error using setError from useError hook
        console.error('Erreur lors de la génération du username :', error);
      }
    };

    generateAndSetUsername(); // Appeler la fonction pour générer et affecter le nom d'utilisateur
  }, []);

  const handleNextStep = async () => {
    if (selectedAddress.trim() === '') {
      setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonCityMissing); // Set error using setError from useError hook
      console.log('Champs non remplis pour le compte professionnel');
      return;
    }
    if (generatedUserName.trim() === '') {
      setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonNicknameMissing); // Set error using setError from useError hook
      console.log('Pseudo manquant');
      return;
    }
    setValidationErrors({});
    setError('');
    onNextStep();
  };

  const handleAddressSelect = address => {
    console.log('Selected Address:', address);
    setError('');
    setSelectedAddress(address);
  };

  const handleNicknameChange = text => {
    setGeneratedUserName(text);
    setError(''); // Reset error message
  };

  return (
    <View>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
    <View key={language} style={styles.container}>
      <View style={styles.addressContent}>
        <AddressAutoPicker
          onAddressSelect={handleAddressSelect}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          placeholder={translations.JsonCreateProfilePages.JsonCityPlaceholder}
        />
      </View>
      <View style={styles.nicknameContainer}>
        <View style={styles.nicknameContent}>
          <View style={styles.nickNameMessage}>
            <Text style={styles.nickNameMessageTxt}>{translations.JsonCreateProfilePages.JsonNickNameMessage}</Text>
          </View>
          <View style={styles.nickName}>

            <TextInputCustom
                Icon={Fields.IconLastName}
                placeholderText={translations.JsonCreateProfilePages.JsonNickName}
                value={generatedUserName}
                handleTextChange={handleNicknameChange}
                isPlaceHolderTextAlignCenter={false}
              />
          </View>
          {validationErrors.nickname && <Text style={styles.errorText}>{validationErrors.nickname}</Text>}
        </View>
      </View>
      <View style={styles.btnContainer}>
        <ButtonNext
          conditionNeed={selectedAddress !== '' && generatedUserName !== ''}
          handleNextStep={handleNextStep}
          btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
        />
      </View>
    </View>
  </View>
  );
};

export default StepThreeProScreen;
