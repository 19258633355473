// Fr  Styles de CreateActivityCommonStep3Screen(FrameFigma037A)
// En  Styles of CreateActivityCommonStep3Screen(FrameFigma037A)

import { StyleSheet } from "react-native";

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        paddingLeft:25,
        paddingRight:25
    },
    title: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 18,
        marginVertical: 10,
    },
    addPhoto: {
        marginLeft: 10,
    },
    choosePhoto: {
        backgroundColor: "#DCF1EA",
        padding: 70,
        borderRadius: 15,
        marginVertical: 10,
        marginLeft: 10,
        marginRight: 30,

    },
    btnChoose: {
        justifyContent: 'center',
        alignItems: 'center',
    },
     imageStyle: {
        width: '90%', // Assurez-vous que la largeur de 'choosePhoto' est définie
        height: 200, // Hauteur fixe, ou 'auto' si vous préférez
        marginLeft: 10,
        resizeMode: 'cover'
    },
    field: {
        margin: 5,
    },
    title: {
        fontWeight: "bold",
        alignItems: 'center',
        fontSize: 18,
        marginVertical: 10,
    },
    inputContainer: {
        paddingHorizontal: 8,
        fontSize: 16,
        borderWidth: 1,
        marginVertical: 20,
        width: '90%',
        borderRadius: 15,
        padding: 100,
        marginLeft: 10,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: "white",
        fontSize: 13,
        textAlign: "center",
        position: "absolute",
        zIndex: 1,
        left: 20,
        top: -10,
    },
    textInput: {
        flex: 1,
        paddingHorizontal: 10, 
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: "space-between",
      },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        elevation: 5,
        margin: 15,
        alignItems: 'center'
    },
    btnText: {
        color: 'white',
        fontSize: 16,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      },
      headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
      },
})