// FR  affiche une interface utilisateur avec une barre de navigation à onglets qui permet à l'utilisateur de visualiser ses activités à venir (FrameFigma021A)
// GB Display a user interface with a tab navigation bar that allows the user to view their upcoming activities. (FrameFigma021A)


import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import React from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';

import LikedScreen from "./MyActivitiesLikedScreen/MyActivitiesLikedScreen";
import OrganizedScreen from "./MyActivitiesOrganizedScreen/MyActivitiesOrganizedScreen";
import PastScreen from "./MyActivitiesPastScreen/MyActivitiesPastScreen";
import UpcomingScreen from "./MyActivitiesUpcomingScreen/MyActivitiesUpcomingScreen";

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const Tab = createMaterialTopTabNavigator();

const CustomTabBar = ({ label, isSelected, onPress }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.button,
        isSelected ? styles.buttonSelected : styles.buttonUnselected,
      ]}
    >
      <Text style={isSelected ? styles.activeLabel : styles.inactiveLabel}>
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const MyActivitiesScreen = () => {
  const { translations } = useLanguage();
  return (
    <Tab.Navigator
      tabBar={(props) => (
        <View style={styles.tabBar}>
          {props.state.routes.map((route, index) => (
            <View key={route.key} style={styles.tabBarButtonContainer}>
              {index > 0 && <View style={styles.separator} />}
              <CustomTabBar
                label={route.name}
                isSelected={props.state.routes[props.state.index].key === route.key}
                onPress={() => props.navigation.navigate(route.name)}
              />
            </View>
          ))}
        </View>
      )}
    >
      <Tab.Screen
        name={translations.JsonTopNav.JsonMyActivitiesThirdLineUpcoming}
        component={UpcomingScreen}
      />
      <Tab.Screen
        name={translations.JsonTopNav.JsonMyActivitiesThirdLinePast}
        component={PastScreen}
      />
      <Tab.Screen
        name={translations.JsonTopNav.JsonMyActivitiesThirdLineLiked}
        component={LikedScreen}
      />
      <Tab.Screen
        name={translations.JsonTopNav.JsonMyActivitiesThirdLineOrganized}
        component={OrganizedScreen}
      />
    </Tab.Navigator>
  )
}

export default MyActivitiesScreen;