//🇫🇷 Ce fichier gère le style mobile du composant ExistingUserPasswordScreen.js (FrameFigma005)
//🇬🇧 This file manages the mobile style of the ExistingUserPasswordScreen.js component (FrameFigma005)
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  passwordContent: {
    marginTop: '20%',
    marginHorizontal: 30,
    width:'80%'
  },
  password: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    borderWidth: 1,
    marginVertical: 13,
    width: '100%',
    borderRadius: 15,
    position: 'relative',
    paddingLeft: '10%',
    backgroundColor: '#F0EEE9',
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
    zIndex: 1,
  },
  iconEye: {
    position: 'absolute',
    right: 10, 
    zIndex: 1,
    marginTop: 12,
  },
  btnContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop:'5%'
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  centerTerms: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  terms: {
    fontSize: 12,
  },
  underline: {
    fontSize: 14,
    textDecorationLine: 'underline',
    marginLeft: 4,
  },
  condition: {
    marginTop:'40%',
    width: '60%',
    flexDirection: 'row',
    flexWrap:'wrap',
    justifyContent: 'center',
    // alignItems:'center',
  },
  forgotPassword: {
    marginTop: '1.5%',
  },
  forgotTxt: {
    fontWeight:'bold',
  },
  btnForgotContainer:{
    margin:10,
    borderWidth: 1, 
    padding:10,
    borderRadius:15,
  },
  btnForgot: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  btnTextForgot:{
    color:'#59C09B',
    textAlign:"center",
    fontWeight:'bold',
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
});
