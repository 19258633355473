//FR il affiche une liste d'activités à venir pour l'utilisateur actuel (FrameFigma21B)
//GB it displays a list of upcoming activities for the current user." (FrameFigma21B)

import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, FlatList, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import ActivityCard from '../../../../components/ActivityCard/ActivityCard';
import { hostname } from '../../../../../hostName/hostName';
import { useFocusEffect } from '@react-navigation/native';
import { useLanguage } from '../../../../contexts/LanguageContext';

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const UpcomingScreen = () => {
  const { translations } = useLanguage();
  const [activities, setActivities] = useState([]);

  const fetchActivities = async () => {
      try {
          const userToken = await AsyncStorage.getItem('userToken'); 
          const userId = await AsyncStorage.getItem('userId');
          const response = await fetch(`${hostname}/activities`, {
              headers: {
                  Authorization: `Bearer ${userToken}` 
              }
          });
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          const myUpcomingActivities = data.filter((activity) => {
            return activity.attendees.includes(userId) && new Date(activity.date) >= new Date();
          });
          console.log(myUpcomingActivities);
          setActivities(myUpcomingActivities);
      } catch (error) {
          if (error instanceof TypeError && error.message === 'Network request failed') {
              console.error("Network request failed:", error);
              // Gérer les erreurs réseau ici
          } else {
              console.error("Error fetching activities:", error);
              // Gérer les autres erreurs ici
          }
      }
  };
  

  useEffect(() => {
      fetchActivities();
  }, []);

  // Utilisez useFocusEffect pour déclencher le chargement des activités chaque fois que l'écran devient focus
  useFocusEffect(
      useCallback(() => {
          fetchActivities();
      }, [])
  );
  
  const renderItem = ({ item }) => (
      <View>
          <ActivityCard activity={item} />
      </View>
  );
  

  return (
      <View style={styles.container}>
        <View>
          <Text style={styles.textOne}>
            {translations.JsonTopNav.JsonMyActivitiesExplanationUpcoming}
          </Text>
        </View>
        {activities.length > 0? (
          <FlatList
            data={activities}
            renderItem={renderItem}
            keyExtractor={(item, index) => item._id ? item._id.toString() : index.toString()}
          />
        ):(
        <View
          style={styles.textTwoContainer}
        >
          <Text style={styles.textTwo}>
            {
              translations.JsonActivityPages.JsonMyActivitiesScreen
                .JsonUpcomingActivityEmpty
            }
          </Text>
        </View>
        )}
      </View>
  );
}

export default UpcomingScreen