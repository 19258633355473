// Import Modules
import React from 'react';

// Import Icons
import { Icons } from '../../../constants/Icons';

const GoldStarMedals = ({points}) => {
  return (
    <>
      {points >= 0 && points <= 499 && <Icons.Level1GoldStar width={52} height={52} />}
      {points >= 500 && points <= 999 && <Icons.Level2GoldStar width={52} height={52} />}
      {points >= 1000 && points <= 1999 && <Icons.Level3GoldStar width={52} height={52} />}
      {points >= 2000 && points <= 4999 && <Icons.Level4GoldStar width={52} height={52} />}
      {points >= 5000 && points <= 9999 && <Icons.Level5GoldStar width={52} height={52} />}
      {points >= 10000 && points <= 49999 && <Icons.Level6GoldStar width={52} height={52} />}
      {points >= 50000 && <Icons.Level7GoldStar />}
    </>
  )
}

export default GoldStarMedals