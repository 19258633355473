import React, { useEffect, useRef, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../../hostName/hostName';
import { useUser } from '../../../../contexts/UserContext';

const MapsWebActivities = () => {
  const mapRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [activities, setActivities] = useState([]);
  const { user, allUsers } = useUser();
  const parisCoordinates = { lat: 48.8566, lng: 2.3522 };
  // Modification ici: utilisez des URLs au lieu d'importer directement
  const activitiesIconMapping = {
    1: 'https://socializus.com/images/ColorsTopicActivity/Business.svg',
    2: 'https://socializus.com/images/ColorsTopicActivity/Afterwork.svg',
    3: 'https://socializus.com/images/ColorsTopicActivity/Apero.svg',
    4: 'https://socializus.com/images/ColorsTopicActivity/Travel.svg',
    5: 'https://socializus.com/images/ColorsTopicActivity/Dancing.svg',
    6: 'https://socializus.com/images/ColorsTopicActivity/Sports.svg',
    7: 'https://socializus.com/images/ColorsTopicActivity/Music.svg',
    8: 'https://socializus.com/images/ColorsTopicActivity/Private.svg',
    9: 'https://socializus.com/images/ColorsTopicActivity/MutualHelp.svg',
    10: 'https://socializus.com/images/ColorsTopicActivity/Linguistic.svg',
    11: 'https://socializus.com/images/ColorsTopicActivity/Movie.svg',
    12: 'https://socializus.com/images/ColorsTopicActivity/Games.svg',
    13: 'https://socializus.com/images/ColorsTopicActivity/Culture.svg',
    14: 'https://socializus.com/images/ColorsTopicActivity/Meal.svg',
    15: 'https://socializus.com/images/ColorsTopicActivity/Party.svg',
    16: 'https://socializus.com/images/ColorsTopicActivity/Picnic.svg',
  };
  const fetchActivities = async () => {
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/activities`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      console.log('Toutes les activités récupérées:', data); // Affiche toutes les activités récupérées

      setActivities(data);
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  // Chargez le script de l'API Google Maps
  useEffect(() => {
    if (!window.google || !window.google.maps) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => setScriptLoaded(true);
    } else {
      setScriptLoaded(true); // Le script est déjà chargé
    }
  }, []);

  useEffect(() => {
    fetchActivities();
  }, []);

  // Initialisation de la carte une fois le script chargé
  useEffect(() => {
    if (scriptLoaded) {
      initMap();
    }
  }, [scriptLoaded, activities]);

  const initMap = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const userPosition = { lat: position.coords.latitude, lng: position.coords.longitude };
        initializeMap(userPosition, true);
      },
      () => {
        // Utilisation de Paris comme emplacement par défaut si la géolocalisation échoue
        initializeMap(parisCoordinates, false);
      },
    );
  };

  const initializeMap = (center, showCurrentUser) => {
    const map = new window.google.maps.Map(mapRef.current, {
      center,
      zoom: 15, // Définit un niveau de zoom initial élevé pour tester l'inclinaison
      mapTypeId: google.maps.MapTypeId.SATELLITE, // Utilisation de l'ID de type de carte SATELLITE pour mieux visualiser l'inclinaison
      tilt: 60, // Incline la vue de la carte de 45 degrés, si disponible
    });
    google.maps.event.addListener(map, 'zoom_changed', function () {
      if (map.getZoom() < 15) {
        map.setTilt(0); // Désactive l'inclinaison si le niveau de zoom est inférieur à 18
      } else {
        map.setTilt(60); // Active l'inclinaison à 45 degrés si le niveau de zoom est de 18 ou plus
      }
    });
    if (showCurrentUser) {
      // Création du marqueur sans label pour l'emplacement de l'utilisateur
      new window.google.maps.Marker({
        position: center,
        map: map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: '#59C09B',
          fillOpacity: 1,
          strokeWeight: 2,
        },
      });

      // Création d'un overlay pour "You are Here"
      const overlay = new google.maps.OverlayView();
      overlay.onAdd = function () {
        const layer = document.createElement('div');
        layer.style.border = 'none';
        layer.style.borderWidth = '0px';
        layer.style.position = 'absolute';
        layer.style.color = 'black';
        layer.style.fontWeight = 'bold';
        layer.style.fontSize = '14px';
        layer.innerHTML = 'You are Here';

        // Ajoute le div personnalisé au floatPane de la carte (utilisé pour positionner les éléments en dessus de la carte)
        const panes = this.getPanes();
        panes.floatPane.appendChild(layer);

        overlay.draw = function () {
          const projection = this.getProjection();
          // Convertit les coordonnées LatLng en pixel sur la carte
          const pixel = projection.fromLatLngToDivPixel(center);

          // Positionne le div en fonction des pixels, avec un décalage pour être en haut à droite du marqueur
          layer.style.left = pixel.x + 10 + 'px';
          layer.style.top = pixel.y - 20 + 'px';
        };
      };

      // Fixe la carte à l'overlay et l'affiche
      overlay.setMap(map);

      // Ajouter un cercle autour de la position de l'utilisateur
      const userLocationCircle = new google.maps.Circle({
        strokeColor: '#59C09B',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#808080',
        fillOpacity: 0.35,
        map: map,
        center: center,
        radius: 2000, // Rayon de 2 km
      });
    }

    activities.forEach(activity => {
      if (activity.location && activity.location.latitude && activity.location.longitude) {
        const position = { lat: activity.location.latitude, lng: activity.location.longitude };
        const iconUrl = activitiesIconMapping[activity.topic-1] || 'default_icon_url';

        new window.google.maps.Marker({
          position,
          map,
          icon: {
            url: iconUrl,
            scaledSize: new google.maps.Size(50, 50),
          },
          title: `${activity.title} - ${activity.topic}`,
        });
      }
    });
  };

  return <div ref={mapRef} style={{ height: '100vh', width: '100%' }} />;
};

export default MapsWebActivities;
