// FR gère l'authentification des utilisateurs(FrameFigma004A,004B,004,005,006,007A,007B,007C,008,010,011,012A,012B,013,014A,014B)
// EN This script manages user authentication.(FrameFigma004A,004B,004,005,006,007A,007B,007C,008,010,011,012A,012B,013,014A,014B)

import React, {useState, useEffect, createContext, useMemo} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AuthContext = createContext();

export const useAuth = () => React.useContext(AuthContext);

export function AuthProvider({children}) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [userName, setUserName] = useState('');
  const [userToken, setUserToken] = useState('');

  useEffect(() => {
    const initAuth = async () => {
      const token = await AsyncStorage.getItem('userToken');
      const userData = await AsyncStorage.getItem('userData');
      const userId = await AsyncStorage.getItem('userId');
      const profileImage = await AsyncStorage.getItem('profileImage');
      const userName = await AsyncStorage.getItem('userName');

      setIsLoggedIn(!!token);
      if (userData) setUser(JSON.parse(userData));
      if (userId) setUserId(userId);
      if (profileImage) setProfileImage(profileImage);
      if (userName) setUserName(userName);
      if (token) setUserToken(token);
    };

    initAuth();
  }, []);

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const loginTimestamp = await AsyncStorage.getItem('loginTimestamp');
      const currentTime = new Date().getTime();
      console.log("Vérification de l'expiration du token...");
      console.log('Timestamp de connexion:', loginTimestamp);
      console.log('Timestamp actuel:', currentTime);

      // Définir le temps d'expiration à 40 heures
      const expiryTime = 40 * 60 * 60 * 1000; // 40 heures
      if (loginTimestamp && currentTime - parseInt(loginTimestamp) > expiryTime) {
        console.log('Session expirée. Déconnexion automatique...');
        logout();
      } else {
        console.log('Session toujours valide.');
      }
    };

    // Vérifier l'expiration du token plus fréquemment, par exemple toutes les 30 minutes
    const interval = setInterval(checkTokenExpiration, 30 * 60 * 1000); // Vérifier toutes les 30minutes

    checkTokenExpiration(); // Vérification initiale au démarrage

    return () => {
      console.log("Arrêt de la vérification de l'expiration du token.");
      clearInterval(interval);
    };
  }, [logout]);

  const login = async (token, userData) => {
    const timestamp = new Date().getTime().toString();
    await AsyncStorage.setItem('userToken', token);
    await AsyncStorage.setItem('userData', JSON.stringify(userData));
    await AsyncStorage.setItem('userId', userData._id);
    await AsyncStorage.setItem('profileImage', userData.profileImage);
    await AsyncStorage.setItem('userName', userData.userName);
    await AsyncStorage.setItem('loginTimestamp', timestamp);


    setIsLoggedIn(true);
    setUser(userData);
    setUserId(userData._id);
    setProfileImage(userData.profileImage);
    setUserName(userData.userName);
  };

  const logout = async () => {
    await AsyncStorage.clear();
    setIsLoggedIn(false);
    setUser(null);
    setUserId('');
    setProfileImage('');
    setUserName('');
  };

  const value = useMemo(
    () => ({
      isLoggedIn,
      login,
      logout,
      user,
      userId,
      profileImage,
      userName,
      userToken,
    }),
    [isLoggedIn, user, userId, profileImage, userName, userToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
