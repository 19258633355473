// Import Modules
import React, { useState } from "react";
import { View, Text, Pressable } from "react-native";

// Import Contexts
import { hostname } from "../../../../../../hostName/hostName";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useActivity } from "../../../../../contexts/ActivityContext";

// Import Components
import UserListHorizontal from "../../../../../components/UserListHorizontal/UserListHorizontal";
import { useLanguage } from "../../../../../contexts/LanguageContext";

const ApprovalModal = ({ activity, userList, listSelected, activityIsFull, closeModal }) => {
  const {translations} = useLanguage();
  // Local Variables
  // Soit positive soit 0, pas de negatif
  const approvalsLeft = Math.max(0, activity.attendeeLimit - (activity.attendees?.length + activity.coOrganizers?.length));
  
  // Contexts
  const { userToken } = useAuth();
  const { getActivities } = useActivity(); // Rafraichir les activités
  
  // States
  const [approved, setApproved] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [approvalsRemaining, setApprovalsRemaining] = useState(approvalsLeft);

  // Toggle Approval
  const toggleApproval = (userId) => {
    setApproved(prevState => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
    if (approvalsRemaining < 0) {
      setApprovalsRemaining(0)

    } else {
      if (approved[userId]) {
        setApprovalsRemaining(approvalsRemaining + 1);
      } else {
        setApprovalsRemaining(approvalsRemaining - 1);
      }
    }
  };

  // Send approved list
  const sendApprovedList = async () => {
    
    // Selectionner l'endpoint celui le list choisi
    let endpoint;
    if (listSelected === 1) {
      endpoint = 'co-organizers/approve';
    } else if (listSelected === 2) {
      endpoint = 'waiting-list/approve';
    }

    try {
      const response = await fetch(`${hostname}/activities/${activity._id}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        },
        body: JSON.stringify(approved)
      });
      if (response.ok) {
        setSuccess(true);
        getActivities();
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }
  return (
    <View>
      {userList.length === 0 && <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonNoUsers}</Text>}
      {!success && !error && userList.length > 0 &&
        <> 
          <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonTitleAprovalModal}</Text>

          {/* Afficher le compter si il y des approvals restant */}
          {approvalsRemaining > 0 && <Text>{approvalsRemaining} {translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonSlotsLeft}</Text>}
          
          {/* Afficher le message si l'activity est plein */}
          {approvalsRemaining === 0 && <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonActivityfull}</Text>}

          {/* Passer le list choisi */}
          <UserListHorizontal userList={userList} prop1={approved} prop2={toggleApproval} />
          
          {/* Le bouton pour confirmer - Disabled si l'activity est plein */}
          <Pressable onPress={sendApprovedList} >
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonConfirmButton}</Text>
          </Pressable>
        </>
      }
      {success || error &&
        <>
          <Text>
            {success && translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonSuccessText}
            {error && translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonErrorText}
          </Text>
          <Pressable onPress={() => {closeModal, success && setSuccess(false) || error && setError(false)}}>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonPopupMessage.JsonCloseButton}</Text>
          </Pressable>
        </>
      }
    </View>
  )
}

export default ApprovalModal