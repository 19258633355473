//  se déconnecter de l'application.
//  Log out of the application

import React, {useState} from 'react';
import {View, Text, TouchableOpacity, Modal, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation} from '@react-navigation/native';
import {useAuth} from '../../contexts/AuthContext';
import {hostname} from '../../../hostName/hostName';
import Fields from '../Fields/Fields';

// en cas de build il faut utiliser userSocketService
//import {useWebSocket} from '../../sockets/WebSocketProvider';
import {useWebSocket} from '../../sockets/WebSocketLocal';
import { useLanguage } from '../../contexts/LanguageContext';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const LogoutButton = () => {
 const {translations} = useLanguage();

  const [showModal, setShowModal] = useState(false);
  const navigation = useNavigation();
  const {handleUserLogout} = useWebSocket();
  const {logout} = useAuth();

  const handleLogout = async () => {
    try {
      const token = await AsyncStorage.getItem('userToken');
      const tokenExpiryTime = await AsyncStorage.getItem('tokenExpiryTime');
      const currentTime = new Date().getTime();

      if (currentTime > tokenExpiryTime) {
        //FR Le token a expiré
        //GB  The token has expired

        console.log('Token expiré. Déconnexion...');
        logout();
        navigation.navigate('Home');
      } else {
        handleUserLogout();
        // FR Le token n'a pas encore expiré, continuez avec la déconnexion
        // GB The token has not expired yet, proceed with the logout
        const response = await fetch(`${hostname}/logout`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          console.log('Token supprimé de AsyncStorage');
          logout();
          navigation.navigate('Home');
        } else {
          console.error('Erreur de déconnexion');
        }
      }
    } catch (error) {
      console.error('Erreur lors de la déconnexion : ', error.message);
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <TouchableOpacity onPress={() => setShowModal(true)} style={styles.logoutButton}>
        <Fields.LogOut />
        <Text style={styles.drawerItemText}>{translations.JsonDrawerNavMenu.JsonLogout}</Text>
      </TouchableOpacity>
      <Modal visible={showModal} transparent={true} animationType="slide" onRequestClose={() => setShowModal(false)}>
        <View style={styles.modal}>
          <View style={styles.modalContent}>
            <Text style={styles.modalText}>{translations.JsonDrawerNavMenu.JsonPopupMessage.JsonTitleComfirmation}</Text>
            <TouchableOpacity style={styles.modalButton} onPress={handleLogout}>
              <Text style={styles.modalButtonText}>{translations.JsonDrawerNavMenu.JsonPopupMessage.JsonYesButton}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalButton} onPress={() => setShowModal(false)}>
              <Text style={styles.modalButtonText}>{translations.JsonDrawerNavMenu.JsonPopupMessage.JsonNoButton}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default LogoutButton;
