import { StyleSheet } from "react-native";
export default StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    width: '100%',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    elevation: 5,
    height: '100%',
  },
  searchInput: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: '100%',
  },
  image: {
    width: 100,
    height: 100,
    margin: 5,
  },
  closeButton: {
    marginTop: 10,
    alignSelf: 'flex-end',
  },
  close: {
    alignSelf: 'flex-end',
  },
});