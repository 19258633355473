import React, { useState, useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AddressAutoPicker from '../../../../components/AddressAutoPicker/AddressAutoPicker';
import { generateUsername } from '../../../../components/UniqueUserName/UniqueUserName';
import { useLanguage } from '../../../../contexts/LanguageContext';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from "../../../../components/ButtonNext/ButtonNext";

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const StepThreePersonalScreen = ({ onNextStep }) => {
  const { translations } = useLanguage();
  const { language } = useLanguage();
  const { error, setError } = useError();

  const [selectedAddress, setSelectedAddress] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [generatedUserName, setGeneratedUserName] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [validationErrors, setValidationErrors] = useState(false);

  useEffect(() => {
    setIsButtonDisabled(!generatedUserName || validationErrors);
  }, [generatedUserName, validationErrors]);

  useEffect(() => {
    const generateAndSetUsername = async () => {
      try {
        const username = await generateUsername();
        setGeneratedUserName(username);
        await AsyncStorage.setItem('userName', username);
      } catch (error) {
        setError(error.message);
        console.error('Erreur lors de la génération du username :', error);
      }
    };

    generateAndSetUsername();
  }, []);

  const handleNextStep = async () => {
    if (selectedAddress.trim() === '' || generatedUserName.trim() === '') {
      setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonCityMissing);
      console.log('Champs non remplis pour le compte professionnel');
      return;
    }
    setValidationErrors({});
    onNextStep();
  };

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
    setError(''); // Reset error message
  };

  return (
    <View key={language} style={styles.container}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.addressContent}>
        <AddressAutoPicker
          onAddressSelect={handleAddressSelect}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          placeholder={translations.JsonCreateProfilePages.JsonCityPlaceholder}
        />
      </View>

      <View style={styles.btnContainer}>
        <ButtonNext
          conditionNeed={selectedAddress !== ''}
          handleNextStep={handleNextStep}
          btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
        />
      </View>
    </View>
  );
};

export default StepThreePersonalScreen;
