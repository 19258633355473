// FR affiche les activités ou interventions récentes dans le profil utilisateur
// EN displays the recent activities or interventions in a profile
// Import Modules
import React from 'react'
import { View, Text, Platform } from 'react-native'

// Import Contexts
import { useLanguage } from '../../../../../contexts/LanguageContext'

// Import Icons
import { Icons } from '../../../../../constants/Icons';

// Import Styles
const styles = Platform.OS === 'web'
  // On utilise les meme styles que pour les prochaines
  ? require('../NextActivitiesBoard/StylesWeb').default
  : require('../NextActivitiesBoard/StylesMobile').default;

const InterventionsDernieres = ({ users, userActivities }) => {
  // Contexts
  const { translations } = useLanguage();

  // Map Activities to Organisers
  const activitiesWithOrganizers = userActivities
    .filter((activity) => new Date(activity.date) < new Date())
    .map(activity => {
      const organizer = users.find(user => user._id === activity.author);
      const organizerName = organizer ? organizer.firstName : "Unknown";
      return { ...activity, organizerName };
    });

  function convertFormatDate(activityDate) {
    // Séparer le jour, le mois et l'année en utilisant "/"
    const inputDate = new Date(activityDate).toLocaleDateString()
    const parts = inputDate.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Construire la nouvelle date au format "jour.mois.année"
    const newDate = `${day}.${month}.${year.slice(-2)}`;

    return newDate;
  }

  return (
    <View style={{ flex: 1, paddingTop: 10 }}>
      <View
        style={{
          flexDirection: "column",
          // borderWidth: 1,
          borderColor: "#797B7A",
          // backgroundColor:'white',
          marginBottom: 10,
        }}
      >
        <View
          style={{
            ...styles.boxShadow,
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#797B7A",
            // backgroundColor: "#f2f2f2",
            background: "#505050",
            borderRadius: 10,
            paddingHorizontal:"2%",
          }}
        >
          <View
            style={{
              ...styles.column,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              // background: "#505050",
              borderColor: "white",
            }}
          >
            <Text
              style={{
                ...styles.column,
                width: 100,
                paddingTop: 5,
                // background: "#505050",
              }}
            >
              <Icons.Calendar width={20} height={20} />
            </Text>
          </View>
          <View
            style={{
              ...styles.column,
              flex: 2,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#505050",
              borderColor: "white",
            }}
          >
            <Text style={{ marginRight: 5, fontWeight: "bold", color: "#fff" }}>
              {translations.JsonProfilePages.JsonMyProfileScreen.JsonActivities} 
            </Text>
            <Icons.CalendarOn width={20} height={20} />
          </View>
          <View
            style={{
              ...styles.column,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: "#505050",
              borderColor: "white",
            }}
          >
            <Text style={{ ...styles.column, width: 100 }}>
              <Icons.PeopleOn width={20} height={20} />
            </Text>
          </View>
          <View
            style={{
              ...styles.column,
              width: 100,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 5,
              paddingRight: 5,
              // background: "#505050",
              borderColor: "white",
              borderRightWidth:0,
              color: "#fff",
            }}
          >
            <Text style={{ fontWeight: "bold", color: "#fff" }}>
              {translations.JsonContactScreen.JsonOrganizer}
            </Text>
          </View>
        </View>

        {/* afficher max les 5 premiers activities, dont activities les plus recents */}
        {activitiesWithOrganizers.slice(0, 5).map((activity, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderBottomWidth: 1,
              borderColor: "#797B7A",
              backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
              marginHorizontal:"2%",
            }}
          >
            <Text style={{ ...styles.column, width: 100 }}>
              {convertFormatDate(activity.date)}
            </Text>
            <View
              style={{
                ...styles.column,
                flex: 2,
                flexDirection: "row",
                alignItems: "flex-end",
                color: "#59C19C",
                textWeight: "bold",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.greenBold}>
                {/* afficher max 17 lettres  */}
                {activity.title.length > 17
                  ? activity.title.substring(0, 17) + '...'
                  : activity.title}
              </Text>
              <Text style={styles.fleche}>{'>'}</Text>
            </View>
            <Text style={{ ...styles.column, width: 100 }}>
              {activity.attendees.length}/{activity.attendeeLimit}
            </Text>
            <Text
              style={{
                ...styles.column,
                width: 100,
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingLeft: 5,
                paddingRight: 5,
                borderColor: 'white',
              }}
            >
              {activity.organizerName}

            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default InterventionsDernieres