// FR sélectionner une adresse automatiquement à partir de suggestions (FrameFigma041,FrameFigma031,FrameFigma032A,FrameFigma035A)
// EN Automatically select an address from suggestions (FrameFigma041,FrameFigma031,FrameFigma032A,FrameFigma035A)
import React, {useState, useEffect} from 'react';
import {View, Platform, TextInput, ScrollView, Text} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Fields from '../Fields/Fields';
import {GOOGLE_MAPS_API_KEY} from '@env';
import TextInputCustom from '../TextInputCustom/TextInputCustom';

// Import Contexts
import {hostname} from '../../../hostName/hostName';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const AddressAutoPicker = ({onAddressSelect, selectedAddress, setSelectedAddress, placeholder, addressType}) => {
  const [addressSelected, setAddressSelected] = useState(false);
  const [query, setQuery] = useState(selectedAddress || '');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (query.length > 1 && !addressSelected) {
        try {
          const apiUrl =
            Platform.OS === 'web'
              ? `${hostname}/api/places/autocomplete?input=${encodeURIComponent(query)}`
              : `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(
                  query,
                )}&key=${GOOGLE_MAPS_API_KEY}&language=en`;

          const response = await fetch(apiUrl);
          const data = await response.json();
          if (response.ok && !addressSelected) {
            setSuggestions(data.predictions);
          } else {
            setSuggestions([]);
          }
        } catch (error) {
          console.error('Failed to fetch address suggestions:', error);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchAddresses();
  }, [query, addressSelected]);

  const handleSuggestionSelect = async selectedSuggestion => {
    const apiUrl =
      Platform.OS === 'web'
        ? `${hostname}/api/places/details?placeid=${selectedSuggestion.place_id}`
        : `https://maps.googleapis.com/maps/api/place/details/json?placeid=${selectedSuggestion.place_id}&key=${GOOGLE_MAPS_API_KEY}&language=ar`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok) {
        const {lat, lng} = data.result.geometry.location;
        await AsyncStorage.setItem('city', selectedSuggestion.description);
        await AsyncStorage.setItem('latitude', JSON.stringify(lat));
        await AsyncStorage.setItem('longitude', JSON.stringify(lng));

        setQuery(selectedSuggestion.description);
        setAddressSelected(true);
        setSelectedAddress(selectedSuggestion.description);
        onAddressSelect(selectedSuggestion.description, lat, lng);
        setSuggestions([]);
      } else {
        console.error('No location found for the selected place');
      }
    } catch (error) {
      console.error('Failed to fetch place details:', error);
    }
  };

  const handleTextChange = text => {
    setQuery(text);
    setSelectedAddress('');
    setAddressSelected(false);
  };

  return (
    <View>
      <TextInputCustom
        Icon={Fields.IconCity}
        placeholderText={placeholder}
        handleTextChange={handleTextChange}
        value={query}
        isPlaceHolderTextAlignCenter={false}
        multiline={addressType === 'activity'}
        numberOfLines={addressType === 'activity' ? 2 : 1}
      />
      {query.length > 0 && suggestions.length > 0 && !addressSelected && (
        <ScrollView style={styles.suggestionsContainer}>
          {suggestions.map((suggestion, index) => (
            <Text key={index} onPress={() => handleSuggestionSelect(suggestion)} style={styles.suggestion}>
              {suggestion.description}
            </Text>
          ))}
        </ScrollView>
      )}
    </View>
  );
};

export default AddressAutoPicker;



