import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  column: {
      flex: 1,
      borderRightWidth: 1,
      borderColor: "#000",
      textAlign: "center",
    },
    greenBold:{
      color:'#59C19C',
      fontWeight:'bold',
      paddingLeft: '5%',
    },
    boxShadow:{
      zIndex: 2,
      boxShadow: "0px 4px 4px 0px #00000040",
    },
    fleche:{
      color: '#59C19C',
      fontWeight: 'bold',
      right: 3,
    }
})

export default styles;