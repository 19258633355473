// ProgressBar.js
import React from 'react';
import {View, StyleSheet} from 'react-native';

// Assuming you have or will create a StyleSheet object named styles
const styles = StyleSheet.create({
  progressBarContainer: {
    height: 5,
    width: '60%',
    backgroundColor: 'grey',
    borderRadius: 5,
    marginTop: 20,
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#59C09B',
    borderRadius: 5,
  },
});

const ProgressBar = ({progress}) => (
  <View style={styles.progressBarContainer}>
    <View style={[styles.progressBar, {width: `${progress * 100}%`}]} />
  </View>
);

export default ProgressBar;
