import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  card: {
    alignItems: 'center',
    gap: 2,
    overflow:'hidden',
    marginBottom:10,
  },
  profileImage: {
    width: 70,
    height: 70,
    borderRadius: 100,
    borderWidth: 5,
    borderColor: '#DAE08D',
  },
  profileImageFriend: {
    borderColor: '#65B3EA',
  },
  nameMale: {
    color: '#001AFF',
    fontWeight: 'bold',
  },
  nameFemale: {
    color: '#FF13CB',
    fontWeight: 'bold',
  },
});