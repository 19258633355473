/* Calculation
Sponsorships----------------Points
0-9 No Star                 0-499 None
10-19 Gold                  500-999 Copper 1   
20-49 Ruby                  1000-1999 Copper 2
50-99 Emerald               2000-4999 Silver 1
100 Diamond                 5000-9999 Silver 2
None                        10000-49999 Master
None                        50000 Grand Master
*/

// Import Modules
import React from 'react';

// Import Components
import NoStarMedals from './NoStarMedals/NoStarMedals';
import RubyStarMedals from './RubyStartMedals/RubyStarMedals';
import GoldStarMedals from './GoldStartMedals/GoldStarMedals';
import EmeraldStarMedals from './EmeraldStartMedals/EmeraldStarMedals';
import DiamondStarMedals from './DiamondStarMedals/DiamondStartMedals';

const ProfileMedal = ({role, points}) => {
  switch (role) {
    case 'diamondUser':
      return <DiamondStarMedals points={points} />;
    case 'emeraldUser':
      return <EmeraldStarMedals points={points} />;
    case 'rubyUser':
      return <RubyStarMedals points={points} />;
    case 'goldUser':
      return <GoldStarMedals points={points} />;
    case 'basicUser':
    default:
      return <NoStarMedals points={points} />;
  }
};

export default ProfileMedal;