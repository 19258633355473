//🇫🇷 Ce fichier gère le style mobile du composant HomeScreen.js (FrameFigma001 FrameFigma003)
//🇬🇧 This file manages the mobile style of the HomeScreen.js component (FrameFigma001 FrameFigma003)
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },
  overlay: {
    flex:1,
    width:'100%',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Noir avec une opacité de 50%
  },
  msgContainer:{
    backgroundColor:'#313131',
    width:'100%',
    paddingVertical:'3%',
  },
  msgText:{
    color:'#82D4BB',
    fontSize:16,
    textAlign:'center'
  },
  logo: {
    width: 340, // optimized for mobile
    height: 220, 
    marginBottom: 60,
  },
  content: {
    alignItems: 'center',
    //style for start text
    // backgroundColor: 'rgba(0, 0, 0, 0.65)',
    // marginBottom: '90%', 
    padding: 20,
    width: '100%', 
    marginBottom: '75%',
  },
  contentText:{
    color: 'white',
    fontSize: 26, 
    fontWeight: 'bold',
    marginBottom:-5,
  },
  btnContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop:'5%'
  },
  btnStart: {
    //style for start text
    // backgroundColor: 'white',
    // padding: 12,
    // borderRadius: 20,
    paddingHorizontal: '12%',
    // marginHorizontal: 40,
    width:'65%',
    alignItems:'center',
  },
  btnText: {
    color: 'black',
    fontSize: 22,
    fontWeight: 'bold',
  },
  condition: {
    marginTop:'55%',
    width: '90%',
    flexDirection: 'row',
    flexWrap:'wrap',
    justifyContent: 'center',
    alignItems:'center'
  },
  terms: {
    fontSize: 13,
    color:'white',
    marginLeft: 4,
  },
  termsBold: {
    fontSize: 13,
    fontWeight:'bold',
    marginLeft: 4,
    color:'white',
  },
});
