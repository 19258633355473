// Fr une page de mise à jour d'application #Frame Figma 061
// En a frame 061 application update page   #Figma Frame 061

import { View, Text, Image } from 'react-native'
import React from 'react'
import styles from './StylesWeb'
import Logo from '../../assets/Images/General/LogoSocializus.png';
import { Images } from "../../constants/Images.js";
// import { useLanguage } from '../../utils/LanguageContext';
import { useLanguage } from '../../contexts/LanguageContext.js'

const UpdateScreen = () => {
  const { translations } = useLanguage();
  const { language } = useLanguage();

  return (
    <View key={language} style={styles.container}>
      <Image source={Logo} style={styles.logo}/>
      <Text style={styles.update}>{translations.JsonUpdateScreen.JsonDontForgetUpdate.toUpperCase()}</Text>
      <View style={styles.appStore}>
      <Images.SocialNetworksAppStore/>
      </View>
      <View style={styles.googlePlay}>
      <Images.SocialNetworksGooglePlay/>
      </View>
      
      
    </View>
  )
}

export default UpdateScreen