//FR  permet à un utilisateur de rejoindre ou de quitter une activité 
//EN  Allows a user to join or leave an activity.

// Import Modules
import AsyncStorage from '@react-native-async-storage/async-storage';

// Import Contexts
import { hostname } from '../../hostName/hostName';

export const joinActivity = async (activityId, userId, inWaitingList=false) => {
  const token = await AsyncStorage.getItem('userToken');
  
  // Update Logged In User
  const response = await fetch(`${hostname}/activities/${activityId}/participate`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      inWaitingList
    }),
  });
  if (response.ok && inWaitingList) {
    console.info("You joined the Waiting List")
  } else {
    console.info("You joined this activity");
  }
}

export const leaveActivity = async (activityId, userId, inWaitingList=false) => {
  const token = await AsyncStorage.getItem('userToken');

  const response = await fetch(`${hostname}/activities/${activityId}/participate`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userId,
      inWaitingList
    }),
  });
  if (response.ok && inWaitingList) {
    console.info("You left the Waiting List")
  } else {
    console.info("You left this activity");
  }
}