//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷 Ce fichier gère la page qui affiche les activités qu'il a déjà faites ou ce qu'il fera du profil. (FrameFigma072)
// 🇬🇧 This file manage the page that displays the activities he has already done or will do with the profile.   (FrameFigma072)

// Import Modules
import React from 'react'
import { ScrollView, View, Text, Platform } from 'react-native'

// Import Components
import NextActivitiesBoard from './NextActivitiesBoard/NextActivitiesBoard';
import LastActionBoard from './LastActionBoard/LastActionBoard';
import { activitiesIcon } from '../../../../components/Fields/ActivityListIcon';

// Import Contexts
import { useLanguage } from '../../../../contexts/LanguageContext';

// Import Styles
const styles = Platform.OS === 'web'
  ? require('./Styles.web').default
  : require('./Styles.mobile').default;

const ProfileActivitiesScreen = ({ currentUser, users, userActivities }) => {
  // Contexts
  const { translations } = useLanguage();

  // Map Activities to Icons
  const userActivityIcons = activitiesIcon.filter((icon) => {
    return userActivities.some((activity) => activity.topic === icon.id - 1);
  })

  const trans = translations.JsonProfilePages.JsonMyProfileScreen;

  return (
    <ScrollView>
      <View style={styles.container}>
        <View>
          <Text style={{ fontSize: 16, fontWeight: "bold"}}>
            Les prochaines activités de {currentUser.firstName ? currentUser.firstName : 'unknown'}
          </Text>
          <NextActivitiesBoard users={users} allActivities={userActivities}/>
        </View>
        <View>
          <Text style={{ fontSize: 16, fontWeight: "bold"}}>
            Les dernieres interventions de {currentUser.firstName ? currentUser.firstName : 'unknown'}
          </Text>
          <LastActionBoard users={users} allActivities={userActivities}/>
        </View>
      </View>
      <View style={styles.greenBar}>
        <Text style={styles.greenBarText}>{trans.JsonActivitiesDone}:</Text>
      </View>
      <View style={styles.bottomIcons}>
        {userActivityIcons.map((icon) => (
          <View key={icon.id}>
            {icon.activityIconOn}
          </View>
        ))}
      </View>
    </ScrollView>
  )
}

export default ProfileActivitiesScreen