import React, {useCallback, useState, useEffect} from 'react';
import {useFocusEffect} from '@react-navigation/native';
import MessageScreen from '../../pages/MessagesScreen/MessagesScreen';
import {useChatModal} from '../../contexts/ChatModalContext';
import {useWebSocket} from '../../sockets/WebSocketLocal';
import {  useUser } from '../../contexts/UserContext';
const MessageTabWrapper = () => {
  const {fetchUserChats, isFetchingUserChats, userChats, displayChatHistory} = useWebSocket();
  const {isChatModalVisible, chatDetails, openChatModal, closeChatModal, privateChatMessages} = useChatModal(); // Assurez-vous d'avoir ces états dans votre contexte

  const [hasFetchedChats, setHasFetchedChats] = useState(false);
  const [formattedChats, setFormattedChats] = useState([]);
  const {allUsers, user} = useUser();
  console.log('user', user);

  useFocusEffect(
    useCallback(() => {
      if (!hasFetchedChats && !isFetchingUserChats) {
        console.log('Début de la récupération des chats utilisateur...');
        fetchUserChats();
        setHasFetchedChats(true);
      }
    }, [fetchUserChats, hasFetchedChats, isFetchingUserChats, displayChatHistory, privateChatMessages]),
  );
  console.log(allUsers);
  useEffect(() => {
    if (userChats.length > 0 && allUsers.length > 0 && user) {
      const currentUserId = user._id; // ID de l'utilisateur actuel

      const formattedChats = userChats.map(chat => {
        const lastMessage = chat.messages[chat.messages.length - 1] || {};
        // Identifier le destinataire comme étant le participant qui n'est pas l'utilisateur actuel
        const recipientId = chat.participants.find(p => p._id !== currentUserId)?._id;

        // Trouver les détails du destinataire dans allUsers
        const recipientDetails = allUsers.find(user => user._id === recipientId) || {
          firstName: 'Utilisateur',
          lastName: 'Inconnu',
          profileImage: 'default_image_path',
        };

        // Construire le nom complet du destinataire
        const fullName = `${recipientDetails.firstName} ${recipientDetails.lastName}`;

        return {
          chatId: chat._id,
          userName: fullName, // Utiliser le nom complet du destinataire
          profileImage: recipientDetails.profileImage,
          lastMessage: lastMessage.content || 'Aucun message',
          lastMessageTimestamp: lastMessage.timestamp || '',
        };
      });

      setFormattedChats(formattedChats);
    }
  }, [userChats, allUsers, user]); // Inclure `user` dans les dépendances pour réagir aux changements de l'utilisateur actuel

  console.log("Chats formatés pour l'affichage:", formattedChats);

  return (
    <MessageScreen
      userChats={formattedChats}
      currentUser={user}
      openChatModal={openChatModal}
      closeChatModal={closeChatModal}
      isChatModalVisible={isChatModalVisible}
      chatDetails={chatDetails}
      privateChatMessages={privateChatMessages}
    />
  );
};
export default MessageTabWrapper;
