// Styles Web pour ProfileActivitiesScreen
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    gap: 20,
  },
  greenBar: {
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 25,
  backgroundColor: '#59C09B',
  },
  bottomIcons: {
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  }
})

export default styles;