//Fr styles de RequestCoOrganiser (FrameFigma035A,FrameFigma035B)
//En styles of RequestCoOrganiser (FrameFigma035A,FrameFigma035B)

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'left',
  },
  innerContainer: {
    gap: 10,
    marginVertical: 20,
  },
  btnContainer: {
    flexDirection: 'row',
    gap: 10,
    flexWrap: 'wrap',
  },
  buttonItem: {
    height: 110,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  }
})

export default styles;