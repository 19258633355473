// FR l'écran de la première étape de la modification de profil.
// EN  the first step screen of the profile modification

import React, { useState, useEffect } from 'react';
import { View, ScrollView, Image, TextInput, TouchableOpacity, Text, Pressable, Platform } from 'react-native';
import Fields from '../../../../components/Fields/Fields';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import ProfileImagePickerModal from '../../../../components/ImagePicker/ProfileImagePickerModal';
import { useUser } from '../../../../contexts/UserContext';
import { useNavigation } from '@react-navigation/native';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom'
import countriesListSquareFlags from '../../../../components/LanguagesFlags/LanguagesFlagsSquare';
import { Picker } from '@react-native-picker/picker';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext'
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useError } from '../../../../contexts/ErrorContext'; // Import useError hook

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditProfileStep1Screen = ({ onNextStep }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { user, fetchUser, updateUser, updateUserProfileImage, fetchUserDetails } = useUser();
  const { changeLanguage } = useLanguage();
  const { error, setError } = useError(); // Initialize error and setError from useError hook
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const navigation = useNavigation();
  const [progress, setProgress] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [displayLanguage, setDisplayLanguage] = useState('');
  const [selectedFlag, setSelectedFlag] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [formDataBefore, setFormDataBefore] = useState('');
  useEffect(() => {
    // Initialisez avec une langue par défaut si nécessaire
    const defaultLanguage = countriesListSquareFlags[0];
    setSelectedLanguage(defaultLanguage.language);
    setSelectedFlag(defaultLanguage.FlagComponent);
    const loadLanguage = async () => {
      const displayLanguageGetted = (await AsyncStorage.getItem('selectedLanguage'));
      setDisplayLanguage(displayLanguageGetted)
    };
    loadLanguage();
  }, []);
  const [formData, setFormData] = useState({
    profileImage: '',
    accountType: '',
    firstName: '',
    userName: '',
    city: '',
    nativeLanguage: '',
    appDisplayLanguages: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        profileImage: user.profileImage || '',
        accountType: user.accountType || '',
        firstName: user.firstName || '',
        userName: user.userName || '',
        city: user.city || '',
        nativeLanguage: user.nativeLanguage || '',
        appDisplayLanguages: user.appDisplayLanguages || user.nativeLanguage, //si il n'a pas choisi pour 1er fois, alors appliDisplayedLanguage par default est son language maternelle
      });
      setFormDataBefore({
        profileImage: user.profileImage || '',
        accountType: user.accountType || '',
        firstName: user.firstName || '',
        userName: user.userName || '',
        city: user.city || '',
        nativeLanguage: user.nativeLanguage || '',
        appDisplayLanguages: user.appDisplayLanguages || user.nativeLanguage, //si il n'a pas choisi pour 1er fois, alors appliDisplayedLanguage par default est son language maternelle
      });
    } else {
      fetchUser();
    }
  }, [user, fetchUser]);

  const openImagePickerModal = () => {
    setIsLoading(true);
    setModalVisible(true);
  };

  const { translations } = useLanguage();

  const onImageSelectedHandler = async selectedImage => {
    if (selectedImage && selectedImage.file) {
      // Créez une URL pour l'aperçu de l'image
      const imageUrl = URL.createObjectURL(selectedImage.file);

      // Mettez à jour formData pour l'aperçu de l'image
      setFormData(prevFormData => ({
        ...prevFormData,
        profileImage: imageUrl, // Mise à jour pour l'aperçu
      }));

      // Stockez le fichier d'image pour l'envoi
      setProfileImageFile(selectedImage.file);
      setIsChanged(formData.profileImage !== formDataBefore.profileImage)
    }
    setIsLoading(false);
    simulateProgress();
  };

  const simulateProgress = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress(currentProgress => {
        const newProgress = currentProgress + 0.1;
        if (newProgress >= 1) {
          clearInterval(interval);
          setIsLoading(false);
          return 1;
        }
        return newProgress;
      });
    }, 100);
  };
  const handleInputChange = (key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [key]: value,
    }));
    setIsChanged(value !== formDataBefore[key])
  };
  const handleNativeLanguageChange = language => {
    setFormData(prevFormData => ({
      ...prevFormData,
      nativeLanguage: language,
    }));
    setIsChanged(language !== formDataBefore.nativeLanguage)
  };

  const handleDisplayLanguageChange = async language => {
    setFormData(prevFormData => ({
      ...prevFormData,
      appDisplayLanguages: language,
    }));
    setIsChanged(language !== formDataBefore.appDisplayLanguages)
    try {
      await AsyncStorage.setItem('selectedLanguage', language);
      const displayLanguageGetted = (await AsyncStorage.getItem('selectedLanguage'));
      setDisplayLanguage(displayLanguageGetted)
    } catch (error) {
      setError(error.message); // Set error using setError from useError hook
      console.error('Échec de la récupération des détails du lieu', error);
    }
  };
  const selectedNativeLanguageFlag = countriesListSquareFlags.find(lang => lang.bigram === formData.nativeLanguage)?.FlagComponent;
  const selectedDisplayLanguageFlag = countriesListSquareFlags.find(lang => lang.bigram === displayLanguage)?.FlagComponent;
  const handleSubmit = async () => {
    // Vérifier si les champs obligatoires sont vides
    if (!formData.firstName || !formData.userName || !formData.city ) {
    setError("Il faut remplir tous les champs.");
    return;
  }
    setIsLoading(true);
    try {
      // Mise à jour des informations de l'utilisateur sans l'image de profil
      await updateUser(user._id, formData);

      // Mise à jour de l'image de profil séparément si une image a été sélectionnée
      if (profileImageFile) {
        await updateUserProfileImage(user._id, profileImageFile);
      }

      console.log('User updated successfully');
      fetchUserDetails(); // Rafraîchir les informations de l'utilisateur

      changeLanguage(formData.appDisplayLanguages);
      navigation.navigate('MyScreen', { userData: user, userId: user._id });
    } catch (error) {
      setError(error.message); // Set error using setError from useError hook
      console.error('Error updating user:', error);
    } finally {
      setIsLoading(false);
      setIsChanged(false)
    }
  };

  const handleAccountTypeChange = accountType => {

    setFormData(prevFormData => ({
      ...prevFormData,
      accountType: accountType,
    }));
  }


  // creation dui handleContinue
  const handleContinue = () => {
    onNextStep();
  };
  return (
    <ScrollView style={styles.editProfileContainer}>
      {error && (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      )}
      <View style={styles.profileImageContainer}>
        <View>
          <Pressable onPress={openImagePickerModal}>
            {formData.profileImage ? <Image key={formData.profileImage} source={{ uri: formData.profileImage }} style={styles.image} /> : <Fields.IconNoGenderNormalSize />}
          </Pressable>
        </View>
        {isLoading && <ProgressBar progress={progress} />}
        <View style={styles.camera}>
          <Pressable onPress={openImagePickerModal}>
            <Fields.IconCamera />
          </Pressable>
        </View>
      </View>
      <ProfileImagePickerModal onImageSelected={onImageSelectedHandler} modalVisible={modalVisible} setModalVisible={setModalVisible} />
      <View style={styles.compte}>
        <TouchableOpacity onPress={() => handleAccountTypeChange('personal')} style={[styles.ButtonPersonelCompte, formData.accountType === 'personal' && { backgroundColor: '#59C09B' }]}>
          <Text style={styles.textButtonPersonelCompte}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonPersonal}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleAccountTypeChange('professional')} style={[styles.ButtonProfessionelCompte, formData.accountType === 'professional' && { backgroundColor: '#59C09B' }]}>
          <Text style={styles.textButtonProfessionelCompte}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonPro}</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.userForm}>
        {/* Dynamic Form Fields */}
        <View style={styles.inputContainer}>
          {/* quand value of input(ici formData.firstName) n'est pas vide, affiche label, sinon rien afficher comme sur figama*/}
          {formData.firstName && <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonFirstName}</Text>}
          <TextInputCustom
            Icon={Fields.IconLastName}
            placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonFirstName}
            value={formData.firstName}
            handleTextChange={text => handleInputChange('firstName', text)}
            isPlaceHolderTextAlignCenter={false}
          />
        </View>

        <View style={styles.inputContainer}>
          {formData.userName && <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNickname}</Text>}
          <TextInputCustom
            Icon={Fields.IconLastName}
            placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNickname}
            value={formData.userName}
            handleTextChange={text => handleInputChange('userName', text)}
            isPlaceHolderTextAlignCenter={false}
          />
        </View>

        <View style={styles.inputContainer}>
          {formData.city && <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonActivityAroundYourCity}</Text>}
          <TextInputCustom Icon={Fields.IconCity} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonActivityAroundYourCity} value={formData.city} handleTextChange={text => handleInputChange('city', text)} isPlaceHolderTextAlignCenter={false} />
        </View>

        <View style={styles.pickerContainer}>
          {/* combiner 2 json puis comme ya majuscule au debut pour 2eme json, j'ai mis en minuscule */}
          <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNativeLanguagePlaceholder + " " + translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonLanguageVisibleOnYourProfile.charAt(0).toLowerCase() + translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonLanguageVisibleOnYourProfile.slice(1)}</Text>
          <View style={styles.containerWithIcon}>
            <View style={styles.icon}><Fields.IconLanguage /></View>
            <View style={styles.containerWithFlags}>
              <Picker selectedValue={formData.nativeLanguage} onValueChange={handleNativeLanguageChange} style={styles.pickerStyle}>
                {countriesListSquareFlags.map(({ language, bigram }) => (
                  <Picker.Item key={bigram} label={language} value={bigram} />
                ))}
              </Picker>
              {selectedNativeLanguageFlag && React.createElement(selectedNativeLanguageFlag, { style: styles.flagStyle })}
            </View>
          </View>
          {/* Afficher le drapeau de la langue native sélectionnée */}

        </View>

        <View style={styles.pickerContainer}>
          <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonAppLanguageDisplay}</Text>
          <View style={styles.containerWithIcon}>
            <View style={styles.icon}><Fields.IconLanguage /></View>
            <View style={styles.containerWithFlags}>
              <Picker selectedValue={displayLanguage} onValueChange={handleDisplayLanguageChange} style={styles.pickerStyle}>
                {countriesListSquareFlags.map(({ language, bigram }) => (
                  <Picker.Item key={bigram} label={language} value={bigram} />
                ))}
              </Picker>
              {/* Afficher le drapeau de la langue parlée sélectionnée */}
              {selectedDisplayLanguageFlag && React.createElement(selectedDisplayLanguageFlag, { style: styles.flagStyle })}
            </View>
          </View>
        </View>
      </View>
      <View style={styles.btnsRow}>
        <View style={styles.ButtonContainer}>
          <ButtonNext
            conditionNeed={isChanged}
            handleNextStep={handleSubmit}
            btnText={translations.JsonProfilePages.JsonEditProfileScreen.JsonSaveButton} />
        </View>
        <View style={styles.ButtonContainer}>
          <ButtonNext
            handleNextStep={handleContinue}
            btnText={translations.JsonProfilePages.JsonEditProfileScreen.JsonContinueButton} />
        </View>
      </View>
      {/* Profile Image Picker Modal */}
    </ScrollView>
  );
};

export default EditProfileStep1Screen