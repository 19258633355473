import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    width: '100%',
    paddingTop: '8%',
  },
  addressContent: {
    width:'80%',
  },
  nicknameContent: {
    // marginTop: '20%',
    alignItems: 'center',
    zIndex: -5,
  },
  nicknameContainer: {
    width: '80%',
    zIndex: -5,
  },
  nickNameMessage :{
  //  padding:'10%',
    textAlign: "center",
    marginTop: 15,
    marginBottom: 1,
    marginHorizontal:2
  },
  btnContainer: {
    width: '80%',
    alignItems: 'center',
    marginTop: '1%',
    zIndex: -5,
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  comment: {
    marginTop: 10,
    fontSize: 12,
    width: '60%',
  },
  nickName: {
    // flexDirection: 'row',
    // alignItems: 'center',
    width: '100%',
  },
});
