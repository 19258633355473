import React from 'react';
import {View, Text, Pressable, Linking} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';

const InviteByLink = () => {
  const {translations} = useLanguage();

  const inviteByLink = () => {
    const link = 'https://install.socializus.com/';
    Linking.openURL(link);
  };

  return (
    <View>
      <Pressable onPress={inviteByLink}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteWithLink}</Text>
      </Pressable>
    </View>
  );
};

export default InviteByLink;
