import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  profile: {
    backgroundColor: 'white',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  pictureCameraContainer: {
    width: '40%',
    height: '20%'
    // backgroundColor:'yellow'
  },
  picture: {
    marginTop:"15%",
    position: 'relative',
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  camera: {
    marginTop:"13%",
    position: 'absolute',
    top: 80,
    right: '37%',
  },
  textView: {
    width: '100%',
    marginTop: '10%',
    paddingHorizontal: '5%',
    paddingVertical: '1%',
    alignItems: 'center',
    backgroundColor: 'red'
  },
  text: {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
  },
  btnContainer: {
    width: '40%',
    alignItems: 'center',
    marginTop: '5%',
  },
  btnSave: {
    backgroundColor: '#59C09B',
    width: '30%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});
