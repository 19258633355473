import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1000,
  },
  background: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Couleur de fond semi-transparente
  },
  drawerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '80%',
    maxHeight: '88%',
    backgroundColor: '#fff',
    borderBottomRightRadius: 20,
    boxShadow: '-2px 0px 3px rgba(0, 0, 0, 0.5)',
    zIndex: 1001,
  },
  drawerHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 10,
    backgroundColor: '#59C09B',
    height: '14.2%',
  },
  iconContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '10%',
  },
  drawerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 5,
    width: '100%',
    marginLeft: 20,
    marginTop: 20,
    padding: 10,
  },
  drawerItemText: {
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 23,
  },
  closeButton: {
    borderRadius: '60%',
  },
  closeButtonText: {
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 22,
  },
  profileImage: {
    width: 64,
    height: 64,
    borderRadius: 50,
  },
  userInfosContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between', // Alignement des éléments à l'extrémité
    flex: 1, // Utiliser tout l'espace disponible
    paddingLeft: 10, // Ajout de marge à gauche pour l'espacement
  },
  trophyContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end', // Alignement à droite
    paddingRight: 10, // Ajout de marge à droite pour l'espacement
  },
  userNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userNameMale: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'blue',
  },
  userNameFemale: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#ff13cb',
  },
  userOnlineContainer: {
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 'bold',
  },
  logoutBtn: {
    marginLeft: 20,
    padding: 10,
    marginBottom: '-30%',
    backgroundColor: '#8A8A8A',
    borderRadius: 15,
  },
  trophyContainer: {
    alignItems: 'center',
  },
  textPoint: {
    fontSize: 15,
    fontWeight: 'bold',
    color: 'black',
  },

});
