//FR  un écran de modification de profil
//EN  Edit Profile screen, likely for a mobile
import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, StyleSheet, Platform } from 'react-native';
import DatePicker from '../../../../components/DateTimePicker/DatePicker/DatePicker';
import PhoneWebPicker from '../../../../components/PhonePicker/PhoneWebPicker/PhoneWebPicker';
import PhoneMobilePicker from '../../../../components/PhonePicker/PhoneMobilePicker/PhoneMobilePicker';
import { Images } from '../../../../constants/Images';
import { useUser } from '../../../../contexts/UserContext';
import { useNavigation } from '@react-navigation/native';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
// import Email from '../../../../assets/Icons/InviteFriends/Email';
import { Icons } from '../../../../constants/Icons';
import { useLanguage } from '../../../../contexts/LanguageContext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom'
import Fields from '../../../../components/Fields/Fields';
import CustomDatePicker from './CustomDatePicker';

// Assurez-vous que les styles correspondent à vos besoins
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const EditProfileStep2Screen = ({ onNextStep }) => {
  const { user, updateUser, fetchUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [addAsFriend, setAddAsFriend] = useState(false);
  const navigation = useNavigation();
  const { translations } = useLanguage();

 let PhonePicker;
 if (Platform.OS === 'web') {
   PhonePicker = require('../../../../components/PhonePicker/PhoneWebPicker/PhoneWebPicker').default;
 } else {
   PhonePicker = require('../../../../components/PhonePicker/PhoneMobilePicker/PhoneMobilePicker').default;
 }

  const [formData, setFormData] = useState({
    lastName: '',
    phone: '',
    dateOfBirth: '',
    email: '',
    // Ajouter les champs de confidentialité dans l'état initial
    useMyPhone: false,
    useMyLastName: false,
    useMyBirthday: true,
  });
  let initialState= formData.dateOfBirth;

  useEffect(() => {
    if (user) {
      setFormData({
        lastName: user.lastName || '',
        phone: user.phone || '',
        dateOfBirth: user.dateOfBirth ? new Date(user.dateOfBirth).toISOString().split('T')[0] : '',
        email: user.email || '',
        useMyPhone: user.useMyPhone || false,
        useMyLastName: user.useMyLastName || false,
        useMyBirthday: user.useMyBirthday || true,
      });
      setIsSelected({
        ...isSelected,
        phone: user.useMyPhone || false, // Assurez-vous que cette clé est 'phone' en minuscule
        lastName: user.useMyLastName || false,
        birthday: user.useMyBirthday || true,
      });
    }
  }, [user]);

  const [isSelected, setIsSelected] = useState({
    email: true, // Cela semble être non utilisé pour la confidentialité
    phone: false,
    lastName: false,
    birthday: false,
    memberNumber: true, // Cela semble être non utilisé pour la confidentialité
  });

  const toggleSelection = key => {
    setIsSelected(prevState => ({ ...prevState, [key]: !prevState[key] }));

    // Mettre à jour les champs de confidentialité dans formData basé sur la sélection
    if (key === 'phone') setFormData(prevState => ({ ...prevState, useMyPhone: !prevState.useMyPhone }));
    else if (key === 'lastName') setFormData(prevState => ({ ...prevState, useMyLastName: !prevState.useMyLastName }));
    else if (key === 'birthday') setFormData(prevState => ({ ...prevState, useMyBirthday: !prevState.useMyBirthday }));
  };

  const handleInputChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handlePhoneNumberChange = phone => {
    setFormData(prevState => ({ ...prevState, phone }));
  };

  const handleSave=(date)=>{
    setFormData(prevState => ({ ...prevState, dateOfBirth: date.toString().split('T')[0] }));
  }
  const handleDateChange = date => {
    setFormData(prevState => ({ ...prevState, dateOfBirth: date.toISOString().split('T')[0] }));
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    const updatedUserData = {
      ...user, // inclut toutes les données existantes
      ...formData, // applique les modifications faites dans l'étape 2
    };

    try {
      await updateUser(user._id, updatedUserData);
      console.log('User updated successfully');
      fetchUser(); // Rafraîchir les informations de l'utilisateur après la mise à jour
      navigation.navigate('MyScreen', {userId: user._id});
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleContinue = () => {
    console.log('Continue to the next step');
    onNextStep();
  };
  const handleAddFriend=()=>{
    if(addAsFriend){
      setAddAsFriend(false)
    }else if(!addAsFriend){
      setAddAsFriend(true)
    }
  }
  const [isVisible, setIsVisible] = useState(false);
  const [country, setCountry] = useState('fr');

  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.logoConfidentiality}>
        <Images.ProfilesConfidential width="85" height="85" />
        <Text style={styles.textConfidential}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonWarning}</Text>
      </View>
      <View style={styles.label}>
        <Text style={styles.labelText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonYourMembershipIs}</Text> <Text style={styles.memberId}> {user?.memberId}</Text>
      </View>
      <View style={styles.inputContainer}>
        {formData.lastName && <Text style={styles.inputDesc}>{translations.JsonCreateProfilePages.JsonLastNamePlaceholder}</Text>}
        <TextInputCustom
          multiline={true}
          Icon={Fields.IconLastName}
          placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonLastnamePlaceholder}
          value={formData.lastName}
          handleTextChange={text => handleInputChange('lastName', text)}
          isPlaceHolderTextAlignCenter={false}
        />
        <Text style={styles.inputDesc}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumberDescription}</Text>
        <PhonePicker value={formData.phone} onPhoneNumberChange={handlePhoneNumberChange} country={country}/>
        <Text style={styles.inputDesc}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonBirthday}</Text>
        <CustomDatePicker 
        handleSave={handleSave}
        isVisible={isVisible}
        // onDateChange={handleDateChange}
        initialDate={formData.dateOfBirth}
          // currentValue={selectedDate}
          // value={selectedDate} 
          // onChange={handleDateChanger} 
        />
        {formData.email && <Text style={styles.inputDesc}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail}</Text>}
        <TextInputCustom
          Icon={Fields.IconArobase}
          placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmailPlaceholder}
          value={formData.email}
          handleTextChange={text => handleInputChange('email', text)}
          isPlaceHolderTextAlignCenter={false}
        />
      </View>

      <View style={styles.Confidentiality}>
        <Text style={styles.labelText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonAgeDisplayed}</Text>
        <SwitchBtn state={formData.useMyBirthday} setState={value => setFormData(prevState => ({...prevState, useMyBirthday: value}))} />
      </View>
      <View style={styles.Confidentiality}>
        <Text style={styles.labelText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonChooseMember}</Text>
        <SwitchBtn state={addAsFriend} setState={handleAddFriend} />
      </View>
      {/* affichage confitionnel */}
      {addAsFriend && (
        <View style={styles.userChoice}>
          {/* Ligne 1 */}
          <View style={styles.buttonRow}>
            {/* Bouton Email */}
            <TouchableOpacity onPress={() => toggleSelection('email')} style={[styles.button, isSelected.email ? styles.buttonSelected : styles.buttonNotSelected]}>
              <View style={styles.iconsContainer}>
                <Icons.Email style={styles.icons} />
              </View>
              <Text style={[styles.buttonText, isSelected.email ? styles.buttonTextSelected : styles.buttonTextNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail}</Text>
              <Text style={[styles.buttonTextPrivate, isSelected.email ? styles.buttonTextPrivateSelected : styles.buttonTextPrivateNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
            </TouchableOpacity>

            {/* Bouton Phone */}
            <TouchableOpacity
              onPress={() => toggleSelection('phone')}
              disabled={!formData.phone || formData.phone.replace(/\D/g, '').length < 10} // Ajoutez une condition pour la longueur
              style={[
                styles.button,
                isSelected.phone ? styles.buttonSelected : styles.buttonNotSelected,
                (!formData.phone || formData.phone.replace(/\D/g, '').length < 10) && styles.buttonDisabled, // Utilisez une condition améliorée ici aussi
              ]}>
              <View style={styles.iconsContainer}>
                <Icons.Phone style={styles.icons} />
              </View>
              <Text style={[styles.buttonText, isSelected.phone ? styles.buttonTextSelected : styles.buttonTextNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumber}</Text>
              <Text style={[styles.buttonTextPrivate, isSelected.phone ? styles.buttonTextPrivateSelected : styles.buttonTextPrivateNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
            </TouchableOpacity>
          </View>

          {/* Ligne 2 */}
          <View style={styles.buttonRow}>
            {/* Bouton Last Name */}
            <TouchableOpacity onPress={() => toggleSelection('lastName')} style={[styles.button, isSelected.lastName ? styles.buttonSelected : styles.buttonNotSelected]}>
              <View style={styles.iconsContainer}>
                <Icons.LastName style={styles.icons} />
              </View>
              <Text style={[styles.buttonText, isSelected.lastName ? styles.buttonTextSelected : styles.buttonTextNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonLastname}</Text>
              <Text style={[styles.buttonTextPrivate, isSelected.lastName ? styles.buttonTextPrivateSelected : styles.buttonTextPrivateNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
            </TouchableOpacity>

            {/* Bouton Birthday */}
            <TouchableOpacity
              onPress={() => toggleSelection('birthday')}
              disabled={!formData.dateOfBirth} // Désactive le bouton si dateOfBirth est vide
              style={[
                styles.button,
                isSelected.birthday ? styles.buttonSelected : styles.buttonNotSelected,
                !formData.dateOfBirth && styles.buttonDisabled, // Appliquez un style différent si désactivé
              ]}>
              <View style={styles.iconsContainer}>
                <Icons.Birthday style={styles.icons} />
              </View>
              <Text style={[styles.buttonText, isSelected.birthday ? styles.buttonTextSelected : styles.buttonTextNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonBirthday}</Text>
              <Text style={[styles.buttonTextPrivate, isSelected.birthday ? styles.buttonTextPrivateSelected : styles.buttonTextPrivateNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
            </TouchableOpacity>
          </View>

          {/* Pour le bouton Member Number qui est seul, il peut rester dans sa propre ligne ou vous pouvez l'ajouter à une nouvelle ligne si vous prévoyez d'ajouter plus de boutons à l'avenir */}
          <View style={styles.buttonRow}>
            {/* Bouton Member Number */}
            <TouchableOpacity onPress={() => toggleSelection('memberNumber')} style={[styles.button, isSelected.memberNumber ? styles.buttonSelected : styles.buttonNotSelected]}>
              <View style={styles.iconsContainer}>
                <Icons.MemberNumber style={styles.icons} />
              </View>
              <Text style={[styles.buttonText, isSelected.memberNumber ? styles.buttonTextSelected : styles.buttonTextNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonMemberShip}</Text>
              <Text style={[styles.buttonTextPrivate, isSelected.memberNumber ? styles.buttonTextPrivateSelected : styles.buttonTextPrivateNotSelected]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}

      <View style={styles.ButtonContainer}>
        <TouchableOpacity onPress={handleSubmit} style={styles.submitButton}>
          <Text style={styles.submitButtonText}>{translations.JsonCreateProfilePages.JsonSaveButton/*Save*/}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={handleContinue} style={styles.ContinueButton}>
          <Text style={styles.ContinueButtonText}>{translations.JsonCreateProfilePages.JsonContinueButton}</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

export default EditProfileStep2Screen;
