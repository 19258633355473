//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷 Ce fichier gère la page qui affiche votre profil. (FrameFigma071)
// 🇬🇧 This file manage the page displaying your profile.   (FrameFigma071)

// Import Modules
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {useState, useEffect} from 'react';
import {View, Pressable, Text, TouchableOpacity, ImageBackground, Platform} from 'react-native';

// Import Contexts / Hooks / Utils
import {useLanguage} from '../../../../contexts/LanguageContext';
import {hostname} from '../../../../../hostName/hostName';
import {useUser} from '../../../../contexts/UserContext';
import { getActivities } from '../../../../utils/ActivityFetch';
import {calculateUserPoints} from '../../../../utils/UserPointsCalculator';
import { addUserLike, deleteUserLike } from '../../../../utils/UserLikes';

// Import Icons
import { Icons } from '../../../../constants/Icons';

// Import des composants
import ProfileInfoScreen from '../MyProfileBodyBottomInfoScreen/MyProfileBodyBottomInfoScreen';
import ProfileNetworkScreen from '../MyProfileBodyBottomNetworkScreen/MyProfileBodyBottomNetworkScreen';
import ProfileActivitiesScreen from '../MyProfileBodyDownActivitiesScreen/MyProfileBodyDownActivitiesScreen';
import AddFriendModal from '../../../../components/AddFriendModal/AddFriendModal';
import ProfileMedal from '../../../../components/ProfileMedal/ProfileMedal';
import ChatModal from '../../../../components/ChatModal/ChatModal';
import {useWebSocket} from '../../../../sockets/WebSocketLocal';

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const MyProfileScreen = ({route, navigation}) => {
  // Les Contexts
  const {translations} = useLanguage();
  const trans = translations.JsonProfilePages.JsonMyProfileScreen;
  const {user, allUsers} = useUser();
  const {socket, requestChatHistory, privateChatMessages, updateCurrentChatId, currentChatId} = useWebSocket();

  // Les States
  const [activeButton, setActiveButton] = useState('Information');
  const [userActivities, setUserActivities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userIsLiked, setUserIsLiked] = useState(false);
  const {isChatModalVisible, chatDetails, openChatModal, closeChatModal} = useChatModal();

  // Defines which user's profile to display
  const userID = route.params?.userId || user._id;

  /* ATTENTION - Current User est l'user dont vous consulter actuellement le profil */
  const currentUser = allUsers.find(u => u._id === userID);

  // Handle Modals
  const toggleModal = () => setShowModal(!showModal);

   useEffect(() => {
     console.log('Mise à jour des messages privés :', privateChatMessages);
     // Boucle à travers chaque message privé
     Object.entries(privateChatMessages).forEach(([messageId, messagesArray]) => {
       console.log('ID du message privé :', messageId); // Affiche l'ID du message privé
       // Boucle à travers chaque message dans le tableau de messages
      //  messagesArray.forEach(message => {
      //    console.log('Expéditeur :', message.sender); // Affiche le nom de l'expéditeur
      //    console.log('Contenu du message :', message.content); // Affiche le contenu du message
      //    console.log('Date du message :', message.timestamp); // Affiche la date du message
      //  });
     });
   }, [privateChatMessages]);

   // Logique pour déterminer l'ID du chat basé sur l'utilisateur actuel
   useEffect(() => {
     if (userID) {
       const chatId = Object.keys(privateChatMessages).find(key => key.includes(userID)); // Simplification
       console.log(`Chat ID trouvé pour l'utilisateur ${userID}:`, chatId); // Log pour voir l'ID du chat trouvé
       if (chatId) {
         updateCurrentChatId(chatId);
       }
     }
   }, [userID, privateChatMessages, updateCurrentChatId]);

   const toggleChatModal = () => {
     setShowChatModal(!showChatModal);
     if (!showChatModal && userID && currentChatId) {
       console.log(`Demande de l'historique de chat pour l'ID: ${currentChatId}`); // Log pour voir l'ID du chat utilisé lors de la demande
       requestChatHistory(currentChatId);
     }
   };
  // Fonction pour enregistrer la visite du profil
  const recordProfileVisit = async visitedUserID => {
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/users/${visitedUserID}/visit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({visitorId: user._id}),
      });

      if (!response.ok) {
        throw new Error('Failed to record profile visit');
      }

      const profileVisits = await response.json();
      console.log('Profile visit recorded:', profileVisits);

      // Vérifiez si le socket est défini et que l'ID de l'utilisateur visité n'est pas celui de l'utilisateur connecté
      if (socket && visitedUserID !== user._id) {
        console.log(`Attempting to emit profileVisit event for ${visitedUserID} visited by ${user._id}`);
        socket.emit('profileVisit', {visitedUserId: visitedUserID, visitorUserId: user._id});
        console.log(`profileVisit event emitted for ${visitedUserID} visited by ${user._id}`);
      }
    } catch (error) {
      console.error('Error recording profile visit:', error);
    }
  };

  useEffect(() => {
    // Vérifiez si socket est défini avant d'utiliser socket.emit et si l'utilisateur actuel n'est pas celui visité
    if (socket && userID !== user._id) {
      console.log(`Calling recordProfileVisit for userID ${userID}`);
      recordProfileVisit(userID);
    }
  }, [userID, user._id, socket]);

  // Fetch Data using useEffect
  useEffect(() => {
    const fetchActivities = async () => {
      const activities = await getActivities(userID);
        setUserActivities(activities.uniqueUserActivities);
    };

    const getUserLikes = async currentUserId => {
      const token = await AsyncStorage.getItem('userToken');
      const userId = await AsyncStorage.getItem('userId');

      const response = await fetch(`${hostname}/userLikes`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      // On utilise .some() method pour avoir un booleen
      const likeStatus = data.some(like => like.likedBy === userId && like.likedUser === currentUserId);
      setUserIsLiked(likeStatus);
    };
    getUserLikes(currentUser._id);
    fetchActivities();
  }, [userID]);

  // Coupe l'adresse entier pour garder juste le nom de la ville
  let city = '';
  if (currentUser && currentUser.city && currentUser.city.trim() !== '') {
    city = currentUser.city.split(',')[0];
  }

  // Liker un User
  const toggleLike = async () => {
    setUserIsLiked(!userIsLiked);

    if (userIsLiked) {
      deleteUserLike(currentUser._id);
    } else {
      addUserLike(currentUser._id);
    }
  };

  const userPoints = calculateUserPoints(userID || currentUser._id);
  const userSponsorships = currentUser?.sponsoredUsers.length > 0 ? currentUser?.sponsoredUsers.length : 0;

  return (
    <>
      <View style={styles.header}>
        <Text>Profile de {userID}</Text>
        <ImageBackground source={currentUser?.profileImage} style={styles.profilePhoto}>
          {currentUser._id === user._id ? (
            <TouchableOpacity onPress={() => navigation.navigate('EditProfile', {userData: currentUser})} style={styles.editButton}>
              <Icons.Edit width={24} height={24} style={styles.editIcon} />
              <Text style={styles.editButtonText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonTitleEditProfile}</Text>
            </TouchableOpacity>
          ) : null}
          {!userIsLiked && currentUser._id !== user._id ? (
            <Pressable onPress={toggleLike} style={styles.likeButton}>
              <Icons.HeartGrey width={40} height={40} />
            </Pressable>
          ) : null}
          {userIsLiked && currentUser._id !== user._id ? (
            <Pressable onPress={toggleLike} style={styles.likeButton}>
              <Icons.HeartRed width={40} height={40} />
            </Pressable>
          ) : null}
        </ImageBackground>
      </View>
      <View style={styles.container}>
        <View style={styles.cupIconContainer}>
          <View style={styles.cupIcon}>
            <ProfileMedal points={userPoints} noOfSponsorships={userSponsorships} />
          </View>
          <Text style={styles.pointsTextBelowCup}>
            {userPoints} {trans.JsonPoints}
          </Text>
        </View>
        <View style={styles.userInfo}>
          <View style={[styles.flexRowCenter, styles.spaceEvenly, styles.pad20]}>
            <View style={styles.individualEntry}>
              <Text style={styles.bold}>
                {/* Changer le traduction de Organizer avant d'utiliser le json */}
                {/* {trans.JsonOrganizerRating}:  */}
                Organizer:
              </Text>
              <Text>⭐⭐⭐⭐⭐</Text>
            </View>
            <View style={styles.individualEntry}>
              <Text style={styles.bold}>{trans.JsonReliability}: </Text>
              <Text>Never</Text>
            </View>
          </View>
          <View style={[styles.flexRowCenter, styles.spaceEvenly, styles.pad20]}>
            <Text style={styles.userName}>
              {currentUser.firstName} {currentUser.lastName}
            </Text>
            <Text style={styles.userAge}>{currentUser.age}ans</Text>
            <View style={styles.flexRowCenter}>
              <Icons.PinBlackAndWhite style={styles.Location} width={24} height={24} />
              <Text style={styles.userCityAbove}>{city}</Text>
            </View>
          </View>
        </View>
        {/* Boutons à afficher selon le profil de l'utilisateur */}
        {currentUser._id !== user._id ? (
          <View style={[styles.flexRowCenter, styles.spaceEvenly, styles.pad20]}>
            {currentUser.friends.includes(user._id) ? null : (
              <Pressable style={[styles.actionButton, styles.addFriendButton]} onPress={toggleModal}>
                <Icons.AddFriend width={18} height={18} style={styles.buttonIcon} />
                <Text style={styles.buttonText}>{trans.JsonAddFriend}</Text>
              </Pressable>
            )}
            <Pressable onPress={toggleChatModal} style={[styles.actionButton, styles.chatButton]}>
              <Icons.ChatColors width={18} height={18} style={styles.buttonIcon} />
              <Text style={styles.buttonText}>{trans.JsonChat}</Text>
            </Pressable>
            <ChatModal showModal={showChatModal} toggleModal={toggleChatModal} currentUser={currentUser} chatId={currentChatId} userID={userID} privateMessages={privateChatMessages} />
            <Pressable style={[styles.actionButton, styles.blockButton]}>
              <Icons.BlockFriend width={18} height={18} style={styles.buttonIcon} />
              <Text style={styles.buttonText}>{trans.JsonBlock}</Text>
            </Pressable>
          </View>
        ) : null}
        <View style={styles.menuContainer}>
          <Pressable style={[styles.button, activeButton === 'Information' && {backgroundColor: '#59c09b'}]} onPress={() => setActiveButton('Information')}>
            <Text style={styles.text}>{trans.JsonInformations}</Text>
          </Pressable>

          <View style={styles.separator} />

          <Pressable style={[styles.button, activeButton === 'Activities' && {backgroundColor: '#59c09b'}]} onPress={() => setActiveButton('Activities')}>
            <Text style={styles.text}>{trans.JsonActivities}</Text>
          </Pressable>

          <View style={styles.separator} />

          <Pressable style={[styles.button, activeButton === 'Network' && {backgroundColor: '#59c09b'}]} onPress={() => setActiveButton('Network')}>
            <Text style={styles.text}>{trans.JsonNetwork}</Text>
          </Pressable>
        </View>
        <AddFriendModal visible={showModal} onClose={toggleModal} currentUser={currentUser} />
        {activeButton === 'Information' && <ProfileInfoScreen currentUser={currentUser} city={city} />}
        {activeButton === 'Activities' && <ProfileActivitiesScreen currentUser={currentUser} users={allUsers} userActivities={userActivities} />}
        {activeButton === 'Network' && <ProfileNetworkScreen currentUser={currentUser} users={allUsers} userActivities={userActivities} />}
      </View>
    </>
  );
};

export default MyProfileScreen;
