import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  confirmButton: {

    // Button CSS
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#6f6f6f',
    borderRadius: 10,

    // Button Text CSS
    buttonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        marginRight: 5,
    }
  },
  inputContainer: {
    width: '100%', // Assurez-vous que le conteneur d'input prend toute la largeur pour aligner les labels à gauche
  },
  input: {
    borderWidth: 1,
    borderColor: '#cccccc',
    padding: 10,
    borderRadius: 15,
  },
  iconRow: {
    alignSelf: 'flex-start',
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  }
})

export default styles;