// 🇫🇷 Ce fichier gère la page où on rentre notre mot de passe lors du login/register si l'email rentré est un email qui existe dans la base de donnée (FrameFigma005)
// 🇬🇧 This file manages the page where we enter our password during login/register if the email entered is an email that exists in the database (FrameFigma005)import React, { useState, useEffect } from 'react';
import React, {useState, useEffect} from 'react';
import {View, Text, Linking, Platform, TouchableOpacity, Pressable} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Fields from '../../../../components/Fields/Fields';
import {hostname} from '../../../../../hostName/hostName';
import {useAuth} from '../../../../contexts/AuthContext';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useUser} from '../../../../contexts/UserContext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import {useError} from '../../../../contexts/ErrorContext';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ResetExistingUserPasswordScreen = ({navigation}) => {
  const {translations} = useLanguage();
  const {language} = useLanguage();
  const {updateUserState} = useUser();
  const {error, setError} = useError();
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const {login} = useAuth();
  const [revealClicked, setRevealClicked] = useState(false);

  useEffect(() => {
    getEmailFromStorage();
  }, []);

  const getEmailFromStorage = async () => {
    try {
      const storedEmail = await AsyncStorage.getItem('email');
      if (storedEmail) {
        console.log('Email retrieved from storage:', storedEmail);
        setEmail(storedEmail);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'email depuis AsyncStorage :", error);
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageTooShort)
      .required(translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageEmpty),
  });

  const handleNext = async () => {
    try {
      await validationSchema.validate({password});
      console.log('Email for login:', email);

      const loginResponse = await fetch(`${hostname}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (loginResponse.ok) {
        const loginData = await loginResponse.json();
        console.log('Login response data:', loginData);

        if (loginData.authToken && loginData.user) {
          await AsyncStorage.setItem('authToken', loginData.authToken);
          await AsyncStorage.setItem('userData', JSON.stringify(loginData.user));
          await AsyncStorage.setItem('userId', loginData.user._id);
          await AsyncStorage.setItem('userEmail', loginData.user.email);
          await AsyncStorage.setItem('profileImage', loginData.user.profileImage);
          await AsyncStorage.setItem('userName', loginData.user.userName);

          login(loginData.authToken, loginData.user);
          updateUserState(loginData.user);
          navigation.navigate('Activities', {userId: loginData.user._id});
          setError('');
        } else {
          setError(translations.JsonHomeScreen.JsonConnectionIssues);
        }
      } else if (loginResponse.status === 401) {
        console.log('Incorrect password, sending verification email');
        await sendVerificationEmail(email);
        navigation.navigate('VerifyEmailExistingUserScreen', {newPassword: password});
      } else {
        setError(translations.JsonHomeScreen.JsonConnectionIssues);
      }
    } catch (validationError) {
      console.error('Validation error:', validationError);
      setError(validationError.message);
    }
  };

  const sendVerificationEmail = async email => {
    try {
      console.log('Sending verification email to:', email);
      await fetch(`${hostname}/sendVerificationExistingUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande de vérification par e-mail", error);
    }
  };

  const handleTextChange = text => {
    setPassword(text);
    if (error) {
      setError('');
    }
  };

  return (
    <View key={language} style={styles.container}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.passwordContent}>
        <TextInputCustom
          Icon={Fields.IconPassword}
          placeholderText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder}
          value={password}
          handleTextChange={handleTextChange}
          isPlaceHolderTextAlignCenter={false}
          isPassword={!isPasswordVisible}
        />
      </View>
      <View style={styles.btnContainer}>
        <ButtonNext
          handleNextStep={handleNext}
          btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
          conditionNeed={password.length >= 8}
        />
      </View>
      <View style={styles.forgotPassword}>
        <Text style={styles.forgotTxt}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonForgotPassword}</Text>
        <View style={styles.btnForgotContainer}>
          <TouchableOpacity style={styles.btnForgot} onPress={() => navigation.navigate('ResetPassword')}>
            <Text style={styles.btnTextForgot}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonResetPasswordButton}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.condition}>
        <Text style={styles.terms}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}</Text>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}</Text>
        </Pressable>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default ResetExistingUserPasswordScreen;
