// FR (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma035B,FrameFigma033,FrameFigma038A,FrameFigma038B,FrameFigma039)
import { View, Text, Platform, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import Slider from "@react-native-community/slider";
import Fields from '../../components/Fields/Fields';
import { Slider as SliderRange } from "@miblanchard/react-native-slider";

const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const SliderComponent = ({ min, max, sliderValue, setSliderValue, type = "", translations }) => {

    return type === ""
        ? (<View style={styles.container}>
            <Slider
                style={styles.slider}
                minimumValue={min}
                maximumValue={max}
                value={sliderValue}
                onValueChange={(value) => setSliderValue(value)}
            />
            {/* pour deplacer les valeur min et max en ligne suivant, pour mieux correpondant a figma */}
            <View style={styles.valueContainer}>
                <Text style={styles.label}>{min}</Text>
                <Text style={styles.label}>{max}</Text>
            </View>

            <View style={styles.sliderValueContainer}>
                <Text style={styles.sliderValue}>{Math.round(sliderValue)}</Text>
            </View>
        </View>)
        : type === "parity"
            ? (<View style={styles.containerForParity}>
                <Slider
                    style={styles.slider}
                    minimumValue={min}
                    maximumValue={max}
                    value={sliderValue}
                    onValueChange={(value) => setSliderValue(value)}
                    thumbTintColor="#A5CCF2"
                    minimumTrackTintColor="#A5CCF2"
                    maximumTrackTintColor="#FF4181"
                />
                {/* pour deplacer les valeur min et max en ligne suivant, pour mieux correpondant a figma */}
                <View style={styles.valueContainer}>
                    <Text style={styles.label}>{min}%</Text>
                    <Text style={styles.label}>{max}%</Text>
                </View>

                <View style={styles.iconsContainerTop}>
                    <Fields.IconMaleSign />
                    <Fields.IconFemaleSign />
                </View>

                <View style={styles.sliderValueContainerTop}>
                    <Text style={styles.sliderValue}>{Math.round(sliderValue)}%</Text>
                </View>
            </View>)
            : (<View style={styles.container}>
                <SliderRange
                    containerStyle={styles.slider}
                    maximumTrackTintColor="#d3d3d3"
                    maximumValue={max}
                    minimumTrackTintColor="#1fb28a"
                    minimumValue={min}
                    value={sliderValue}
                    onValueChange={(value) => setSliderValue(value)}
                    thumbTintColor="#1a9274"
                />
                {/* pour deplacer les valeur min et max en ligne suivant, pour mieux correpondant a figma */}
                <View style={styles.valueContainer}>
                    <Text style={styles.label}>{min}</Text>
                    <Text style={styles.label}>{max} {translations.JsonProfilePages.JsonMyProfileScreen.JsonYears}</Text>
                </View>

                <View style={styles.sliderValueContainerAge}>
                    <Text style={styles.sliderValue}>{Math.round(sliderValue[0])}-{Math.round(sliderValue[1])}</Text>
                </View>
            </View>)
}

export default SliderComponent;
