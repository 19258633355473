// Fr Cette page affiche l'étape 3 du formulaire pour modifier une activité (FrameFigma035A, FrameFigma035B)
// En This page displays the third step of the form to edit an activity (FrameFigma035A, FrameFigma035B)

import React, {useState, useEffect} from 'react';
import {ScrollView, View, Text, TextInput, Pressable, Platform} from 'react-native';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useError} from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';

// Import Components
import RequestCoOrganisers from '../../../../components/RequestCoOrganisers/RequestCoOrganisers';
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const EditActivityCommonStepOne = ({onNextStep, currentActivity,onPrevStep}) => {
  const {translations} = useLanguage();
  const {error, setError} = useError();

  const [switchBtnUnlimitted, setSwitchBtnUnlimitted] = useState(false);
  const [price, setPrice] = useState(false);
  const [switchBtn2, setSwitchBtn2] = useState(false);
  const [switchBtn3, setSwitchBtn3] = useState(false);
  const [switchBtn4, setSwitchBtn4] = useState(false);
  const [switchBtn5, setSwitchBtn5] = useState(false);
  const [sliderValue, setSliderValue] = useState(2);
  const [sliderValue1, setSliderValue1] = useState(2);
  const [isFocused, setIsFocused] = useState(false);
  const [priceValue, setPriceValue] = useState(0);
  const [buyTicketsLink, setBuyTicketsLink] = useState('');
  const [coOrgConditionsExisting, setCoOrgConditionsExisting] = useState([]);
  const [coOrgOffersExisting, setCoOrgOffersExisting] = useState([]);

  const handleNextStep = () => {
    const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;
    let errorMessage = '';
    let isValid;
    if (switchBtnUnlimitted !== true && (sliderValue < 2 || sliderValue > 30)) {
      errorMessage = "Sélectionne un nombre d'invités entre 2 et 30.";
    } else if (price === true && priceValue > 0 && buyTicketsLink && !urlRegex.test(buyTicketsLink)) {
      errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPriceLinkErrorMessage;
    } else if (price === true && priceValue === 0) {
      errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPriceErrorMessage;
    } else if (price === true && priceValue <= 0) {
      errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPriceErrorMessage;
    } else if (switchBtn2 === true && switchBtn3 === false && switchBtn4 === false && switchBtn5 === false) {
      errorMessage =
        'Sélectionne au moins une option parmi "Notifier mes participants précédents", "Inviter plus de personnes" ou "Demander un co-organisateur".';
    }

    if (errorMessage !== '') {
      setError(errorMessage);
    } else {
      isValid = true;
      onNextStep(); // Appel à la fonction pour passer à l'étape suivante
    }
  };

  const handlePriceChange = text => {
    // Filtrer les caractères non numériques, Autoriser uniquement un point ou une virgule
    const numericValue = text.replace(/[^\d.,]|(?<=([.,]))[.,]|^[.,]/g, '');
    // Remplacer les virgules par des points pour uniformiser le format
    const formattedValue = numericValue.replace(',', '.');
    setPriceValue(formattedValue);
    setError('');
  };
  useEffect(() => {
    setError('');
  }, [price]);
  useEffect(() => {
    async function saveData() {
      try {
        const roundedSliderValue = Math.round(sliderValue);

        await AsyncStorage.setItem('AttendeeLimitation', roundedSliderValue.toString());
        await AsyncStorage.setItem('switchBtnUnlimited', JSON.stringify(switchBtnUnlimitted));
        await AsyncStorage.setItem('hasPrice', price.toString());
        await AsyncStorage.setItem('notifyPreviousAttendees', switchBtn3.toString());
        await AsyncStorage.setItem('inviteMore', switchBtn4.toString());
        await AsyncStorage.setItem('requestCoOrga', switchBtn5.toString());
        await AsyncStorage.setItem('friendsNumber', sliderValue1.toString());
        await AsyncStorage.setItem('priceValue', priceValue.toString());
        await AsyncStorage.setItem('buyTicketsLink', buyTicketsLink.toString());
      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
    saveData();
  }, [sliderValue, switchBtnUnlimitted, price, sliderValue1, priceValue, buyTicketsLink, switchBtn3, switchBtn4, switchBtn5]);

  useEffect(() => {
    // Fonction de nettoyage pour supprimer les données du AsyncStorage lorsque la page est déchargée
    const cleanup = async () => {
      await AsyncStorage.removeItem('AttendeeLimitation');
      await AsyncStorage.removeItem('switchBtnUnlimited');
      await AsyncStorage.removeItem('hasPrice');
      await AsyncStorage.removeItem('notifyPreviousAttendees');
      await AsyncStorage.removeItem('inviteMore');
      await AsyncStorage.removeItem('requestCoOrga');
      await AsyncStorage.removeItem('friendsNumber');
      await AsyncStorage.removeItem('priceValue');
      await AsyncStorage.removeItem('buyTicketsLink');
    };

    cleanup();
  }, []);

  useEffect(() => {
    // get data of current activty
    setSwitchBtnUnlimitted(currentActivity.attendeeLimit > 30);
    setSliderValue(currentActivity.attendeeLimit || 2);
    setPrice(currentActivity.hasPrice || false);
    setPriceValue(currentActivity.price || 0);
    setBuyTicketsLink(currentActivity.buyTicketsLink || '');
    setSliderValue1(currentActivity.friendsNumber || 0);
    setSwitchBtn2((currentActivity.notifyPreviousAttendees && currentActivity.inviteMore && currentActivity.requestCoOrga) || false);
    setSwitchBtn3(currentActivity.notifyPreviousAttendees || false);
    setSwitchBtn4(currentActivity.inviteMore || false);
    setSwitchBtn5(currentActivity.requestCoOrga || false);
    setCoOrgConditionsExisting(currentActivity.coOrganizerConditions || []);
    setCoOrgOffersExisting(currentActivity.coOrganizerGifts || []);
  }, [currentActivity]);

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.container}>
        <View>
          <View style={styles.switchBtn}>
            <Text style={styles.textbtn}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonUnlimitedAttendees}
            </Text>
            <SwitchBtn state={switchBtnUnlimitted} setState={setSwitchBtnUnlimitted} />
          </View>
          <Text>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonOnlyAppWithoutRestrictionForOrganizers}</Text>
        </View>
        {switchBtnUnlimitted !== true && (
          <View style={styles.slider}>
            <Text style={styles.slider}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonAttendeesLimitation}
            </Text>
            <SliderComponent min={2} max={30} sliderValue={sliderValue} setSliderValue={setSliderValue} />
          </View>
        )}
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}</Text>
          <SwitchBtn state={price} setState={setPrice} />
        </View>
        {price === true && (
          <View>
            <View style={{width: '30%'}}>
              <TextInputCustom
                placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonPrice}
                handleTextChange={handlePriceChange}
                value={priceValue}
                isPlaceHolderTextAlignCenter={false}
              />
            </View>
            <View style={{width: '95%'}}>
              <TextInputCustom
                placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonBuyTicket}
                handleTextChange={text => setBuyTicketsLink(text)}
                value={buyTicketsLink}
                isPlaceHolderTextAlignCenter={false}
              />
            </View>
          </View>
        )}
        <View style={styles.slider}>
          <Text style={styles.slider}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonHowMuchFriends}</Text>
          <SliderComponent min={0} max={9} sliderValue={sliderValue1} setSliderValue={setSliderValue1} />
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonHelpOrganizers}</Text>
          <SwitchBtn state={switchBtn2} setState={setSwitchBtn2} />
        </View>
        {switchBtn2 === true && (
          <View style={styles.switchBtnSousSwitchBtn2}>
            {/* ici pour reculer(des marginLeft) les sous boutton */}
            <View style={styles.switchBtn2}>
              <Text style={styles.textbtn}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonNotifyMyPreviousAttendees}
              </Text>
              <SwitchBtn state={switchBtn3} setState={setSwitchBtn3} />
            </View>
            <View style={styles.switchBtn2}>
              <Text style={styles.textbtn}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonInviteMorePeople}
              </Text>
              <SwitchBtn state={switchBtn4} setState={setSwitchBtn4} />
            </View>
            <View style={styles.switchBtn2}>
              <Text style={styles.textbtn}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerRequestAsk}
              </Text>
              <SwitchBtn state={switchBtn5} setState={setSwitchBtn5} />
            </View>
          </View>
        )}
        <View>
          {switchBtn5 === true && <RequestCoOrganisers coOrgConditionsExisting={coOrgConditionsExisting} coOrgOffersExisting={coOrgOffersExisting} />}
        </View>
        <View style={{flexDirection: 'row'}}>
          <View style={styles.btnContainer}>
            {/* si btn price est on, ya condition, sinon pas de condition */}
            <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
            <ButtonNext
              conditionNeed={price === true ? priceValue > 0 : null}
              handleNextStep={handleNextStep}
              btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default EditActivityCommonStepOne;
