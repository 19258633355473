import React from 'react';
import {View, Text, Pressable, Linking} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';

const InviteByMessenger = () => {
  const {translations} = useLanguage();

  const inviteByMessenger = () => {
    const messengerLink = `fb-messenger://user/?message=${translations.JsonInviteFriendsScreen.JsonInvitationMessage}`;
    Linking.openURL(messengerLink);
  };

  return (
    <View>
      <Pressable onPress={inviteByMessenger}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteFacebook}</Text>
      </Pressable>
    </View>
  );
};

export default InviteByMessenger;
