// FR  afficher les informations de base d'un utilisateur sous forme de carte
// EN  "Display basic user information in a card format."
import React from 'react';
import { View, Text, Image, Platform } from 'react-native';
import { hostname } from '../../../hostName/hostName';

const styles = Platform.OS === 'web'
  ? require('./StylesWeb').default
  : require('./StylesMobile').default;

const UserCard = ({ user }) => {
  if (!user) {
    return <Text>Utilisateur introuvable</Text>;
  }

  // Assurez-vous d'avoir une URL valide avant de tenter de charger l'image
  const imageUrl = user.user.profileImage
    ? user.user.profileImage
    : `${hostname}/uploads/${user.user.profileImage}`;

  return (
    <View style={styles.card}>
      {imageUrl && (
        <Image
          source={{ uri: imageUrl }}
          style={[styles.profileImage, user.isFriend ? styles.profileImageFriend : styles.profileImage]}
        />
      )}
      <Text style={user.user.sexe === "Male" ? styles.nameMale : styles.nameFemale}>{user.user.userName.length > 14 ? user.user.userName.substring(0, 11) + '...' : user.user.userName}</Text>
    </View>
  );
};

export default UserCard;