import React from 'react';
import { View, Text, Pressable, Linking, Platform } from 'react-native';
import { useLanguage } from '../../../contexts/LanguageContext';


const InviteBySMS = () => {
  const { translations } = useLanguage();

  // Cette fonction ouvre l'application de messagerie par défaut avec un message pré-rempli
  const inviteBySMS = () => {
    const message = encodeURIComponent(translations.JsonInviteFriendsScreen.JsonInvitationMessage);
    // Le lien varie légèrement selon la plateforme
    const smsLink = Platform.OS === 'android'
      ? `sms:?body=${message}`
      : `sms:&body=${message}`;
    Linking.openURL(smsLink);
  };

  return (
    <View style={{ alignItems: 'center', margin: 10 }}>
      <Pressable onPress={inviteBySMS} style={{ backgroundColor: '#007bff', padding: 10, borderRadius: 5 }}>
        <Text style={{ color: '#ffffff' }}>{translations.JsonInviteFriendsScreen.JsonInviteTextMessage}</Text>
      </Pressable>
    </View>
  );
};

export default InviteBySMS;



