// frontend/src/pages/MembersSection/MembersNavigator.js

import React, { useState } from 'react';
import { View, Pressable, Text, StyleSheet, TextInput, Platform, } from 'react-native';
import MembersScreen from './MembersScreen/MembersScreen';
import FriendListScreen from './FriendListScreen/FriendListScreen';
import NetworkScreen from './NetworkScreen/NetworkScreen';
import { useLanguage } from '../../contexts/LanguageContext';


const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;


const MembersNavigator = (props) => {
    const { translations } = useLanguage();
  const { language } = useLanguage();
  const [activeButton, setActiveButton] = useState("Members");
  const [subFilter, setSubFilter] = useState("");
  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      <View ke={language} style={styles.menuContainer}>
        <Pressable
          style={[
            styles.button,
            activeButton === "Members" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => setActiveButton("Members")}
        >
          <Text style={styles.text}>
            {translations.JsonTopNav.JsonMembersFirstLine}
          </Text>
        </Pressable>

        <View style={styles.separator} />

        <Pressable
          style={[
            styles.button,
            activeButton === "Network" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => setActiveButton("Network")}
        >
          <Text style={styles.text}>
            {translations.JsonTopNav.JsonMembersSecondLineNetwork}
          </Text>
        </Pressable>

        <View style={styles.separator} />

        <Pressable
          style={[
            styles.button,
            activeButton === "FriendList" && { backgroundColor: "#59c09b" },
          ]}
          onPress={() => setActiveButton("FriendList")}
        >
          <Text style={styles.text}>
            {translations.JsonTopNav.JsonMembersSecondLineFriends}
          </Text>
        </Pressable>
      </View>
      {activeButton === "Members" && (
        <View style={styles.secondaryMenuContainer}>
          <Pressable
            style={[
              styles.smallButton,
              subFilter === "Male" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Male")}
          >
            {/* Remplacez par <Image source={MaleIcon} style={{ width: 21, height: 21 }} /> */}
            <Text style={styles.smallText}>Male</Text>
          </Pressable>

          <View style={styles.smallSeparator} />

          <Pressable
            style={[
              styles.smallButton,
              subFilter === "Pros" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Pros")}
          >
            <Text style={styles.smallText}>Pros</Text>
          </Pressable>

          <View style={styles.smallSeparator} />

          <Pressable
            style={[
              styles.smallButton,
              subFilter === "Female" && { backgroundColor: "#59c09b" },
            ]}
            onPress={() => setSubFilter("Female")}
          >
            {/* Remplacez par <Image source={FemaleIcon} style={{ width: 21, height: 21 }} /> */}
            <Text style={styles.smallText}>Female</Text>
          </Pressable>
        </View>
      )}
      {activeButton === "Members" && (
        <View style={styles.searchContainer}>
          {/* Remplacez par une icône de recherche si nécessaire */}
          {/* <Image source={SearchIcon} style={styles.searchIcon} /> */}
          <TextInput
            style={styles.searchInput}
            placeholder={translations.JsonMembersScreen.JsonSearchMemberPlaceHolder}
            placeholderTextColor="#CCCCCC"
            onChangeText={(text) => setSearchValue(text)}
          />
        </View>
      )}

      {/* Ici, vous pouvez conditionner l'affichage des différents écrans en fonction de `activeButton` */}
      {activeButton === "Members" && <MembersScreen />}
      {activeButton === "Network" && <NetworkScreen scr={translations} />}
      {activeButton === "FriendList" && <FriendListScreen scr={translations} />}
    </>
  );
};

// Utilisez le style que vous avez fourni ici


// Styles as you provided, with slight modifications to match the structure

export default MembersNavigator;
