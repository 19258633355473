import React, {useEffect, useState, useRef} from 'react';
import {Modal, View, Text, TextInput, Pressable, ScrollView, StyleSheet} from 'react-native';
import {format} from 'date-fns';

const InboxChatModal = ({isVisible, onClose, currentUser, chatId, messages = []}) => {
  // Default messages to an empty array
  const [message, setMessage] = useState('');
  const scrollViewRef = useRef();

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({animated: true});
    }
  }, [messages]);

  const sendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        senderId: currentUser._id,
        content: message,
        timestamp: new Date().toISOString(),
      };
      sendMessageToServer(chatId, newMessage); // Assuming you have a method to handle message sending
      setMessage('');
    } else {
      alert('Message cannot be empty.');
    }
  };

  return (
    <Modal visible={isVisible} animationType="slide" transparent={false} onRequestClose={onClose}>
      <View style={styles.container}>
        <ScrollView ref={scrollViewRef} style={styles.scrollView}>
          {messages.map((msg, index) => (
            <View key={msg._id || index} style={styles.messageContainer}>
              {index === 0 ||
                (new Date(messages[index].timestamp).getDate() !== new Date(messages[index - 1].timestamp).getDate() && (
                  <Text style={styles.date}>{format(new Date(msg.timestamp), 'dd/MM/yyyy')}</Text>
                ))}
              <View style={msg.senderId === currentUser._id ? styles.messageBubbleRight : styles.messageBubbleLeft}>
                <Text style={styles.messageContent}>{msg.content}</Text>
                <Text style={styles.timestamp}>{format(new Date(msg.timestamp), 'HH:mm')}</Text>
              </View>
            </View>
          ))}
        </ScrollView>
        <View style={styles.inputContainer}>
          <TextInput style={styles.input} value={message} onChangeText={setMessage} placeholder="Type your message here" />
          <Pressable style={styles.sendButton} onPress={sendMessage}>
            <Text style={styles.buttonText}>Send</Text>
          </Pressable>
        </View>
        <Pressable style={styles.closeButton} onPress={onClose}>
          <Text style={styles.buttonText}>Close</Text>
        </Pressable>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  scrollView: {
    flex: 1,
    marginBottom: 10,
  },
  messageContainer: {
    marginVertical: 5,
  },
  messageBubbleLeft: {
    backgroundColor: '#f0f0f0',
    padding: 10,
    borderRadius: 10,
    maxWidth: '80%',
    alignSelf: 'flex-start',
  },
  messageBubbleRight: {
    backgroundColor: '#007bff',
    padding: 10,
    borderRadius: 10,
    maxWidth: '80%',
    alignSelf: 'flex-end',
    color: 'white',
  },
  messageContent: {
    fontSize: 16,
  },
  timestamp: {
    fontSize: 12,
    color: '#888',
    alignSelf: 'flex-end',
  },
  date: {
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    marginBottom: 5,
  },
  inputContainer: {
    flexDirection: 'row',
    padding: 10,
  },
  input: {
    flex: 1,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 10,
    padding: 10,
  },
  sendButton: {
    padding: 10,
    backgroundColor: '#007bff',
    borderRadius: 5,
  },
  closeButton: {
    padding: 10,
    backgroundColor: 'red',
    borderRadius: 5,
    marginTop: 10,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
  },
});

export default InboxChatModal;
