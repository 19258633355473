// Fr  Styles de ActivityBodyTopAttendeesListsComponent(FrameFigma044)
// En  Styles of ActivityBodyTopAttendeesListsComponent(FrameFigma044) 
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    listContainer: {
        // flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between', 
        alignItems: 'center',
        padding: 10,
        backgroundColor: 'white',
        height: 50,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgrey',
    },
    seeAllBtn: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center',
        padding: 20,
        elevation: 10,
    },
    modal: {
        width: 400,
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: 'white',
        gap: 20, 
        padding: 10,
        borderRadius: 10,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    },
    close: {
        alignSelf:'flex-end',
    },
})

export default styles;