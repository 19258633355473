// Fr Ce component affiche le haut de la page adresse la carte googlemaps(FrameFigma042)
// En This component displays the top of the page adress the googlemap(FrameFigma042)

// Import Modules
import React from "react";
import { View, Platform } from "react-native";

// Import Components
import AddressMapPickerWeb from "../../../../../components/AddressMapPicker/AdressMapPickerWeb/AddressMapPickerWeb";
import AddressMapPickerMobile from "../../../../../components/AddressMapPicker/AdressMapPickerMobile/AddressMapPickerMobile";

const ActivityMap = ({ activity }) => {
  const selectedCoords = {
    lat: activity.location.latitude,
    lng: activity.location.longitude,
  };

  return (
    <View>
      <View style={{ zIndex: -1 }}>
        {/* view ici pour ajout des espace entre AddressAutoPicker et Map */}
        {Platform.OS === 'web' ? <AddressMapPickerWeb selectedCoords={selectedCoords} /> : <AddressMapPickerMobile selectedCoords={selectedCoords} />}
      </View>
    </View>
  );
};

export default ActivityMap;