// Fr  Styles de CreateActivityVipStep2Screen(FrameFigma039)
// En  Styles of CreateActivityVipStep2Screen(FrameFigma039)

import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    marginVertical: 15,
    gap: 30,
    paddingLeft: 25,
    paddingRight: 25,
  },
  issueButtonRow: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginBottom: 40,
    marginTop: 20,
    gap: '4%',
  },
  issueButton: {
    flex: 1,
    borderRadius: 8,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 35,
    minHeight: 42,
    minWidth: 180,
    borderColor: '#59C09B',
    marginVertical: '1%',
  },
  btnsRowContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: '5%',
  },
  repeatItem: {
    borderWidth: 1,
    width: '33%',
    overflow: 'hidden',
    padding: 7,
    height: 37,
    justifyContent: 'center',
    alignItems: 'center',
  },
  repeatItemLeft: {
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  repeatItemRight: {
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  daysContainer: {
    maxWidth: '100%',
    gap: '6%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '10%',
    marginTop: '6%',
  },
  dayButton: {
    flex: 1,
    height: 46,
    padding: 8,
    borderWidth: 1,
    marginBottom: '2%',
  },
  buttonText: {
    fontSize: 16,
    textAlign: 'center',
  },
  btnSelectedGreen: {
    backgroundColor: '#59C09B',
  },
  buttonTextWhite: {
    color: 'white',
  },
  textbtn: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  switchBtn: {
    marginTop: 20,
    marginBottom: 2.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  parityContainer: {
    gap: 15,
  },
  twoSliders: {
    gap: 15,
  },
  sliderText: {
    fontSize: 18,
    fontWeight: 500,
    marginVertical: 10,
  },
  btnContainer: {
    width: '100%',
    alignContent: 'center',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  infoLineText: {
    position: 'absolute',
    top: -10,
    backgroundColor: 'white',
    left: 20,
    paddingHorizontal: 10,
  },
  infoLineInput: {
    height: 36,
    borderWidth: 1,
    borderRadius: 15,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 5,
  },
});
