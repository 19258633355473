// Fr Cette page affiche l'étape 2 du formulaire pour modifier une activité de type VIP(FrameFigma039)
// En This page displays the second step of the form to edit an activity of type VIP (FrameFigma039)

import React, {useState, useEffect} from 'react';
import {ScrollView, View, Text, TextInput, Pressable, Platform, TouchableOpacity} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useUser} from '../../../../contexts/UserContext';
import {hostname} from '../../../../../hostName/hostName';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import Modal from '../../../../components/Modal/Modal';
import DateTimePickerComponent from '../../../../components/DateTimePicker/DateTimePickerMobile/DateTimePickerMobile';
import DateTimePickerModal from '../../../../components/DateTimePicker/DateTimePickerWeb/DateTimePickerWeb';
import axios from 'axios';
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const EditActivityVipStepTwo = ({currentActivity, onNextStep, onPrevStep}) => {
  const {translations} = useLanguage();
  const {user} = useUser();

  // var pour afficher le pop up ComingSoon
  const [comingSoonVisible, setComingSoonVisible] = useState(false);

  const [switchBtnOption, setSwitchBtnOption] = useState(false);
  const [repeatEvent, setRepeatEvent] = useState(false);
  const [manageParity, setManageParity] = useState(false);
  const [sliderSexeValue, setSliderSexeValue] = useState(50);
  const [sliderFriendValue, setSliderFriendValue] = useState(3);
  const [sliderAgeValue, setSliderAgeValue] = useState([18, 99]);
  const [selectedDateExisting, setSelectedDateExisting] = useState(null);

  const [formData, setFormData] = useState({
    optionalselected: [],
    repeatChoice: '',
    repeatDays: [],
    endDate: '',
    infoLine: '',
  });
  //  a modifier plus tard

  const optionalFeatures = [
    {id: 'parity', text: '50% Guys 50% girls'},
    {id: 'girls', text: 'Girls only'},
    {id: 'guys', text: 'Guys only'},
    {id: 'friendsOnly', text: 'Event visible for friends only'},
    {id: 'PhoneVisible', text: 'Phone number visble to attendees'},
  ];

  const days = [
    {id: 1, text: 'Monday'},
    {id: 2, text: 'Tuesday'},
    {id: 3, text: 'Wednesday'},
    {id: 4, text: 'Thursday'},
    {id: 5, text: 'Friday'},
    {id: 6, text: 'Saturday'},
    {id: 7, text: 'Sunday'},
  ];

  const handleOptionPress = btn => {
    setFormData(prevFormData => ({
      ...prevFormData,
      optionalselected: prevFormData.optionalselected.includes(btn)
        ? prevFormData.optionalselected.filter(b => b !== btn)
        : [...prevFormData.optionalselected, btn],
    }));
  };

  const handleDayPress = btn => {
    setFormData(prevFormData => ({
      ...prevFormData,
      repeatDays: prevFormData.repeatDays.includes(btn) ? prevFormData.repeatDays.filter(b => b !== btn) : [...prevFormData.repeatDays, btn],
    }));
  };

  const handleStringChange = (key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    // get data of current activty
    if (currentActivity) {
      setSwitchBtnOption(currentActivity.optionalFeaturesIsSelected);
      setRepeatEvent(currentActivity.repeatEvent);
      setManageParity(currentActivity.manageParityIsSelected);
      setSliderSexeValue(currentActivity.manageParityMalePercentage || 50);
      setSliderFriendValue(currentActivity.manageParityFriendsAllowed || 3);
      setSliderAgeValue(currentActivity.ages.length > 0 ? currentActivity.ages : [18, 99]);
      setFormData({
        optionalselected: currentActivity.optionalFeaturesOptions.length > 0 ? JSON.parse(currentActivity.optionalFeaturesOptions) : [],
        repeatChoice: currentActivity.repeatEventFrequency || '',
        repeatDays: currentActivity.repeatEventDays.length > 0 ? JSON.parse(currentActivity.repeatEventDays) : [],
        endDate: currentActivity.repeatEventEndDate !== '' ? new Date(JSON.parse(currentActivity.repeatEventEndDate).date) : null,
        infoLine: currentActivity.manageParityInfoLine || '',
      });
    }
  }, [currentActivity]);
  console.log(currentActivity.repeatEventEndDate);
  console.log(formData.endDate);

  const saveActivity = async () => {
    const savedTitle = await AsyncStorage.getItem('activityTitle');
    const savedDate = await AsyncStorage.getItem('selectedDate');
    const savedEndTime = await AsyncStorage.getItem('endTime');
    const savedAttendeeLimit = await AsyncStorage.getItem('AttendeeLimitation');
    const savedHasPrice = await AsyncStorage.getItem('hasPrice');
    const savedPrice = await AsyncStorage.getItem('priceValue');
    const savedTopic = await AsyncStorage.getItem('topic');

    const switchBtnUnlimited = JSON.parse(await AsyncStorage.getItem('switchBtnUnlimited'));
    let attendeeLimitValue = switchBtnUnlimited ? 1000000 : parseInt(savedAttendeeLimit, 10);

    const userId = await AsyncStorage.getItem('userId');
    const latitude = parseFloat(await AsyncStorage.getItem('latitudeActivities'));
    const longitude = parseFloat(await AsyncStorage.getItem('longitudeActivities'));
    const hour = await AsyncStorage.getItem('selectedHour');
    const minute = await AsyncStorage.getItem('selectedMinute');
    const postalCode = await AsyncStorage.getItem('postalCode');
    const address = await AsyncStorage.getItem('city');
    const locality = await AsyncStorage.getItem('locality');

    const startTime = {
      hour: parseInt(hour, 10),
      minute: parseInt(minute, 10),
    };

    // Assumer que endTime n'est pas configuré et est donc null
    const endTime = null;

    // les donnes selectionné dans CreateActivityCommonStep3Screen
    const savedActivityImage = await AsyncStorage.getItem('activityImage');
    const savedDescription = await AsyncStorage.getItem('activityDescription');
    const savedHowToFind = await AsyncStorage.getItem('howToFind');

    // les donnes dans ecran actuel, c a dire dans CreateActivityVipStep2Screen
    const ages = sliderAgeValue.map(age => Math.round(age));

    const activityData = {
      title: savedTitle,
      author: userId,
      date: savedDate,
      startTime: startTime, // Assurez-vous que startTime est un objet
      endTime: endTime, // De même pour endTime s'il est non null
      attendeeLimit: attendeeLimitValue,
      attendees: [userId],
      hasPrice: savedHasPrice,
      price: savedPrice,
      topic: savedTopic,
      activityImage: savedActivityImage,
      location: {address, latitude, longitude, postalCode, locality},
      description: savedDescription,
      howToFind: savedHowToFind,
      createAtivityType: await AsyncStorage.getItem('selectedQuestion'),
      localOnlyForAttendees: await AsyncStorage.getItem('switchBtn1'),
      inviteCommunity: await AsyncStorage.getItem('switchBtn2'),
      buyTicketsLink: await AsyncStorage.getItem('buyTicketsLink'),
      friendsNumber: await AsyncStorage.getItem('friendsNumber'),
      notifyPreviousAttendees: await AsyncStorage.getItem('notifyPreviousAttendees'),
      inviteMore: await AsyncStorage.getItem('inviteMore'),
      requestCoOrga: await AsyncStorage.getItem('requestCoOrga'),
      coOrganizerConditions: JSON.parse(await AsyncStorage.getItem('coOrganizerConditions')),
      coOrganizerGifts: JSON.parse(await AsyncStorage.getItem('coOrganizerGifts')),
      attendeesValidation: await AsyncStorage.getItem('attendeesValidation'),
      communityOptionIsSelected: await AsyncStorage.getItem('chooseCommunity'),
      communityMeDistance: await AsyncStorage.getItem('aroundMeSliderValue'),
      communityMeetingPointDistance: await AsyncStorage.getItem('aroundPointSliderValue'),
      peopleMetNotif: await AsyncStorage.getItem('peopleMet'),
      peopleMetAsOrgaNotif: await AsyncStorage.getItem('peopleMetOrga'),
      peopleWhoLoveMyTopicNotif: await AsyncStorage.getItem('peopleLoveTopics'),
      peopleLanguageOptions: await AsyncStorage.getItem('peopleChoiceLanguages'),
      peopleLanguageIsSelected: await AsyncStorage.getItem('peopleMyLanguage'),
      optionalFeaturesIsSelected: switchBtnOption,
      optionalFeaturesOptions: formData.optionalselected || [],
      repeatEvent: repeatEvent || false,
      repeatEventFrequency: formData.repeatChoice || '',
      repeatEventDays: formData.repeatDays || [],
      repeatEventEndDate: formData.endDate || '',
      manageParityIsSelected: manageParity || false,
      manageParityMalePercentage: sliderSexeValue || 50,
      manageParityInfoLine: formData.infoLine || '',
      manageParityFriendsAllowed: sliderFriendValue || 3,
      ages: ages || [18, 99],
    };

    console.log('activityData', activityData);

    const formDataToSend = new FormData();
    // Ajouter chaque propriété de activityData comme un champ dans le FormData
    Object.keys(activityData).forEach(key => {
      if (typeof activityData[key] === 'object' && activityData[key] !== null) {
        formDataToSend.append(key, JSON.stringify(activityData[key]));
      } else {
        formDataToSend.append(key, activityData[key]);
      }
    });

    // Ajouter l'image s'il y en a une
    if (savedActivityImage && savedActivityImage.file) {
      formDataToSend.append('activityImage', savedActivityImage.file);
    }

    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await axios.put(`${hostname}/activitieOne/${currentActivity._id}`, formDataToSend, {
        headers: {
          Authorization: 'Bearer ' + userToken,
          // 'Content-Type': 'application/json',
        },
      });

      console.log('Valeurs à envoyer :', activityData);
      console.log('Corps de la requête :', JSON.stringify(activityData));

      if (response.status === 200) {
        Platform.OS === 'web' ? navigation.navigate('Activities') : navigation.navigate('Home');

        // Récupérer toutes les clés du AsyncStorage
        const allKeys = await AsyncStorage.getAllKeys();

        // Filtrer les clés à conserver
        const keysToKeep = allKeys.filter(
          key => key === 'selectedLanguage' || key === 'selectedLanguageName' || key === 'userToken' || key === 'userId',
        );

        // Supprimer les clés restantes
        await AsyncStorage.multiRemove(allKeys.filter(key => !keysToKeep.includes(key)));

        // Ajouter un console.log pour vérifier que les clés ont été supprimées
        const remainingKeys = await AsyncStorage.getAllKeys();
        console.log('Clés restantes dans AsyncStorage :', remainingKeys);
      } else {
        console.error('Échec de la sauvegarde de l activité sur le serveur:', response.status, response.statusText);
        console.error("Détails de l'erreur du serveur:", await response.text());
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de l activité:', error);
    }
  };

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <View style={styles.switchBtn}>
        {/* json a corriger */}
        {/* {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures} */}
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures}
        </Text>
        <SwitchBtn
          state={switchBtnOption}
          // setState={setSwitchBtnOption}
          setState={setComingSoonVisible}
        />
      </View>
      {switchBtnOption === true && (
        <View style={styles.issueButtonRow}>
          {optionalFeatures.map(button => (
            <View key={button.id}>
              <TouchableOpacity
                style={[styles.issueButton, formData.optionalselected.includes(button.id) && styles.btnSelectedGreen]}
                onPress={() => handleOptionPress(button.id)}>
                <Text style={[styles.buttonText, formData.optionalselected.includes(button.id) && styles.buttonTextWhite]}>{button.text}</Text>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      )}
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonRepeatEvent} :{' '}
        </Text>
        <SwitchBtn
          state={repeatEvent}
          // setState={setRepeatEvent}
          setState={setComingSoonVisible}
        />
      </View>
      {repeatEvent === true && (
        <View style={styles.repeatContainer}>
          <View style={styles.btnsRowContainer}>
            <Pressable
              style={[styles.repeatItem, styles.repeatItemLeft, formData.repeatChoice === 'weekly' && styles.btnSelectedGreen]}
              onPress={() => handleStringChange('repeatChoice', 'weekly')}>
              <Text style={[styles.textbtn, formData.repeatChoice === 'weekly' && styles.buttonTextWhite]}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonWeekly}
              </Text>
            </Pressable>
            <Pressable
              style={[styles.repeatItem, formData.repeatChoice === 'monthly' && styles.btnSelectedGreen]}
              onPress={() => handleStringChange('repeatChoice', 'monthly')}>
              <Text style={[styles.textbtn, formData.repeatChoice === 'monthly' && styles.buttonTextWhite]}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonMonthly}
              </Text>
            </Pressable>
            <Pressable
              style={[styles.repeatItem, styles.repeatItemRight, formData.repeatChoice === 'yearly' && styles.btnSelectedGreen]}
              onPress={() => handleStringChange('repeatChoice', 'yearly')}>
              <Text style={[styles.textbtn, formData.repeatChoice === 'yearly' && styles.buttonTextWhite]}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonYearly}
              </Text>
            </Pressable>
          </View>
          {/* json a corriger, pas pareil que figma */}
          <Text style={styles.textbtn}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonSelectDays}
          </Text>
          <View style={styles.daysContainer}>
            {days.map(day => (
              <View key={day.id}>
                <TouchableOpacity
                  style={[
                    styles.dayButton,
                    formData.repeatDays.includes(day.id) && [styles.btnSelectedGreen, {borderWidth: 0}],
                    day.id === 7 ? {width: 199} : {width: 131},
                  ]}
                  onPress={() => handleDayPress(day.id)}>
                  <Text style={[styles.buttonText, formData.repeatDays.includes(day.id) && styles.buttonTextWhite]}>{day.text}</Text>
                </TouchableOpacity>
              </View>
            ))}
          </View>
          <View style={styles.dateContainer}>
            {/* pas encore tester en mobile  */}
            <DateTimePickerModal
              // json a ajouter
              title="Set an end date"
              onDateChangeForOnlyDate={date => handleStringChange('endDate', date)}
              type="onlyDate"
              selectedDateExisting={currentActivity.repeatEventEndDate !== '' ? new Date(JSON.parse(currentActivity.repeatEventEndDate).date) : null}
            />
          </View>
        </View>
      )}
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonParity}
        </Text>
        <SwitchBtn
          state={manageParity}
          // setState={setManageParity}
          setState={setComingSoonVisible}
        />
      </View>
      {manageParity === true && (
        <View style={styles.parityContainer}>
          <SliderComponent min={0} max={100} sliderValue={sliderSexeValue} setSliderValue={setSliderSexeValue} type="parity" />
          <View style={styles.inputContainer}>
            <Text style={styles.infoLineText}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonInfoLine}
            </Text>
            <TextInput style={styles.infoLineInput} value={formData.infoLine} onChangeText={text => handleStringChange('infoLine', text)} />
          </View>

          <View style={styles.twoSliders}>
            <View>
              {/* json a ajouter  */}
              <Text style={styles.sliderText}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonAllowGuests}:{' '}
              </Text>
              <SliderComponent min={1} max={5} sliderValue={sliderFriendValue} setSliderValue={setSliderFriendValue} />
            </View>
            <View>
              <Text style={styles.sliderText}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonAgeRestrict}
              </Text>
              <SliderComponent
                min={18}
                max={99}
                sliderValue={sliderAgeValue}
                setSliderValue={setSliderAgeValue}
                type="age"
                translations={translations}
              />
            </View>
          </View>
        </View>
      )}
      <Modal
        modalVisible={comingSoonVisible}
        onClose={() => setComingSoonVisible(false)}
        children={<Text>{translations.JsonConceptScreen.JsonConceptPageSevenTitle}</Text>}
      />
      <View style={styles.btnContainer}>
        <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
        <ButtonNext
          // conditionNeed={
          //     switchBtnOption === true && formData.optionalselected.length > 0
          //     || repeatEvent === true && formData.repeatChoice !== '' && formData.repeatDays.length > 0 && formData.endDate !== null
          //     || manageParity === true && formData.infoLine !== ''}
          handleNextStep={saveActivity}
          btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPublishButton}
        />
      </View>
    </ScrollView>
  );
};

export default EditActivityVipStepTwo;
