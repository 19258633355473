//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷 Ce fichier gère la page qui permet d'interagir avec notifications. (FrameFigma024C )
// 🇬🇧 This file manages the page used to interact with our notifications.  (FrameFigma024C )


import { View, Text } from 'react-native'
import React from 'react'

const NotificationsInteractScreen = () => {
  return (
    <View>
      <Text>NotificationsInteractScreen</Text>
    </View>
  )
}

export default NotificationsInteractScreen