const styles = {
  menuItem: {
    marginLeft: 20,
    fontWeight: 'bold',
  },
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    //backgroundColor: 'rgba(0, 0, 0, 0.5)',
    //with: 150,
  },
  modalContent: {
    width: 250,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    elevation: 5,
  },
  modalText: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'center',
  },
  modalButton: {
    padding: 10,
    margin: 5,
    backgroundColor: '#2196F3',
    borderRadius: 5,
  },
  modalButtonText: {
    color: 'white',
    textAlign: 'center',
  },
  drawerItemText: {
    fontWeight: 'bold', // Texte en gras
    flex: 1, // Prend tout l'espace disponible en largeur
    marginLeft: 45, // Espacement à gauche pour l'icône et le texte
    fontSize: 23,
    marginTop: "-10%",
  },
};

export default styles;
