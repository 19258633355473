// Fr Ce component affiche le haut de la page participants avec(FrameFigma044)
// En This component displays the top of the page participants(FrameFigma044)

// Import Modules
import React, { useState } from "react";
import { View, Text, Pressable, Platform } from "react-native";

// Import Components
import ApprovalModal from "./ApprovalModal";
import Modal from "../../../../../components/Modal/Modal";

// Import Contexts
import { useAuth } from "../../../../../contexts/AuthContext";
import { useLanguage } from "../../../../../contexts/LanguageContext";

// Import Icons
import { Icons } from "../../../../../constants/Icons";

// Import Styles
const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const ActivityAttendeesLists = ({ activity, userStatus, userList }) => {
  // Contexts
  const { user } = useAuth();
  const { translations } = useLanguage();

  // States
  const [modalVisible, setModalVisible] = useState(false);
  const [listSelected, setListSelected] = useState(null);

  // Local Variables
  const coOrgCount = activity && activity.coOrganizerRequests.length > 0 ? activity.coOrganizerRequests.length : 0;
  const waitingListCount = activity && activity.waitingList.length > 0 ? activity.waitingList.length : 0;
  const unApprovedListCount = activity && activity.unApprovedUsers?.length > 0 ? activity.unApprovedUsers.length : 0;
  const activityIsFull = activity && activity.attendeeLimit <= activity.attendees.length + activity.coOrganizers.length;

  const lists = [
    {name: translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonUnapprovedList, count: unApprovedListCount},
    {name: translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll, JsonCoOrganizerWaitingList: coOrgCount},
    {name: translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll, JsonWaitingList: waitingListCount},
  ]

  // Find User Details from All Lists
  const coOrgUsers = userList.filter((u) => activity.coOrganizerRequests.includes(u._id));
  const waitingListUsers = userList.filter((u) => activity.waitingList.includes(u._id));
  const unApprovedUsers = userList.filter((u) => activity.unApprovedUsers.includes(u._id));

  // Check if logged in user is in co-organiser waiting list
  const isInCoOrgWaitingList = coOrgUsers?.find((u) => u._id === user?._id);

  return (
    <View>
      <>
        {userStatus.author? (
          <View>
            {lists.map((list, index) => (
              <View key={index} style={styles.listContainer}>
                <Text>{list.name} ({list.count})</Text>
                <Pressable style={styles.seeAllBtn} onPress={() => {setModalVisible(true), setListSelected(index)}}>
                  <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll}</Text>
                  <Icons.RightArrow width={15} height={15} />
                </Pressable>
              </View>
            ))}
          </View>
        ) : (
          <View>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonHelpOrganizerOrCreateOwnActivityMessage}</Text>
            {isInCoOrgWaitingList ? 
              <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonWaitingListComfirmation}</Text>
            : (
              <Pressable>
                <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonHelpAndOrganisation}</Text>
              </Pressable>
            )
            }
          </View>
        )}

        <Modal modalVisible={modalVisible} onClose={() => setModalVisible(false)}>
          <ApprovalModal
            listSelected={listSelected} 
            userList={listSelected === 1 && coOrgUsers || listSelected === 2 && waitingListUsers || listSelected === 0 && unApprovedUsers}
            activity={activity}
            activityIsFull={activityIsFull}
            closeModal={() => setModalVisible(false)} 
          />
        </Modal>
      </>
    </View>
  )
};

export default ActivityAttendeesLists