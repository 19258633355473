import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  addressContent:{
    width:'80%'
  },
  nickNameContent: {
    // marginTop: '20%',
    alignItems: 'center',
    zIndex: -5,
  },
  nicknameContainer: {
    width: '80%',
    zIndex: -5,
  },
  btnContainer: {
    width: '80%',
    alignItems: 'center',
    marginTop: '1%',
    zIndex: -5,
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  comment: {
    marginTop: 10,
    fontSize: 12,
    width: '60%',

  },
  nickNameMessage: {
    textAlign: "center",
    marginTop: 15,
    marginBottom: 1,
    marginHorizontal:5

  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)', 
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  nickName: {
    // flexDirection: 'row',
    // alignItems: 'center',
    width: '100%',
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold',
  },
});
