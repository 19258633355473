/*FR
  Module définissant un objet Fields avec des composants SVG représentant des icônes.
  Centralise les icônes utilisées dans l'application pour une utilisation modulaire. (frame 003/003/004/009/010/011/012/022popup suite 2/022A/021B2/091/022B)
*/
/*GB
  This module defines a Fields object with SVG components representing icons.
  It centralizes the icons used in the application for modular usage. (frame 003/003/004/009/010/011/012/022popup suite 2/022A/021B2/091/022B)
*/
import React from 'react';
import Arobase from '../../assets/Icons/Profiles/Arobase.svg';
import Password from '../../assets/Icons/Profiles/PasswordPadLock.svg';
import FemaleSign from '../../assets/Icons/Profiles/FemaleSign.svg';
import MaleSign from '../../assets/Icons/Profiles/MaleSign.svg';
import LeftArrow from '../../assets/Icons/General/Colors/LeftArrow.svg';
import LastName from '../../assets/Icons/Profiles/LastName.svg';
import Language from '../../assets/Icons/Profiles/Language.svg';
import City from '../../assets/Icons/Profiles/City.svg';
import NoGender from '../../assets/Icons/Profiles/NoGender.svg';
import FemaleIcon from '../../assets/Icons/Profiles/Female.svg';
import MaleIcon from '../../assets/Icons/Profiles/Male.svg';
import Camera from '../../assets/Icons/Profiles/Camera.svg';
import EyeToggle from '../../assets/Icons/General/Colors/EyeToggle.svg';
import EyeToggleOff from '../../assets/Icons/General/Colors/EyeToggleCross.svg';
import NextWhite from '../../assets/Icons/General/BlackAndWhite/NextWhite.svg';
import Loop from '../../assets/Icons/General/BlackAndWhite/Loop.svg';
import PrecidentWhite from '../../assets/Icons/General/BlackAndWhite/PreviousWhite.svg';
import CalendarOff from '../../assets/Icons/NavBar/BlackAndWhite/CalendarBottom.svg';
import CalendarOn from '../../assets/Icons/NavBar/Colors/CalendarBottom.svg';
import ContactOn from '../../assets/Icons/NavBar/Colors/ContactUs.svg';
import ContactOff from '../../assets/Icons/NavBar/BlackAndWhite/Contact-us.svg';
import ConceptOn from '../../assets/Icons/NavBar/Colors/Concept.svg';
import ConceptOff from '../../assets/Icons/NavBar/BlackAndWhite/Concept.svg';
import NewsOn from '../../assets/Icons/NavBar/Colors/News.svg';
import NewsOff from '../../assets/Icons/NavBar/BlackAndWhite/News.svg';
import MembersOff from '../../assets/Icons/NavBar/BlackAndWhite/MembersBottom.svg';
import MembersOn from '../../assets/Icons/NavBar/Colors/MembersBottomColor.svg';
import Upload from '../../assets/Icons/General/Colors/Upload.svg';
import ImageGallery from '../../assets/Icons/General/BlackAndWhite/ImageGallery.svg';
import UnsplashFill from '../../assets/Icons/General/BlackAndWhite/UnsplashFill.svg';
import Participants from '../../assets/Icons/UsersInteraction/BlackAndWhite/Participants.svg';
import LocalisationIcon from '../../assets/Icons/Maps/PinBlackAndWhite.svg';
import HeartGrey from '../../assets/Icons/UsersInteraction/BlackAndWhite/HeartGrey.svg';
import HeartRed from '../../assets/Icons/UsersInteraction/Colors/HeartRed.svg';
import HeartEmpty from '../../assets/Icons/UsersInteraction/Colors/HeartEmpty.svg';
import Share from '../../assets/Icons/General/BlackAndWhite/ShareWhite.svg';
import PinGoogleMaps from '../../assets/Icons/Maps/PinGoogleMaps.svg';
import Edit from '../../assets/Icons/Profiles/Edit.svg';
import Trash from '../../assets/Icons/Profiles/Trash.svg';

import QrCode from '../../assets/Icons/General/Colors/QrCode.svg';
import LogOut from '../../assets/Icons/General/Colors/LogOut.svg';
import News from '../../assets/Icons/NavBar/Colors/News.svg';
import InviteFriends from '../../assets/Icons/General/Colors/InviteFriends.svg';
import Settings from '../../assets/Icons/General/Colors/Settings.svg';
import Update from '../../assets/Icons/General/Colors/Update.svg';
import CreateAnActivity from '../../assets/Icons/General/Colors/CreateAnActivity.svg';
import GoldStar from '../../assets/Icons/General/Colors/IconsGoldStar.svg';
import MapOn from '../../assets/Icons/NavBar/Colors/Map.svg';
import MapOff from '../../assets/Icons/NavBar/BlackAndWhite/Map.svg';
import BellOn from '../../assets/Icons/Notifications/BellOn.svg';
import BellOff from '../../assets/Icons/NavBar/BlackAndWhite/Bell.svg';
import MessengerOn from '../../assets/Icons/NavBar/Colors/Messenger.svg';
import MessengerOff from '../../assets/Icons/NavBar/BlackAndWhite/Messenger.svg';
const Fields = {
  IconArobase: () => <Arobase />,
  IconPassword: () => <Password />,
  IconFemaleSign: () => <FemaleSign />,
  IconMaleSign: () => <MaleSign />,
  IconLeftArrow: () => <LeftArrow />,
  IconLastName: () => <LastName />,
  IconLanguage: () => <Language/>,
  IconCity: () => <City />,
  IconNoGender: () => <NoGender />,
  IconNoGenderNormalSize: () => <NoGender/>,//IconNoGender trop petit pour certain page
  IconFemaleIcon: () => <FemaleIcon />,
  IconMaleIcon: () => <MaleIcon />,
  IconCamera: () => <Camera />,
  IconEyeToggle: () => <EyeToggle />,
  IconEyeToggleOff: () => <EyeToggleOff />,
  IconNextWhite: () => <NextWhite />,
  IconLoop: () => <Loop />,
  IconPrecidentWhite: () => <PrecidentWhite />,
  IconContactOn: () => <ContactOn />,
  IconContactOff: () => <ContactOff />,
  IconNewsOn: () => <NewsOn />,
  IconNewsOff: () => <NewsOff />,
  IconConceptOn: () => <ConceptOn />,
  IconConceptOff: () => <ConceptOff />,
  IconMembersOn: () => <MembersOn />,
  IconMembersOff: () => <MembersOff />,
  IconCalendarOff: () => <CalendarOff />,
  IconCalendarOn: () => <CalendarOn />,
  IconUpload: () => <Upload style={{width: 20, height: 20}}/>,
  ImageGallery: () => <ImageGallery />,
  UnsplashFill: () => <UnsplashFill />,
  Participants: () => <Participants />,
  LocalisationIcon: () => <LocalisationIcon style={{width: 22, height: 22}} />,
  MapOn: () => <MapOn style={{width: 30, height: 30}} />,
  MapOff: () => <MapOff style={{width: 30, height: 30}} />,
  BellOn: () => <BellOn style={{width: 50, height: 50}} />,
  BellOff: () => <BellOff style={{width: 30, height: 30}} />,
  MessengerOn: () => <MessengerOn style={{width: 30, height: 30}} />,
  MessengerOff: () => <MessengerOff style={{width: 30, height: 30}} />,
  HeartGrey: () => <HeartGrey />,
  HeartRed: () => <HeartRed />,
  HeartEmpty: () => <HeartEmpty style={{width: 50, height: 50}} />,
  Share: () => <Share style={{width: 20, height: 20}} />,
  PinGoogleMaps: () => <PinGoogleMaps style={{width: 50, height: 50}} />,
  Edit: () => <Edit style={{width: 35, height: 35}} />,
  EditSmall: () => <Edit style={{width: 15, height: 15}} />,
  Trash: () => <Trash />,

  QrCode: () => <QrCode style={{width: 35, height: 35}} />,
  LogOut: () => <LogOut style={{width: 35, height: 35}} />,
  News: () => <News style={{width: 35, height: 35}} />,
  InviteFriends: () => <InviteFriends style={{width: 35, height: 35}} />,
  Settings: () => <Settings style={{width: 35, height: 35}} />,
  Update: () => <Update style={{width: 35, height: 35}} />,
  CreateAnActivity: () => <CreateAnActivity style={{width: 35, height: 35}} />,
  GoldStar: () => <GoldStar />,
};

export default Fields;
