// Fr  Styles de ActivityBodyBottomAddressComponent( FrameFigma042 )
// En  Styles of ActivityBodyBottomAddressComponent( FrameFigma042 ) 

import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 20,
    marginHorizontal: 50,
  },
  interessedContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  interessedText: {
    color: 'gray',
  },
  follower: {
    flexDirection: 'row',
  },
  followerHeart: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  heartWithText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -4 }, { translateY: -25 }],
    flexDirection: 'row',
    alignItems: 'center',
  },
  heartText: {
    color: 'gray',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: -35,
  },
  participantContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '95%',
    marginVertical: 15,
    marginTop: '10%',
    marginLeft: 10,
  },
  btnContent: {
    width: '48%',
  },
  btnParticipate: {
    backgroundColor: '#59C09B',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderRadius: 10,
  },
  btnUnsubscribe: {
    backgroundColor: 'red',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderRadius: 10,
  },
  editTrash: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  btnShare: {
    backgroundColor: 'gray',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnText: {
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
  },
  aboutText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  description: {
    padding: 10,
    backgroundColor: "white",
    marginVertical: 20,
  },
  descriptionText: {
    marginTop: 10,
    padding: 5,
    color: "gray",
    textAlign: "justify",
    height: 200,
  },
  btnHelp: {
    backgroundColor: '#FFD700',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnCopy: {
    backgroundColor: 'gray',
    paddingVertical: 12,
    paddingHorizontal: 13,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnHelpText: {
    color: 'white',
    fontSize: 15,
    textAlign: 'center',
  },
  btnCopyText: {
    color: 'black',
    fontSize: 12.6,
    textAlign: 'center',
  }
})

export default styles;