// Fr Cette page affiche un formulaire pour copier une activité étape par étape (FrameFigma031)
// En This component displays a form to copy an activity step by step (FrameFigma031)

// Import Modules
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';

// Import Components
import CopyActivityStepOne from './CopyActivityStep1Screen/CopyActivityStep1Screen';
import CopyActivityStepTwo from './CopyActivityStep2Screen/CopyActivityStep2Screen';
import CopyActivityStepThree from './CopyActivityStep3Screen/CopyActivityStep3Screen';

// Import Contexts
import { useActivity } from '../../../contexts/ActivityContext';
import { useLanguage } from '../../../contexts/LanguageContext';

const CopyActivityScreen = ({ route }) => {

  // Navigation Params
  const { activityId } = route?.params;

  // Contexts
  const { activities } = useActivity();
  const { translations } = useLanguage();

  // Local Variables
  const originalActivity = activities.find(activity => activity._id === activityId);
  const [step, setStep] = useState(1);

  const onNextStep = async () => {
    if (step < 7) {
      setStep(step + 1)
    }
  };

  const onPrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <CopyActivityStepOne onNextStep={onNextStep} activity={originalActivity} />;
      case 2:
        return <CopyActivityStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} activity={originalActivity}/>;
      case 3:
        return <CopyActivityStepThree onNextStep={onNextStep} onPrevStep={onPrevStep} activity={originalActivity}/>;
      default:
        return null;
    }
  }


  return <View style={styles.PContainer}>{renderStep()}</View>;
};


const styles = StyleSheet.create({
  PContainer: {
    flex: 1,
    backgroundColor: 'white',
    paddingLeft: 25,
    paddingRight: 25,
  }
})

export default CopyActivityScreen;
