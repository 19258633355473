import React, {useState, useRef, useEffect} from 'react';
import {View, Text, Pressable} from 'react-native';
import ReactCrop, {centerCrop, makeAspectCrop, convertToPixelCrop} from 'react-image-crop';
import {canvasPreview} from './CanvasPreview';
import {useDebounceEffect} from './useDebounceEffect';
import 'react-image-crop/dist/ReactCrop.css';

// Import Icons
import {Icons} from '../../constants/Icons';

// Import Styles
const styles = require('./StylesWeb').default;

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const ImageCropperWeb = ({file, handleCroppedImage, imgType, hideToggleAspect}) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);

  const selectAspect = () => {
    if (imgType === 'profileImage') {
      setAspect(1); // Crop en Carré seulement
    }
  };

  const handleFile = () => {
    if (file instanceof File) {
      setCrop(undefined); // Makes crop preview update between images
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
      });
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    selectAspect();
    handleFile();
  }, [file]);

  function onImageLoad(e) {
    if (aspect) {
      const {width, height} = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  async function saveCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop?.width || !completedCrop?.height) {
      throw new Error('Crop canvas does not exist or crop is not completed');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    // Drawing the cropped area
    ctx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height);

    // Create a canvas to add white borders
    const finalCanvas = new OffscreenCanvas(image.naturalWidth, image.naturalHeight);
    const finalCtx = finalCanvas.getContext('2d');
    if (!finalCtx) {
      throw new Error('No 2d context');
    }

    // Fill the canvas with white
    finalCtx.fillStyle = 'white';
    finalCtx.fillRect(0, 0, finalCanvas.width, finalCanvas.height);

    // Calculate the offset to center the cropped image
    const offsetX = (finalCanvas.width - offscreen.width) / 2;
    const offsetY = (finalCanvas.height - offscreen.height) / 2;

    // Draw the cropped image onto the final canvas
    finalCtx.drawImage(offscreen, offsetX, offsetY);

    // Convert the final canvas to a blob
    const blob = await finalCanvas.convertToBlob({type: 'image/jpeg'});

    const imageUrl = URL.createObjectURL(blob);
    console.info('Image URL:', imageUrl);

    const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
    const croppedFileName = `${fileNameWithoutExtension}_cropped.jpeg`;

    const croppedImageFile = new File([blob], croppedFileName, {type: 'image/jpeg'});

    console.info('Cropped Image File:', croppedImageFile);

    return {file: croppedImageFile, imageUrl};
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined); // Freeform Crop
    } else {
      setAspect(16 / 9);

      if (imgRef.current) {
        const {width, height} = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(newCrop);
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  return (
    <View style={styles.container}>
      {!hideToggleAspect && (
        <View className="Crop-Controls">
          <View>
            <Pressable
              onPress={handleToggleAspectClick}
              disabled={imgType === 'profileImage'}
              style={imgType === 'profileImage' ? [styles.toggleAspectButton, styles.toggleAspectButton.disabled] : styles.toggleAspectButton}>
              {aspect ? (
                <Icons.PadLock width={20} height={20} fill={imgType === 'profileImage' ? '#999' : '#000'} />
              ) : (
                <Icons.PadLockOpen width={20} height={20} />
              )}
              <Text style={imgType === 'profileImage' && styles.toggleAspectButton.disabled.text}>Toggle Aspect</Text>
            </Pressable>
          </View>
        </View>
      )}
      {!!imgSrc && (
        <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)} aspect={aspect}>
          <img ref={imgRef} alt="Crop me" src={imgSrc} style={{transform: `scale(${scale}) rotate(${rotate}deg)`}} onLoad={onImageLoad} />
        </ReactCrop>
      )}
      {!!completedCrop && (
        <>
          <View>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </View>
        </>
      )}
      <Pressable
        onPress={async () => {
          const croppedImageUrl = await saveCropClick();
          handleCroppedImage(croppedImageUrl);
        }}
        style={styles.saveButton}>
        <Icons.CheckSignGeneralColor width={40} height={40} />
      </Pressable>
    </View>
  );
};

export default ImageCropperWeb;
