//FR un écran permettant aux utilisateurs de modifier des détails supplémentaires de leur profil
//EN screen allowing users to edit additional details of their profile
import React, {useState, useEffect} from 'react';
import {ScrollView, Text, TextInput, TouchableOpacity, View, StyleSheet, Platform} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import {activitiesIcon} from '../../../../components/Fields/ActivityListIcon';
import countriesListSquareFlags from '../../../../components/LanguagesFlags/LanguagesFlagsSquare';
import {useUser} from '../../../../contexts/UserContext';
import { useLanguage } from '../../../../contexts/LanguageContext';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const EditProfileStep3Screen = () => {
  const {user, updateUser, fetchUser} = useUser();
  const navigation = useNavigation();
  const [formData, setFormData] = useState({
    about: '',
    activitiesTopic: [],
    spokenLanguage: [],
    children: 'Secret',
    tobacco: 'Secret',
    alcohol: 'Secret',
  });
  // const selectedActivitiesIds = formData.activitiesTopic;
  const [isLoading, setIsLoading] = useState(false);
  const {translations} = useLanguage();

  useEffect(() => {
    console.log('useeffect user in step 3 ', user);
    if (user) {
      setFormData({
        about: user.about || '',
        activitiesTopic: user.activitiesTopic || [],
        spokenLanguage: user.spokenLanguage || [],
        children: user.children || 'Secret',
        tobacco: user.tobacco || 'Secret',
        alcohol: user.alcohol || 'Secret',
      });
    }
  }, [user]);

const handleAboutChange = text => {
  setFormData(prevFormData => ({
    ...prevFormData,
    about: text,
  }));
};


const handleActivitiesTopicChange = id => {
  // Assurez-vous que 'id' est au format attendu (nombre ou chaîne)
  const topicId = id.toString(); // Convertissez en chaîne si nécessaire

  const newTopics = formData.activitiesTopic.includes(topicId)
    ? formData.activitiesTopic.filter(existingId => existingId !== topicId)
    : [...formData.activitiesTopic, topicId];

  setFormData(prev => ({...prev, activitiesTopic: newTopics}));
};

const handleSpokenLanguageChange = bigram => {
  setFormData(prevFormData => ({
    ...prevFormData,
    spokenLanguage: prevFormData.spokenLanguage.includes(bigram) ? prevFormData.spokenLanguage.filter(b => b !== bigram) : [...prevFormData.spokenLanguage, bigram],
  }));
};


  const handleChildrenChange = value => {
    setFormData(prev => ({...prev, children: value}));
  };

  const handleTobaccoChange = value => {
    setFormData(prev => ({...prev, tobacco: value}));
  };

  const handleAlcoholChange = value => {
    setFormData(prev => ({...prev, alcohol: value}));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const submitData = {
      ...formData,
      activitiesTopic: formData.activitiesTopic,
    };
    try {
      await updateUser(user._id, submitData);
      console.log('User updated successfully');
      fetchUser();
      navigation.navigate('MyScreen', {userData: user, userId: user._id});
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const renderButtonGroup = (key, choices, currentValue) => (
    <View style={styles.buttonGroup}>
      {choices.map(choice => (
        <TouchableOpacity
          key={choice}
          onPress={() => {
            const handleMap = {
              children: handleChildrenChange,
              tobacco: handleTobaccoChange,
              alcohol: handleAlcoholChange,
            };
            (handleMap[key] || (() => {}))(choice);
          }}
          style={[styles.button, Array.isArray(currentValue) ? currentValue.includes(choice) : currentValue === choice ? styles.buttonSelected : {}]}>
          <Text style={styles.buttonText}>{choice}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.sectionTitlePublic}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonPublicInformations.toUpperCase()} </Text>
      <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonMoreOnYou}</Text>
      <Text style={styles.aboutYouText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonAboutYou}</Text>
      <TextInput
        style={styles.inputMoreOnYou} 
        placeholder={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonMoreOnYou}
        multiline
        value={formData.about}
        onChangeText={handleAboutChange}
      />
      
      <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonWhatILoveToDo}</Text>
      {/* <ActivityTopics/> */}
      <View style={styles.activitiesContainer}>
        {activitiesIcon.map(activity => {
          const isSelected = formData.activitiesTopic.includes(activity.id.toString());
          return (
            <View style={styles.activityView} key={activity.id}>
            <TouchableOpacity key={activity.id} onPress={() => handleActivitiesTopicChange(activity.id)} style={[
                                styles.activityCard,
                                { backgroundColor: formData.activitiesTopic.includes(activity.id.toString()) ? "#59c09b" : "white" },
                            ]}>
              {isSelected ? activity.activityIconOn : activity.activityIconOff}
              <Text
                            style={[
                                styles.activityTitle,
                                {
                                    fontWeight: isSelected ? "bold" : "400",
                                    color: isSelected ? "white" : "black",
                                },
                            ]}
                        >
                            {activity.activityTitle}
                        </Text>
            </TouchableOpacity>
            </View>
          );
        })}
      </View>
      <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSpokenLanguages}</Text>
      <View style={styles.flagsContainer}>
        {countriesListSquareFlags.map(({language, FlagComponent, bigram}) => (
          <TouchableOpacity key={bigram} onPress={() => handleSpokenLanguageChange(bigram)} style={[styles.flagWrapper, formData.spokenLanguage.includes(bigram) ? styles.flagWrapperSelected : {}]}>
            <FlagComponent width={30} height={30} />
          </TouchableOpacity>
        ))}
      </View>
      <View style={styles.PersoContainer}>
        <View style={styles.PersoContainer}>
          <Text style={styles.sectionPerso}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonChildren}</Text>
          {renderButtonGroup('children', [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret, translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes, translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo], formData.children)}

          <Text style={styles.sectionPerso}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonTobacco}</Text>
          {renderButtonGroup('tobacco', [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret, translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNever, translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSometimes], formData.tobacco)}

          <Text style={styles.sectionPerso}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonAlcohol}</Text>
          {renderButtonGroup('alcohol', [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret, translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNever, translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSometimes], formData.alcohol)}
        </View>
      </View>
      <View style={styles.ButtonContainer}>
        <TouchableOpacity onPress={handleSubmit} disabled={isLoading} style={styles.submitButton}>
          <Text>{translations.JsonCreateProfilePages.JsonSaveButton/*Save*/}</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

export default EditProfileStep3Screen;
