//(FrameFigma042) (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma032A,FrameFigma032B,FrameFigma032C)
import React, {useEffect, useState} from 'react';
import Maps from './Maps'; // Vérifiez le chemin

const AddressMapPickerWeb = ({selectedCoords}) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
      if (selectedCoords && typeof selectedCoords.lat === 'number' && typeof selectedCoords.lng === 'number') {
        // Logic for handling map updates if needed
      }
    }
  }, [map, selectedCoords]);

  return (
    <div>
      <Maps selectedCoords={selectedCoords} onMapLoad={setMap} />
    </div>
  );
};

export default AddressMapPickerWeb;


