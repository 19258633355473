// Fr Ce component affiche les petits détails sur l'organisateur tel que sa note ses points et son nom (FrameFigma041,FrameFigma042,FrameFigma043,FrameFigma044)
// En This component displays little details about the organizer like his rate, his points and his name (FrameFigma041,FrameFigma042,FrameFigma043,FrameFigma044)

// Import Modules
import React from 'react';
import {View, Text, Image, Pressable, Platform} from 'react-native';

// Import Images / Icons
import {Icons} from '../../../../../constants/Icons';

// Import Contexts / Hooks / Utils
import {useActivity} from '../../../../../contexts/ActivityContext';
import {handleLike, handleUnlike} from '../../../../../components/ActivityCard/LikedActivityContent';

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ActivityMetaDetails = ({activity, activityAuthor, isLiked, userStatus}) => {
  // Contexts
  const {getActivityLikes} = useActivity();

  // Fonction pour gérer le like
  const handleLikeAction = async () => {
    await handleLike(activity);
    getActivityLikes(); // Rafraichir les likes
  };

  // Fonction pour gérer le "unlike"
  const handleUnlikeAction = async () => {
    if (isLiked.likeId) {
      await handleUnlike(isLiked.likeId);
      getActivityLikes();
    } else {
      console.error("L'ID du like n'est pas disponible.");
    }
  };

  const formatDate = dateStr => {
    const date = new Date(dateStr);
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
    return date.toLocaleDateString('fr-FR', options);
  };

  return (
    <View style={styles.blocOne}>
      {userStatus.participant || userStatus.author ? (
        <View style={styles.address}>
          <Text style={styles.addressText}>{activity?.location.address}</Text>
        </View>
      ) : null}
      <View style={styles.titleContainer}>
        <Text style={styles.activityTitle}>{activity ? activity.title : 'Chargement...'}</Text>
      </View>
      <View style={styles.dateAndHour}>
        <Text style={styles.hour}>{activity ? `${activity.startTime.hour}:${activity.startTime.minute}` : 'Chargement...'}</Text>
        <Text style={styles.date}>{activity ? formatDate(activity.date) : 'Chargement...'}</Text>
      </View>
      <View style={styles.profileCodeHeart}>
        <View style={styles.authorImageView}>
          <Image source={{uri: activityAuthor ? activityAuthor.profileImage : null}} style={styles.authorImage} />
          <View style={styles.authorText}>
            <Text style={{fontSize: 16, fontWeight: 'bold'}}>{activityAuthor ? activityAuthor.firstName : 'Unknown'}</Text>
            <Text style={{fontSize: 10}}>5 ⭐⭐⭐⭐⭐</Text>
            <Text style={{fontSize: 10}}>{activityAuthor ? activityAuthor.appPoints : 0} points</Text>
          </View>
        </View>
        <View style={styles.postal}>
          {activity?.metroStation && activity.metroStation !== 'null' ? (
            <>
              <Icons.Metro width={50} height={50} />
              <Text>{activity?.metroStation}</Text>
            </>
          ) : (
            <>
              <Icons.PinGoogleMaps width={50} height={50} />
              <Text>{activity?.location.postalCode}</Text>
            </>
          )}
        </View>
        <View>
          <Pressable onPress={isLiked.status ? handleUnlikeAction : handleLikeAction}>
            {isLiked.status ? <Icons.HeartRed width={50} height={50} /> : <Icons.HeartGrey width={50} height={50} />}
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default ActivityMetaDetails;
