//Fr Styles de SliderComponent (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma035A,FrameFigma035B) (FrameFigma033) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
//En Styles of SliderComponent (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma035A,FrameFigma035B) (FrameFigma033) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    label: {
        fontSize: 18,
        marginRight: 10,
    },
    slider: {
        flex: 1,
        width:"100%"
    },
    valueContainer:{
        flexDirection: 'row',
        justifyContent:'space-between',
        width:"100%",
    },
    sliderValueContainer: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: [{ translateX: -10 }],
        marginTop: 20,
    },
    sliderValue: {
        fontSize: 16,
        fontWeight: 'bold',
    },
})

export default styles