//(FrameFigma041, FrameFigma042, FrameFigma043,FrameFigma044) (FrameFigma041)
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hostname } from "../../../hostName/hostName";


export const handleLike = async (activity) => {
    try {
        // Récupérer le token JWT depuis AsyncStorage
        const userToken = await AsyncStorage.getItem('userToken');
        // Récupérer l'Id de l'utilisateur
        const userId = await AsyncStorage.getItem('userId')

        // Envoi de la requête au serveur pour enregistrer le like
        const response = await fetch(`${hostname}/activityLikes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}` 
            },
            body: JSON.stringify({
                likedBy: userId, // Utiliser l'ID de l'utilisateur récupéré
                activity: activity._id,
                type: 'like', 
            }),
        });

        // Vérification de la réussite de la requête
        if (response.ok) {
            const responseData = await response.json();
            const likeId = responseData._id; // Récupérer l'ID du like créé
            // Sauvegarde de l'état "liked" dans AsyncStorage
            const userLikedActivities = await AsyncStorage.getItem('userLikedActivities');
            if (userLikedActivities) {
                const likedActivities = JSON.parse(userLikedActivities);
                likedActivities.push(likeId); // Utiliser l'ID du like créé
                await AsyncStorage.setItem('userLikedActivities', JSON.stringify(likedActivities));
            } else {
                await AsyncStorage.setItem('userLikedActivities', JSON.stringify([likeId])); // Utiliser l'ID du like créé
            }

            return likeId; // Renvoyer l'ID du like créé
        } else {
            console.error('Erreur lors de la sauvegarde du like:', response.status, response.statusText);
            console.error("Détails de l'erreur du serveur:", await response.text());
            return null;
        }
    } catch (error) {
        console.error('Erreur lors de la sauvegarde du like:', error);
        return null;
    }
};


export const handleUnlike = async (likeId) => { // Prendre l'ID du like comme argument
    try {
        const userToken = await AsyncStorage.getItem('userToken');

        const response = await fetch(`${hostname}/activityLikes/${likeId}`, { // Utiliser l'ID du like dans l'URL
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`
            },
        });

        // Vérification de la réussite de la requête
        if (response.ok) {
            // Supprimer l'état "liked" dans AsyncStorage seulement si la suppression est réussie
            const userLikedActivities = await AsyncStorage.getItem('userLikedActivities');
            if (userLikedActivities) {
                let likedActivities = JSON.parse(userLikedActivities);
                likedActivities = likedActivities.filter(id => id !== likeId); // Utiliser l'ID du like
                await AsyncStorage.setItem('userLikedActivities', JSON.stringify(likedActivities));
            }

            return true;
        } else {
            console.error('Erreur lors de la suppression du like:', response.status, response.statusText);
            console.error("Détails de l'erreur du serveur:", await response.text());
            return false;
        }
    } catch (error) {
        console.error('Erreur lors de la suppression du like:', error);
        return false;
    }
};

