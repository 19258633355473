//FR implémente une barre de navigation personnalisée pour un onglet supérieur 
//EN "Implement a custom navigation bar for a top tab."

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { useLanguage } from '../../contexts/LanguageContext';

import MyActivitiesScreen from '../../pages/CalendarSection/MyActivitiesScreen/MyActivitiesNavigator';
import ActivitiesScreen from '../../pages/CalendarSection/ActivitesListScreen/ActivitiesScreen';

const Tab = createMaterialTopTabNavigator();

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const CustomTabBar = (props) => {
    const { state, descriptors, navigation } = props;
  
    return (
      
      <View style={{ flexDirection: "row" }}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label = options.tabBarLabel ?? options.title ?? route.name;
          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
            });
  
            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          };
  
          return (
            <TouchableOpacity
              key={index}
              role="button"
              onPress={onPress}
              style={{
                backgroundColor: isFocused ? "#59C09B" : "gray",
                borderRightWidth: index === 0 ? 2 : 0, 
              ...styles.buttonNav}}
            >
              <Text
                style={{
                    color: isFocused ? "gray" : "white",
                    ...styles.titleText}}
              >
                {label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

export default function Navbar({arg}) {
  const { translations } = useLanguage();
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarScrollEnabled: false,
      }}
      tabBar={(props) => <CustomTabBar {...props} />}
    >
      <Tab.Screen name={translations.JsonTopNav.JsonSecondLineLeft} component={ActivitiesScreen} />
      <Tab.Screen name={translations.JsonTopNav.JsonSecondLineRight} component={MyActivitiesScreen} />
    </Tab.Navigator>
  );
}

