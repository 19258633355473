//FR  il afficher une liste d'activités organisées par l'utilisateur actuel (FrameFigma22B3)
//GB  To display a list of activities organized by the current user (FrameFigma22B3)

import React, { useState, useEffect, useCallback } from 'react'; 
import { View, Text, FlatList, Platform } from 'react-native' //composants de base de React Native
import AsyncStorage from '@react-native-async-storage/async-storage'; // composant de Gestion du stockage asynchrone des données
import ActivityCard from '../../../../components/ActivityCard/ActivityCard'; // Composant personnalisé ActivityCard pour afficher les détails d'une activité
import { hostname } from '../../../../../hostName/hostName'; //
import { useFocusEffect } from '@react-navigation/native'; // Un hook pour exécuter un effet lorsqu'un écran devient focus.
import { useLanguage } from '../../../../contexts/LanguageContext'; // Un hook pour obtenir la langue actuelle de l'application.

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const OrganizedScreen = () => {
  const { translations } = useLanguage();
  const [activities, setActivities] = useState([]);

  const fetchActivities = async () => {
      try {
          const userToken = await AsyncStorage.getItem('userToken'); 
          const userId = await AsyncStorage.getItem('userId');
          const response = await fetch(`${hostname}/activities`, {
              headers: {
                  Authorization: `Bearer ${userToken}` 
              }
          });
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          const myOrganizedActivities = data.filter(
            (activity) => activity.author === userId
          );
          console.log(myOrganizedActivities);
          setActivities(myOrganizedActivities);
      } catch (error) {
          if (error instanceof TypeError && error.message === 'Network request failed') {
              console.error("Network request failed:", error);
              // Gérer les erreurs réseau ici
          } else {
              console.error("Error fetching activities:", error);
              // Gérer les autres erreurs ici
          }
      }
  };
  

  useEffect(() => {
      fetchActivities();
  }, []);

  // composant  useFocusEffect pour déclencher le chargement des activités chaque fois que l'écran devient focus
  useFocusEffect(
      useCallback(() => {
          fetchActivities();
      }, [])
  );
  
  const renderItem = ({ item }) => (
      <View>
          <ActivityCard activity={item} />
      </View>
  );
  
// composant View Utilisé pour créer des conteneurs de mise en page.
// composant text affiche le text explicatif 
// composant FlastList Utilisé pour afficher une liste de données de manière efficace, en particulier pour les listes longues.

  return (
      <View style={styles.container}>
        <View>
          <Text style={styles.textOne}>
            {translations.JsonTopNav.JsonMyActivitiesExplanationOrganized}
          </Text>
        </View>
        {activities.length > 0? (
          <FlatList
            data={activities}
            renderItem={renderItem}
            keyExtractor={(item, index) => item._id ? item._id.toString() : index.toString()}
          />
        ):(
        <View
          style={styles.textTwoContainer}
        >
          <Text style={styles.textTwo}>
            {
              translations.JsonActivityPages.JsonMyActivitiesScreen
                .JsonOrganizedActivityEmpty
            }
          </Text>
        </View>
        )}
      </View>
  );
}

export default OrganizedScreen