// Fr  Styles de ActivityMiddleNavigation(FrameFigma041,FrameFigma042,FrameFigma043,FrameFigma044)
// En  Styles of ActivityMiddleNavigation(FrameFigma041,FrameFigma042,FrameFigma043,FrameFigma044)

import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        // marginTop: "5%",
        flexDirection: "row",
        width: "95%",
        height: 70,
        overflow: "hidden",
        borderRadius: 50,
        backgroundColor: "#59C09B",
        justifyContent: "space-evenly",
        marginLeft: 11,
    }, 
    middleNav: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
        borderColor: "white",
    },
    divider: {
        width: 2,
        backgroundColor: "white",
        height: "80%",
        marginTop: "2%", 
    },
    middleText: {
        fontWeight: "bold",
        fontSize: 11,
        color: "white",
    }
});
