//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷 Ce fichier gère la page qui permet d'interagir avec notifications. (FrameFigma024C )
// 🇬🇧 This file manages the page used to interact with our notifications.  (FrameFigma024C )

import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, Platform } from 'react-native';
import SwitchBtn from '../../../components/SwitchBtn/SwitchBtn';
import { useWebSocket } from '../../../sockets/WebSocketLocal';
import { useUser } from '../../../contexts/UserContext';
import { useAuth } from "../../../contexts/AuthContext";
import { getActivities } from '../../../utils/ActivityFetch';
import { hostname } from '../../../../hostName/hostName';
import axios from 'axios';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const NotificationsManageScreen = ({ user }) => {

  const { allUsers } = useUser();
  const { userToken } = useAuth();

  const [userActivities, setUserActivities] = useState([]);
  const [activityIds, setActivityIds] = useState([]);
  const [organisersMet, setOrganisersMet] = useState([])
  const [activityTopics, setActivityTopics] = useState([])
  // manque recuprearation de activityTopics
  const [notificationsStateUser, setNotificationsStateUser] = useState({
    visitorNotification: false,
    eventCommentsNotification: false,
    friendRequest: false,
    friendBirthday: false,
    likeNotification: false,
    activityLikeNotifications: false,
    activityInMyCity: false,
    activityByTopics: false,
    organisersMetNotification: false,
  });
  const [notificationsStateActivity, setNotificationsStateActivity] = useState({
    activitiesRemindNotification: false,
    activityNearCompletion: false,
    activityCompleted: false,
    activityDiscussion: false,
    activityReminder: false,
    organizerUpdate: false,
  });

  const updateNotificationPreferencesUser = async settings => {
    try {
      const response = await axios.put(`${hostname}/updateNotifications/${user._id}`, settings, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log('Notifications settings updated:', response.data);
    } catch (error) {
      console.error('Failed to update notifications settings:', error);
    }
  };
  const updateNotificationPreferencesActivity = async settings => {
    const userId = await user._id
    console.log(activityIds, userActivities)
    activityIds.map(async id => {
      try {
        const response = await axios.put(`${hostname}/activities/${id}/updateNotifications`, { settings, userId }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        console.log('Notifications settings updated:', response.data);
      } catch (error) {
        console.error('Failed to update notifications settings:', error);
      }
    })

  };

  const toggleNotification = ({ notificationKey, notificationType }) => {
    const notificationsState = notificationType === "user" ? notificationsStateUser : notificationsStateActivity
    const newSettings = {
      ...notificationsState,
      [notificationKey]: !notificationsState[notificationKey],
    };

    if (notificationType === "user") {
      setNotificationsStateUser(newSettings)
      updateNotificationPreferencesUser({ notificationSettings: newSettings });
    }
    else {
      setNotificationsStateActivity(newSettings)
      updateNotificationPreferencesActivity({ notificationSettings: newSettings });
    }
  };
  // console.log(notificationsState)
  // Liste des types de notifications pour faciliter le rendu
  const notificationsUser = [
    { key: 'visitorNotification', label: 'Visit your profil' },
    { key: 'eventCommentsNotification', label: 'Comments in your events' },
    { key: 'friendRequest', label: 'Ajout ami' },
    { key: 'friendBirthday', label: "Anniversaire d'un ami" },
    { key: 'likeNotification', label: 'Like your profile' },
    { key: 'activityLikeNotifications', label: 'like your activity' },
    { key: 'activityInMyCity', label: 'Activité dans ma ville' },
    {
      key: 'activityByTopics', label: 'Activités selon mes topics',
      child: activityTopics
    },
    {
      key: 'organisersMetNotification', label: 'From organisers you met',
      child: organisersMet
    },
    // { key: 'visitorNotification', label: 'Notification de visite' },
  ];
  // { key: 'socializusNotification', label: 'Socializus message' },
  const notificationsActivity = [
    // { key: 'followOrganizer', label: "Suivre l'organisateur" },
    { key: 'activitiesRemindNotification', label: 'Remind me my activities' },
    { key: 'activityNearCompletion', label: 'Activité presque complète (80%)' },
    { key: 'activityCompleted', label: 'Activité complète' },
    { key: 'activityDiscussion', label: "Discussion de l'activité" },
    { key: 'activityReminder', label: "Rappel de l'activité" },
    { key: 'organizerUpdate', label: "Mises à jour de l'organisateur" },
  ];

  useEffect(() => {
    const organisers = userActivities?.map(activity => {
      const organizer = allUsers?.find(user => user._id === activity.author);
      const organizerName = organizer ? organizer.userName : "Unknown";
      return { key: organizerName, label: organizerName };
    });
    if (user !== undefined) {
      const userSetting = user?.notificationSettings
      setNotificationsStateUser({ ...notificationsStateUser, ...userSetting })
      const fetchActivities = async () => {
        const activities = await getActivities(user?._id);
        setUserActivities(activities.uniqueUserActivities);
      };
      fetchActivities();
    }
    setOrganisersMet(organisers);
  }, []);

  useEffect(() => {
    if (userActivities !== undefined && userActivities.length > 0) {
      const activityIDs = userActivities?.map(activity => activity._id)
      setActivityIds(prevActivityIds => [...prevActivityIds, ...activityIDs]);
      const activitySetting = userActivities[0]?.notificationSettings ? userActivities[0]?.notificationSettings[user._id] : null
      setNotificationsStateActivity({ ...notificationsStateActivity, ...activitySetting })
    }
  }, [userActivities])

  console.log(userActivities, activityIds)

  return (
    <ScrollView style={styles.container}>
      {/* <Text>Bienvenue, {user?.firstName}!</Text> */}
      <Text style={styles.textCenter}>Here you can manage your notifications about  :</Text>
      <View style={[styles.notifsContainer, styles.marginBas]}>
        {notificationsUser.map(notification => (
          <View key={notification.key} style={styles.notifContainer}>
            <View style={styles.notifParentContainer}>
              <Text style={styles.textCenter}>{notification.label}</Text>
              <SwitchBtn state={notificationsStateUser[notification.key]} setState={() => toggleNotification({ notificationKey: notification.key, notificationType: "user" })} />
            </View>
            {notification.child !== undefined && notificationsStateUser[notification.key] && notification.child.map(notification => (
              <View key={notification.key} style={styles.notifChildContainer}>
                <Text style={styles.textCenter}>{notification.label}</Text>
                <SwitchBtn state={notificationsStateUser[notification.key]} setState={() => toggleNotification({ notificationKey: notification.key, notificationType: "user" })} />
              </View>
            ))}
          </View>
        ))}
      </View>

      <View style={styles.notifsContainer}>
        {notificationsActivity.map(notification => (
          <View key={notification.key} style={styles.notifContainer}>
            <View style={styles.notifParentContainer}>
              <Text style={styles.textCenter}>{notification.label}</Text>
              <SwitchBtn state={notificationsStateActivity[notification.key]} setState={() => toggleNotification({ notificationKey: notification.key, notificationType: "activity" })} />
            </View>
            {notification.child !== undefined && notificationsStateActivity[notification.key] && notification.child.map(notification => (
              <View key={notification.key} style={styles.notifChildContainer}>
                <Text style={styles.textCenter}>{notification.label}</Text>
                <SwitchBtn state={notificationsStateActivity[notification.key]} setState={() => toggleNotification({ notificationKey: notification.key, notificationType: "activity" })} />
              </View>
            ))}
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

export default NotificationsManageScreen;
