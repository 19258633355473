//FR gère le processus de demande pour devenir co-organisateur d'une activité (FrameFigma041,FrameFigma041p1,FrameFigma041p2,FrameFigma041p3,FrameFigma041p4,FrameFigma041pC)
//EN Manage the request process to become a co-organizer of an activity (FrameFigma041,FrameFigma041p1,FrameFigma041p2,FrameFigma041p3,FrameFigma041p4,FrameFigma041pC)
// Import Modules
import React, { useState, useEffect } from "react";
import { Modal, View, Text, Pressable, Platform } from "react-native";

// Import Contexts
import { useLanguage } from "../../contexts/LanguageContext";
import { hostname } from "../../../hostName/hostName";
import { useAuth } from "../../contexts/AuthContext";

// Import Components
import ExistingRequestModal from "./ConfirmationModal/ExistingRequestModal";
import ConfirmationModal from "../BecomeCoOrganiser/ConfirmationModal/ConfirmationModal";
import CreateRequestModal from "../BecomeCoOrganiser/ConfirmationModal/CreateRequestModal";

// Import Icons
import { Icons } from "../../constants/Icons";

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const BecomeCoOrganiser = ({ activity, user, showCoOrgModal, setShowCoOrgModal }) => {
  
  // Contexts
  const { translations } = useLanguage();
  const { userToken } = useAuth();

  // States
  const [success, setSuccess] = useState(false);
  const [alreadyRequested, setAlreadyRequested] = useState(false);
  const [alreadyCoOrganizer, setAlreadyCoOrganizer] = useState(false);

  // Local Variables
  const conditions = activity?.coOrganizerConditions;
  const gifts = activity?.coOrganizerGifts;
  const message = {
    success: "Congratulations! You are now on the waiting list to become a co-organizer",
    alreadyRequested: "You have already requested to become a co-organizer",
  }
  
  // Temp function to send co-organiser request - To be added to Activity context once working
  const sendCoOrganiserRequest = async () => {
    try {
      const response = await fetch(`${hostname}/activities/${activity._id}/co-organizers`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user._id,
        }),
      });

      if (response.ok) {
        console.log('Co-organiser request sent successfully');
        setSuccess(true);
      }
    } catch (error) {
      console.error('Error sending co-organiser request:', error);
    } 
  };

  useEffect(() => {
    if (activity?.coOrganizerRequests.includes(user?._id)) setAlreadyRequested(true);
    if (activity?.coOrganizers.includes(user?._id)) setAlreadyCoOrganizer(true);
  }, []);
  
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={showCoOrgModal}
      onRequestClose={() => {
        setShowCoOrgModal(!showCoOrgModal);
      }}
    >
      <View style={styles.container}>
        <View style={styles.modal}>
          <Pressable style={styles.close} onPress={() => setShowCoOrgModal(!showCoOrgModal)}>
            <Icons.CloseSignGreen width={30} height={30} />
          </Pressable>
          {alreadyRequested && 
            <ExistingRequestModal message={message.alreadyRequested} onClose={() => setShowCoOrgModal(!showCoOrgModal)} />}
          {success &&
            <ConfirmationModal message={message.success} onClose={() => {setShowCoOrgModal(!showCoOrgModal), setSuccess(false), setAlreadyRequested(true)}} /> 
          } 
          {!success && !alreadyRequested &&  <CreateRequestModal conditions={conditions} gifts={gifts} onConfirm={sendCoOrganiserRequest} /> }            
        </View>
      </View>
    </Modal>
  )
};

export default BecomeCoOrganiser