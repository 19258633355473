// FR Icons qui est utilisé dans les pages ( FrameFigma050a FrameFigma050B FrameFigma051A FrameFigma051B FrameFigma051C FrameFigma051D FrameFigma052 FrameFigma053)
// 🇬🇧 Icons used in the ( FrameFigma050a FrameFigma050B FrameFigma051A FrameFigma051B FrameFigma051C FrameFigma051D FrameFigma052 FrameFigma053)


/* Achievements /Box -------------------- */
import FreeGift from '../assets/Icons/Achievements/Box/FreeGift.svg';
import Gift from '../assets/Icons/Achievements/Box/Gift.svg';
import GiftDiamondStars from '../assets/Icons/Achievements/Box/GiftDiamondStars.svg';
import GiftEmeraldStars from '../assets/Icons/Achievements/Box/GiftEmeraldStars.svg';
import GiftGoldStars from '../assets/Icons/Achievements/Box/GiftGoldStars.svg';
import GiftRubyStars from '../assets/Icons/Achievements/Box/GiftRubyStars.svg';
import OtherGift from '../assets/Icons/Achievements/Box/OtherGift.svg';

/* Achievements /Cups -------------------- */
import CupsLevel36 from '../assets/Icons/Achievements/Cups/Level36.svg';

// ./Diamond
import Level1DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level1DiamondStar.svg';
import Level2DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level2DiamondStar.svg';
import Level3DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level3DiamondStar.svg';
import Level4DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level4DiamondStar.svg';
import Level5DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level5DiamondStar.svg';
import Level6DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level6DiamondStar.svg';
import Level7DiamondStar from '../assets/Icons/Achievements/Cups/Diamond/Level7DiamondStar.svg';

// ./Emerald
import Level1EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level1EmeraldStar.svg';
import Level2EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level2EmeraldStar.svg';
import Level3EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level3EmeraldStar.svg';
import Level4EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level4EmeraldStar.svg';
import Level5EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level5EmeraldStar.svg';
import Level6EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level6EmeraldStar.svg';
import Level7EmeraldStar from '../assets/Icons/Achievements/Cups/Emerald/Level7EmeraldStar.svg';

// ./Gold
import Level1GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level1GoldStar.svg';
import Level2GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level2GoldStar.svg';
import Level3GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level3GoldStar.svg';
import Level4GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level4GoldStar.svg';
import Level5GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level5GoldStar.svg';
import Level6GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level6GoldStar.svg';
import Level7GoldStar from '../assets/Icons/Achievements/Cups/Gold/Level7GoldStar.svg';

// ./NoStar
import Level1NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level1NoStar.svg';
import Level2NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level2NoStar.svg';
import Level3NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level3NoStar.svg';
import Level4NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level4NoStar.svg';
import Level5NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level5NoStar.svg';
import Level6NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level6NoStar.svg';
import Level7NoStar from '../assets/Icons/Achievements/Cups/NoStar/Level7NoStar.svg';

// ./Ruby
import Level1RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level1RubyStar.svg';
import Level2RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level2RubyStar.svg';
import Level3RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level3RubyStar.svg';
import Level4RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level4RubyStar.svg';
import Level5RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level5RubyStar.svg';
import Level6RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level6RubyStar.svg';
import Level7RubyStar from '../assets/Icons/Achievements/Cups/Ruby/Level7RubyStar.svg';

// /Stars
import StarsDiamond from '../assets/Icons/Achievements/Stars/Diamond.svg';
import StarsEmerald from '../assets/Icons/Achievements/Stars/Emerald.svg';
import StarsGold from '../assets/Icons/Achievements/Stars/Gold.svg';
import StarsRatingHalfAndOff from '../assets/Icons/Achievements/Stars/RatingHalfAndOff.svg';
import StarsRatingOff from '../assets/Icons/Achievements/Stars/RatingOff.svg';
import StarsRatingOn from '../assets/Icons/Achievements/Stars/RatingOn.svg';
import RatingOnActiveUsers from '../assets/Icons/Achievements/Stars/RatingOnActiveUsers.svg';
import StarsRuby from '../assets/Icons/Achievements/Stars/Ruby.svg';

// /Border
import RubyBorder from "../assets/Icons/Achievements/Border/RubyBorder.svg";
import GoldBorder from "../assets/Icons/Achievements/Border/GoldBorder.svg";
import EmeraldBorder from "../assets/Icons/Achievements/Border/EmeraldBorder.svg";
import DiamandBorder from "../assets/Icons/Achievements/Border/DiamondBorder.svg";
import GoldSmallBorder from "../assets/Icons/Achievements/Border/GoldSmallBorder.svg";
import RubySmallBorder from "../assets/Icons/Achievements/Border/RubySmallBorder.svg";
import EmeraldSmallBorder from "../assets/Icons/Achievements/Border/EmeraldSmallBorder.svg";
import DiamandSmallBorder from "../assets/Icons/Achievements/Border/DiamondSmallBorder.svg";

// /Sign
import CheckSign from "../assets/Icons/Achievements/Sign/CheckSign.svg";
import SignRed from "../assets/Icons/Achievements/Sign/CloseLargeSignRed.svg";

/* Activity /Goodies -------------------- */
import FreeDrink from '../assets/Icons/Activity/Goodies/FreeDrink.svg';
import FreePass from '../assets/Icons/Activity/Goodies/FreePass.svg';

/* General /Black and White -------------------- */
import RightArrowWhite from '../assets/Icons/General/BlackAndWhite/RightArrowWhite.svg';
import LeftArrow from '../assets/Icons/General/BlackAndWhite/LeftArrow.svg';
import ShareWhite from '../assets/Icons/General/BlackAndWhite/ShareWhite.svg';
import CloseSign from '../assets/Icons/General/BlackAndWhite/CloseSign.svg';
import RightArrow from '../assets/Icons/General/BlackAndWhite/RightArrow.svg';
import PadLock from '../assets/Icons/General/BlackAndWhite/PadLock.svg';
import PadLockOpen from '../assets/Icons/General/BlackAndWhite/PadLockOpen.svg';

/* General /Color -------------------- */
import Calendar from '../assets/Icons/General/Colors/Calendar.svg';
import CloseLargeRedSign from '../assets/Icons/General/Colors/CloseLargeSignRed.svg';
import CloseRed from '../assets/Icons/General/Colors/CloseRed.svg';
import Checkbox from '../assets/Icons/General/Colors/Checkbox.svg';
import CheckSignGeneralColor from '../assets/Icons/General/Colors/CheckSign.svg';
import CloseSignGreen from '../assets/Icons/General/Colors/CloseSignGreen.svg';
import Plus from '../assets/Icons/General/Colors/Plus.svg';
import Information from '../assets/Icons/General/Colors/Information.svg';
import GreenClock from '../assets/Icons/General/Colors/GreenClock.svg';
import LeftArrowGreen from '../assets/Icons/General/Colors/LeftArrowGreen.svg';

/* Maps -------------------- */
import PinBlackAndWhite from '../assets/Icons/Maps/PinBlackAndWhite.svg';
import PinGoogleMaps from '../assets/Icons/Maps/PinGoogleMaps.svg';
import PinPointColors from '../assets/Icons/Maps/PinPointColors.svg';
import Metro from '../assets/Icons/Maps/Metro.svg';

/* Navbar /Black and White -------------------- */
import NavbarEditInfo from '../assets/Icons/NavBar/BlackAndWhite/EditInfo.svg';
import NavbarPinPointMiddle from '../assets/Icons/NavBar/BlackAndWhite/PinPointMiddle.svg';
import NavbarChat from '../assets/Icons/NavBar/BlackAndWhite/Chat.svg';

/* Navbar /Color -------------------- */
import NavbarEditInfoColors from '../assets/Icons/NavBar/Colors/EditInfo.svg';
import NavbarChatColors from '../assets/Icons/NavBar/Colors/ChatColors.svg';
import NavbarMembersMiddle from '../assets/Icons/NavBar/Colors/MembersMiddle.svg';
import NavbarMembersBottom from '../assets/Icons/NavBar/Colors/MembersBottomColor.svg';

/* Notifications -------------------- */
import BellRinging from '../assets/Icons/Notifications/BellRinging.svg';

/* Profiles -------------------- */
import AddFriend from '../assets/Icons/Profiles/AddFriend.svg';
import BlockFriend from '../assets/Icons/Profiles/BlockFriend.svg';
import ChatColors from '../assets/Icons/Profiles/ChatColors.svg';
import Edit from '../assets/Icons/Profiles/Edit.svg';
import Email from '../assets/Icons/Profiles/Email.svg';
import Phone from '../assets/Icons/Profiles/Phone.svg';
import Birthday from '../assets/Icons/Profiles/Birthday.svg';
import MemberNumber from '../assets/Icons/Profiles/MemberNumber.svg';
import LastName from '../assets/Icons/Profiles/LastName.svg';
import CalendarOn from '../assets/Icons/NavBar/Colors/CalendarBottom.svg';

/* Users Interaction /Black and White -------------------- */
import HeartGrey from '../assets/Icons/UsersInteraction/BlackAndWhite/HeartGrey.svg';
import Participants from '../assets/Icons/UsersInteraction/BlackAndWhite/Participants.svg';

/* Users Interaction /Color -------------------- */
import PeopleOn from '../assets/Icons/UsersInteraction/Colors/PeopleOn.svg';
import HeartRed from '../assets/Icons/UsersInteraction/Colors/HeartRed.svg';
import HeartEmpty from '../assets/Icons/UsersInteraction/Colors/HeartEmpty.svg';
import HeartFilled from '../assets/Icons/UsersInteraction/Colors/HeartFilled.svg';
export const Icons = {
  /* Achievements /Box -------------------- */
  FreeGift,
  Gift,
  GiftDiamondStars,
  GiftEmeraldStars,
  GiftGoldStars,
  GiftRubyStars,
  OtherGift,

  /* Achievements /Cups -------------------- */
  CupsLevel36,

  //Icons/Achievements/Cups/Diamond
  Level1DiamondStar,
  Level2DiamondStar,
  Level3DiamondStar,
  Level4DiamondStar,
  Level5DiamondStar,
  Level6DiamondStar,
  Level7DiamondStar,

  //Icons/Achievements/Cups/Emerald
  Level1EmeraldStar,
  Level2EmeraldStar,
  Level3EmeraldStar,
  Level4EmeraldStar,
  Level5EmeraldStar,
  Level6EmeraldStar,
  Level7EmeraldStar,

  //Achievements/Cups/Gold
  Level1GoldStar,
  Level2GoldStar,
  Level3GoldStar,
  Level4GoldStar,
  Level5GoldStar,
  Level6GoldStar,
  Level7GoldStar,

  //Achievements/Cups/NoStar
  Level1NoStar,
  Level2NoStar,
  Level3NoStar,
  Level4NoStar,
  Level5NoStar,
  Level6NoStar,
  Level7NoStar,

  //Achievements/Cups/Ruby
  Level1RubyStar,
  Level2RubyStar,
  Level3RubyStar,
  Level4RubyStar,
  Level5RubyStar,
  Level6RubyStar,
  Level7RubyStar,

  //Achievements/Stars
  StarsDiamond,
  StarsEmerald,
  StarsGold,
  StarsRatingHalfAndOff,
  StarsRatingOff,
  StarsRatingOn,
  RatingOnActiveUsers,
  StarsRuby,
  
 //Achievements/Border
  GoldSmallBorder,
  RubySmallBorder,
  EmeraldSmallBorder,
  DiamandSmallBorder,
  GoldBorder,
  RubyBorder,
  EmeraldBorder,
  DiamandBorder,
 
  //Achievements/Sign
  CheckSign,
  SignRed ,

  /* Activity /Goodies -------------------- */
  FreeDrink,
  FreePass,

  /* General /Black and White -------------------- */
  RightArrowWhite,
  LeftArrow,
  ShareWhite,
  CloseSign,
  RightArrow,
  PadLock,
  PadLockOpen,

  /* General /Color -------------------- */
  Calendar,
  CloseLargeRedSign,
  CloseRed,
  Checkbox,
  CheckSignGeneralColor,
  CloseSignGreen,
  Plus,
  Information,
  GreenClock,
  LeftArrowGreen,

  /* Maps -------------------- */
  PinBlackAndWhite,
  PinGoogleMaps,
  PinPointColors,
  Metro,

  /* Navbar /Black and White -------------------- */
  NavbarEditInfo,
  NavbarPinPointMiddle,
  NavbarChat,

  /* Navbar /Color -------------------- */
  NavbarEditInfoColors,
  NavbarChatColors,
  NavbarMembersMiddle,
  NavbarMembersBottom,

  /* Notifications -------------------- */
  BellRinging,

  /* Profiles -------------------- */
  AddFriend,
  BlockFriend,
  ChatColors,
  Edit,
  Email,
  Phone,
  Birthday,
  MemberNumber,
  LastName,
  CalendarOn,

  /* Users Interaction /Black and White -------------------- */
  HeartGrey,
  Participants,
  
  /* Users Interaction /Color -------------------- */
  PeopleOn,
  HeartRed,
  HeartEmpty,
  HeartFilled,
}