//Fr Styles de AddressAutoPicker AddressAutoPickerActivites (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma041) (FrameFigma031,FrameFigma032A,FrameFigma035A)
//En Styles of AddressAutoPicker AddressAutoPickerActivites (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma041) (FrameFigma031,FrameFigma032A,FrameFigma035A)
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  address: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
    zIndex: 1,
  },
  suggestionsContainer: {
    maxHeight: 200,
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderWidth: 1,
    // color: 'black',
    width: '90%',
    position: 'absolute',
    top: 65,
    marginHorizontal:'5%',
    zIndex: 3,
  },
  suggestion: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    zIndex: 3,
  },
  inputContainer: {
    fontSize: 20,
    borderWidth: 1,
    marginVertical: 20,
    borderRadius: 15,
    padding: 8,
},
textTitle: {
    paddingHorizontal: 15,
    backgroundColor: "white",
    fontSize: 13,
    textAlign: "center",
    position: "absolute",
    zIndex: 1,
    left: 20,
    top: -10,
},
});
