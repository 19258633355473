// Fr Ce component affiche le haut de la page commentaires (FrameFigma043)
// En This component displays the top of the page comments(FrameFigma043)

// Import Modules
import React, { useState } from "react";
import { View, Text } from "react-native";

// Import Components
import SwitchBtn from "../../../../../components/SwitchBtn/SwitchBtn";
import { useLanguage } from "../../../../../contexts/LanguageContext";

const ActivityChatSettings = () => {
  const {translations} = useLanguage();
  // State
  const [notificationsState, setNotificationsState] = useState({
    allowNotifications: false,
    newMemberInChat: false,
    soundOn: false,
  });
  
  // Fonction pour changer l'état de chaque notification
  const toggleNotification = notification => {
    setNotificationsState({
      ...notificationsState,
      [notification]: !notificationsState[notification],
    });
  };
  
  const chatSettings = [
    {key: 'allowNotifications', label: translations.JsonActivityPages.JsonActivityScreen.JsonActivityChatScreen.JsonNotificationGroupChatSettings},
    {key: 'newMemberInChat', label: translations.JsonActivityPages.JsonActivityScreen.JsonActivityChatScreen.JsonNotificationJoinSettings},
    {key: 'soundOn', label: translations.JsonActivityPages.JsonActivityScreen.JsonActivityChatScreen.JsonNotificationWithSoundSettings},
  ];
  
  return (
    <View>
      <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityChatScreen.JsonChatSettings}</Text>
      {chatSettings.map(setting => (
        <View key={setting.key} style={{flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center'}}>
          <Text>{setting.label}</Text>
          <SwitchBtn state={notificationsState[setting.key]} setState={() => toggleNotification(setting.key)} />
        </View>
      ))}
    </View>
  );
};

export default ActivityChatSettings