//(FrameFigma041p1)
// Import Modules
import React, { useState } from 'react';
import { View, Text, Pressable, Platform } from 'react-native';

// Import Components
import SelectButtonWithIcon from "../../SelectButtonWithIcon/SelectButtonWithIcon";

// Import Contexts
import { useLanguage } from "../../../contexts/LanguageContext";

// Import Icons
import { Icons } from "../../../constants/Icons";

// Import Styles
const styles =
  Platform.OS === 'web'
  // Attention - ce fichier utilise les styles de parent BecomeCoOrganiser
    ? require('../StylesWeb').default
    : require('../StylesMobile').default;

// Logique de component commence ici --------------------------------------------------
const CreateRequestModal = ({ conditions, gifts, onConfirm }) => {

  // Contexts
  const { translations } = useLanguage();
  const t = translations.JsonConfirmFriendshipPopup

  // States
  const [selectedButtons, setSelectedButtons] = useState([]);

  // Define the buttons
  const conditionButtons = [
    { id: 1, text: 'Receive Private Message', icon: <Icons.Email /> },
    { id: 2, text: 'Come 5 minutes Early', icon: <Icons.GreenClock /> },
  ];
  
  const giftButtons = [
    { id: 1, text: 'Free Drink', icon: <Icons.FreeDrink /> },
    { id: 2, text: 'Free Pass', icon: <Icons.FreePass /> },
    { id: 3, text: 'Other Gift', icon: <Icons.OtherGift /> },
  ];

  // Generic function to handle button press
  const handleButtonPress = button => {
    if (selectedButtons.includes(button.id)) {
      setSelectedButtons(selectedButtons.filter(id => id !== button.id));
    } else {
      setSelectedButtons([...selectedButtons, button.id]);
    }
  };

  // Verifier si l'user a clique sur tous les boutons necessaire
  const conditionFulfilled = conditions && conditions.length > 0 ? conditions.length === selectedButtons.length : true; // Toujours true si aucune condition

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <Text>Co Organiser Requests</Text>
        {conditions && conditions.length > 0 ? (
          <>
            <View style={styles.btnContainer}>
            {conditions.map((condition, index) => {
              return conditionButtons.map((button) => {
                if (button.id === parseInt(condition)) {
                  return (
                    <SelectButtonWithIcon
                      key={index}
                      text={button.text}
                      icon={button.icon}
                      selected={selectedButtons.includes(button.id)}
                      onPress={() => handleButtonPress(button, selectedButtons, setSelectedButtons)}
                    />
                  );
                }
                return null; // Return null for non-matching conditions
              });
            })}
            </View>

            <View style={styles.btnContainer}>
              {gifts.map((gift, index) => {
                return giftButtons.map((button) => {
                  if (button.id === parseInt(gift)) {
                    return (
                      <View key={index}>
                        <View>
                          {button.icon}
                        </View>
                        <Text>{button.text}</Text>
                      </View>
                    );
                  }
                  return null; // Return null for non-matching conditions
                });
              })}
            </View>
          </>  
        ) : (
          <Text>No Conditions</Text>
        )}

        {/* Confirm Button */}
        <View>
          <Pressable style={[styles.confirmButton, {backgroundColor: conditionFulfilled || !conditions ? '#59C09B' : '#6f6f6f'}]} onPress={onConfirm} disabled={!conditionFulfilled}>
            <Text style={styles.confirmButton.buttonText}>{translations.JsonCreateProfilePages.JsonContinueButton}</Text>
            <Icons.RightArrowWhite width={30} height={30} />
          </Pressable>
        </View>
      </View>
    </View>
  )
}

export default CreateRequestModal