import {StyleSheet} from 'react-native';
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    fab: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        backgroundColor: 'white',
        width: 56,
        height: 56,
        borderRadius: 28,
        justifyContent: 'center', // Centre verticalement
        alignItems: 'center', // Centre horizontalement
        elevation: 5, // Ajoutez une ombre sous le bouton (uniquement Android)
        shadowColor: '#000', // Ces 4 prochaines lignes ajoutent une ombre (uniquement iOS)
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    plus: {
        fontSize: 56, // Vous pouvez ajuster cette taille selon vos besoins
        color: '#59C09B',
        // Assurez-vous que le signe "+" est bien centré, ajustez si nécessaire
        lineHeight: 62, // Cette ligne peut aider à centrer verticalement le signe "+"
    },
});

export default styles;
