// Fr  Styles VipBoardExplanationScreen( FrameFigma052 )
// En   Styles VipBoardExplanationScreen( FrameFigma052 ) 

import { StyleSheet } from "react-native";


const styles = StyleSheet.create({
  //GrandBox: {
  //flex: 1,
  //height: 200,
  //},
  container: {
    flex: 1,
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    margin: 10,
  },
  text: {
    fontWeight: 'bold',
    alignItems: 'center',
    padding: 10,
    fontSize: 13,
  },
  Text: {
    fontWeight: 'bold',
    alignItems: 'center',
    marginLeft: '3.5%',
    top: 40,
    padding: 10,
  },

  card: {
    flex: 1,
    alignItems: 'center',
    padding: 15,

    // Add this line
  },
  img: {
    width: 36,
    height: 36,

    marginBottom: '-40%',
    position: 'relative',
    right: '-20%',
  },
  imgsmall: {
    width: 70,
    height: 70,
    marginTop: '-62%',
    marginBottom: '-30%',
    fontSize: '2em',
    position: 'relative',
    right: '15%',
  },
  buttonimg: {
    width: 80,
    height: 80,
    position: 'relative',
    right: '5%',
    marginTop: '-15%',
    marginLeft: '-9%',
  },
  buttonimgdiamond: {
    width: 80,
    height: 80,
    position: 'relative',
    right: '16%',
    marginTop: '-20%',
    marginLeft: '-9%',
  },
  cellTextNumber: {
    fontSize: 20,
    marginTop: 7,
    marginLeft: '38%',
    fontWeight: 'bold',
  },
  buttontextdiamond: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    position: 'relative',
    right: '-15%',
    marginTop: '-30%',
    fontSize: 20,
  },
  cardContent: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    padding: 10,
  },

  // alignement des boutons (Gold, Ruby, Emerald et Diamond)
  boxRuby: {
    borderWidth: '20%',
    borderColor: '#fff',
    borderRadius: '20px',
    marginTop: '10%',
    padding: '10%', // Augmenter la valeur du padding
    width: '100%',
    // height:'1%',
    overflow: 'hidden',
    boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#505050',
  },
  boxDiamond: {
    borderWidth: '20%',
    borderColor: '#fff',
    borderRadius: '20px',
    marginTop: '10%',
    padding: '11.3%', // Augmenter la valeur du padding
    width: '100%',
    // height:'1%',
    overflow: 'hidden',
    boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#505050',
  },
  buttonGrey: {
    marginTop: '10%',
    borderWidth: '20%',
    borderColor: '#fff',
    borderRadius: '20px',
    padding: '10%', // Augmenter la valeur du padding
    width: '100%',
    // height:'1%',
    overflow: 'hidden',
    boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#505050',
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    position: 'relative',
    right: '-13%',
    marginTop: '-30%',
    fontSize: 20,
  },
  textCard: {
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: 12,
  },
  cardButton: {
    width: '100%',
    alignItems: 'center', // Aligner le contenu au centre
    backgroundColor: '#fff',
  },
  separator: {
    borderTopWidth: 2,
    borderTopColor: 'grey', // Couleur du trait
    marginVertical: 0,
    width: '100%', // Largeur du trait
  },
  CarTabCentre: {
    backgroundColor: 'white',
    alignItems: 'center',
    width: '100%', // Ajuster la largeur à 100%
  },
  cardTab: {
    flexDirection: 'row', // Add this line
    backgroundColor: '#888',
    borderRadius: 15,
    alignItems: 'center',
    margin: 5,
  },
  cardTextTab: {
    flex: 1,
    alignItems: 'center',
    padding: 25,
    marginLeft: 29,
  },
  cardTabStyle: {
    backgroundColor: 'white',
    alignItems: 'center',
    marginBottom: 0,
    flexDirection: 'row',
  },

  table: {
    borderColor: '#000',
    //  marginTop: "-75%",
    width: '95%',
    marginLeft: '3%',
  },
  tableButton: {
    borderColor: '#000',
    //  marginTop: "-75%",
    width: '95%',
    marginLeft: '3%',
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    flex: 1,
    padding: 10,
    width: '50%',
  },
  cellButton: {
    flex: 1,
    padding: 5,
    width: '50%',
  },
  cellText: {
    textAlign: 'center',
    color: 'white',
    position: 'relative',
    right: '30%',
    fontSize: 14,
  },
  cellTextTitle: {
    textAlign: 'center',
    color: 'white',
    position: 'relative',
    right: '50%',
    fontSize: 13.5,
    marginTop: '18%',
  },
  cellTextCentre: {
    color: 'black',
    fontSize: 11,
    position: 'relative',
    right: '0%',
  },
  rowTab: {
    flexDirection: 'row',
    backgroundColor: '#888',
    borderRadius: 10,
    paddingLeft: 27,
  },
  SeparatorNoir: {
    width: 2,
    height: '100%',
    backgroundColor: '#888',
    transform: 'rotate(180deg)',
    marginLeft: '-2%',
  },
  rowText: {
    alignItems: 'center',
    fontSize: 10,
    padding: 10,
  },
});

export default styles;