// une fenêtre modale utilisée pour ajouter un ami
// A modal window used for adding a friend.

// Import Modules
import React, {useState} from 'react';
import {Modal, Text, View, Pressable, Platform} from 'react-native';

// Import Components
import SelectButtonWithIcon from '../SelectButtonWithIcon/SelectButtonWithIcon';
import {Step1InputScreen} from './Step1InputScreen/Step1InputScreen';

// Import Contexts
import {useLanguage} from '../../contexts/LanguageContext';

// Import Icons
import {Icons} from '../../constants/Icons';

// Import Styles
const styles = Platform.OS === 'web' ? require('./Styles.web').default : require('./Styles.mobile').default;

// Logique de component commence ici --------------------------------------------------
const AddFriendModal = ({visible, onClose, currentUser}) => {
  // States
  const [selectedButton, setSelectedButton] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);

  // Traductions avec Language Context
  const {translations} = useLanguage();
  const t = translations.JsonConfirmFriendshipPopup;

  // Gerer le selection d'Option
  const buttons = [
    {id: 1, text: 'Email', icon: <Icons.Email />},
    {id: 2, text: 'Member Number', icon: <Icons.MemberNumber />},
  ];

  // Boutons Conditionnels selon l'User Settings
  if (currentUser.useMyPhone) {
    buttons.push({id: 3, text: 'Phone', icon: <Icons.Phone />});
  }

  if (currentUser.useMyBirthday) {
    buttons.push({id: 4, text: 'Birthday', icon: <Icons.Birthday />});
  }

  if (currentUser.useMyLastName) {
    buttons.push({id: 5, text: 'Last Name', icon: <Icons.LastName />});
  }

  // Mettre en vert le bouton selectione
  const handleButtonPress = button => {
    setSelectedButton(button);
  };

  // Handle Back Button Navigation
  const handleBack = () => {
    setSelectedButton(null);
    setButtonClicked(false);
  };

  return (
    <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.modal}>
          {/* Afficher Step 2 si bouton selectione et bouton 'Continue' presse */}
          {selectedButton && buttonClicked ? (
            <Step1InputScreen selectedButton={selectedButton} onBack={handleBack} currentUser={currentUser} onClose={onClose} />
          ) : (
            <>
              {/* Si aucune bouton selectione/presse, afficher Step 1 */}

              {/* Close Button */}
              <Pressable style={styles.close} onPress={onClose}>
                <Icons.CloseSignGreen width={30} height={30} />
              </Pressable>

              {/* Modal Title */}
              <View style={styles.modalTitleContainer}>
                <Text style={styles.modalTitle}>{t.JsonPopupConfirmFriendship}</Text>
              </View>

              {/* Buttons Container */}
              <View style={styles.btnContainer}>
                {buttons.map(button => (
                  <View key={button.id} style={styles.buttonItem}>
                    <SelectButtonWithIcon text={button.text} icon={button.icon} selected={selectedButton?.id === button.id} onPress={() => handleButtonPress(button)} />
                  </View>
                ))}
              </View>

              {/* Confirm Button */}
              <View>
                <Pressable style={[styles.confirmButton, {backgroundColor: selectedButton ? '#59C09B' : '#6f6f6f'}]} onPress={() => setButtonClicked(true)} disabled={!selectedButton}>
                  <Text style={styles.confirmButton.buttonText}>{translations.JsonCreateProfilePages.JsonContinueButton}</Text>
                  <Icons.RightArrowWhite width={30} height={30} />
                </Pressable>
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default AddFriendModal;
