//Fr Styles de AddressAutoPicker AddressAutoPickerActivites (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma041) (FrameFigma031,FrameFigma032A,FrameFigma035A)
//En Styles of AddressAutoPicker AddressAutoPickerActivites (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma041) (FrameFigma031,FrameFigma032A,FrameFigma035A)

import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  address: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  textIn: {
    padding: 10
  },
  icon: {
    position: 'absolute',
    marginLeft: '2%',
    zIndex: 1,
  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)',
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  suggestionsContainer: {
    maxHeight: 200,
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderWidth: 1,
    width: '90%',
    position: 'absolute',
    marginHorizontal:'5%',
    top: 55,
  },
  suggestionsContainerActivity: {
    maxHeight: 200,
    backgroundColor: 'white',
    borderColor: 'lightgray',
    borderWidth: 1,
    width: '90%',
    position: 'absolute',
    marginHorizontal:'5%',
    top: 76,
  },
  suggestion: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
    zIndex: 3,
  },
textTitle: {
    paddingHorizontal: 15,
    backgroundColor: "white",
    fontSize: 13,
    textAlign: "center",
    position: "absolute",
    zIndex: 1,
    left: 20,
    top: -10,
},
});
