// 🇫🇷 Ce fichier gère la page Home au lancement de l'application. (FrameFigma001 FrameFigma003)
// 🇬🇧 This file manage the Home page when the application is launched. (FrameFigma001 FrameFigma003)

import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
  Platform,
  Pressable
} from 'react-native';

//  🇬🇧 Import images and styles
//  🇫🇷 Importer des images et des styles

import HomePageBR from '../../assets/Images/Home/HomePageBR.png';
// import Logo from '../../assets/Images/General/LogoSocializus.png';
import Images from '../../constants/Images';
import HomeButtonWorldStart from '../../assets/Images/Home/ButtonWorldStart.png'
// HomeButtonWorldStart ne marche pas dans Images
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

// 🇫🇷 Crée le menu de démarrage avec un logo 
const HomeScreen = ({ navigation }) => {
  return (
    <ImageBackground source={HomePageBR} style={styles.container}>
        <LogoSection />
        <ContentSection navigation={navigation} />
    </ImageBackground>
  );
};

//  🇫🇷 Affiche en dur un bannière
const TopMessage = () => {
  return (
    <View style={styles.msgContainer}>
      <Text style={styles.msgText}>16043 active members including 222 online</Text>
      <Text style={styles.msgText}>306 activities with 3000 participants</Text>
    </View>
  );
}

const LogoSection = () => {
  return (<View style={styles.logo}><Images.GeneralColorsLogoSocializusBlack/></View>);
};

const ContentSection = ({ navigation }) => {
  return (
    <View style={styles.content}>
      {/* <Text style={styles.contentText}>Share, Find or Create</Text>
      <Text style={styles.contentText}>your activities</Text> */}
      <ButtonSection navigation={navigation} />
    </View>
  );
};

const ButtonSection = ({ navigation }) => {
  return (
    <View style={styles.btnContainer}>
      <TouchableOpacity
        style={styles.btnStart}
        onPress={() => navigation.navigate('Languages')}>
        {/* <Text style={styles.btnText}>Start</Text> */}
        {/* <View><Images.HomeButtonWorldStart/></View> */}
        <Image source={HomeButtonWorldStart} style={styles.btnWorldStart} ></Image>
      </TouchableOpacity>
    </View>
  );
};

const Condition = () => {
  return (
    <View style={styles.condition}>
      <Text style={styles.terms}>By connecting , you agree to the</Text>
      <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
        <Text style={styles.termsBold}>Policy of confidentiality</Text>
      </Pressable>
      <Text style={styles.terms}>and to the</Text>
      <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
        <Text style={styles.termsBold}>terms of use.</Text>
      </Pressable>
    </View>
  )
}

export default HomeScreen;
