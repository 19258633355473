// Fr Cette page affiche l'étape 1 pour modifier une activité(FrameFigma031)
// En This page displays the first step to edit an activity(FrameFigma031)

import {View, ScrollView, Text, Pressable, Platform, TextInput} from 'react-native';
import React, {useState, useEffect} from 'react';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import DateTimePickerComponent from '../../../../components/DateTimePicker/DateTimePickerMobile/DateTimePickerMobile';
import DateTimePickerModal from '../../../../components/DateTimePicker/DateTimePickerWeb/DateTimePickerWeb';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useError} from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ClassicStepOne = ({onNextStep, currentActivity}) => {
  const {translations} = useLanguage();
  const {error, setError} = useError();

  const [selectedQuestion, setSelectedQuestion] = useState('classic');
  const [isFocused, setIsFocused] = useState(false);
  const [switchBtn, setSwitchBtn] = useState(false);
  const [switchBtn1, setSwitchBtn1] = useState(false);
  const [switchBtn2, setSwitchBtn2] = useState(false);
  const [activityTitle, setActivityTitle] = useState('');
  const [attendeesValidation, setAttendeesValidation] = useState(false);

  useEffect(() => {
    AsyncStorage.setItem('selectedQuestion', selectedQuestion);
    AsyncStorage.setItem('switchBtn', JSON.stringify(switchBtn));
    AsyncStorage.setItem('switchBtn1', JSON.stringify(switchBtn1));
    AsyncStorage.setItem('switchBtn2', JSON.stringify(switchBtn2));
    AsyncStorage.setItem('activityTitle', activityTitle);
    AsyncStorage.setItem('attendeesValidation', attendeesValidation);
  }, [selectedQuestion, switchBtn, switchBtn1, switchBtn2, activityTitle, attendeesValidation]);

  useEffect(() => {
    // Fonction de nettoyage pour supprimer les données du AsyncStorage lorsque la page est déchargée
    const cleanup = async () => {
      await AsyncStorage.removeItem('selectedQuestion');
      await AsyncStorage.removeItem('switchBtn');
      await AsyncStorage.removeItem('switchBtn1');
      await AsyncStorage.removeItem('switchBtn2');
      await AsyncStorage.removeItem('activityTitle');
      await AsyncStorage.removeItem('attendeesValidation');
    };

    cleanup();
  }, []);

  useEffect(() => {
    // get data of current activty
    setSelectedQuestion(currentActivity.createAtivityType || 'classic');
    setActivityTitle(currentActivity.title || '');
    setAttendeesValidation(currentActivity.attendeesValidation || false);
    setSwitchBtn(currentActivity.endTime !== null || false);
    setSwitchBtn1(currentActivity.localOnlyForAttendees || false);
    setSwitchBtn2(currentActivity.inviteCommunity || false);
  }, [currentActivity]);

  // Fonction pour gérer le changement du titre de l'activité
  const handleActivityTitleChange = text => {
    const trimmedText = text.slice(0, 60);  
    setActivityTitle(trimmedText);
  };

  const handleQuestionPress = option => {
    setSelectedQuestion(option);
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleNextStep = () => {
    if (activityTitle !== '' && selectedQuestion !== '') {
      onNextStep(selectedQuestion);
    } else {
      let errorMessage = "Veuillez remplir toutes les conditions avant de passer à l'étape suivante.";

      if (activityTitle === '') {
        errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonTitleErrorMessage;
      } else if (selectedQuestion === '') {
        errorMessage = "Veuillez sélectionner le type d'activité.";
      }
      setError(errorMessage);
    }
  };

  const handleAttendeeValidationPress = option => {
    setAttendeesValidation(option);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View styles={styles.container}>
        <View style={styles.textTitleOne}>
          <Text style={styles.textTitleOne}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonWhatKindOfActivityToCreate}
          </Text>
        </View>
        <View style={styles.questionContainer}>
          <Pressable
            style={() => [styles.classic, selectedQuestion === 'classic' && styles.activeQuestion]}
            onPress={() => handleQuestionPress('classic')}>
            <Text style={styles.textQuestion}> Classic </Text>
          </Pressable>
          <Pressable
            disabled="true"
            style={() => [styles.withStep, selectedQuestion === 'withStep' && styles.activeQuestion]}
            onPress={() => handleQuestionPress('withStep')}>
            <Text style={styles.textQuestion}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonActivitiesItinerary}
            </Text>
          </Pressable>
        </View>
        <View>
          <Text style={styles.titleA}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitle + ' :'}
          </Text>
        </View>
        <View>
          <TextInputCustom
            placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitlePlaceholder}
            handleTextChange={handleActivityTitleChange}
            value={activityTitle}
            multiline={true}
            numberOfLines={2}
            maxLength={60}
          />
        </View>
        <View style={styles.dateContainer}>
          {Platform.OS === 'web' ? (
            <DateTimePickerModal
              title={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTime}
              onDateChange={handleDateChange}
              selectedDateExisting={new Date(currentActivity.date)}
              hourExisting={currentActivity.startTime.hour}
              minuteExisting={currentActivity.startTime.minute}
            />
          ) : (
            <DateTimePickerComponent
              title={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTime}
              onDateChange={handleDateChange}
              // dateExisting={new Date(currentActivity.date)}
              // pas tester en mobile
            />
          )}
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonAddActivityEnd}</Text>
          <SwitchBtn state={switchBtn} setState={setSwitchBtn} />
        </View>
        {/* {switchBtn && 
                <TimePicker title= {'hour: '} />
            } */}
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonLocalisationOnlyForAttendees}
          </Text>
          <SwitchBtn state={switchBtn1} setState={setSwitchBtn1} />
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonInviteYourCommunity}
          </Text>
          <SwitchBtn state={switchBtn2} setState={setSwitchBtn2} />
        </View>
        <View style={styles.textTitleOne}>
          <Text style={styles.textTitleOne}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidation}
          </Text>
        </View>
        <View style={styles.questionContainer}>
          <Pressable
            style={[styles.classic, attendeesValidation == false && styles.activeQuestion]}
            onPress={() => handleAttendeeValidationPress(false)}>
            <Text style={styles.textQuestion}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationAutomatic}
            </Text>
          </Pressable>
          <Pressable
            onPress={() => handleAttendeeValidationPress(true)}
            style={[styles.withStep, attendeesValidation == true && styles.activeQuestion]}>
            <Text style={styles.textQuestion}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationManual}
            </Text>
          </Pressable>
        </View>
        <View style={styles.btnContainer}>
          <ButtonNext handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton} conditionNeed={activityTitle} />
        </View>
      </View>
    </ScrollView>
  );
};

export default ClassicStepOne;
