// import { useEffect } from 'react';

// export function useDebounceEffect(fn, waitTime, deps) {
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       fn();
//     }, waitTime);

//     return () => {
//       clearTimeout(timer);
//     };
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [deps]);
// }

import { useEffect } from 'react';

export function useDebounceEffect(
  fn,
  waitTime,
  deps,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  },[deps])
}
