import AsyncStorage from '@react-native-async-storage/async-storage';

import Ar from '../../json/Translation/Ar.json';
import Bg from '../../json/Translation/Bg.json';
import Bs from '../../json/Translation/Bs.json';
import Cs from '../../json/Translation/Cs.json';
import Da from '../../json/Translation/Da.json';
import De from '../../json/Translation/De.json';
import El from '../../json/Translation/El.json';
import En from '../../json/Translation/En.json';
import Es from '../../json/Translation/Es.json';
import Et from '../../json/Translation/Et.json';
import Fi from '../../json/Translation/Fi.json';
import Fr from '../../json/Translation/Fr.json';
import Ga from '../../json/Translation/Ga.json';
import Gd from '../../json/Translation/Gd.json';
import He from '../../json/Translation/He.json';
import Hi from '../../json/Translation/Hi.json';
import Hr from '../../json/Translation/Hr.json';
import Hu from '../../json/Translation/Hu.json';
import Is from '../../json/Translation/Is.json';
import It from '../../json/Translation/It.json';
import Ja from '../../json/Translation/Ja.json';
import Lt from '../../json/Translation/Lt.json';
import Lv from '../../json/Translation/Lv.json';
import Mg from '../../json/Translation/Mg.json';
import Nl from '../../json/Translation/Nl.json';
import No from '../../json/Translation/No.json';
import Pl from '../../json/Translation/Pl.json';
import Pt from '../../json/Translation/Pt.json';
import Ro from '../../json/Translation/Ro.json';
import Ru from '../../json/Translation/Ru.json';
import Sk from '../../json/Translation/Sk.json';
import Sl from '../../json/Translation/Sl.json';
import Sr from '../../json/Translation/Sr.json';
import Sv from '../../json/Translation/Sv.json';
import Uk from '../../json/Translation/Uk.json';
import Zh from '../../json/Translation/Zh.json';

export const getLangue = async (state = En, action) => {
  switch (action.type) {
    case 'Arabic':
      return (state = Ar);
    case 'Bosnian':
      return (state = Bs);
    case 'Bulgarian':
      return (state = Bg);
    case 'Chinese':
      return (state = Zh);
    case 'Croatian':
      return (state = Hr);
    case 'Czech':
      return (state = Cs);
    case 'Danish':
      return (state = Da);
    case 'Dutch':
      return (state = Nl);
    case 'Estonian':
      return (state = Et);
    case 'Finnish':
      return (state = Fi);
    case 'French':
      return (state = Fr);
    case 'German':
      return (state = De);
    case 'Greek':
      return (state = El);
    case 'English':
      return (state = En);
    case 'Hebrew':
      return (state = He);
    case 'Hindi':
      return (state = Hi);
    case 'Hungarian':
      return (state = Hu);
    case 'Icelandic':
      return (state = Is);
    case 'Irish':
      return (state = Ga);
    case 'Italian':
      return (state = It);
    case 'Japanese':
      return (state = Ja);
    case 'Latvian':
      return (state = Lv);
    case 'Lithuanian':
      return (state = Lt);
    case 'Malagasy':
      return (state = Mg);
    case 'Norwegian':
      return (state = No);
    case 'Polish':
      return (state = Pl);
    case 'Portuguese':
      return (state = Pt);
    case 'Romanian':
      return (state = Ro);
    case 'Russian':
      return (state = Ru);
    case 'Scottish Gaelic':
      return (state = Gd);
    case 'Serbian':
      return (state = Sr);
    case 'Slovak':
      return (state = Sk);
    case 'Slovenian':
      return (state = Sl);
    case 'Spanish':
      return (state = Es);
    case 'Swedish':
      return (state = Sv);
    case 'Ukrainian':
      return (state = Uk);
    case 'UPDATE_LANGUE':
      await AsyncStorage.setItem('selectedLanguageName', action.payload);
      return action.payload;
    default:
      // Récupérer la langue depuis AsyncStorage au démarrage
      const storedLangue = await AsyncStorage.getItem('selectedLanguageName');
      return storedLangue || state;
  }
};
