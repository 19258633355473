// FR  l'écran de la première étape d'un processus d'inscription multi-étapes
// EN  the first step screen of a multi-step registration process
import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Fields from '../../../../components/Fields/Fields';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ButtonNext from "../../../../components/ButtonNext/ButtonNext";

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const StepOneScreen = ({ onNextStep }) => {
  const { translations } = useLanguage();
  const { language } = useLanguage();

  const [selectedSexe, setSelectedSexe] = useState('Male');
  const [selectedAccount, setSelectedAccount] = useState('personal');
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const handleSexePress = async option => {
    setSelectedSexe(option);
    try {
      await AsyncStorage.setItem('sexe', option);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du sexe:', error);
    }
  };

  useEffect(() => {
    const setInitialAccountType = async () => {
      if (isInitialPageLoad) {
        try {
          // Stockez l'information dans le format attendu par votre backend MongoDB
          await AsyncStorage.setItem('accountType', 'personal');
          await AsyncStorage.setItem('sexe', 'Male');
        } catch (error) {
          console.error("Erreur lors de l'enregistrement du type de compte par défaut:", error);
        }
        setIsInitialPageLoad(false);
      }
    };

    setInitialAccountType();
  }, [isInitialPageLoad]);

  const handleAccountPress = async option => {
    // Conversion des options en valeurs attendues
    const accountType = option === 'isPersonalAccount' ? 'personal' : 'pro';
    try {
      // Stockez directement le type de compte dans le format attendu
      await AsyncStorage.setItem('accountType', accountType);
      setSelectedAccount(accountType);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du type de compte:', error);
    }
  };

  // Utilisez la valeur selectedAccount pour déterminer la redirection
  const navigateNext = () => {
    onNextStep(selectedAccount);
  };

  return (
    <View ke={language} style={styles.container}>
      <View style={styles.sexe}>
        {/* Sélection du sexe */}
        <View style={styles.containerSexe}>
          <Pressable style={() => [styles.pressableMale, selectedSexe === 'Male' && styles.activePressable]} onPress={() => handleSexePress('Male')}>
            <View style={styles.male}>
              <View style={styles.icon}>
                <Fields.IconMaleSign />
              </View>
              <View style={styles.textBtn}>
                <Text style={styles.textSexe}>{translations.JsonCreateProfilePages.JsonMale}</Text>
              </View>
            </View>
          </Pressable>
          <Pressable style={() => [styles.pressableFemale, selectedSexe === 'Female' && styles.activePressable]} onPress={() => handleSexePress('Female')}>
            <View style={styles.female}>
              <View style={styles.icon}>
                <Fields.IconFemaleSign />
              </View>
              <View style={styles.textBtnF}>
                <Text style={styles.textSexe}>{translations.JsonCreateProfilePages.JsonFemale}</Text>
              </View>
            </View>
          </Pressable>
        </View>
        {/* Sélection du type de compte */}
        <View style={styles.containerAccount}>
          <Pressable style={() => [styles.personal, selectedAccount === 'personal' && styles.activeAccount]} onPress={() => handleAccountPress('isPersonalAccount')}>
            <Text style={styles.textAccount}>{translations.JsonCreateProfilePages.JsonPersonal}</Text>
          </Pressable>

          <Pressable style={() => [styles.pro, selectedAccount === 'pro' && styles.activeAccount]} onPress={() => handleAccountPress('isProAccount')}>
            <Text style={styles.textAccount}>{translations.JsonCreateProfilePages.JsonProfessional}</Text>
          </Pressable>
        </View>
        {/* Bouton pour passer à l'étape suivante */}
        <View style={styles.btnContainer}>
          <ButtonNext
            conditionNeed={selectedSexe!==null}
            handleNextStep={navigateNext}
            btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
          />
        </View>
      </View>
    </View>
  );
};

export default StepOneScreen;
