//FR afficher un bouton avec une icône et du texte, conçu pour être pressable 
//EN "Display a button with an icon and text, designed to be pressable.

// Import modules
import React from "react";
import { View, Text, Platform, Pressable } from "react-native";

// styles selon la plateforme
const styles = Platform.OS === 'web'
  ? require('./Styles.web').default
  : require('./Styles.mobile').default;

// Logique de component commence ici -----------------------------------------------------
const SelectButtonWithIcon = ({ icon, text, onPress, selected }) => {

  return (
    <Pressable onPress={onPress} style={[styles.container, selected && styles.container.selected]}>
      <View style={styles.iconSection}>
        {icon}
      </View>
      <View style={styles.textSection}>
        <Text style={styles.text}>{text}</Text>
      </View>
    </Pressable>
  );
}

export default SelectButtonWithIcon
