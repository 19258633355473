// FR  gère les erreurs dans  application(FrameFigma004,007C,013,)
// EN  manages errors in THIS application

import React, {createContext, useState, useContext} from 'react';

const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({children}) => {
  const [error, setError] = useState('');

  const clearError = () => setError('');

  return <ErrorContext.Provider value={{error, setError, clearError}}>{children}</ErrorContext.Provider>;
};


