//FR un navigateur d'édition de profil, utilisé pour guider les utilisateurs à travers les différentes étapes de modification de leur profil
//EN  profile editing navigator, used to guide users through the different steps of editing their profile

import React, {useState, useLayoutEffect} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import Fields from '../../../components/Fields/Fields';

import EditProfileStep1 from './EditProfileStep1Screen/EditProfileStep1Screen';
import EditProfileStep2 from './EditProfileStep2Screen/EditProfileStep2Screen';
import EditProfileStep3 from './EditProfileStep3Screen/EditProfileStep3Screen';

const EditProfileNavigator = () => {
  const navigation = useNavigation();
  const [step, setStep] = useState(1);

  const onNextStep = () => {
    if (step < 3) {
      setStep(prevStep => prevStep + 1);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => navigation.goBack()} style={{marginLeft: 15}}>
          <Fields.IconLeftArrow />
        </TouchableOpacity>
      ),
      // Modifier le titre en fonction de l'étape actuelle
      title: step === 2 ? 'SECRET INFORMATION' : 'Edit Profile',
      headerStyle: {backgroundColor: '#59C09B'},
      headerTintColor: 'white',
      headerTitleStyle: {fontWeight: 'bold', fontSize: 20},
    });
  }, [navigation, step]); // Ajouter 'step' comme dépendance

  const renderStep = () => {
    switch (step) {
      case 1:
        return <EditProfileStep1 onNextStep={onNextStep} />;
      case 2:
        return <EditProfileStep2 onNextStep={onNextStep} />;
      case 3:
        return <EditProfileStep3 onNextStep={onNextStep} />;
      default:
        return null;
    }
  };

  return <View style={styles.container}>{renderStep()}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default EditProfileNavigator;
