// affiche une boîte de dialogue modale pour confirmer la suppression d'un élément (FrameFigma041,FrameFigma041D)
// Display a modal dialog box to confirm the deletion of an item. (FrameFigma041,FrameFigma041D)
import React from "react";
import { Modal, Text, View, Pressable, Platform } from 'react-native';
import { useLanguage } from "../../contexts/LanguageContext";

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const ConfirmDeleteModal = ({ visible, onClose, onDelete }) => {
    const { translations } = useLanguage();
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <View style={styles.modal}>
          <Pressable style={styles.close} onPress={onClose}>
            <Text style={styles.closeText}>X</Text>
          </Pressable>
          <View>
            <Text style={styles.textMsg}>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonDeleteConfirmation}</Text>
          </View>
          <View style={styles.btnContainer}>
            <Pressable style={styles.btnConfirm} onPress={onDelete}>
              <Text style={styles.textMsgConfirm}>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonConfirmButton}</Text>
            </Pressable>
            <Pressable style={styles.btnCancel} onPress={onClose}>
              <Text style={styles.textMsgCancel}>{translations.JsonActivityPages.JsonActivityScreen.JsonCancel}</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmDeleteModal;
