import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useUser } from '../../../contexts/UserContext';
import { hostname } from '../../../../hostName/hostName';
import VisitedNotificationCard from '../../../components/NotificationCard/VisitedNotificationCard/VisitedNotificationCard';
import FriendAddedNotificationCard from '../../../components/NotificationCard/FriendAddedNotificationCard/FriendAddedNotificationCard';
import LikedNotificationCard from '../../../components/NotificationCard/LikedNotificationCard/LikedNotificationCard';
import ActivitiesLikeNotificationCard from '../../../components/NotificationCard/ActivitiesLikeNotification/ActivitiesLikeNotification';
import axios from 'axios';
import Images from '../../../constants/Images';
const NotificationsInfoScreen = () => {
  const {
    user,
    profileVisits = [], // Initialisez à un tableau vide par défaut
    markVisitAsRead,
    markVisitAsUnread,
    friendNotifications = [], // Initialisez à un tableau vide par défaut
    markFriendNotificationAsRead,
    markFriendNotificationAsUnread,
    likeNotifications = [], // Initialisez à un tableau vide par défaut
    markLikeNotificationAsRead,
    markLikeNotificationAsUnread,
    activityLikeNotifications = [], // Initialisez à un tableau vide par défaut
    markActivityLikeNotificationAsRead,
    markActivityLikeNotificationAsUnread,
  } = useUser();
  console.log("profileVisits", profileVisits)
  console.log("friendNotifications", friendNotifications)
  console.log("likeNotifications", likeNotifications)
  console.log("activityLikeNotifications", activityLikeNotifications)
  const [isMute, setIsMute] = useState(false)

  // get user notif setting
  const settings = user?.notificationSettings

  return (
    <>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        {settings?.visitorNotification && profileVisits.map(visit => (
          <VisitedNotificationCard
            key={visit._id}
            visitId={visit._id}
            visitorUserName={visit.visitor?.userName || 'Utilisateur inconnu'}
            visitedAt={visit.visitedAt}
            isRead={visit.isRead}
            onRead={markVisitAsRead}
            onUnread={markVisitAsUnread}
            isMute={isMute}
          />
        ))}
        {settings?.friendRequest && friendNotifications.map(notification =>
          notification.friend ? (
            <FriendAddedNotificationCard
              key={notification._id}
              friendName={notification.friend?.userName}
              friendId={notification.friend._id}
              isRead={notification.isRead}
              addedAt={notification.addedAt}
              onRead={() => markFriendNotificationAsRead(notification._id)}
              onUnread={() => markFriendNotificationAsUnread(notification._id)}
              isMute={isMute}
            />
          ) : (
            <View key={notification._id} style={styles.notificationMissing}>
              <Text>Un ami précédemment ajouté a supprimé son compte.</Text>
            </View>
          ),
        )}

        {settings?.likeNotification && likeNotifications.map(notification => (
          <LikedNotificationCard
            key={notification._id}
            likeId={notification._id}
            likerUserName={notification.likedBy?.userName} // Assurez-vous que le backend envoie bien ces informations
            likedAt={notification.likedAt}
            isRead={notification.isRead}
            onRead={() => markLikeNotificationAsRead(notification._id)}
            onUnread={() => markLikeNotificationAsUnread(notification._id)}
            isMute={isMute}
          />
        ))}
        {settings?.activityLikeNotifications && activityLikeNotifications.map(notification => (
          <ActivitiesLikeNotificationCard
            key={notification._id}
            notificationId={notification._id}
            likerUserName={notification.activityLike.likedBy?.userName}
            likedAt={notification.likedAt}
            isRead={notification.isRead}
            onRead={() => markActivityLikeNotificationAsRead(notification._id)}
            onUnread={() => markActivityLikeNotificationAsUnread(notification._id)}
            isMute={isMute}
          />
        ))}
      </ScrollView>
      <TouchableOpacity
        style={styles.iconMute}
        onPress={() => setIsMute(!isMute)}
      >
        <View>
          <Images.NotificationsBellMute style={{ width: 50, height: 50 }} />
        </View>
      </TouchableOpacity>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    minHeight: "100%",
  },
  notificationMissing: {
    padding: 10,
    backgroundColor: '#f2f2f2',
    marginVertical: 5,
    borderRadius: 5,
  },
  iconMute: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    backgroundColor: 'white',
    width: 56,
    height: 56,
    borderRadius: 28,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    borderColor: '#00000080',
    borderWidth: 1,
  },
  // autres styles...
});

export default NotificationsInfoScreen;
