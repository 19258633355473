// Fr Cette page affiche l'étape 1 du formulaire pour modifier une activité de type VIP(FrameFigma038A, FrameFigma038B)
// En This page displays the first step of the form to edit an activity of type VIP (FrameFigma038A, FrameFigma038B)

import React, {useState, useEffect} from 'react';
import {ScrollView, View, Text, TextInput, Pressable, Platform, TouchableOpacity} from 'react-native';
import SwitchBtn from '../../../../components/SwitchBtn/SwitchBtn';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {useUser} from '../../../../contexts/UserContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import Modal from '../../../../components/Modal/Modal';
import countriesListSquareFlags from '../../../../components/LanguagesFlags/LanguagesFlagsSquare';
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const EditActivityVipStepOne = ({onNextStep, currentActivity,onPrevStep}) => {
  const {translations} = useLanguage();
  const {user} = useUser();

  // var pour afficher le pop up ComingSoon
  const [comingSoonVisible, setComingSoonVisible] = useState(false);

  const [chooseCommunity, setChooseCommunity] = useState(true);
  const [peopleMet, setPeopleMet] = useState(false);
  const [peopleMyLanguage, setPeopleMyLanguage] = useState(false);
  const [peopleMetOrga, setPeopleMetOrga] = useState(false);
  const [peopleLoveTopics, setPeopleLoveTopics] = useState(false);
  const [aroundMeSliderValue, setAroundMeSliderValue] = useState(4);
  const [aroundPointSliderValue, setAroundPointSliderValue] = useState(6);
  const [peopleChoiceLanguages, setPeopleChoiceLanguages] = useState([]);

  // stocker les var dans AsyncStorage pour recuperer dans screen suivant
  useEffect(() => {
    AsyncStorage.setItem('chooseCommunity', JSON.stringify(chooseCommunity));
    AsyncStorage.setItem('peopleMet', JSON.stringify(peopleMet));
    AsyncStorage.setItem('peopleMyLanguage', JSON.stringify(peopleMyLanguage));
    AsyncStorage.setItem('peopleMetOrga', JSON.stringify(peopleMetOrga));
    AsyncStorage.setItem('peopleLoveTopics', JSON.stringify(peopleLoveTopics));
    AsyncStorage.setItem('aroundMeSliderValue', JSON.stringify(aroundMeSliderValue) || 4);
    AsyncStorage.setItem('aroundPointSliderValue', JSON.stringify(aroundPointSliderValue) || 6);
    AsyncStorage.setItem('peopleChoiceLanguages', JSON.stringify(peopleChoiceLanguages) || []);
  }, [
    chooseCommunity,
    peopleMet,
    peopleMyLanguage,
    peopleMetOrga,
    peopleLoveTopics,
    aroundMeSliderValue,
    aroundPointSliderValue,
    peopleChoiceLanguages,
  ]);

  // Fonction de nettoyage pour supprimer les données du AsyncStorage lorsque la page est déchargée
  useEffect(() => {
    const cleanup = async () => {
      await AsyncStorage.removeItem('chooseCommunity');
      await AsyncStorage.removeItem('peopleMet');
      await AsyncStorage.removeItem('peopleMyLanguage');
      await AsyncStorage.removeItem('peopleMetOrga');
      await AsyncStorage.removeItem('peopleLoveTopics');
      await AsyncStorage.removeItem('aroundMeSliderValue');
      await AsyncStorage.removeItem('aroundPointSliderValue');
      await AsyncStorage.removeItem('peopleChoiceLanguages');
    };
    cleanup();
  }, []);

  useEffect(() => {
    // get data of current activty
    if (currentActivity) {
      setChooseCommunity(currentActivity.communityOptionIsSelected);
      setPeopleMet(currentActivity.peopleMetNotif);
      setPeopleMyLanguage(currentActivity.peopleLanguageIsSelected);
      setPeopleMetOrga(currentActivity.peopleMetAsOrgaNotif);
      setPeopleLoveTopics(currentActivity.peopleWhoLoveMyTopicNotif);
      setAroundMeSliderValue(currentActivity.communityMeDistance || 4);
      setAroundPointSliderValue(currentActivity.communityMeetingPointDistance || 6);
      setPeopleChoiceLanguages(currentActivity.peopleLanguageOptions.length > 0 ? JSON.parse(currentActivity.peopleLanguageOptions) : []);
    }
  }, [currentActivity]);

  const flagsDetails = countriesListSquareFlags.filter(flag => {
    const languageSpokenFlag = user?.spokenLanguage?.includes(flag.bigram);
    return languageSpokenFlag;
  });

  const handleSpokenLanguageChange = bigram => {
    setPeopleChoiceLanguages(
      peopleChoiceLanguages.includes(bigram) ? peopleChoiceLanguages.filter(b => b !== bigram) : [...peopleChoiceLanguages, bigram],
    );
  };

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <Text style={styles.textbtn}>
        {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonAppWillHelpToAttractPeopleMessage}
      </Text>
      <View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonChooseYourCommunity}
          </Text>
          <SwitchBtn
            state={chooseCommunity}
            // setState={setChooseCommunity}
            setState={setComingSoonVisible}
          />
        </View>
        <Text style={styles.textFin}>
          ({translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonOnlyOneExceptForVIP})
        </Text>
      </View>
      {chooseCommunity === true && (
        <View style={styles.twoSliders}>
          <View>
            <Text style={styles.sliderText}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleAroundMe}
            </Text>
            <SliderComponent min={2} max={20} sliderValue={aroundMeSliderValue} setSliderValue={setAroundMeSliderValue} />
          </View>
          <View>
            <Text style={styles.sliderText}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleAroundMeetingPoint}
            </Text>
            <SliderComponent min={2} max={20} sliderValue={aroundPointSliderValue} setSliderValue={setAroundPointSliderValue} />
          </View>
        </View>
      )}
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleIMet}
        </Text>
        <SwitchBtn
          state={peopleMet}
          // setState={setPeopleMet}
          setState={setComingSoonVisible}
        />
      </View>
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleWithMyLanguage}
        </Text>
        <SwitchBtn
          state={peopleMyLanguage}
          // setState={setPeopleMyLanguage}
          setState={setComingSoonVisible}
        />
      </View>
      {peopleMyLanguage === true && user?.spokenLanguage && (
        <View style={styles.flagsContainer}>
          {flagsDetails.map(({FlagComponent, bigram}) => (
            <TouchableOpacity
              key={bigram}
              onPress={() => handleSpokenLanguageChange(bigram)}
              style={[styles.flagWrapper, peopleChoiceLanguages.includes(bigram) ? styles.flagWrapperSelected : {}]}>
              <FlagComponent width={30} height={30} />
            </TouchableOpacity>
          ))}
        </View>
      )}
      <Modal
        modalVisible={comingSoonVisible}
        onClose={() => setComingSoonVisible(false)}
        children={<Text>{translations.JsonConceptScreen.JsonConceptPageSevenTitle}</Text>}
      />
      <View style={styles.switchBtn}>
        {/* text a corriger dans json, pas pareil que figma */}
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleIMetAsOrganizer}
        </Text>
        <SwitchBtn
          state={peopleMetOrga}
          // setState={setPeopleMetOrga}
          setState={setComingSoonVisible}
        />
      </View>
      <View style={styles.switchBtn}>
        <Text style={styles.textbtn}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleWhoLoveMyTopic}
        </Text>
        <SwitchBtn
          state={peopleLoveTopics}
          // setState={setPeopleLoveTopics}
          setState={setComingSoonVisible}
        />
      </View>
      <View style={styles.btnContainer}>
        <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
        <ButtonNext
          conditionNeed={peopleMyLanguage === true ? peopleChoiceLanguages.length > 0 : null}
          handleNextStep={onNextStep}
          btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}
        />
      </View>
    </ScrollView>
  );
};

export default EditActivityVipStepOne;
