//🇫🇷 Ce fichier gère le style mobile du composant Sponsorship.js (FrameFigma004B)
//🇬🇧 This file manages the mobile style of the Sponsorship.js component (FrameFigma004B)
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: '10%',
    // justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    // backgroundColor: '#f5f5f5', // Un léger fond gris pour le conteneur
    backgroundColor: 'white',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 15,
  },
  containerInput: {
    width: '80%',
    marginVertical: 30
  },
  containerBtn: {
    width: '90%',
    alignItems: 'center',
  },
  input: {
    width: '100%', // Prend toute la largeur disponible
    height: 40,
    paddingHorizontal: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#cccccc', // Bordure légèrement grise
    borderRadius: 5, // Coins arrondis pour l'input
    backgroundColor: '#ffffff', // Fond blanc pour l'input
  },
  button: {
    width: '100%', // Prend toute la largeur disponible
    paddingVertical: 10,
    backgroundColor: '#59C09B',
    borderRadius: 5, // Coins arrondis pour le bouton
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: '#ffffff', // Texte blanc pour le bouton
    fontSize: 18,
  },
  error: {
    backgroundColor: 'red', // Fond rouge pour l'erreur
    color: 'white', // Texte en blanc
    padding: 10, // Espacement interne pour mieux encadrer le texte
    borderRadius: 5, // Bords arrondis
    marginTop: 10, // Marge au-dessus pour séparation
    textAlign: 'center', // Centrage du texte
    width: '80%', // Largeur pour mieux s'adapter au conteneur
  },
});
