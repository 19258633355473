// FR calcule et met à jour les points d'un utilisateur en fonction de diverses activités et interactions
// EN calculates and updates a user's points based on various activities and interactions
import {useEffect, useState} from 'react';
import {useUser} from '../contexts/UserContext';
import {useAuth} from '../contexts/AuthContext';
import {hostname} from '../../hostName/hostName';

export const CalculateUserPointsDrawer = userId => {
  const {allUsers} = useUser();
  const {userToken} = useAuth();
  const [userPoints, setUserPoints] = useState(0);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`${hostname}/userLikes`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        const userLikesReceived = data.filter(like => like.likedUser === userId);
        const user = allUsers.find(user => user._id === userId);

        if (!user) return;

        const userProfileVisits = user?.profileVisits?.length > 0 ? [...new Set(user.profileVisits?.map(visit => visit.visitor))].length : 0;
        const userFriends = user?.friends?.length > 0 ? user.friends.length : 0;
        const userSponsoredUsers = user?.sponsoredUsers?.length > 0 ? user.sponsoredUsers.length : 0;
        const activitiesOrganising = user.activitiesOrganising ?? 0;
        const activitiesAttending = user.activitiesAttending ?? 0;
        const activitiesCoOrganising = user.activitiesCoOrganising ?? 0;

        const calculatedPoints =
          userFriends * 20 +
          activitiesOrganising * 100 +
          activitiesAttending * 25 +
          activitiesCoOrganising * 50 +
          userProfileVisits * 5 +
          userSponsoredUsers * 250 +
          userLikesReceived.length * 10;

        setUserPoints(calculatedPoints);

        // Update user points on the server
        await fetch(`${hostname}/users/${userId}/updatePoints`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({points: calculatedPoints}),
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [userId, userToken, allUsers]);

  return userPoints;
};
