// Fr  Styles de ActivityMetaDetails(FrameFigma041,FrameFigma042,FrameFigma043,FrameFigma044)
// En  Styles of ActivityMetaDetails(FrameFigma041,FrameFigma042,FrameFigma043,FrameFigma044)

import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
    blocOne:{
        backgroundColor: 'white',
    },
    titleContainer: {
        alignItems: 'center',
    },
    activityTitle: {
        fontSize: 16,
        fontWeight: "bold",
    },
    dateAndHour: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
    },
    hour: {
        color: 'red',
    },
    date: {
        color: 'red',
    },
    profileCodeHeart:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 10,
        marginRight: 10,
        marginVertical: 10,
    },
    postal:{
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    authorImageView: {
        flexDirection: 'row',
        paddingBottom: 5,
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
    }, 
    authorImage: {
        width: 45, 
        height: 45, 
        borderRadius: 50, 
        justifyContent: 'center'
    },
    address: {
        backgroundColor: "#606062",
    },
    addressText: {
        color: 'white',
        textAlign:'center',
        fontWeight: 700,
        fontSize: 16,
    },
    authorText: {
        marginLeft: 5,
        gap: 5,
    }
})

export default styles;