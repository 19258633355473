//🇫🇷 Page d'informations à propos de nous (FrameFigma91)
//🇬🇧 News Screen about us (FrameFigma91)

import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {
  ImageBackground,
  SafeAreaView,
  ScrollView,
  Text,
  Pressable,
  View,
  Platform,
  Linking, //🇫🇷 Importer Linking pour les liens externes //🇬🇧 Import Linking for external links
} from 'react-native';

//🇫🇷 Import des images , icones et des styles
// 🇬🇧 Import of images, icons, and styles
import Facebook from '../../assets/Icons/SocialNetworks/Facebook.svg';
import FreeApp from '../../assets/Icons/Achievements/Box/FreeGift.svg';
import Idea from '../../assets/Icons/General/Colors/Idea.svg';
import NonProfit from '../../assets/Icons/UsersInteraction/Colors/NonProfit.svg';
import StarUsers from '../../assets/Icons/Achievements/Stars/RatingOnActiveUsers.svg';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

import { useLanguage } from '../../contexts/LanguageContext';

const News = () => {
  const { translations } = useLanguage();
  const navigation = useNavigation();

  return (
    <SafeAreaView style={styles.container}>
      {/* 🇫🇷 ScrollView pour permettre le défilement du contenu 🇬🇧 ScrollView to allow content scrolling */}
      <ScrollView style={styles.scrollViewContainer}>
        {/* 🇫🇷 Conteneur pour l'image de fond en haut de l'écran 🇬🇧 Container for the top background image */}
        <View style={styles.imgViewContainer}>
          {/* 🇫🇷 Utilisez conditionnellement ImageBackground ou une balise <img> pour le web 🇬🇧 Conditionally use ImageBackground or an <img> tag for the web */}
          {Platform.OS === 'web' ? <img src={require('../../assets/Images/Backgrounds/GroupSelfieFriends.png').default} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt="Group Selfie" /> : <ImageBackground source={require('../../assets/Images/Backgrounds/GroupSelfieFriends.png')} resizeMode={'cover'} style={{flex: 1}} />}
        </View>
        {/* 🇫🇷 Titre de la page 🇬🇧 Page title */}
        <Text style={styles.title}>{translations.JsonNewsScreen.JsonMainTitle}</Text>

        {/* 🇫🇷 Section des informations avec icônes et textes 🇬🇧 Information section with icons and texts */}
        {/* 🇫🇷 Chaque ligne contient une icône et un texte descriptif 🇬🇧 Each line contains an icon and a descriptive text */}

        {/* 🇫🇷 Première ligne : NonProfit 🇬🇧 First line: NonProfit */}
        <View style={styles.newslist}>
          <NonProfit style={styles.img} />
          <Text style={styles.txt}>{translations.JsonNewsScreen.JsonNonProfitMessage}</Text>
        </View>

        {/* 🇫🇷 Deuxième ligne : StarUsers 🇬🇧 Second line: StarUsers */}
        <View style={styles.newslist}>
          <StarUsers style={styles.img} />
          <Text style={styles.txt}>{translations.JsonNewsScreen.JsonActiveUsersMessage}</Text>
        </View>

        {/* 🇫🇷 Troisième ligne : FreeApp 🇬🇧 Third line: FreeApp */}
        <View style={styles.newslist}>
          <FreeApp style={styles.img} />
          <Text style={styles.txt}>{translations.JsonNewsScreen.JsonFreeAppMessage}</Text>
        </View>

        {/* 🇫🇷 Quatrième ligne : Idea 🇬🇧 Fourth line: Idea */}
        <View style={styles.helpParagraph}>
          <Idea style={styles.img} />
          <Text style={styles.txt}>{translations.JsonNewsScreen.JsonHelpTheAppMessage}</Text>
        </View>

        {/* 🇫🇷 Bouton pour rejoindre le groupe Facebook 🇬🇧 Button to join the Facebook group */}
        <Pressable style={styles.ButtonFB} onPress={() => Linking.openURL('https://www.facebook.com/groups/socializus')}>
          <Facebook />
          <View style={styles.row}>
            <Text style={styles.buttonTextFB}>{translations.JsonNewsScreen.JsonJoinFaceBookButton}</Text>
          </View>
        </Pressable>

        {/* 🇫🇷 Bouton pour naviguer vers la liste des activités 🇬🇧 Button to navigate to the activities list */}
        <Pressable style={styles.eventRedirectButton} onPress={() => navigation.push('Activities List')}>
          <View>
            <Text style={styles.buttonText}>{translations.JsonNewsScreen.JsonFindEventButton}</Text>
          </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>
  );
};

export default News;
