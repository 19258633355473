import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  label: {
    fontSize: 16,
    marginVertical: 8,
    flexDirection: 'row',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
    color: '#8C8F8E',
    borderRadius: 15,
  },
  inputDesc: {
    marginLeft: 10,
    marginBottom: 5,
    color: '#C4C4C4',
    fontStyle: 'italic',
  },
  datePicker: {
    width: '100%',
    marginBottom: 20,
  },
  memberId: {
    color: '#59C09B',
    fontWeight: 'bold',

  },
  labelText: {
    fontWeight: 'bold',
  },
  button: {
    padding: 10,
    borderRadius: 5,
    margin: 5,
    width: '40%',
    height: 100,
  },
  buttonText: {
    color: 'white',
  },
  buttonSelected: {
    backgroundColor: '#59C09B',
  },
  buttonNotSelected: {
    backgroundColor: 'grey',
  },
  ButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
    alignItems: 'center',
  },
  button: {
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    width: 156,
    height: 156,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonSelected: {
    backgroundColor: '#59C09B',
    color:'white',
  },
  buttonNotSelected: {
    backgroundColor: 'transparent',
  },
  buttonTextPrivate: {
    fontSize: 15,
    marginTop:10,

    textAlign: "center",
    fontWeight: 400,
    color: 'black',
  },
  buttonTextPrivateSelected: {
    fontSize: 12,
    marginTop:10,
    textAlign: "center",
    fontWeight: 400,
    color: 'white',
  },
  buttonTextPrivateNotSelected: {
    fontSize: 12,
  },
  buttonTextSelected: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    marginTop:10,
  },
  buttonTextNotSelected: {
    color: 'black',
    fontSize: 16,
    fontWeight: 600,
    marginTop:10,
  },
buttonDisabled: {
  backgroundColor: '#ccc',

},
  ContinueButton: {
    backgroundColor: '#59C09B',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
  },
  submitButton: {
    backgroundColor: '#59C09B',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
  },
  submitButtonText: {
    color: 'white',
    fontSize: 18,
    width: 160,
    height: 24,
    textAlign: "center",
  },
  ContinueButtonText: {
    color: 'white',
    fontSize: 18,
    width: 160,
    height: 24,
    textAlign: "center",
  },
  birthdayContainer:{
    flexDirection: 'row', 
  },
  icons: {
    width: 24, 
    height: 24,

  }, 
  iconsContainer:{
    width: 48, 
    height: 48,
    borderRadius: '50%', 
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoConfidentiality:{
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoBirthday:{
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 5,
  },
  Confidentiality:{
    display:"flex",
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textConfidential:{
    textAlign: "center",
    color: '#FF0000',
    fontSize: 14,
    fontWeight: 700,
  }
});


