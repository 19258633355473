import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  sectionTitlePublic: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 10,
    textAlign: 'center',
    color: '#59C09B',
  },
  inputMoreOnYou: {
    height: 100, 
    borderColor: 'gray', 
    borderWidth: 1,
    borderRadius: 10,
    paddingLeft: 20,
    paddingTop: 15,
  },
  submitButton: { 
    marginTop: 40,
    backgroundColor: '#59C09B',
    padding: 10,
    borderRadius: 10,
    width: '100%',
    boxShadow: "0px 4px 4px 0px #00000040",
  },
  aboutYouText: {
    position: 'absolute',
    top: 77,
    backgroundColor: '#F0EEE9',
    left: 20,
    paddingHorizontal: 10,

  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 10,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  activitiesContainer: {
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    flexWrap: "wrap",
    backgroundColor: "#DDDDDD",
    borderRadius: 30,
    opacity: 0.5,
    marginTop: 15,
    // marginHorizontal: 0,
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 20,
    boxShadow: "0px 4px 4px 0px #00000040",
    // marginHorizontal: -2.5
    // marginHorizontal:-20,
    color: 'black'
  },
  activityCard: {
    marginHorizontal: 4,
    marginTop: 8,
    paddingTop: 10,
    borderRadius: 10,
    width: 80,
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 4px 0px #00000040",
},
activityTitle: {
  marginBottom: 5,
  fontSize: 12.5,
  paddingTop: 10,
},
  activityIcon: {
    margin: 5,
  },
  flagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  flagWrapper: {
    width: 76,
    height: 40,
    backgroundColor: '#FFFFFF', // Default background color
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#59C09B',
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  flagWrapperSelected: {
    backgroundColor: '#59C09B', // Background color when selected
  },
  sectionPerso: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  button: {
    backgroundColor: '#E8E8E8',
    padding: 10,
    borderRadius: 10,
    width: 120,
    boxShadow: "0px 4px 4px 0px #00000040",
  },
  buttonSelected: {
    backgroundColor: '#59C09B',
  },
  buttonText: {
    color: '#000',
  },
});
