import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
   sexe: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
  },
  containerSexe: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '12%',
  },

  pressableMale: {
    height:37,
    borderWidth: 1,
    width: '40%',
    borderTopLeftRadius: 13,
    borderBottomLeftRadius: 13,
    padding: 5,
  },
  male: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pressableFemale: {
    height:37,
    borderWidth: 1,
    width: '40%',
    borderTopRightRadius: 13,
    borderBottomRightRadius: 13,
    padding: 5,
  },
  female: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginHorizontal: '5%',
  },
  textBtn: {
    alignItems: 'center',
    width: '60%',
  },
  textBtnF: {
    alignItems: 'center',

  },
  textSexe: {
    fontWeight: 'bold',
    textAlign: 'center',

  },
  activePressable: {
    backgroundColor: '#59C09B',
  },
  containerAccount: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: '5%',
  },
  personal: {
    borderWidth: 1,
    width: '40%',
    borderTopLeftRadius: 13,
    borderBottomLeftRadius: 13,
    padding: 7,
    height: 58,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pro: {
    borderWidth: 1,
    width: '40%',
    borderTopRightRadius: 13,
    borderBottomRightRadius: 13,
    padding: 7,
    height: 58,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textAccount: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1rem',
  },
  activeAccount: {
    backgroundColor: '#59C09B',
  },
  btnContainer: {
    width: '80%',
    alignItems: 'center',
    marginTop: '10%',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: '15%',
    alignItems: 'center',
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
});
