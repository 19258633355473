import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    width: '100%',
    // gap: 20,
    paddingHorizontal: 20,
    paddingTop: 10
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  networkNumber: {
    flexDirection: 'row',
  },
  headerLeft: {
    flexDirection: 'column',
  },
  boldText: {
    fontWeight: 'bold'
  },
  headerRight: {
    flexDirection: 'row',
    // gap: 20
  },
  underHeader: {
    top: -10,
  },
  legendCircleYellow: {
    width: 15,
    height: 15,
    borderColor: '#DAE08D',
    borderWidth: 2,
    borderRadius: 15
  },
  legendCircleBlue: {
    width: 15,
    height: 15,
    borderColor: '#65B3EA',
    borderWidth: 2,
    borderRadius: 15
  },
  legend: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    marginBottom: 25
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  membersTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 5
  },
  networkCount: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#59C09B',
    marginLeft: 15
  },
  network: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '4%'
  },
  networkItem: {
    overflow: 'visible',
    width: 90,
    marginBottom: '4%',
  },
});