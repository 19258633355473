//(FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma031) (FrameFigma039)
import React, { useState, useCallback,useEffect } from "react";
import { View, Text, Pressable, Platform } from "react-native";
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { format, addYears, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Importation des styles en fonction de la plateforme
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const DateTimePicker = ({ title, onDateChange = "", type = "", onDateChangeForOnlyDate = "", selectedDateExisting = null, hourExisting = null, minuteExisting = null }) => {
    const [selectedDate, setSelectedDate] = useState(selectedDateExisting);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
useEffect(()=>{
    if (hourExisting !== null && minuteExisting !== null&&selectedDateExisting !== null) {
        selectedDate.setHours(hourExisting, minuteExisting)
        AsyncStorage.setItem('selectedHour', hourExisting.toString());
        AsyncStorage.setItem('selectedMinute', minuteExisting.toString());
    }
    if (selectedDateExisting !== null) {
        AsyncStorage.setItem('selectedDate', selectedDate ? selectedDate.toISOString() : tomorrow.toISOString());
    }
},[selectedDateExisting , hourExisting , minuteExisting])

    // La date minimale est définie sur la date du lendemain pour empêcher la sélection de jours passés
    const minDate = new Date();

    // Fonction pour ouvrir le sélecteur de date
    const openDatePicker = () => {
        setIsDatePickerOpen(true);
    };

    // Fonction pour fermer le sélecteur de date
    const dismissDatePicker = useCallback(() => {
        setIsDatePickerOpen(false);
    }, []);

    // Fonction pour ouvrir le sélecteur d'heure après avoir choisi une date
    const openTimePicker = () => {
        setIsTimePickerOpen(true);
    };

    // Fonction pour fermer le sélecteur d'heure
    const dismissTimePicker = useCallback(() => {
        setIsTimePickerOpen(false);
    }, []);

    // Fonction appelée lors de la confirmation de la date
    const handleDateConfirm = ({ date }) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (!date) {
            setSelectedDate(tomorrow);
        } else {
            setSelectedDate(date);
        }
        dismissDatePicker();
        openTimePicker(); // Ouvrir directement le sélecteur d'heure après la sélection de la date

        // Sauvegarde de la date sélectionnée dans AsyncStorage
        AsyncStorage.setItem('selectedDate', date ? date.toISOString() : tomorrow.toISOString());
    };
    const handleDateConfirmForOnlyDate = ({ date }) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (!date) {
            setSelectedDate(tomorrow);
        } else {
            setSelectedDate(date);
        }
        dismissDatePicker();
    };




    // Fonction appelée lors de la confirmation de l'heure
    const handleTimeConfirm = ({ hours, minutes }) => {
        const newDate = new Date(selectedDate);
        newDate.setHours(hours, minutes);
        setSelectedDate(newDate);
        dismissTimePicker();

        // Formatage et sauvegarde de l'heure sélectionnée dans AsyncStorage
        const formattedTime = format(newDate, "HH:mm", { locale: fr });
        AsyncStorage.setItem('selectedTime', formattedTime);

        // Stocker les heures et les minutes dans AsyncStorage
        AsyncStorage.setItem('selectedHour', hours.toString());
        AsyncStorage.setItem('selectedMinute', minutes.toString());
        { onDateChange !== "" && onDateChange(newDate) }
        //si ya cette fonction, envoyez data a parents, sinon rien fait
    };

    // Formatage de la date et de l'heure pour l'affichage
    const formattedDateTime = selectedDate ? format(selectedDate, "dd/MM/yyyy 'à' HH:mm", { locale: fr }) : "";
    const formattedDateTimeForEnd = selectedDate ? format(selectedDate, "dd/MM/yyyy", { locale: fr }) : "";

    // Définition de la plage de dates valides
    const validRange = {
        startDate: minDate,
        endDate: addYears(minDate, 10), // 10 ans à partir de la date actuelle
    };

    // Année de départ pour le sélecteur de date
    const startYear = minDate.getFullYear();

    return type === ""
        ? (<View style={{ flex: 1 }}>
            <Pressable
                style={styles.container}
                onPress={openDatePicker}
            >
                <View style={styles.row}>
                    <Text style={styles.boldTitle}>{title}</Text>
                    <Text style={styles.dateTime}>{formattedDateTime || "__ / __ /____ à __ : __"}</Text>
                </View>
            </Pressable>
            {isDatePickerOpen && (
                <DatePickerModal
                    mode="single"
                    visible={isDatePickerOpen}
                    onDismiss={dismissDatePicker}
                    date={selectedDate}
                    validRange={validRange} // Plage de dates valides
                    startYear={startYear} // Année de départ
                    onConfirm={handleDateConfirm}
                    locale="fr"
                />
            )}
            {isTimePickerOpen && (
                <TimePickerModal
                    visible={isTimePickerOpen}
                    onDismiss={dismissTimePicker}
                    hours={selectedDate ? selectedDate.getHours() : minDate.getHours()}
                    minutes={selectedDate ? selectedDate.getMinutes() : minDate.getMinutes()}
                    onConfirm={handleTimeConfirm}
                    locale="fr"
                />
            )}
        </View>)
        : (<View style={{ flex: 1 }}>
            <Pressable
                style={styles.container}
                onPress={openDatePicker}
            >
                <View style={styles.row}>
                    <Text style={styles.boldTitle}>{title}</Text>
                    <Text style={styles.dateTime}>{formattedDateTimeForEnd || "__ / __ /____"}</Text>
                </View>
            </Pressable>
            {isDatePickerOpen && (
                <DatePickerModal
                    mode="single"
                    visible={isDatePickerOpen}
                    onDismiss={dismissDatePicker}
                    date={selectedDate}
                    validRange={validRange} // Plage de dates valides
                    startYear={startYear} // Année de départ
                    onConfirm={(date) => {
                        onDateChangeForOnlyDate(date)
                        handleDateConfirmForOnlyDate(date)
                    }}
                    locale="fr"
                />
            )}
        </View>);
};

export default DateTimePicker;
