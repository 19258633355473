import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    elevation: 10,
  },
  modal: {
    width: 300,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#59C09B',
    gap: 20,
    padding: 10,
    borderRadius: 10,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
  },
  close: {
    alignSelf: 'flex-end',
  },
  modalText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
  },
  modalButton: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 10,
  },
  modalButtonText: {
    color: '#59C09B',
  },
});

export default styles;
