import React, { useState, useEffect } from 'react';
import { View, Dimensions, PermissionsAndroid, Image, Platform } from 'react-native';
import Geolocation from '@react-native-community/geolocation';
import MapView, { Marker } from 'react-native-maps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { GOOGLE_MAPS_API_KEY } from '@env';
import { hostname } from '../../../../../hostName/hostName';
import { useUser } from '../../../../contexts/UserContext';

// Mappage des icônes pour chaque type d'activité
const activitiesIconMapping = {
  1: require('../../../../assets/Images/Activity/Business.png'),
  2: require('../../../../assets/Images/Activity/Afterwork.png'),
  3: require('../../../../assets/Images/Activity/Apero.png'),
  4: require('../../../../assets/Images/Activity/Travel.png'),
  5: require('../../../../assets/Images/Activity/Dancing.png'),
  6: require('../../../../assets/Images/Activity/Sports.png'),
  7: require('../../../../assets/Images/Activity/Music.png'),
  8: require('../../../../assets/Images/Activity/Private.png'),
  9: require('../../../../assets/Images/Activity/MutualHelp.png'),
  10: require('../../../../assets/Images/Activity/Linguistic.png'),
  11: require('../../../../assets/Images/Activity/Movie.png'),
  12: require('../../../../assets/Images/Activity/Games.png'),
  13: require('../../../../assets/Images/Activity/Culture.png'),
  14: require('../../../../assets/Images/Activity/Meal.png'),
  15: require('../../../../assets/Images/Activity/Party.png'),
  16: require('../../../../assets/Images/Activity/Picnic.png'),
  // 1: 'https://images.socializ.us/server-image/image/1713774303392_Business.svg',
  // 2: 'https://images.socializ.us/server-image/image/1713774303371_Afterwork.svg',
  // 3: 'https://images.socializ.us/server-image/image/1713774303358_Apero.svg',
  // 4: 'https://images.socializ.us/server-image/image/1713774303410_Travel.svg',
  // 5: 'https://images.socializ.us/server-image/image/1713774303406_Dancing.svg',
  // 6: 'https://images.socializ.us/server-image/image/1713774303396_Sports.svg',
  // 7: 'https://images.socializ.us/server-image/image/1713774303375_Music.svg',
  // 8: 'https://images.socializ.us/server-image/image/1713774303381_Private.svg',
  // 9: 'https://images.socializ.us/server-image/image/1713774303403_MutualHelp.svg',
  // 10: 'https://images.socializ.us/server-image/image/1713774303400_Linguistic.svg',
  // 11: 'https://images.socializ.us/server-image/image/1713774303367_Movie.svg',
  // 12: 'https://images.socializ.us/server-image/image/1713774303378_Games.svg',
  // 13: 'https://images.socializ.us/server-image/image/1713774303386_Culture.svg',
  // 14: 'https://images.socializ.us/server-image/image/1713774303414_Meal.svg',
  // 15: 'https://images.socializ.us/server-image/image/1713774303353_Party.svg',
  // 16: 'https://images.socializ.us/server-image/image/1713774303364_Picnic.svg',
};
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const MapsMobileActivities = () => {
  const [activities, setActivities] = useState([]);
  const { user } = useUser();
  const [region, setRegion] = useState({
    latitude: 48.8566,
    longitude: 2.3522,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  });

  const requestLocationPermission = async () => {
    if (Platform.OS === 'ios') {
      Geolocation.requestAuthorization('whenInUse');
      return true;
    }

    if (Platform.OS === 'android') {
      const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION, {
        title: 'Location Permission',
        message: 'This app needs access to your location',
      });
      return granted === PermissionsAndroid.RESULTS.GRANTED;
    }

    return false;
  };

  const getCurrentPosition = () => {
    Geolocation.getCurrentPosition(
      position => {
        setRegion({
          ...region,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      error => console.warn(error),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
    );
  };

  const fetchActivities = async () => {
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/activities`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      if (!response.ok) throw new Error('Failed to fetch activities');
      const data = await response.json();
      setActivities(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    requestLocationPermission().then(granted => {
      if (granted) {
        getCurrentPosition();
        fetchActivities();
      }
    });
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <MapView style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height }} region={region} showsUserLocation={true} onRegionChangeComplete={region => setRegion(region)}>
        {activities.map(activity => (
          <Marker
            key={activity._id}
            coordinate={{ latitude: activity.location.latitude, longitude: activity.location.longitude }}
            title={activity.title}
            description={activity.description}
          // image={activitiesIconMapping[activity.topic]}
          >
            <View style={[styles.imageContainer, { width: Dimensions.get('window').width * 0.15, height: Dimensions.get('window').width * 0.15, }]}>
              <Image source={activitiesIconMapping[activity.topic]} style={styles.imageItem} />
            </View>
          </Marker>
        ))}
      </MapView>
    </View>
  );
};

export default MapsMobileActivities;