//FR permet à l'utilisateur de sélectionner une image depuis sa galerie, puis de la recadrer en forme de cercle. (FrameFigma037A,FrameFigma037E) 
//EN Allow the user to select an image from their gallery, then crop it into a circular shape (FrameFigma037A,FrameFigma037E) 

// Import modules
import React, {useState, useRef, useEffect} from 'react';
import {View, Text, Pressable, Image, StyleSheet} from 'react-native';
import ImagePicker from 'react-native-image-crop-picker'; // Bibliothèque pour la gestion de l'image et le crop

// Import Icons
import Icons from '../../constants/Icons';

// Styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  imageContainer: {
    width: 300,
    height: 300,
    borderRadius: 150, // Pour un crop circulaire, modifiable selon les besoins
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  button: {
    marginTop: 20,
    backgroundColor: 'blue',
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
  },
});

const ImageCropperMobile = () => {
  const [image, setImage] = useState(null);

  const openImagePicker = async () => {
    try {
      const pickedImage = await ImagePicker.openPicker({
        width: 300,
        height: 300,
        cropping: true,
        cropperCircleOverlay: true,
        compressImageQuality: 0.7,
      });
      setImage(pickedImage.path);
    } catch (error) {
      console.error('Error picking the image: ', error);
    }
  };

  return (
    <View style={styles.container}>
      <Pressable style={styles.button} onPress={openImagePicker}>
        <Text style={styles.buttonText}>Select Image</Text>
      </Pressable>
      {image && (
        <View style={styles.imageContainer}>
          <Image source={{uri: image}} style={styles.image} />
        </View>
      )}
    </View>
  );
};

export default ImageCropperMobile;
