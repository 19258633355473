// 🇫🇷 cette page affiche les informations sur le premium badges Diamand star (FrameFigma051D)
// 🇫🇷 this page displays information about the premium diamond star badges (FrameFigma051D)

import React from "react";
import { Text, View, Image, Pressable, ScrollView, ImageBackground } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Components 
import styles from "./StylesWeb.js"


// import des icons
import { Icons } from "../../../../constants/Icons.js";
import { useLanguage } from '../../../../contexts/LanguageContext.js';




const VipBadgesDiamond = (props) => {
    const { scr } = props; // Voici comment extraire scr des props passées  const navigation = useNavigation(); // Get the navigation object
    const {translations} = useLanguage();
  
    const navigation = useNavigation();

// 🇫🇷 Trait permettant d'afficher la separation entre les étoiles(Golden, Emurald, Ruby ,Diamond) et les parrainages 
  // 🇬🇧 Feature for displaying the separation between the stars (Golden, Emerald, Ruby, Diamond) and sponsorships
;const SeparatorD = () => {
    return <View style={styles.separatorD} />;
  };

  
  return (
    <ScrollView style={styles.GrandBox}>
    
       <View style={styles.rowText}>
          <Text style={styles.text}>

            {translations.JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
        </View>


          <View style={styles.button}>
            <View style={styles.backgroundContainer}>
                <Icons.DiamandBorder style={styles.backgroundImage} />
           </View>
          <Icons.StarsDiamond style={styles.img} />
            <Text style={styles.diamandtitle} > {/*title*/}
                {translations.JsonAchievementsPages.JsonDiamondStar}
            </Text>

            <SeparatorD />
            {/* <Text style={styles.diamand} >
                14.90€/month
            </Text> */}

            <View style={styles.container}>
            {/*texte random*/}
                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

             
                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem.
                </Text>

                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

                <Text style={styles.text}>
                • Feature one lorem ipsum lorem ipsum lorem. 
                </Text>

             
            </View>
            <Pressable style={styles.buttonGrey}   
            onPress={() => navigation.navigate('VipBoardExplanation')}
            >
                <Icons.StarsDiamond style={styles.imgsmall} />
                <Text style={styles.textbutton}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonMoreInformations}
                </Text>

           </Pressable>

            

        </View>  
       
        <Text style={styles.Text}>
           {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDonatorToGetAStar}
          </Text>
    
       
    </ScrollView>

    );
};
  
 
export default VipBadgesDiamond;
