//FR  écran de la deuxième étape du processus d'inscription pour un compte professionnel
//EN  the screen for the second step in the registration process for a professional account
import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as yup from 'yup';
import Fields from '../../../../components/Fields/Fields';
import { useLanguage } from '../../../../contexts/LanguageContext';
import ButtonNext from "../../../../components/ButtonNext/ButtonNext";
import TextInputCustom from '../../../../components/TextInputCustom/TextInputCustom';
import { useError } from '../../../../contexts/ErrorContext'; // Import useError hook

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const StepTwoProScreen = ({ onNextStep }) => {
  const { translations } = useLanguage();
  const { language } = useLanguage();
  const { error, setError } = useError(); // Initialize error and setError from useError hook

  const [isFocused, setIsFocused] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const validationSchemaPersonal = yup.object().shape({
    firstname: yup
      .string()
      .min(2, translations.JsonCreateProfilePages.JsonErrorNameMinLength)
      .max(30, translations.JsonCreateProfilePages.JsonErrorNameMaxLength),
    lastname: yup
      .string()
      .min(2, translations.JsonCreateProfilePages.JsonErrorNameMinLength)
      .max(30, translations.JsonCreateProfilePages.JsonErrorNameMaxLength),
  });  

  const handleNextStep = async () => {
    try {
      await validationSchemaPersonal.validate({ firstname, lastname }, { abortEarly: false });
      await AsyncStorage.setItem('firstname', firstname);
      await AsyncStorage.setItem('lastname', lastname);
      setError('');
      onNextStep();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errorMessages = error.inner.map(err => {
          if (err.path === 'firstname') {
            return `${translations.JsonCreateProfilePages.JsonFirstNamePlaceholder} ${err.message}`;
          } else if (err.path === 'lastname') {
            return `${translations.JsonCreateProfilePages.JsonLastNamePlaceholder} ${err.message}`;
          } else {
            return err.message; // Utiliser le message d'erreur par défaut
          }
        });
        setError(errorMessages.join('\n')); // Concaténer tous les messages d'erreur
      } else {
        console.error('Error during validation:', error.message);
      }
    }
  };

        //Supprime le msg d'erreur lorsque l'on quitte la page
    useEffect(() => {
      // Cleanup function
        return () => {
          setError(""); // Reset error message when unmounting
        };
       }, []);

  const handleTextChange = (text, setterFunction) => {
    // Filtrer les caractères non autorisés (autres que lettres, accents, espaces, apostrophes et tirets)
    text = text.replace(/[^a-zA-ZÀ-ÿ'\-\s]/g, '');
  
    // Filtrer les espaces, tirets et apostrophes en début de chaîne
    text = text.replace(/^[\s'-]+/, '');
  
  // Remplacer les répétitions de caractères spéciaux par un seul
    text = text.replace(/[\s'-]{2,}/g, match => match[0]);

    setterFunction(text);
    setError(''); // Réinitialiser les erreurs
  };

  return (
    <View key={language} style={styles.container}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.nameContent}>
        <View style={styles.firstName}>
          <TextInputCustom
            Icon={Fields.IconLastName}
            placeholderText={translations.JsonCreateProfilePages.JsonFirstNamePlaceholder}
            value={firstname}
            handleTextChange={(text) => handleTextChange(text, setFirstname)}
            isPlaceHolderTextAlignCenter={false}
          />
        </View>
        <View style={styles.lastName}>
          <TextInputCustom
            Icon={Fields.IconLastName}
            placeholderText={translations.JsonCreateProfilePages.JsonLastNamePlaceholder}
            value={lastname}
            handleTextChange={(text) => handleTextChange(text, setLastname)}
            isPlaceHolderTextAlignCenter={false}
          />
        </View>
      </View>
      <View style={styles.comment}>
        <Text>{translations.JsonCreateProfilePages.JsonSecretNameMessage}</Text>
      </View>
      <View style={styles.btnContainer}>
        <ButtonNext
          conditionNeed={firstname && lastname}
          handleNextStep={handleNextStep}
          btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}
        />
      </View>
    </View>
  );
};

export default StepTwoProScreen;