import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    tabBarOptions: {
        activeTintColor: 'blue',
        inactiveTintColor: 'gray',
    },
    screenOptionsStyles: {
        tabBarStyle: { height: 70 },
        tabBarItemStyle: { flexDirection: "column" },
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "white",
        tabBarActiveBackgroundColor: "#F48225",
        tabBarInactiveBackgroundColor: "#59C09B",
    },
    tabScreenOptions: {
        tabBarIconStyle: { height: 30 },
        tabBarLabelStyle: { fontSize: 16, marginLeft: 0, marginBottom: 3 },
    },
    
});