import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1, // 🇫🇷 Utilise tout l'espace disponible de manière flexible // 🇬🇧 Uses all available space flexibly
    backgroundColor: 'white', // 🇫🇷 Couleur de fond de la page // 🇬🇧 Background color of the page
  },
  scrollViewContainer: {
    // 🇫🇷 Pas besoin de styles spécifiques ici, mais vous pouvez ajouter des marges si nécessaire // 🇬🇧 No specific styles needed here, but you can add margins if necessary
  },
  imgViewContainer: {
    width: '100%', // 🇫🇷 L'image de fond occupe toute la largeur // 🇬🇧 The background image occupies the full width
    height: 200, // 🇫🇷 Hauteur fixe pour l'image de fond // 🇬🇧 Fixed height for the background image
    backgroundColor: '#ccc', // 🇫🇷 Couleur de fond en cas de non-chargement de l'image // 🇬🇧 Background color in case the image does not load
  },
  title: {
    fontSize: 20, // 🇫🇷 Taille de la police pour le titre // 🇬🇧 Font size for the title
    fontWeight: '800', // 🇫🇷 Poids de la police // 🇬🇧 Font weight
    textAlign: 'center', // 🇫🇷 Alignement du texte // 🇬🇧 Text alignment
    marginTop: 20, // 🇫🇷 Marge du haut // 🇬🇧 Margin top
    marginBottom: 20, // 🇫🇷 Marge du bas // 🇬🇧 Margin bottom
  },
  txt: {
    textAlign: 'center', // 🇫🇷 Alignement du texte // 🇬🇧 Text alignment
    fontSize: 15, // 🇫🇷 Taille de la police // 🇬🇧 Font size
    fontWeight: '700', // 🇫🇷 Poids de la police // 🇬🇧 Font weight
    marginBottom: 20, // 🇫🇷 Marge du bas pour espacer les sections // 🇬🇧 Bottom margin to space sections
    width: '80%', // 🇫🇷 Largeur du texte pour le centrer // 🇬🇧 Text width to center it
    alignSelf: 'center', // 🇫🇷 Alignement au centre de son conteneur // 🇬🇧 Alignment at the center of its container
  },
  ButtonFB: {
    backgroundColor: '#3B5998', // 🇫🇷 Couleur de fond du bouton Facebook // 🇬🇧 Background color of the Facebook button
    alignSelf: 'center', // 🇫🇷 Centre le bouton dans le conteneur // 🇬🇧 Centers the button in the container
    borderRadius: 10, // 🇫🇷 Bordures arrondies // 🇬🇧 Rounded borders
    flexDirection: 'row', // 🇫🇷 Disposition horizontale des éléments intérieurs // 🇬🇧 Horizontal layout of the inner elements
    height: 50, // 🇫🇷 Hauteur fixe // 🇬🇧 Fixed height
    width: '60%', // 🇫🇷 Largeur relative à son conteneur // 🇬🇧 Width relative to its container
    justifyContent: 'center', // 🇫🇷 Centre les éléments horizontalement // 🇬🇧 Centers elements horizontally
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement // 🇬🇧 Centers elements vertically
    marginTop: 20, // 🇫🇷 Marge du haut // 🇬🇧 Margin top
    marginBottom: 20, // 🇫🇷 Marge du bas // 🇬🇧 Margin bottom
  },
  buttonTextFB: {
    color: 'white', // 🇫🇷 Couleur du texte // 🇬🇧 Text color
    fontWeight: '700', // 🇫🇷 Poids de la police // 🇬🇧 Font weight
    marginLeft: 10, // 🇫🇷 Marge à gauche pour espacer l'icône du texte // 🇬🇧 Margin left to space the icon from the text
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    textAlign: 'center',
  },
  eventRedirectButton: {
    backgroundColor: '#59c09b', // 🇫🇷 Couleur de fond du bouton d'activité // 🇬🇧 Background color of the activity button
    alignSelf: 'center', // 🇫🇷 Centre le bouton dans le conteneur // 🇬🇧 Centers the button in the container
    borderRadius: 10, // 🇫🇷 Bordures arrondies // 🇬🇧 Rounded borders
    justifyContent: 'center', // 🇫🇷 Centre les éléments horizontalement // 🇬🇧 Centers elements horizontally
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement // 🇬🇧 Centers elements vertically
    marginTop: 20, // 🇫🇷 Marge du haut // 🇬🇧 Margin top
    marginBottom: 50, // 🇫🇷 Marge du bas // 🇬🇧 Margin bottom
    height: 50, // 🇫🇷 Hauteur fixe // 🇬🇧 Fixed height
    width: '60%', // 🇫🇷 Largeur relative à son conteneur // 🇬🇧 Width relative to its container
  },
  newslist: {
    flexDirection: 'row', // 🇫🇷 Éléments disposés horizontalement // 🇬🇧 Elements arranged horizontally
    justifyContent: 'flex-start', // 🇫🇷 Alignement au début du conteneur // 🇬🇧 Alignment at the start of the container
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement dans la ligne // 🇬🇧 Centers elements vertically in the line
    paddingLeft: 25, // 🇫🇷 Padding à gauche // 🇬🇧 Padding left
    paddingRight: 25, // 🇫🇷 Padding à droite // 🇬🇧 Padding right
    paddingBottom: 15, // 🇫🇷 Padding en bas // 🇬🇧 Padding bottom
  },
  helpParagraph: {
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement // 🇬🇧 Centers elements vertically
    marginTop: 20, // 🇫🇷 Marge du haut // 🇬🇧 Margin top
    marginBottom: 20, // 🇫🇷 Marge du bas // 🇬🇧 Margin bottom
    paddingLeft: 25, // 🇫🇷 Padding à gauche pour l'alignement // 🇬🇧 Padding left for alignment
    paddingRight: 25, // 🇫🇷 Padding à droite // 🇬🇧 Padding right
  },
  img: {
    width: 50, // 🇫🇷 Largeur fixe pour les icônes // 🇬🇧 Fixed width for the icons
    height: 50, // 🇫🇷 Hauteur fixe pour les icônes // 🇬🇧 Fixed height for the icons
    marginRight: 20, // 🇫🇷 Marge à droite pour espacer l'icône du texte // 🇬🇧 Margin right to space the icon from the text
    marginTop: -25,
  },
  row: {
    flexDirection: 'row', // 🇫🇷 Éléments disposés horizontalement // 🇬🇧 Elements arranged horizontally
    alignItems: 'center', // 🇫🇷 Centre les éléments verticalement // 🇬🇧 Centers elements vertically
  },
});

export default styles;
