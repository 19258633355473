import React from 'react';
import {View, Text, Pressable, Linking} from 'react-native';
import {useLanguage} from '../../../contexts/LanguageContext';

const InviteByTwitter = () => {
  const {translations} = useLanguage();

  const inviteByTwitter = () => {
    const twitterLink = `twitter://post?message=${translations.JsonInviteFriendsScreen.JsonInvitationMessage}`;
    Linking.openURL(twitterLink);
  };

  return (
    <View>
      <Pressable onPress={inviteByTwitter}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteTwitter}</Text>
      </Pressable>
    </View>
  );
};

export default InviteByTwitter;
