// FR page d'écran de démarrage(FrameFigma001)
// EN the application's startup screen(FrameFigma001)

import React, { useState, useEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Image, ImageBackground, Platform, Text, TouchableOpacity, View } from 'react-native';
import { useLanguage } from '../../contexts/LanguageContext'; // Assurez-vous que le chemin est correct
import AsyncStorage from '@react-native-async-storage/async-storage';
// Importations des ressources et styles
import Logo from '../../assets/Images/General/LogoSocializus.png';
// import Images from '../../constants/Images';
import liveConcert from '../../assets/Images/Home/LiveConcert.png';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const StartScreen = ({ navigation }) => {
  const { translations, changeLanguage } = useLanguage();
  const { language } = useLanguage();
  const LogoSection = () => <Image source={Logo} style={styles.logo} />;
  // const LogoSection = () => {return (<View style={styles.logo}><Images.GeneralColorsLogoSocializusBlack/></View>)};//pbm lors montage 1er fois deviens photo bizarre (reste que partie noir)

  const LanguageBtnSection = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('');

 useFocusEffect(
    React.useCallback(() => {
      const refreshLanguage = async () => {
        const selectedLanguageName = await AsyncStorage.getItem('selectedLanguageName');
        setSelectedLanguage(selectedLanguageName || translations.JsonHomeScreen.JsonSelectLanguage);
      };

      refreshLanguage();
    }, [translations])
  );

    const handleChangeLanguage = () => navigation.navigate('Languages');

    return (
      <View style={styles.languageBtn}>
        <TouchableOpacity onPress={handleChangeLanguage}>
          <Text>{selectedLanguage}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const StartSection = () => (
    <View style={styles.content}>
      <View style={styles.DescriptionTxtView}>
        <Text style={styles.DescriptionTxt}>{translations.JsonHomeScreen.JsonShareActivities}</Text>
      </View>
      <View style={styles.btnContainer}>
        <TouchableOpacity style={styles.btnStart} onPress={() => navigation.navigate('Email')}>
          <Text style={styles.btnText}>{translations.JsonHomeScreen.JsonStart}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
  <View key={language} style={styles.container}>
 <ImageBackground source={liveConcert} style={styles.backImg}>
      <LanguageBtnSection />
      <LogoSection />
      <StartSection />
    </ImageBackground>
  </View>
);
};

export default StartScreen;
