// Import Modules
import React, { useState } from 'react';
import { View, Text, TextInput, Pressable } from 'react-native';

// Import Components
import Step2ConfirmationScreen from '../../Step2ConfirmationScreen/Step2ConfirmationScreen';

// Import Contexts
import { useLanguage } from "../../../../contexts/LanguageContext";
import { addFriendPostRequest } from '../../../../utils/AddFriendPostRequest';

// Import Styles - Attention - ce fichier utilise les styles de parent Step1InputScreen
import { styles } from '../../Step1InputScreen/Step1InputScreen';

// Logique de component commence ici --------------------------------------------------
const EmailInputScreen = ({ icon, currentUser, onClose }) => {

  // Contexts
  const { translations } = useLanguage();
  const t = translations.JsonConfirmFriendshipPopup

  // States
  const [email, setEmail] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  // Handle Form Input
  const handleInputChange = (value) => {
    setEmail(value);
  };

  // Handle Submit & Verification
  const handleSubmit = async () => {
    setButtonClicked(true);

    if (email === currentUser.email) {
      // Show Success Page
      setVerificationSuccess(true);

      // Send Backend Requests to Add Friend
      addFriendPostRequest(currentUser._id)
    }
  };

  return (
    <>
      {buttonClicked ? (
      <Step2ConfirmationScreen verificationSuccess={verificationSuccess} onClose={onClose} />
      ) : (
        <>
          <View>
            <Text style={styles.modalTitle}>{t.JsonPopupEnterEmail}</Text>
            {icon}
          </View>
          <View style={styles.inputContainer}>
            <TextInput 
              style={styles.input} 
              onChangeText={handleInputChange} 
              placeholder="usernameexample@gmail.com"
              placeholderTextColor={'#C3BDBD'}
            />
          </View>
          <View>
            <Pressable 
              style={[styles.confirmButton, { backgroundColor: email ? '#59C09B' : '#6f6f6f'}]}
              disabled={!email}
              onPress={handleSubmit}
            >
              <Text style={styles.confirmButton.buttonText}>{t.JsonButtonConfirm}</Text>
            </Pressable>
          </View>
        </>
      )}
    </>
  );
};

export default EmailInputScreen