import React from 'react';
import { View, Text, StyleSheet, Image, Pressable, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { hostname } from '../../../hostName/hostName';
import ProfileMedal from '../../components/ProfileMedal/ProfileMedal';
import { Images } from "../../constants/Images.js";
import { calculateUserPoints } from '../../utils/UserPointsCalculator';

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const UserCardFriend = ({ user }) => {
  const navigation = useNavigation();
  const userPoints = calculateUserPoints(user?._id);

  if (!user) {
    return <Text>Utilisateur introuvable</Text>;
  }

  const imageUrl = user.profileImage ? user.profileImage : `${hostname}/uploads/${user.profileImage}`;

  const handleMemberPress = () => {
    navigation.navigate('ProfileScreen', { userId: user._id });
  };

  const formattedCity = user?.city?.split(',')[0]?.trim();

  return (
    <Pressable style={styles.cardContainer} onPress={handleMemberPress}>
      <Image source={{ uri: imageUrl }} style={styles.profileImage} />
      <ProfileMedal style={styles.profileMedal} points={userPoints} noOfSponsorships={user.sponsoredUsers?.length || 0} />
      <View style={styles.infoContainer}>
        <View style={styles.nameContainer}>
          <Text style={styles.nameText}>{user.userName}</Text>
        </View>
        <Text style={styles.cityText}>{formattedCity}</Text>
      </View>
      <Images.RightArrowGrey style={styles.arrowImage} />
    </Pressable>
  );
};

export default UserCardFriend;
