// Fr Cette page affiche un formulaire pour modifier une activité étape par étape (FrameFigma031)
// En This component displays a form to edit an activity step by step (FrameFigma031)

import {View, TouchableOpacity, StyleSheet} from 'react-native';
import React, {useState, useEffect, useLayoutEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation} from '@react-navigation/native';
import {useActivity} from '../../../contexts/ActivityContext';

import ClassicStepOne from './EditActivityClassicStep1Screen/EditActivityClassicStep1Screen';
import ClassicStepTwo from './EditActivityClassicStep2Screen/EditActivityClassicStep2Screen';
import WithStepsOne from './EditActivityScreenWithSteps1Screen/EditActivityScreenWithSteps1Screen';
import WithStepsTwo from './EditActivityScreenWithSteps2Screen/EditActivityScreenWithSteps2Screen';
import EditActivityCommonStepOne from './EditActivityCommonStep1Screen/EditActivityCommonStep1Screen';
import EditActivityCommonStepTwo from './EditActivityCommonStep2Screen/EditActivityCommonStep2Screen';
import EditActivityCommonStepThree from './EditActivityCommonStep3Screen/EditActivityCommonStep3Screen';
import EditActivityVipStepOne from './EditActivityVipStep1Screen/EditActivityVipStep1Screen';
import EditActivityVipStepTwo from './EditActivityVipStep2Screen/EditActivityVipStep2Screen';
import Fields from '../../../components/Fields/Fields';
import {useLanguage} from '../../../contexts/LanguageContext';

const EditActivityScreen = ({navigation, route}) => {
  const {translations} = useLanguage();

  const activityId = route.params.activityId;
  const [questionType, setQuestionType] = useState(null);
  const [step, setStep] = useState(1);
  const [sliderValue, setSliderValue] = useState(0);

  const {activities} = useActivity();
  const activity = activities.find(activity => activity._id === activityId);

  useEffect(() => {
    const fetchData = async () => {
      const value = (await AsyncStorage.getItem('selectedQuestion')) || activity.createAtivityType;
      console.log('Async:', value);
      setQuestionType(value);
    };
    fetchData();
  }, [step]);

  const resetSteps = () => {
    setStep(1);
    setQuestionType(null);
    // Réinitialisez toutes les autres valeurs que vous devez réinitialiser
  };

  const onNextStep = async (sliderValue, selectedQuestion) => {
    console.log('selectedQuestion', selectedQuestion);
    setSliderValue(sliderValue);
    if (step === 1 && selectedQuestion) {
      await AsyncStorage.setItem('selectedQuestion', selectedQuestion === 'classic' ? 'classic' : 'withStep');
      setQuestionType(selectedQuestion === 'classic' ? 'classic' : 'withStep');
    }
    if (step < 7) {
      setStep(step + 1);
    }
  };

  const onPrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      resetSteps(); // Réinitialisez les étapes lorsque l'écran est en focus
    });
    return unsubscribe;
  }, [navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity onPress={() => navigation.navigate('Activities')} style={{marginLeft: 15}}>
          <Fields.IconLeftArrow />
        </TouchableOpacity>
      ),
      title: translations.JsonActivityPages.JsonActivityManagement.JsonTitleModifyActivity,
      headerStyle: {backgroundColor: '#59C09B'},
      headerTintColor: 'white',
      headerTitleStyle: {fontWeight: 'bold', fontSize: 20},
    });
  }, [navigation, step, onPrevStep]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <ClassicStepOne onNextStep={onNextStep} currentActivity={activity} />;
      case 2:
        return questionType === 'classic' ? (
          <ClassicStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />
        ) : (
          <WithStepsOne onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />
        );
      case 3:
        if (questionType === 'classic') {
          return <EditActivityCommonStepOne onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />;
        } else {
          return <WithStepsTwo onNextStep={onNextStep} sliderValue={sliderValue} onPrevStep={onPrevStep} currentActivity={activity} />;
        }
      case 4:
        if (questionType === 'classic') {
          return <EditActivityCommonStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />;
        } else {
          return <EditActivityCommonStepOne onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />;
        }
      case 5:
        if (questionType === 'classic') {
          return <EditActivityCommonStepThree onPrevStep={onPrevStep} onNextStep={onNextStep} currentActivity={activity} />;
        } else {
          return <EditActivityCommonStepTwo onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />;
        }
      case 6:
        if (questionType !== 'classic') {
          return <EditActivityCommonStepThree onPrevStep={onPrevStep} onNextStep={onNextStep} currentActivity={activity} />;
        } else {
          //  a ajouter condition que pour vip
          return <EditActivityVipStepOne onNextStep={onNextStep} onPrevStep={onPrevStep} currentActivity={activity} />;
        }
      case 7:
        return <EditActivityVipStepTwo onPrevStep={onPrevStep} onNextStep={onNextStep} currentActivity={activity} />;
      default:
        return null;
    }
  };

  return <View style={styles.PContainer}>{renderStep()}</View>;
};

const styles = StyleSheet.create({
  PContainer: {
    flex: 1,
    backgroundColor: 'white',
  },
});

export default EditActivityScreen;
