//Fr Styles de SwitchBtn (FrameFigma043) (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B) (FrameFigma033) (FrameFigma034) (FrameFigma038A,FrameFigma038B) (FrameFigma039) (FrameFigma031)
//En Styles of SwitchBtn (FrameFigma043) (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B) (FrameFigma033) (FrameFigma034) (FrameFigma038A,FrameFigma038B) (FrameFigma039) (FrameFigma031)

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        marginLeft: 10,
        width: 45,
        height: 24,
        borderRadius: 15,
        borderWidth: 2,
        borderColor: "#ccc",
        justifyContent: "center",
    },
    circle: {
        width: 16,
        height: 16,
        borderRadius: 8,
        margin: 2,
        backgroundColor: "#ffffff"
    },
    switchInfo: {
        fontSize: 10,
        fontWeight: "bold",
        color: "white",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        top: 3,
    },
});

export default styles