import React, {createContext, useContext, useEffect, useState, useCallback, useMemo} from 'react';
import {useUser} from '../contexts/UserContext';
import handleUserStatusChanged from './SousSocketFront/UserStatusChanged';
import handleProfileVisited from './SousSocketFront/ProfileVisited';
import handleFriendAdded from './SousSocketFront/handleFriendAdded';
import handleUserLiked from './SousSocketFront/UserLikeNotification';
import {handleActivityComment} from './SousSocketFront/handleActivityComment';
import {handleChat} from './SousSocketFront/handleFrontChat';
import {handleUserInfoRequest} from './SousSocketFront/handleUserInfoRequest';
import {hostname} from '../../hostName/hostName';
import io from 'socket.io-client';

const WebSocketContext = createContext();

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({children}) => {
  const {user} = useUser();
  const [socket, setSocket] = useState(null);
  const [userStatus, setUserStatus] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [groupChatMessages, setGroupChatMessages] = useState({});
  const [privateChatMessages, setPrivateChatMessages] = useState({});
  const [currentChatId, setCurrentChatId] = useState(null);
  const [userChats, setUserChats] = useState([]);
  const [isFetchingUserChats, setIsFetchingUserChats] = useState(false);
  const [messageSubscribers, setMessageSubscribers] = useState([]);

  const addNotification = useCallback(notification => {
    setNotifications(prevNotifications => [...prevNotifications, notification]);
  }, []);
  const updateGroupChatMessages = useCallback((chatId, newMessages, replace = false) => {
    setGroupChatMessages(prevMessages => {
      if (replace) {
        return {...prevMessages, [chatId]: newMessages};
      } else {
        const existingMessages = prevMessages[chatId] || [];
        return {...prevMessages, [chatId]: [...existingMessages, ...newMessages]};
      }
    });
  }, []);
  const updatePrivateChatMessages = useCallback((chatId, newMessages, replace = false) => {
    setPrivateChatMessages(prevMessages => {
      if (replace) {
        return {...prevMessages, [chatId]: newMessages};
      } else {
        const existingMessages = prevMessages[chatId] || [];
        return {...prevMessages, [chatId]: [...existingMessages, ...newMessages]};
      }
    });
  }, []);
  const displayChatHistory = useCallback((chatId, messages) => {
    setPrivateChatMessages(prevMessages => ({
      ...prevMessages,
      [chatId]: messages,
    }));
  }, []);
  const updateCurrentChatId = useCallback(newChatId => {
    setCurrentChatId(newChatId);
  }, []);
  const fetchUserChats = useCallback(() => {
    if (socket && typeof socket.emit === 'function') {
      socket.emit('fetchAllChatsForUser', {userId: user._id});
    } else {
      console.log('Socket is not initialized or emit is not a function');
    }
  }, [socket, user?._id, isFetchingUserChats]);
  const requestChatHistory = useCallback(
    chatId => {
      if (socket && chatId) {
        socket.emit('requestChatHistory', {chatId});
      }
    },
    [socket],
  );
  const sendChatMessage = useCallback(
    (chatId, message) => {
      if (socket && chatId && message) {
        socket.emit('sendChatMessage', {chatId, message});
      }
    },
    [socket],
  );
  const [requestUserInfo, setRequestUserInfo] = useState(() => () => {});
  const [onUserInfoResponse, setOnUserInfoResponse] = useState(() => () => {});
  const [offUserInfoResponse, setOffUserInfoResponse] = useState(() => () => {});
  const subscribeToNewMessages = useCallback(callback => {
    setMessageSubscribers(prev => [...prev, callback]);

    // Retourner une fonction pour annuler l'abonnement
    return () => {
      setMessageSubscribers(prev => prev.filter(cb => cb !== callback));
    };
  }, []);
  useEffect(() => {
    if (!user?._id) return; //|| !userChats.length

    const newSocket = io(`${hostname}`, {
      autoConnect: true,
      query: {userId: user._id},

    });

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server frontend');
      newSocket.emit('registerUser', user._id);
      newSocket.emit('userOnline', user._id);

      // Configuration des gestionnaires d'événements WebSocket
      handleUserStatusChanged(newSocket, setUserStatus);
      handleProfileVisited(newSocket, addNotification);
      handleFriendAdded(newSocket, addNotification);
      handleUserLiked(newSocket, addNotification);

      // Gestionnaire pour les commentaires d'activité (chats de groupe) et les chats
      const activityHandlers = handleActivityComment(newSocket, updateGroupChatMessages);

      const chatHandlers = handleChat(newSocket, updateGroupChatMessages, updatePrivateChatMessages, displayChatHistory);
      setSocket(prevSocket => ({...prevSocket, ...chatHandlers}));

      // Gestion des informations utilisateur
      const userInfoHandlers = handleUserInfoRequest(newSocket);
      setRequestUserInfo(() => userInfoHandlers.requestUserInfo);
      setOnUserInfoResponse(() => userInfoHandlers.onUserInfoResponse);
      setOffUserInfoResponse(() => userInfoHandlers.offUserInfoResponse);

      // Écouter la réponse des informations de l'utilisateur
      userInfoHandlers.onUserInfoResponse(usersInfo => {
        console.log("Réponse 'userInfoResponse' reçue avec les informations :", usersInfo);
      });

      // Écouter les erreurs lors de la récupération des informations utilisateur
      newSocket.on('userInfoResponseError', error => {
        console.error('Erreur lors de la récupération des informations utilisateur :', error);
      });

      newSocket.on('newPrivateChatMessage', ({chatId, message, senderId}) => {
        updatePrivateChatMessages(chatId, [{message, senderId}], false);
        messageSubscribers.forEach(callback => callback({chatId, message, senderId}));
      });
      const userIds = userChats
        .flatMap(chat => chat.participants.filter(participant => participant._id !== user._id).map(participant => participant._id))
        .filter(Boolean)
        .filter(id => id !== user._id); // Assurez-vous d'éliminer les doublons si nécessaire.

      if (userIds.length) {
        console.log("Envoi de la requête 'requestUserInfo' avec les IDs :", userIds);
        userInfoHandlers.requestUserInfo(userIds);
      }
      setSocket(prevSocket => ({...prevSocket, ...chatHandlers, ...activityHandlers}));

      newSocket.emit('requestAllChatsForUser');

      // Gérer la réception des chats de l'utilisateur
      newSocket.on('allChatsForUserResponse', chats => {
        console.log("Chats reçus pour l'utilisateur:", chats);
        setUserChats(chats);
      });
      // newSocket.on('newPrivateChatMessage', ({chatId, message, senderId}) => {
      //   updatePrivateChatMessages(chatId, [{message, senderId}], false);

      //   // Notifier tous les abonnés d'un nouveau message
      //   messageSubscribers.forEach(callback => callback({chatId, message, senderId}));
      // });
    });

    // Mettez à jour le socket dans l'état
    setSocket(newSocket);

    // Nettoyage lors du démontage
    return () => {
      if (newSocket) {
        newSocket.emit('userOffline', user._id);
        // userInfoHandlers.offUserInfoResponse();
        // ↑ error userInfoHandlers is not defined at WebSocketLocal.js:156:9
        handleUserInfoRequest(newSocket).offUserInfoResponse();
        newSocket.disconnect();
      }
    };
  }, [user?._id]); // Retirer userChats des dépendances pour éviter un déclenchement prématuré du useEffect

  const contextValue = useMemo(
    () => ({
      // socket: socket?.socket,
      // ↑ error socket undefined
      socket: socket,
      userStatus,
      notifications,
      groupChatMessages,
      privateChatMessages,
      updateGroupChatMessages,
      requestUserInfo, // Permet aux composants de demander les infos utilisateur
      onUserInfoResponse,
      offUserInfoResponse,
      isFetchingUserChats,
      updatePrivateChatMessages,
      displayChatHistory,
      currentChatId,
      fetchUserChats,
      requestChatHistory,
      userChats,
      updateCurrentChatId,
      setUserStatusOnline: userId => socket?.emit('userOnline', userId),
      sendChatMessage: socket?.sendChatMessage,
      joinChatRoom: socket?.joinChatRoom,
      requestChatHistory: socket?.requestChatHistory,
      requestActivityComments: socket?.requestActivityComments,
      sendActivityComment: socket?.sendActivityComment,
      subscribeToNewMessages,
    }),
    [
      socket,
      userStatus,
      notifications,
      isFetchingUserChats,
      groupChatMessages,
      privateChatMessages,
      requestUserInfo,
      onUserInfoResponse,
      offUserInfoResponse,
      updateGroupChatMessages,
      updatePrivateChatMessages,
      displayChatHistory,
      currentChatId,
      updateCurrentChatId,
      requestChatHistory,
      sendChatMessage,
      userChats,
      fetchUserChats,
      subscribeToNewMessages,
    ],
  );

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};
