// Fr Cette page affiche l'étape 4 du formulaire pour créer une activité (FrameFigma036)
// En This page displays the fourth step of the form to create an activity (FrameFigma036)
import React, {useState, useEffect} from 'react';
import {View, ScrollView, Text, Pressable, Platform} from 'react-native';
import {activitiesIcon} from '../../../../components/Fields/ActivityListIcon';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useLanguage} from '../../../../contexts/LanguageContext';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import {useError} from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonNext/ButtonNext';
import ButtonPrevious from '../../../../components/ButtonPrevious/ButtonPrevious';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const CreateActivityCommonStepTwo = ({onNextStep, onPrevStep}) => {
  const {translations} = useLanguage();
  const [topic, setTopic] = useState(null);
  const {error, setError} = useError();

  useEffect(() => {
    async function saveData() {
      try {
        await AsyncStorage.setItem('topic', JSON.stringify(topic));
      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
    saveData();
  }, [topic]);

  const handleNextStep = () => {
    if (topic !== null) {
      onNextStep(); // Appeler la fonction pour passer à l'étape suivante
    } else {
      setError(translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonTopicErrorMessage);
    }
  };

  return (
    <ScrollView>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>{translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonChooseTopic}</Text>
          <Text style={styles.info}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonIncreaseYourActivityInterestMessage}{' '}
          </Text>
        </View>
        <View style={styles.activityIconContainer}>
          {activitiesIcon.map((activity, index) => (
            <View style={styles.activityView} key={index}>
              <Pressable
                style={[styles.activityCard, {backgroundColor: topic === index ? '#59c09b' : 'white'}]}
                onPress={async () => {
                  setTopic(index);
                  setError('');
                  try {
                    await AsyncStorage.setItem('selectedActivityTitle', activity.activityTitle);
                    console.log('Activity title saved:', activity.activityTitle);
                  } catch (error) {
                    console.error('Error saving activity title:', error);
                  }
                }}>
                <View style={styles.flexView}>{topic === index ? activity.activityIconOn : activity.activityIconOff}</View>
                <Text
                  style={[
                    styles.activityTitle,
                    {
                      fontWeight: topic === index ? 'bold' : '400',
                      color: topic === index ? 'white' : 'black',
                    },
                  ]}>
                  {activity.activityTitle}
                </Text>
              </Pressable>
            </View>
          ))}
        </View>
        <View>
          <View style={styles.btnContainer}>
            <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton} />
            <ButtonNext
              conditionNeed={topic !== null}
              handleNextStep={handleNextStep}
              btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default CreateActivityCommonStepTwo;
