// Fr Cette page affiche des details sur une activité pouvoir participer ou partager (FrameFigma041)
// En This page displays details about an activity able to participate or share it(FrameFigma041)

// Import Modules
import React, { useState, useEffect } from "react";
import { Modal, ScrollView, View, ActivityIndicator, Pressable, Text, Platform } from "react-native";

// Import Contexts
import { useActivity } from "../../../contexts/ActivityContext";
import { useUser } from "../../../contexts/UserContext";
import { useNavigation } from "@react-navigation/native";
import { useLanguage } from '../../../contexts/LanguageContext';

// Import Hooks
import { joinActivity, leaveActivity } from "../../../utils/ActivityParticipate";

// Import Components Common
import ActivityMetaDetails from "./ActivityBodyCommonComponents/ActivityMetaDetails/ActivityMetaDetails";
import ActivityMiddleNavigation from "./ActivityBodyCommonComponents/ActivityMiddleNavigation/ActivityMiddleNavigation";
import ActivityAttendeesWithButtons from "./ActivityBodyCommonComponents/ActivityAttendeesWIthButtons/ActivityAttendeesWithButtons";
import ActivityFooterButtons from "./ActivityBodyCommonComponents/ActivityFooterButtons/ActivityFooterButtons";
import BecomeCoOrganiser from "../../../components/BecomeCoOrganiser/BecomeCoOrganiser";

// Import Components Body Top
import ActivityImage from "./ActivityBodyTopScreen/ActivityBodyTopImageComponent/ActivityBodyTopImageComponent";
import ActivityMap from "./ActivityBodyTopScreen/ActivityBodyTopMapComponent/ActivityBodyTopMapComponent";
import ActivityChatSettings from "./ActivityBodyTopScreen/ActivityBodyTopChatSettingsComponent/ActivityBodyTopChatSettingsComponent";
import ActivityAttendeesLists from "./ActivityBodyTopScreen/ActivityBodyTopAttendeesListsComponent/ActivityBodyTopAttendeesListsComponent";

// Import Components Body Bottom
import ActivityDescription from "./ActivityBodyBottomComponents/ActivityBodyBottomDescriptionComponent/ActivityBodyBottomDescriptionComponent";
import ActivityAddress from "./ActivityBodyBottomComponents/ActivityBodyBottomAddressComponent/ActivityBodyBottomAddressComponent";
import ActivityComments from "./ActivityBodyBottomComponents/ActivityBodyBottomCommentsComponent/ActivityBodyBottomCommentsComponent";
import ActivityAttendees from "./ActivityBodyBottomComponents/ActivityBodyBottomAttendeesComponent/ActivityBodyBottomAttendeesComponent";

// Import Icons
import { Icons } from "../../../constants/Icons";

// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const ActivityDetailsNavigator = ({ route }) => {
  // Contexts
  const { translations } = useLanguage();

  // Get Activity ID from Params
  const { activityId } = route.params;

  // Contexts
  const { activitiesLoading, activities, userLikedActivities, getActivities } = useActivity();
  const { user, allUsers, isLoading } = useUser();
  const navigation = useNavigation();

  // Find Activity to Display
  const activity = activities.find((activity) => activity._id === activityId);

  // Get Details of Author of this Activity
  const activityAuthor = allUsers?.find((u) => u._id === activity?.author);

  // States
  const [activeButton, setActiveButton] = useState('ActivityDescription');
  const [showModal, setShowModal] = useState(false);
  const [showCoOrgModal, setShowCoOrgModal] = useState(false);
  const [isFull, setIsFull] = useState(false);

  // Check if User has liked this activity
  // Note - Dans le tableau Activity Likes - le ID d'activity est nommer 'activity' donc ici on utilise `activity.activity` pour comparaison
  const isLiked = userLikedActivities.length > 0 ?
    ((likedActivity = userLikedActivities.find(activity => activityId === activity.activity)) =>
      likedActivity ? { status: true, likeId: likedActivity._id } : false
    )()
    : false;

  // Checks pour logged in User
  const userStatus = {
    // Retourne boolean
    participant: activity?.attendees?.length > 0 ? activity.attendees?.includes(user?._id) : false,
    author: activity?.author === user?._id,
    coOrganizer: activity?.coOrganizers && activity.coOrganizers?.length > 0 ? activity.coOrganizers.includes(user?._id) : false,
    inWaitingList: activity?.waitingList?.length > 0 ? activity.waitingList.includes(user?._id) : false
  }

  // Verifier si l'Activite est plein
  useEffect(() => {
    if (activity?.attendees?.length >= activity?.attendeeLimit) {
      setIsFull(true);
    }
  }, [])

  // Handle Component Selection
  const handleComponentSelection = (component) => {

    // Montrer un modal si l'utilisateur n'est pas author ni participant
    if (!userStatus.author && !userStatus.participant && component !== 'ActivityDescription') {
      setShowModal(true);
    } else {
      setActiveButton(component);
    }
  }

  // Fonction pour rejoindre / quitter l'activité / retirer quelqu'un
  const handleParticipateAction = async (userId, isRemoved = false) => {

    if (isRemoved) {
      await leaveActivity(activity._id, userId);
      getActivities();
    } else {
      // Join / Leave Activity
      if (userStatus.participant) {
        await leaveActivity(activity._id, user._id);
        getActivities(); // Rafraichir les activités
      } else {
        await joinActivity(activity._id, user._id);
        getActivities();
        setShowModal(false);
      }
    }
  };

  const handleCoOrgModal = () => setShowCoOrgModal(!showCoOrgModal);

  return (
    <>
      {activity === undefined ? (
        <View style={styles.container}>
          <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityDeleted}</Text>
          <Pressable onPress={() => navigation.navigate('Activities')}>
            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonViewAllActivities}</Text>
          </Pressable>
        </View>
      ) : (
        <ScrollView style={{ gap: 20 }}>
          {isLoading || activitiesLoading ? (
            <ActivityIndicator />
          ) : (
            <>
              <View>
                {/* Afficher le Top Component selon le bouton choisi */}
                {activeButton === 'ActivityDescription' && <ActivityImage activityImage={activity?.activityImage} />}
                {activeButton === 'ActivityAddress' && <ActivityMap activity={activity} />}
                {activeButton === 'ActivityComments' && <ActivityChatSettings activity={activity} />}
                {activeButton === 'ActivityAttendees' && <ActivityAttendeesLists activity={activity} userStatus={userStatus} userList={allUsers} />}

                {/* Partie details de l'activity (nom, date, lieu, organisateur, etc) */}
                <ActivityMetaDetails activity={activity} activityAuthor={activityAuthor} isLiked={isLiked} userStatus={userStatus} />
              </View>

              <View>
                {/* Bar Nav au milieu */}
                <ActivityMiddleNavigation handleComponentSelection={handleComponentSelection} activeButton={activeButton} userStatus={userStatus} />

                {/* Partie particpants avec les boutons Invite et Participate (seulement dans Description et Address*/}
                {activeButton !== 'ActivityAttendees' && activeButton !== 'ActivityComments' && <ActivityAttendeesWithButtons activity={activity} user={user} allUsers={allUsers} userStatus={userStatus} handleParticipateAction={handleParticipateAction} isFull={isFull} />}

                {/* Afficher le Bottom Component selon le bouton choisi */}
                {activeButton === 'ActivityDescription' && <ActivityDescription activityDescription={activity?.description} activity={activity} userStatus={userStatus} activityAuthor={activityAuthor} />}
                {activeButton === 'ActivityAddress' && <ActivityAddress activity={activity} user={user} userStatus={userStatus} />}
                {activeButton === 'ActivityComments' && <ActivityComments activity={activity} />}
                {activeButton === 'ActivityAttendees' && <ActivityAttendees activity={activity} allUsers={allUsers} userStatus={userStatus} handleParticipateAction={handleParticipateAction} user={user} />}

                {/* Partie en bas avec boutons Help & Organise et Copy to own activity */}
                {activeButton !== 'ActivityComments' && activeButton !== 'ActivityAttendees' && <ActivityFooterButtons handleCoOrgModal={handleCoOrgModal} userStatus={userStatus} activity={activity} />}

              </View>

              {/* Modal Erreur pour non-participants - a changer pour le custom Modal component avec children*/}
              <Modal animationType="fade" transparent={true} visible={showModal} onRequestClose={() => setShowModal(false)}>
                <View style={styles.container}>
                  <View style={styles.modal}>
                    {/* Close Button */}
                    <Pressable onPress={() => setShowModal(false)} style={styles.close}>
                      <Icons.CloseSign width={30} height={30} />
                    </Pressable>
                    {isFull ? (
                      <>
                        <Text style={styles.modalText}>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonAdressOnlyForParticipants}</Text>
                      </>
                    ) : (
                      <>
                        <Text style={styles.modalText}>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonAdressOnlyForParticipants}</Text>
                        <Pressable style={styles.modalButton} onPress={handleParticipateAction}>
                          <Text style={styles.modalButtonText}>{translations.JsonActivityPages.JsonActivityScreen.JsonPopupMessage.JsonParticipateNowButton}</Text>
                        </Pressable>
                      </>
                    )}
                  </View>
                </View>
              </Modal>

              {/* Modal pour devenir Co-Orgnaiser */}
              <BecomeCoOrganiser activity={activity} showCoOrgModal={showCoOrgModal} setShowCoOrgModal={setShowCoOrgModal} user={user} />
            </>
          )}
        </ScrollView>

      )}
    </>
  );
};

export default ActivityDetailsNavigator;
