//🇫🇷 Ce fichier gère la page où on rentre notre email, il sert à détérminé la validité de l'email et s'il existe dans la base de donnée (FrameFigma004A)
//🇬🇧 This file manages the page where we enter our email, it is used to determine the validity of the email and if it exists in the database (FrameFigma004A)
import React, {useState} from 'react';
import {View, Text, Linking, Platform, Pressable} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Fields from '../../../components/Fields/Fields';
import {hostname} from '../../../../hostName/hostName';
import {useLanguage} from '../../../contexts/LanguageContext';
import TextInputCustom from '../../../components/TextInputCustom/TextInputCustom';
import ButtonNext from '../../../components/ButtonNext/ButtonNext';
import {useError} from '../../../contexts/ErrorContext';

const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;

const emailRegex = /^[\w.!#$%&'*+/=?^`{|}~-]+@\w+(\.\w+)+$/;

const EmailScreen = ({navigation}) => {
  const {translations} = useLanguage();
  const {language} = useLanguage();
  const {error, setError} = useError();

  const [isFocused, setIsFocused] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, 'E-mail invalide').required("L'e-mail est requis"),
  });

  const handleEmailChange = text => {
    setEmail(text.toLowerCase());
    setError(''); // Efface l'erreur dès que l'utilisateur modifie le texte

    validationSchema
      .isValid({email: text})
      .then(valid => {
        setIsEmailValid(valid);
      })
      .catch(() => {
        setIsEmailValid(false);
      });
  };
  const getButtonStyle = () => {
    return isEmailValid ? styles.buttonActive : styles.buttonInactive;
  };

  const handleNext = async () => {
    try {
      await validationSchema.validate({email});

      const response = await fetch(`${hostname}/verifyExistingEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });

      const responseData = await response.json();
      if (responseData.conflict) {
        await AsyncStorage.setItem('email', email);
        navigation.navigate('Password');
      } else {
        await AsyncStorage.setItem('email', email);
        navigation.navigate('Sponsorship');
      }

      setError(''); // Réinitialise le message d'erreur en cas de succès
    } catch (validationError) {
      setError(translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailErrorMessage);
    }
  };
  return (
    <View key={language} style={styles.container}>
      {error ? (
        <View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>
      ) : null}
      <View style={styles.content}>
        <View>
          <Text style={styles.subtitle}>{translations.JsonLoginRegisterPages.JsonEmailScreen.JsonCreateOrConnectAccount}</Text>
        </View>
        <View style={styles.email}>
          <TextInputCustom
            Icon={Fields.IconArobase}
            placeholderText={translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailPlaceholder}
            handleTextChange={handleEmailChange}
            value={email}
            isPlaceHolderTextAlignCenter={false}
          />
        </View>
        <View style={styles.btnContainer}>
          <ButtonNext handleNextStep={handleNext} btnText={translations.JsonLoginRegisterPages.JsonEmailScreen.JsonNextButton} conditionNeed={isEmailValid} />
        </View>
        <View style={styles.condition}>
          <Text style={styles.terms}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}</Text>
          <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
            <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}</Text>
          </Pressable>
          <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
            <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default EmailScreen;
