/*Fr ce fichier represente une diapositive de concept. 
Il inclut une image, un titre ("Never Alone"), une description, des icônes de navigation, et des boutons "Participate" 
et "Share". Les styles s'adaptent à la plateforme (web ou mobile), et la diapositive est défilable verticalement.(Frame 080AFR-080Ffr)*/

/*GB this file represents a concept slide. It includes an image, 
a title ("Never Alone"), a description, navigation icons, and "Participate" and "Share" buttons. Styles adapt based 
on the platform (web or mobile), and the slide is vertically scrollable.(Frame 080AFR-080Ffr)*/
import React, { useState } from 'react';
import {
  View,
  Text,
  Image,
  Platform,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import Fields from '../../components/Fields/Fields';
import { SlidesData } from '../../data/Slides'; // Importez SlidesData
import { useLanguage } from '../../contexts/LanguageContext';

const styles =
  Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;

const ConceptSlidesScreen = () => {
  const { translations } = useLanguage();

  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide(prev => (prev === 0 ? SlidesData().length - 1 : prev - 1)); // Utilisez SlidesData() comme une fonction pour obtenir les données
  };

  const nextSlide = () => {
    setCurrentSlide(prev => (prev === SlidesData().length - 1 ? 0 : prev + 1)); // Utilisez SlidesData() comme une fonction pour obtenir les données
  };

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.scrollContent}>
      <Image
        style={styles.img}
        source={SlidesData()[currentSlide].image} 
      />
      <View style={styles.content}>
        <TouchableOpacity onPress={prevSlide}>
          <Fields.IconPrecidentWhite />
        </TouchableOpacity>
        <View style={styles.titleContainer}>
          <Text style={styles.titleTxt}>{SlidesData()[currentSlide].title}</Text> 
        </View>
        <TouchableOpacity onPress={nextSlide}>
          <Fields.IconNextWhite />
        </TouchableOpacity>
      </View>
      <View style={styles.descriptionContent}>
        <Text style={styles.descriptionTxt}>
          {SlidesData()[currentSlide].description} 
        </Text>
      </View>
      <View style={styles.btnContainer}>
        <View style={styles.btnContent}>
          <TouchableOpacity style={styles.btnParticipate}>
            <Text style={styles.btnText}>{translations.JsonConceptScreen.JsonParticipateButton}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.btnContent}>
          <TouchableOpacity style={styles.btnParticipate}>
            <Text style={styles.btnText}>{translations.JsonConceptScreen.JsonShareButton}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};

export default ConceptSlidesScreen;
