import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
    width: '100%',
    paddingTop: '10%',
  },
  addressContent: {
    marginTop: '8%',
    marginHorizontal: 30,
    alignItems: 'center',
    zIndex: 3,
  },
  nicknameContent: {
    marginTop: '5%',
    marginHorizontal: 30,
    alignItems: 'center',
  },
  
  btnContainer: {
    width: '80%',
    alignItems: 'center',
    marginTop: '10%',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  comment: {
    marginTop: 10,
    fontSize: 12,
    width: '60%',
  },
});
