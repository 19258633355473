//🇫🇷 Ce fichier gère le style web du composant VerifyEmailScreen.js (FrameFigma007A FrameFigma007B FrameFigma007C)
//🇬🇧 This file manages the web style of the VerifyEmailScreen.js component (FrameFigma007A FrameFigma007B FrameFigma007C)
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  verification: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  container: {
    width: '80%',
    flex: 1,
    marginTop: '8%',
  },
  verificationText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 18.46,
    // marginBottom: 64,
  },
  opt: {
    width: 43.35,
    height: 40.17,
    backgroundColor: 'white',
    borderColor: '#000000',
    borderWidth: 1,
    borderRadius: 10,
    textAlign: 'center',
    marginHorizontal: 7,
  },
  inputs: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginVertical: '1%',
  },
  btnContainer: {
    marginHorizontal: '5%',
    alignItems: 'center',
    marginTop: 8,
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '85%',
    padding: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    marginVertical: 50,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  btnVerify: {
    backgroundColor: '#59C09B',
    width: '85%',
    padding: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    marginVertical: 50,
  },
  paste: {
    width: 20,
    height: 20,
    alignSelf: 'flex-end',
    marginRight: 1,
  },
  resend: {
    alignItems: 'center',
    marginVertical: 20,
    marginHorizontal: '6%',
  },
  resendText: {
    // fontWeight: 'bold',
    textAlign: 'center',
  },
  btnResendContainer: {
    margin: 10,
    borderWidth: 1,
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderRadius: 15,
  },
  btnResend: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  btnTextResend: {
    color: '#59C09B',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    margin: 30,
  },
});
