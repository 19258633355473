// Fr Page de profil - onglet informations (FrameFigma071)
// En  Profile page - information tab  (FrameFigma071)

// Import Modules
import React from 'react'
import { View, Text, Platform, ScrollView } from 'react-native'

// Import Contexts
import { useLanguage } from '../../../../contexts/LanguageContext';

// Import Components
import countriesListCircleFlags from '../../../../components/LanguagesFlags/LanguagesFlags';
import { activitiesIcon } from '../../../../components/Fields/ActivityListIcon';

// Import Styles
const styles = Platform.OS === 'web'
  ? require('./Styles.web').default
  : require('./Styles.mobile').default;

const ProfileInfoScreen = ({ currentUser, city }) => {
  // Contexts
  const { translations } = useLanguage();
  const trans = translations.JsonProfilePages.JsonMyProfileScreen;
  
  // Find Native Language Flag
  const nativeLanguage = currentUser.nativeLanguage;
  const LanguageFlagNative = countriesListCircleFlags.find((flag) =>
    flag.bigram === nativeLanguage
  );
  
  // Map Spoken Languages to Flags
  const spokenLanguages = currentUser.spokenLanguage;
  const mapLanguagesToFlags = (Languages) => {
    return Languages.map((language) => {
      const flag = countriesListCircleFlags.find((flag) => flag.bigram === language);
      return flag.FlagComponent;
    });
  };

  const languageFlagsSpoken = mapLanguagesToFlags(spokenLanguages);

  // Map Activities to Icons
  const userActivityIcons = activitiesIcon.filter((icon) => {
    return currentUser.activitiesTopic.find((topic) => parseInt(topic) === parseInt(icon.id));
  })
  
  return (
    <ScrollView>
      <View style={styles.infoContainer}>
        <Text style={styles.aboutDescription}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam fermentum lacus non ligula efficitur, ut venenatis ex feugiat. Fusce nec faucibus arcu, at dictum nunc.
        </Text>
      </View>
      <View style={[styles.detailsContainer, styles.pad20]}>
        <View style={styles.gap}>
          <View style={styles.individualEntry}>
            <Text style={styles.bold}>{trans.JsonCity}: </Text>
            <Text>{city}</Text>
          </View>
          <View style={styles.individualEntry}>
            <Text style={styles.bold}>{trans.JsonChildren}: </Text>
            <Text>No</Text>
          </View>
          <View style={styles.individualEntry}>
            <Text style={styles.bold}>{trans.JsonStudies}: </Text>
            <Text>{currentUser.studies}</Text>
          </View>
        </View>
        <View style={styles.gap}>
          <View style={styles.individualEntry}>
            <Text style={styles.bold}>{trans.JsonUniversity}: </Text>
            <Text>{currentUser.university}</Text>
          </View>
          <View style={styles.individualEntry}>
            <Text style={styles.bold}>{trans.JsonAlcohol}: </Text>
            <Text>Never</Text>
          </View>
          <View style={styles.individualEntry}>
            <Text style={styles.bold}>{trans.JsonTobacco}: </Text>
            <Text>Never</Text>
          </View>
        </View>
      </View>
      <View style={styles.languagesContainer}>
        <View style={styles.individualEntry}>
          <Text style={styles.langTitle}>{trans.JsonNativeLanguage}: </Text>
          <View style={styles.flexRowCenter}>
            {nativeLanguage ? (
              <LanguageFlagNative.FlagComponent width={20} height={20} style={styles.flags} />
            ) : <Text>Not Selected</Text>}
          </View>
        </View>
        <View style={styles.individualEntry}>
          <Text style={styles.langTitle}>{trans.JsonSpokenLanguage}: </Text>
          <View style={styles.flexRowCenter}>
            {languageFlagsSpoken.length > 0 ? languageFlagsSpoken.map((Flag, index) => (
              <Flag width={20} height={20} style={styles.flags} key={index}/>
            )) : <Text>Not Selected</Text>}
          </View>
        </View>
      </View>
      <View style={styles.greenBar}>
        <Text style={styles.greenBarText}>{trans.JsonWhatILoveToDo}:</Text>
      </View>
      <View style={styles.bottomIcons}>
        {userActivityIcons.map((icon) => (
          <View key={icon.id}>
            {icon.activityIconOn}
          </View>
        ))}
      </View>
    </ScrollView>
  )
}

export default ProfileInfoScreen