import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
  nickNameContent: {
    marginTop: '20%',
    marginHorizontal: 30,
    alignItems: 'center',
    zIndex: 3,
  },
  addressContent:{
    marginTop: '5%',
    width:'80%'
  },
  btnContainer: {
    width: '80%',
    alignItems: 'center',
    marginTop: '10%',
    zIndex:-2
  },
  btnNext: {
    backgroundColor: '#59C09B',
    width: '70%',
    padding: 10,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    marginTop: "13%",
    zIndex:-1
  },
  btnNextDisabled: {
    backgroundColor: '#8C8F8E',
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  comment: {
    marginTop: 10,
    fontSize: 12,
    width: '60%',
  },
  nickNameMessage: {
    textAlign: "center",
    margin: 15,
  },
  focusedInput: {
    borderColor: 'rgba(0, 0, 0, 0)', 
    outlineColor: 'rgba(0, 0, 0, 0)',
  },
  headerError: {
    backgroundColor: 'red',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerErrorText: {
      color: 'white',
      fontSize: 18,
      fontWeight: 'bold',
  },
});
