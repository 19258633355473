import React, {useEffect, useState} from 'react';
import {View, ImageBackground, Text, Pressable, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {hostname} from '../../../../../hostName/hostName';
import {useUser} from '../../../../contexts/UserContext';
import {useLanguage} from '../../../../contexts/LanguageContext';
import {Icons} from '../../../../constants/Icons';
import ProfileMedal from '../../../../components/ProfileMedal/ProfileMedal';
import AddFriendModal from '../../../../components/AddFriendModal/AddFriendModal';
import {calculateUserPoints} from '../../../../utils/UserPointsCalculator'; // Correct import
import {addUserLike, deleteUserLike} from '../../../../utils/UserLikes';
import ChatModal from '../../../../components/ChatModal/ChatModal';
import {useWebSocket} from '../../../../sockets/WebSocketLocal';
import {useChatModal} from '../../../../contexts/ChatModalContext';

const MyProfileTopScreen = ({userId, user, profileOwner, navigation}) => {
  const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
  const {socket, privateChatMessages, updateCurrentChatId} = useWebSocket();
  const {translations} = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [userIsLiked, setUserIsLiked] = useState(false);
  const {isChatModalVisible, chatDetails, openChatModal, closeChatModal} = useChatModal();
  const toggleModal = () => setShowModal(!showModal);
  const userPoints = calculateUserPoints(userId || profileOwner?._id);

  useEffect(() => {
    if (userId) {
      const chatId = Object.keys(privateChatMessages).find(key => key.includes(userId));
      if (chatId) {
        updateCurrentChatId(chatId);
      }
    }
  }, [userId, privateChatMessages, updateCurrentChatId]);

  const toggleLike = async () => {
    setUserIsLiked(!userIsLiked);
    if (userIsLiked) {
      deleteUserLike(userId);
    } else {
      addUserLike(userId);
    }
  };

  const recordProfileVisit = async visitedUserID => {
    try {
      const userToken = await AsyncStorage.getItem('userToken');
      const response = await fetch(`${hostname}/users/${visitedUserID}/visit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({visitorId: user?._id}),
      });

      if (!response.ok) {
        throw new Error('Failed to record profile visit');
      }

      const profileVisits = await response.json();
      console.log('Profile visit recorded:', profileVisits);

      if (socket && visitedUserID !== user?._id) {
        socket.emit('profileVisit', {visitedUserId: visitedUserID, visitorUserId: user?._id});
      }
    } catch (error) {
      console.error('Error recording profile visit:', error);
    }
  };

  useEffect(() => {
    if (socket && userId !== user?._id) {
      recordProfileVisit(userId);
    }
  }, [userId, user?._id, socket]);

  useEffect(() => {
    const getUserLikes = async currentUserId => {
      const token = await AsyncStorage.getItem('userToken');
      const userId = await AsyncStorage.getItem('userId');

      const response = await fetch(`${hostname}/userLikes`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const likeStatus = data.some(like => like.likedBy === userId && like.likedUser === currentUserId);
      setUserIsLiked(likeStatus);
    };
    getUserLikes(userId);
  }, [userId]);

  if (!profileOwner) {
    return <Text>Loading profile...</Text>;
  }

  return (
    <View style={styles.container}>
      <ImageBackground source={{uri: profileOwner?.profileImage}} style={styles.profileImage}>
        {!userIsLiked && userId !== user?._id ? (
          <Pressable onPress={toggleLike} style={styles.likeButton}>
            <Icons.HeartGrey width={40} height={40} />
          </Pressable>
        ) : null}
        {userIsLiked && userId !== user?._id ? (
          <Pressable onPress={toggleLike} style={styles.likeButton}>
            <Icons.HeartRed width={40} height={40} />
          </Pressable>
        ) : null}
      </ImageBackground>
      <View style={styles.cupIconContainer}>
        <View style={styles.cupIcon}>
          <ProfileMedal role={profileOwner?.role} points={userPoints} />
        </View>
        <Text style={styles.pointsTextBelowCup}>
          {userPoints} {translations.JsonProfilePages.JsonMyProfileScreen.JsonPoints}
        </Text>
      </View>
      <View style={styles.infosContainer}>
        <Text style={styles.info}>Organizer: ⭐⭐⭐⭐⭐</Text>
        <Text style={styles.info}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonReliability}: -------------</Text>
      </View>
      <View style={styles.infosContainer2}>
        <Text style={styles.userName}>{profileOwner?.userName}</Text>
        {profileOwner?.useMyBirthday ? (
          <Text style={styles.info2Text}>
            {new Date().getFullYear() - new Date(profileOwner?.dateOfBirth).getFullYear()}{' '}
            {translations.JsonProfilePages.JsonMyProfileScreen.JsonYears}
          </Text>
        ) : (
          <Text>secret</Text>
        )}
        <View style={styles.infosCityRow}>
          <Icons.PinBlackAndWhite style={styles.Location} width={24} height={24} />
          <Text style={styles.text}>{profileOwner?.city?.split(',')[0]}</Text>
        </View>
      </View>
      {userId !== user?._id ? (
        <View style={styles.btnsAmiContainer}>
          {profileOwner?.friends.includes(user?._id) ? null : (
            <Pressable style={[styles.actionButton, styles.addFriendButton]} onPress={toggleModal}>
              <Icons.AddFriend width={18} height={18} style={styles.buttonIcon} />
              <Text style={styles.buttonText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonAddFriend}</Text>
            </Pressable>
          )}
          <Pressable
            onPress={() => openChatModal(chatDetails.chatId, userId, profileOwner, profileOwner.name, privateChatMessages)}
            style={[styles.actionButton, styles.chatButton]}>
            <Icons.ChatColors width={18} height={18} style={styles.buttonIcon} />
            <Text style={styles.buttonText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonChat}</Text>
          </Pressable>
          {isChatModalVisible && (
            <ChatModal
              showModal={isChatModalVisible}
              toggleModal={() => closeChatModal()}
              currentUser={profileOwner}
              chatId={chatDetails.chatId}
              userID={chatDetails.userId}
              privateMessages={privateChatMessages}
            />
          )}
          <Pressable style={[styles.actionButton, styles.blockButton]}>
            <Icons.BlockFriend width={18} height={18} style={styles.buttonIcon} />
            <Text style={styles.buttonText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonBlock}</Text>
          </Pressable>
        </View>
      ) : null}
      <AddFriendModal visible={showModal} onClose={toggleModal} currentUser={profileOwner} />
    </View>
  );
};

export default MyProfileTopScreen;
