//🇫🇷 Ce fichier gère le style mobile du composant VerifyEmailScreen.js (FrameFigma007A FrameFigma007B FrameFigma007C)
//🇬🇧 This file manages the mobile style of the VerifyEmailScreen.js component (FrameFigma007A FrameFigma007B FrameFigma007C)
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  verification: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  container: {
    width: '80%',
    flex: 1,
    marginTop: '8%',
  },
  verificationText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 18.46,
    // marginBottom: 64,
  },
  opt: {
    width: 41.61,
    height: 38.56,
    backgroundColor: 'white',
    borderColor: '#000000',
    borderWidth: 1,
    borderRadius: 10,
    textAlign: 'center',
    marginHorizontal: 7,
  },
  inputs: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginVertical: '1%'
  },
  btnContainer: {
    marginHorizontal: '5%',
    alignItems: 'center',
    marginTop: 8
  },
  btnVerify: {
    backgroundColor: '#59C09B',
    width: '85%',
    padding: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginHorizontal: 50,
    alignItems: 'center',
    elevation: 5,
    marginVertical: 50,
  },
  paste: {
    width: 20,
    height: 20,
    alignSelf: 'flex-end',
    marginRight: 1,
  },
  btnText: {
    color: 'white',
    fontSize: 22,
    fontWeight: 'bold',
  },
  resend: {
    alignItems: 'center',
    marginVertical: 20,
    marginHorizontal: '6%'
  },
  resendText: {
    // fontWeight: 'bold',
    textAlign: 'center'
  },
  btnResendContainer: {
    margin: 10,
    borderWidth: 1,
    paddingVertical: 6,
    paddingHorizontal: 15,
    borderRadius: 15,
  },
  btnResend: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  btnTextResend: {
    color: '#59C09B',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  error: {
    backgroundColor: 'red', // Fond rouge pour l'erreur
    color: 'white', // Texte en blanc
    padding: 10, // Espacement interne pour mieux encadrer le texte
    borderRadius: 5, // Bords arrondis
    marginTop: 10, // Marge au-dessus pour séparation
    textAlign: 'center', // Centrage du texte
    width: '100%', // Largeur pour mieux s'adapter au conteneur
  },
});
